export const searchTags = [
    {
        "title": "Purpose",
        "suggestions": [
            { "name": "Revenue&Taxation&Fees" },
            { "name": "LandRevenue" },
            { "name": "Buildings" },
            { "name": "Betterment/DevelopmentCharge/Toll/Fees" },
            { "name": "OtherTax" },
            { "name": "WaterTax/DrainageCess" },
            { "name": "Mines&Minerals" },
            { "name": "ConversionFee" },
            { "name": "Inheritance,Succession&Partition" },
            { "name": "Partition" },
            { "name": "Inheritance/Succession/Coparcenery" },
            { "name": "Escheats&BonaVacantia" },
            { "name": "Escheats" },
            { "name": "BonaVacantia" },
            { "name": "CharitableEndowments,Trusts&Societies" },
            { "name": "CharitableEndowments" },
            { "name": "Religious&CharitableTrusts" },
            { "name": "Societies/CooperativeSocieties" },
            { "name": "LandReforms" },
            { "name": "Grants&Leases" },
            { "name": "TenancyRelatedReform" },
            { "name": "TenancyProtection" },
            { "name": "Rural" },
            { "name": "PanchayatiRaj" },
            { "name": "DevelopmentAgencies" },
            { "name": "Government/PublicPremises" },
            { "name": "EvictionOfTenants" },
            { "name": "Urban" },
            { "name": "Municipalities/MunicipalBoards/TownPlanning" },
            { "name": "Municipalities&MunicipalCorporations" },
            { "name": "TownPlanning/Improvement" },
            { "name": "Municipalities/MunicipalBoards/TownPlanning/Improvement" },
            { "name": "Exemptions" },
            { "name": "InfrastructureDevelopmentAuthorities" },
            { "name": "DevelopmentCorporations/Agencies/Authorities" },
            { "name": "RegulatoryAuthorities" },
            { "name": "LandEncroachment" },
            { "name": "AbolitionOfIntermediaries" },
            { "name": "ConsolidationOfHoldings" },
            { "name": "LandCeiling&Redistribution" },
            { "name": "InvoluntaryLandCeiling&Redistribution" },
            { "name": "VoluntaryLandCeiling&Redistribution/Bhoodan" },
            { "name": "ExemptionsFromLandCeiling&Redistribution" },
            { "name": "CommonLand" },
            { "name": "LandCooperatives" },
            { "name": "WelfareFund" },
            { "name": "AncillaryToLand" },
            { "name": "IndigoContracts" },
            { "name": "RoadRepairs" },
            { "name": "Buildings,Hotels&Lodging" },
            { "name": "Goshala" },
            { "name": "Other" },
            { "name": "LandRecords,LandRegistration&LandSurvey" },
            { "name": "Rehabilitation&Resettlement" },
            { "name": "ScheduledAreas/Tribal" },
            { "name": "ProhibitionOfLandAlienation/Transfer" },
            { "name": "AutonomousDistrictCouncil/VillageAuthorities/Panchayat" },
            { "name": "Prohibition/RestrictionOfMoneylending" },
            { "name": "DevelopmentCorporation" },
            { "name": "PreventionOfAtrocities" },
            { "name": "Expropriation" },
            { "name": "Acquisition" },
            { "name": "Land" },
            { "name": "LandReclamation/Utilisation" },
            { "name": "Reclamation" },
            { "name": "Utilisation" },
            { "name": "WasteLands" },
            { "name": "RightOfUser" },
            { "name": "OtherRights" },
            { "name": "Cultivation/Pasturing" },
            { "name": "ErectionOfABuilding" },
            { "name": "EarthCuttingQuarrying/Charcoal/Pottery/Lime&Brick-Kiln" },
            { "name": "MiningLease" },
            { "name": "Water&GasPipelines/IrrigationChannels&Drainage" },
            { "name": "Water/Irrigation" },
            { "name": "FloodControl" },
            { "name": "Minerals" },
            { "name": "Coal" },
            { "name": "NaturalGas/Petroleum/AtomicEnergy" },
            { "name": "Forests" },
            { "name": "Defence" },
            { "name": "TownPlanning" },
            { "name": "RehabilitationOfDisplacedPeople" },
            { "name": "Damaged/DisturbedAreas" },
            { "name": "Infrastructure" },
            { "name": "Slums" },
            { "name": "ArchaeologicalSite/Monument" },
            { "name": "Markets" },
            { "name": "Urban/StateCapitalDevelopment" },
            { "name": "ImmovableProperty(Undertakings/Mines)" },
            { "name": "Appropriation" },
            { "name": "Requisition" },
            { "name": "Movable&ImmovableProperty(IncludingRoads/Buildings/Hospitals&Drains)" },
            { "name": "LandCredit&Distraint" },
            { "name": "ScheduledCaste/BackwardClass" },
            { "name": "General" },
            { "name": "Tenants" },
            { "name": "Landlords" },
            { "name": "Banks&FinancialInstitutions/ProvincialOrDistrictBoards/CooperativeSocieties" },
            { "name": "Industry" },
            { "name": "LandEncroachment/Grabbing" },
            { "name": "Encroachment" },
            { "name": "Grabbing" },
            { "name": "PolicePower" },
            { "name": "UnauthorisedOccupants" },
            { "name": "TemporaryEntryIntoLandForRepairs/Survey/Inspection/Excavation" },
            { "name": "RemovalOfObstructionToEmbankments/WaterCourse/WaterSupply" },
            { "name": "UnauthorisedDevelopment/Encroachment" },
            { "name": "DisturbedAreas/DangerZones" },
            { "name": "LandImprovement" },
            { "name": "DefacementOfProperty" },
            { "name": "DestructionOfCrop" },
            { "name": "Water" },
            { "name": "Irrigation" },
            { "name": "GroundWater" },
            { "name": "DrinkingWaterSupply" },
            { "name": "WaterTax" },
            { "name": "LandUse" },
            { "name": "ProtectionOfSpecificLands" },
            { "name": "AgriculturalDevelopment" },
            { "name": "ReclamationOfLand/ConservationOfLand/IrrigationScheme/GroundWaterRecharge" },
            { "name": "UrbanDevelopment" },
            { "name": "Defence/Ports" },
            { "name": "IndustrialDevelopment" },
            { "name": "PublicUtility" },
            { "name": "ConstructionOfHighways/Roads/Tramways" },
            { "name": "RuralDevelopment" },
            { "name": "Plantation" },
            { "name": "RiotStrickenAreas/HeritageAreas" },
            { "name": "ConversionFromAgricultureToNonAgriculture" },
            { "name": "Forest" },
            { "name": "LandDevelopment/Improvement" },
            { "name": "Irrigation/Drainage/DrinkingWaterSupplySchemes" },
            { "name": "RegularAreas" },
            { "name": "TouristAreas" },
            { "name": "LandAlienation/Transfer" },
            { "name": "RegulatingAlienation/Transfer" },
            { "name": "ByIndividuals" },
            { "name": "Specific/MarginalisedGroups" },
            { "name": "HinduUndividedFamily" },
            { "name": "ScheduledTribes/ScheduledArea" },
            { "name": "Agriculturist/AgriculturalTribes/AgriculturalPurpose" },
            { "name": "ScheduledCastes" },
            { "name": "MortgagedLand/SubjectToAcquisition" },
            { "name": "ToPreventFragmentation/ToComplyWithLandCeiling/TenancyProtection/ForProperIrrigation" },
            { "name": "AssignedLands" },
            { "name": "VoidingOfBenamiTransactions" },
            { "name": "ByGovernment" },
            { "name": "Prohibiting/RestrictingTransfer" },
            { "name": "Easements" },
            { "name": "Mining/MineralDevelopment" },
            { "name": "Forest/EcologicalSite" },
            { "name": "PreservationOfForest/EcologicalSite" },
            { "name": "RightsOfSTs&ForestDwellers" },
            { "name": "DecentralisedGovernance" },
            { "name": "CulturalProperty" },
            { "name": "Archaelogical" },
            { "name": "Religious" },
            { "name": "CourtOfWards" },
            { "name": "LandGrant/LeaseToIndustry" },
            { "name": "LandDisputesResolution&Tribunals" },
            { "name": "EvacueeProperty" },
            { "name": "Regularisation/RemovalOfUnauthorisedDevelopment" },
            { "name": "Removal" },
            { "name": "Regularisation" },
            { "name": "HousingBoards" },
            { "name": "EnemyProperty" },
            { "name": "AllotmentOfGovernmentLands" },
            { "name": "Territory/Reorganisation" }
        ]
    },
    {
        "title": "Acts",
        "suggestions": [
            {
                "id": 0,
                "CitationOfAct": "1793BE02",
                "name": "Bengal Land-Revenue Regulation, 1793",
                "YearOfEnactment": 1793
            },
            {
                "id": 1,
                "CitationOfAct": "1793BE08",
                "name": "Bengal Decennial Settlement Regulation, 1793",
                "YearOfEnactment": 1793
            },
            {
                "id": 2,
                "CitationOfAct": "1793BE11",
                "name": "Bengal Inheritance Regulation, 1793",
                "YearOfEnactment": 1793
            },
            {
                "id": 3,
                "CitationOfAct": "1800BE10",
                "name": "Bengal Inheritance Regulation, 1800",
                "YearOfEnactment": 1800
            },
            {
                "id": 4,
                "CitationOfAct": "1810BE19",
                "name": "Bengal Charitable Endowments, Public Buildings and Escheats Regulation, 1810",
                "YearOfEnactment": 1810
            },
            {
                "id": 5,
                "CitationOfAct": "1812BE05",
                "name": "Bengal Land-Revenue Sales Regulation, 1812",
                "YearOfEnactment": 1812
            },
            {
                "id": 6,
                "CitationOfAct": "1812BE18",
                "name": "Bengal Leases and Land-Revenue Regulation, 1812",
                "YearOfEnactment": 1812
            },
            {
                "id": 7,
                "CitationOfAct": "1821BE04",
                "name": "Bengal Land-Revenue (Assistant Collectors) Regulation, 1821",
                "YearOfEnactment": 1821
            },
            {
                "id": 8,
                "CitationOfAct": "1823BE06",
                "name": "Bengal Indigo Contracts Regulation, 1823",
                "YearOfEnactment": 1823
            },
            {
                "id": 9,
                "CitationOfAct": "1825BE09",
                "name": "Bengal Land-Revenue Settlement Regulation, 1825",
                "YearOfEnactment": 1825
            },
            {
                "id": 10,
                "CitationOfAct": "1825BE13 ",
                "name": "Bengal Land-Revenue Settlement (Resumed Kanungos and Revenue-Free Lands) Regulation, 1825",
                "YearOfEnactment": 1825
            },
            {
                "id": 11,
                "CitationOfAct": "1828BE03",
                "name": "Bengal Land-Revenue Assessment (Resumed Lands) Regulation, 1828",
                "YearOfEnactment": 1828
            },
            {
                "id": 12,
                "CitationOfAct": "1828BE04",
                "name": "Bengal Land Revenue Settlement Regulation, 1828 ",
                "YearOfEnactment": 1828
            },
            {
                "id": 13,
                "CitationOfAct": "1839AP07",
                "name": "Andhra Pradesh Rent and Revenue Sales Act, 1839",
                "YearOfEnactment": 1839
            },
            {
                "id": 14,
                "CitationOfAct": "1841BE12",
                "name": "Bengal Land-Revenue Sales Act, 1841",
                "YearOfEnactment": 1841
            },
            {
                "id": 15,
                "CitationOfAct": "1847BE09",
                "name": "Bengal Alluvion and Diluvion Act, 1847",
                "YearOfEnactment": 1847
            },
            {
                "id": 16,
                "CitationOfAct": "1848GOI20",
                "name": "Bengal Land-Holders' Attendance Act, 1848",
                "YearOfEnactment": 1848
            },
            {
                "id": 17,
                "CitationOfAct": "1852GOI11",
                "name": "Bombay Rent-Free Estates Act, 1852",
                "YearOfEnactment": 1852
            },
            {
                "id": 18,
                "CitationOfAct": "1855JH37",
                "name": "Sonthal Parganas Act, 1855",
                "YearOfEnactment": 1855
            },
            {
                "id": 19,
                "CitationOfAct": "1856GOI18",
                "name": "Calcutta Land-Revenue Act, 1856",
                "YearOfEnactment": 1856
            },
            {
                "id": 20,
                "CitationOfAct": "1858BE31",
                "name": "Bengal Alluvial Land Settlement Act, 1858",
                "YearOfEnactment": 1858
            },
            {
                "id": 21,
                "CitationOfAct": "1859BE11",
                "name": "Bengal Land-Revenue Sales Act, 1859",
                "YearOfEnactment": 1859
            },
            {
                "id": 22,
                "CitationOfAct": "1859GOI05",
                "name": "Bengal Ghatwali Lands Act, 1859",
                "YearOfEnactment": 1859
            },
            {
                "id": 23,
                "CitationOfAct": "1862BE07",
                "name": "Bengal Land-Revenue Resumption Act, 1862",
                "YearOfEnactment": 1862
            },
            {
                "id": 24,
                "CitationOfAct": "1863GOI23",
                "name": "Waste Lands (Claims) Act, 1863",
                "YearOfEnactment": 1863
            },
            {
                "id": 25,
                "CitationOfAct": "1864AP02",
                "name": "Andhra Pradesh Revenue Recovery Act, 1864  ",
                "YearOfEnactment": 1864
            },
            {
                "id": 26,
                "CitationOfAct": "1869BE02",
                "name": "Chota Nagpur Tenures Act, 1869",
                "YearOfEnactment": 1869
            },
            {
                "id": 27,
                "CitationOfAct": "1871GOI01",
                "name": "Cattle-trespass Act, 1871",
                "YearOfEnactment": 1871
            },
            {
                "id": 28,
                "CitationOfAct": "1873GOI08",
                "name": "Northern India Canal and Drainage Act, 1873",
                "YearOfEnactment": 1873
            },
            {
                "id": 29,
                "CitationOfAct": "1876BE03",
                "name": "The Bengal Irrigation Act, 1876",
                "YearOfEnactment": 1876
            },
            {
                "id": 30,
                "CitationOfAct": "1876BE07",
                "name": "Land Registration Act, 1876",
                "YearOfEnactment": 1876
            },
            {
                "id": 31,
                "CitationOfAct": "1876MAD01",
                "name": "Andhra Pradesh (Andhra Area) Land Revenue Assessment Act, 1876",
                "YearOfEnactment": 1876
            },
            {
                "id": 32,
                "CitationOfAct": "1876UP(OUDH)18",
                "name": "Oudh Laws Act, 1876",
                "YearOfEnactment": 1876
            },
            {
                "id": 33,
                "CitationOfAct": "1879BE09",
                "name": "Court of Wards Act, 1879",
                "YearOfEnactment": 1879
            },
            {
                "id": 34,
                "CitationOfAct": "1879GJ(BOM)05",
                "name": "Gujarat Land Revenue Code, 1879",
                "YearOfEnactment": 1879
            },
            {
                "id": 35,
                "CitationOfAct": "1881GOI18",
                "name": "Central Provinces Land-Revenue Act, 1881",
                "YearOfEnactment": 1881
            },
            {
                "id": 36,
                "CitationOfAct": "1882BE02",
                "name": "The Bengal Embankment Act, 1882",
                "YearOfEnactment": 1882
            },
            {
                "id": 37,
                "CitationOfAct": "1882GOI04",
                "name": "Transfer of Property Act, 1882",
                "YearOfEnactment": 1882
            },
            {
                "id": 38,
                "CitationOfAct": "1882GOI05",
                "name": "Indian Easements Act, 1882",
                "YearOfEnactment": 1882
            },
            {
                "id": 39,
                "CitationOfAct": "1883GOI19",
                "name": "Land Improvement Loans Act, 1883",
                "YearOfEnactment": 1883
            },
            {
                "id": 40,
                "CitationOfAct": "1884GOI12",
                "name": "Agriculturists' Loans Act, 1884",
                "YearOfEnactment": 1884
            },
            {
                "id": 41,
                "CitationOfAct": "1885BH08",
                "name": "Bihar Tenancy Act, 1885",
                "YearOfEnactment": 1885
            },
            {
                "id": 42,
                "CitationOfAct": "1885GOI08",
                "name": "Bengal Tenancy Act, 1885",
                "YearOfEnactment": 1885
            },
            {
                "id": 43,
                "CitationOfAct": "1885GOI18",
                "name": "Land Acquisition (Mines) Act, 1885",
                "YearOfEnactment": 1885
            },
            {
                "id": 44,
                "CitationOfAct": "1886AS01",
                "name": "Assam Land and Revenue Regulation, 1886",
                "YearOfEnactment": 1886
            },
            {
                "id": 45,
                "CitationOfAct": "1887PN16",
                "name": "Punjab Tenancy Act, 1887",
                "YearOfEnactment": 1887
            },
            {
                "id": 46,
                "CitationOfAct": "1890AP02",
                "name": "Andhra Pradesh (Andhra area) Canals and Public Ferries Act, 1890",
                "YearOfEnactment": 1890
            },
            {
                "id": 47,
                "CitationOfAct": "1890GOI01",
                "name": "Revenue Recovery Act, 1890",
                "YearOfEnactment": 1890
            },
            {
                "id": 48,
                "CitationOfAct": "1890GOI06",
                "name": "Charitable Endowments Act, 1890",
                "YearOfEnactment": 1890
            },
            {
                "id": 49,
                "CitationOfAct": "1891AS07",
                "name": "Assam Forest Regulation, 1891",
                "YearOfEnactment": 1891
            },
            {
                "id": 50,
                "CitationOfAct": "1893GOI04",
                "name": "Partition Act, 1893",
                "YearOfEnactment": 1893
            },
            {
                "id": 51,
                "CitationOfAct": "1895BE08",
                "name": "Bengal Land Records Maintenance Act, 1895",
                "YearOfEnactment": 1895
            },
            {
                "id": 52,
                "CitationOfAct": "1897BE05",
                "name": "Estates Partition Act, 1897",
                "YearOfEnactment": 1897
            },
            {
                "id": 53,
                "CitationOfAct": "1899GOI04",
                "name": "Government Buildings Act, 1899",
                "YearOfEnactment": 1899
            },
            {
                "id": 54,
                "CitationOfAct": "1900PN02",
                "name": "Punjab Land Preservation Act, 1900",
                "YearOfEnactment": 1900
            },
            {
                "id": 55,
                "CitationOfAct": "1903GOI07",
                "name": "Works of Defence Act, 1903",
                "YearOfEnactment": 1903
            },
            {
                "id": 56,
                "CitationOfAct": "1904GOI07",
                "name": "Ancient Monuments Preservation Act, 1904",
                "YearOfEnactment": 1904
            },
            {
                "id": 57,
                "CitationOfAct": "1904AP11",
                "name": "Andhra Pradesh (Andhra Area) Impartible Estates Act, 1904",
                "YearOfEnactment": 1904
            },
            {
                "id": 58,
                "CitationOfAct": "1905MAD03",
                "name": "Andhra Pradesh Land Encroachment Act, 1905",
                "YearOfEnactment": 1905
            },
            {
                "id": 59,
                "CitationOfAct": "1907AP08",
                "name": "Land Revenue Act of His Exalted Highness The Nizam's Dominions, [1317 (Fasli)] 1907",
                "YearOfEnactment": 1907
            },
            {
                "id": 60,
                "CitationOfAct": "1908AP01",
                "name": "Andhra Pradesh (Andhra Area) Estates Land Act, 1908",
                "YearOfEnactment": 1908
            },
            {
                "id": 61,
                "CitationOfAct": "1908BH06",
                "name": "Chota Nagpur Tenancy Act (Bihar), 1908",
                "YearOfEnactment": 1908
            },
            {
                "id": 62,
                "CitationOfAct": "1908GOI16",
                "name": "Registration Act, 1908",
                "YearOfEnactment": 1908
            },
            {
                "id": 63,
                "CitationOfAct": "1908JH06",
                "name": "Chota Nagpur Tenancy Act (Jharkhand), 1908",
                "YearOfEnactment": 1908
            },
            {
                "id": 64,
                "CitationOfAct": "1912PN05",
                "name": "Colonization of Government Lands (Punjab) Act, 1912",
                "YearOfEnactment": 1912
            },
            {
                "id": 65,
                "CitationOfAct": "1913BHOR01",
                "name": "Bihar and Orissa Board of Revenue Act, 1913",
                "YearOfEnactment": 1913
            },
            {
                "id": 66,
                "CitationOfAct": "1913PN03",
                "name": "Punjab Forest (Sale of Timber) Act, 1913",
                "YearOfEnactment": 1913
            },
            {
                "id": 67,
                "CitationOfAct": "1914AP01",
                "name": "Andhra Pradesh (Andhra Area) Hindu Transfers and Bequests Act, 1914",
                "YearOfEnactment": 1914
            },
            {
                "id": 68,
                "CitationOfAct": "1916GOI15",
                "name": "Hindu Disposition of Property Act, 1916",
                "YearOfEnactment": 1916
            },
            {
                "id": 69,
                "CitationOfAct": "1917MAD08",
                "name": "Andhra Pradesh (Telangana Area) Land Revenue Act, 1317F",
                "YearOfEnactment": 1917
            },
            {
                "id": 70,
                "CitationOfAct": "1918BHOR01",
                "name": "Champaran Agrarian Act, 1918",
                "YearOfEnactment": 1918
            },
            {
                "id": 71,
                "CitationOfAct": "1920BHOR04",
                "name": "Bihar and Orissa Mining Settlements Act, 1920",
                "YearOfEnactment": 1920
            },
            {
                "id": 72,
                "CitationOfAct": "1920MAD07",
                "name": "Andhra Pradesh Town Planning Act, 1920",
                "YearOfEnactment": 1920
            },
            {
                "id": 73,
                "CitationOfAct": "1922PN04",
                "name": "Punjab Town Improvement Act, 1922",
                "YearOfEnactment": 1922
            },
            {
                "id": 74,
                "CitationOfAct": "1923GOI06",
                "name": "Cantonments (House-Accomodation) Act, 1923",
                "YearOfEnactment": 1923
            },
            {
                "id": 75,
                "CitationOfAct": "1925GOI39",
                "name": "Indian Succession Act, 1925",
                "YearOfEnactment": 1925
            },
            {
                "id": 76,
                "CitationOfAct": "1926BHOR03",
                "name": "Bihar and Orissa Highways Act, 1926",
                "YearOfEnactment": 1926
            },
            {
                "id": 77,
                "CitationOfAct": "1927GOI26",
                "name": "Indian Forest Act, 1927",
                "YearOfEnactment": 1927
            },
            {
                "id": 78,
                "CitationOfAct": "1929BHOR01",
                "name": "Chota Nagpur Tenure Holders\u2019 Rent Account Act, 1929",
                "YearOfEnactment": 1929
            },
            {
                "id": 79,
                "CitationOfAct": "1930GOI30",
                "name": "Hindu Gains of Learning Act, 1930",
                "YearOfEnactment": 1930
            },
            {
                "id": 80,
                "CitationOfAct": "1931AS02",
                "name": "Bijni Succession Act, 1931",
                "YearOfEnactment": 1931
            },
            {
                "id": 81,
                "CitationOfAct": "1934BH04",
                "name": "Darbhanga Improvement Act, 1934",
                "YearOfEnactment": 1934
            },
            {
                "id": 82,
                "CitationOfAct": "1935BH06",
                "name": "Bihar Co-operative Societies Act, 1935",
                "YearOfEnactment": 1935
            },
            {
                "id": 83,
                "CitationOfAct": "1935BHOR06",
                "name": "Bihar and Orissa Co-operative Societies Act, 1935",
                "YearOfEnactment": 1935
            },
            {
                "id": 84,
                "CitationOfAct": "1935PN05",
                "name": "Punjab State Aid to Industries Act, 1935",
                "YearOfEnactment": 1935
            },
            {
                "id": 85,
                "CitationOfAct": "1936AS08",
                "name": "Assam Land Revenue Re-assessment Act, 1936",
                "YearOfEnactment": 1936
            },
            {
                "id": 86,
                "CitationOfAct": "1938GOI04",
                "name": "Insurance Act, 1938",
                "YearOfEnactment": 1938
            },
            {
                "id": 87,
                "CitationOfAct": "1938GOI05",
                "name": "Manoeuvres, Field Firing and Artillery Practice Act, 1938",
                "YearOfEnactment": 1938
            },
            {
                "id": 88,
                "CitationOfAct": "1939BH13",
                "name": "Kosi Diara (Reduction of Settled Rents) Act, 1939",
                "YearOfEnactment": 1939
            },
            {
                "id": 89,
                "CitationOfAct": "1940PN17",
                "name": "Punjab Urban Immovable Property Tax Act, 1940",
                "YearOfEnactment": 1940
            },
            {
                "id": 90,
                "CitationOfAct": "1941GOI12",
                "name": "Delhi Restriction of Uses of Land Act, 1941",
                "YearOfEnactment": 1941
            },
            {
                "id": 91,
                "CitationOfAct": "1941PN05",
                "name": "Punjab Jagirs Act, 1941  ",
                "YearOfEnactment": 1941
            },
            {
                "id": 92,
                "CitationOfAct": "1942GJ(BOM)28",
                "name": "Gujarat Land Improvement Schemes Act, 1942",
                "YearOfEnactment": 1942
            },
            {
                "id": 93,
                "CitationOfAct": "1946BH16",
                "name": "Bihar Waste Lands (Reclamation, Cultivation and Improvement) Act, 1946",
                "YearOfEnactment": 1946
            },
            {
                "id": 94,
                "CitationOfAct": "1947BH13",
                "name": "Bihar Bakasht Disputes Settlement Act, 1947",
                "YearOfEnactment": 1947
            },
            {
                "id": 95,
                "CitationOfAct": "1947GJ(BOM)57",
                "name": "Bombay Rents, Hotel and Lodging House Rates Control Act, 1947",
                "YearOfEnactment": 1948
            },
            {
                "id": 96,
                "CitationOfAct": "1947GJ(BOM)62",
                "name": "Bombay Prevention of Fragmentation and Consolidation of Holdings Act, 1947",
                "YearOfEnactment": 1947
            },
            {
                "id": 97,
                "CitationOfAct": "1947GOI24",
                "name": "Rubber Act, 1947",
                "YearOfEnactment": 1947
            },
            {
                "id": 98,
                "CitationOfAct": "1947PN06",
                "name": "Punjab Urban Rent Restriction Act, 1947",
                "YearOfEnactment": 1947
            },
            {
                "id": 99,
                "CitationOfAct": "1948AP26",
                "name": "Andhra Pradesh (Andhra Area) Estates (Abolition and Conversion into Ryotwari) Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 100,
                "CitationOfAct": "1948AP58",
                "name": "Hyderabad Record of Rights in Land Regulation, [1358 (Fasli)] 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 101,
                "CitationOfAct": "1948AS12",
                "name": "The Assam Adhiars Protection and Regulation Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 102,
                "CitationOfAct": "1948AS24",
                "name": "The Assam Assessment of Revenue Free Waste Land Grants Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 103,
                "CitationOfAct": "1948BH01",
                "name": "Ranchi District [Tana Bhagat] Raiyats' Agricultural Lands Restoration Act, 1947",
                "YearOfEnactment": 1948
            },
            {
                "id": 104,
                "CitationOfAct": "1948BH04",
                "name": "Bihar Privileged Persons Homestead Tenancy Act, 1947",
                "YearOfEnactment": 1948
            },
            {
                "id": 105,
                "CitationOfAct": "1948BH06",
                "name": "Bihar Provincialisation of Roads and Hospitals Act, 1947",
                "YearOfEnactment": 1948
            },
            {
                "id": 106,
                "CitationOfAct": "1948BH09",
                "name": "Bihar Private Forest Act, 1947",
                "YearOfEnactment": 1948
            },
            {
                "id": 107,
                "CitationOfAct": "1948GJ(BOM)31",
                "name": "Gujarat Building (Control on Erection, Re-Erection and Conversion) Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 108,
                "CitationOfAct": "1948GJ(BOM)33",
                "name": "Bombay Land Requisition Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 109,
                "CitationOfAct": "1948GJ(BOM)67",
                "name": "Gujarat Tenancy and Agricultural Lands Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 110,
                "CitationOfAct": "1948GOI60",
                "name": "Resettlement of Displaced Persons (Land Acquisition) Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 111,
                "CitationOfAct": "1948GOI66",
                "name": "Delhi and Ajmer-Merwara Land Development Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 112,
                "CitationOfAct": "1948JH01",
                "name": "Ranchi District Tana Bhagat Raiyats' Agricultural Lands Restoration Act, 1947",
                "YearOfEnactment": 1948
            },
            {
                "id": 113,
                "CitationOfAct": "1948PN24",
                "name": "East Punjab Enumeration of Dwellings Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 114,
                "CitationOfAct": "1949AP14",
                "name": "Andhra Pradesh (Andhra Area) Aided Institutions (Prohibition of Transfers of Property) Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 115,
                "CitationOfAct": "1949AP22",
                "name": "Andhra Pradesh (Andhra Area) Land Improvement Schemes (Contour Building and Contour Trenching) Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 116,
                "CitationOfAct": "1949GJ(BOM)59",
                "name": "Bombay Provincial Municipal Corporations Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 117,
                "CitationOfAct": "1949GOI02",
                "name": "Mangrol and Manavadar (Administration of Property) Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 118,
                "CitationOfAct": "1949GOI22",
                "name": "Payment of Taxes (Transfer of Property) Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 119,
                "CitationOfAct": "1949PN03",
                "name": "East Punjab Urban Rent Restriction Act, 1949 ",
                "YearOfEnactment": 1949
            },
            {
                "id": 120,
                "CitationOfAct": "1949PN10",
                "name": "East Punjab Damaged Areas Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 121,
                "CitationOfAct": "1949PN36",
                "name": "East Punjab Displaced Persons (Land Re-settlement) Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 122,
                "CitationOfAct": "1949PN38",
                "name": "East Punjab Utilization of Lands Act, 1949",
                "YearOfEnactment": 1949
            },
            {
                "id": 123,
                "CitationOfAct": "1950AP21",
                "name": "Andhra Pradesh (Telangana Area) Tenancy and Agricultural Lands Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 124,
                "CitationOfAct": "1950AS14",
                "name": "Assam Land Revenue, Rent and Cess (Apportionment) Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 125,
                "CitationOfAct": "1950BH28",
                "name": "Bihar Goshala Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 126,
                "CitationOfAct": "1950BH30",
                "name": "Bihar Land Reforms Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 127,
                "CitationOfAct": "1950BH38",
                "name": "Bihar Displaced Persons Rehabilitation (Acquisition of Land) Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 128,
                "CitationOfAct": "1950GOI42",
                "name": "Ajmer Tenancy and Land Records Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 129,
                "CitationOfAct": "1950PN06",
                "name": "Punjab Abolition of Village Cess (Kuri Kamini) Act, 1950",
                "YearOfEnactment": 1950
            },
            {
                "id": 130,
                "CitationOfAct": "1951AS11",
                "name": "The Assam Evacuee Property Act, 1951",
                "YearOfEnactment": 1951
            },
            {
                "id": 131,
                "CitationOfAct": "1951BH01",
                "name": "Bihar Hindu Religious Trusts Act, 1950",
                "YearOfEnactment": 1951
            },
            {
                "id": 132,
                "CitationOfAct": "1951BH30",
                "name": "Kosi Area (Restoration of Lands to Raiyats) Act, 1951",
                "YearOfEnactment": 1951
            },
            {
                "id": 133,
                "CitationOfAct": "1951PN10",
                "name": "Punjab Development of Damaged Areas Act, 1951",
                "YearOfEnactment": 1951
            },
            {
                "id": 134,
                "CitationOfAct": "1952AP12",
                "name": "Andhra Pradesh (Telangana Area) Jagirdars Debt Settlement Act, 1952",
                "YearOfEnactment": 1952
            },
            {
                "id": 135,
                "CitationOfAct": "1952AP32",
                "name": "Andhra Pradesh (Telangana Area) Land (Special Assessment) Act, 1952",
                "YearOfEnactment": 1952
            },
            {
                "id": 136,
                "CitationOfAct": "1952GJ03",
                "name": "Saurashtra Estate Acquisition Act, 1952",
                "YearOfEnactment": 1952
            },
            {
                "id": 137,
                "CitationOfAct": "1952GOI30",
                "name": "Requisitioning and Acquisition of Immovable Property Act, 1952",
                "YearOfEnactment": 1952
            },
            {
                "id": 138,
                "CitationOfAct": "1952GOI38",
                "name": "Delhi and Ajmer Rent Control Act, 1952",
                "YearOfEnactment": 1952
            },
            {
                "id": 139,
                "CitationOfAct": "1952PN27",
                "name": "Capital of Punjab (Development and Regulation) Act, 1952",
                "YearOfEnactment": 1952
            },
            {
                "id": 140,
                "CitationOfAct": "1953AS23",
                "name": "Assam Land Acquisition (Dibrugarh) Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 141,
                "CitationOfAct": "1953BOM39",
                "name": "Bombay Merged Territories and Areas (Jagirs Abolition) Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 142,
                "CitationOfAct": "1953BOM70",
                "name": "Bombay Service Inams (Useful to Community) Abolition Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 143,
                "CitationOfAct": "1953GJ(BOM)50",
                "name": "Bombay Land Tenures Abolition (Recovery of Records) Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 144,
                "CitationOfAct": "1953GOI29",
                "name": "Tea Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 145,
                "CitationOfAct": "1953PN01",
                "name": "Punjab New Capital (Periphery) Control Act, 1952",
                "YearOfEnactment": 1953
            },
            {
                "id": 146,
                "CitationOfAct": "1953PN02",
                "name": "Punjab Acreage Rates Act, 1952",
                "YearOfEnactment": 1953
            },
            {
                "id": 147,
                "CitationOfAct": "1953PN08",
                "name": "Punjab Occupancy Tenants (Vesting of Proprietary Rights) Act, 1952",
                "YearOfEnactment": 1953
            },
            {
                "id": 148,
                "CitationOfAct": "1953PN09",
                "name": "Punjab Abolition of Ala Malikiyat and Talukadari Rights Act, 1952",
                "YearOfEnactment": 1953
            },
            {
                "id": 149,
                "CitationOfAct": "1953PN10",
                "name": "Punjab Security of Land Tenures Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 150,
                "CitationOfAct": "1953PN11",
                "name": "Punjab Requisitioning and Acquisition of Immovable Property Act, 1953",
                "YearOfEnactment": 1953
            },
            {
                "id": 151,
                "CitationOfAct": "1954AP08",
                "name": "Andhra Pradesh (Prevention of Speculation in Immovable Property) Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 152,
                "CitationOfAct": "1954AP11",
                "name": "Andhra Requisitoning of Buildings Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 153,
                "CitationOfAct": "1954AP12",
                "name": "Andhra Pradesh (Andhra Area) Preservation of Private Forests Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 154,
                "CitationOfAct": "1954AS01",
                "name": "Assam Embankment and Drainage Act, 1953",
                "YearOfEnactment": 1954
            },
            {
                "id": 155,
                "CitationOfAct": "1954BH22",
                "name": "Bihar Bhoodan Yagna Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 156,
                "CitationOfAct": "1954DL08",
                "name": "The Delhi Land Reforms Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 157,
                "CitationOfAct": "1954JH22",
                "name": "Jharkhand Bhoodan Yagna Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 158,
                "CitationOfAct": "1954PN17",
                "name": "Pepsu Abolition of Ala Malikiyat Rights Act, 1954 ",
                "YearOfEnactment": 1954
            },
            {
                "id": 159,
                "CitationOfAct": "1954PN18",
                "name": "Pepsu Occupancy Tenants (Vesting of Proprietary Rights) Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 160,
                "CitationOfAct": "1954PN36",
                "name": "Punjab Land Revenue (Surcharge) Act, 1954",
                "YearOfEnactment": 1954
            },
            {
                "id": 161,
                "CitationOfAct": "1955AP16",
                "name": "Andhra Absorbed Enclaves (Hyderabad Jagirdars Commutation Sum and Allowance) Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 162,
                "CitationOfAct": "1955AP17",
                "name": "Andhra Pradesh (Andhra Area) Inams (Assessment) Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 163,
                "CitationOfAct": "1955AS06",
                "name": "Assam Acquisition of Land for Flood Control and Prevention of Erosion Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 164,
                "CitationOfAct": "1955AS12",
                "name": "Assam Non-Agricultural Urban Areas Tenancy Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 165,
                "CitationOfAct": "1955AS19",
                "name": "Assam Disturbed Areas Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 166,
                "CitationOfAct": "1955BH02",
                "name": "Bihar Disqualified Owners' (Management of Property) Act, 1952",
                "YearOfEnactment": 1955
            },
            {
                "id": 167,
                "CitationOfAct": "1955BH17",
                "name": "Bihar Displaced Persons Government Premises (Rent Recovery and Eviction) Act, 1954",
                "YearOfEnactment": 1955
            },
            {
                "id": 168,
                "CitationOfAct": "1955BH22",
                "name": "Bihar Emergency Cultivation and Irrigation Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 169,
                "CitationOfAct": "1955GJ(BOM)55",
                "name": "Gujarat Highways Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 170,
                "CitationOfAct": "1955PN02",
                "name": "Pepsu Townships Development Board Act, 1954",
                "YearOfEnactment": 1955
            },
            {
                "id": 171,
                "CitationOfAct": "1955PN13",
                "name": "Pepsu Tenancy and Agricultural Lands Act, 1955",
                "YearOfEnactment": 1955
            },
            {
                "id": 172,
                "CitationOfAct": "1956AP18",
                "name": "Andhra Pradesh (Andhra Area) Tenancy Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 173,
                "CitationOfAct": "1956AP29",
                "name": "Andhra Pradesh (Andhra Area) Land Revenue Assessments (Standardisation) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 174,
                "CitationOfAct": "1956AP32",
                "name": "Nagarjuna Sagar Project (Acquisition of Land) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 175,
                "CitationOfAct": "1956AP37",
                "name": "Andhra Pradesh (Andhra Area) Inams (Abolition and Conversion into Ryotwari) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 176,
                "CitationOfAct": "1956AP44",
                "name": "Andhra Pradesh (Telangana Area) Mining Settlements Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 177,
                "CitationOfAct": "1956AP46",
                "name": "Andhra Pradesh Housing Board Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 178,
                "CitationOfAct": "1956BH14",
                "name": "Bihar Khadi and Village Industries Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 179,
                "CitationOfAct": "1956BH15",
                "name": "Bihar Public Land Encroachment Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 180,
                "CitationOfAct": "1956BH20",
                "name": "Bihar Government Premises (Allotment, Rent, Recovery and Eviction) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 181,
                "CitationOfAct": "1956BH24",
                "name": "Bihar State Aid to Industries Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 182,
                "CitationOfAct": "1956BH32",
                "name": "Bihar Consolidation of Holdings and Prevention of Fragmentation Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 183,
                "CitationOfAct": "1956GJ03",
                "name": "Bombay Aerial Ropeways Act, 1955",
                "YearOfEnactment": 1956
            },
            {
                "id": 184,
                "CitationOfAct": "1956GOI30",
                "name": "Hindu Succession Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 185,
                "CitationOfAct": "1956GOI48",
                "name": "National Highways Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 186,
                "CitationOfAct": "1956GOI80",
                "name": "Manipur (Village Authorities in Hill Areas) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 187,
                "CitationOfAct": "1956GOI90",
                "name": "Faridabad Development Corporation Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 188,
                "CitationOfAct": "1956GOI96",
                "name": "Slum Areas (Improvement and Clearance) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 189,
                "CitationOfAct": "1956JH20",
                "name": "Jharkhand Government Premises (Allotment, Rent, Recovery and Eviction) Act, 2002",
                "YearOfEnactment": 1956
            },
            {
                "id": 190,
                "CitationOfAct": "1956PN06",
                "name": "Punjab Land Revenue (Special Assessments) Act, 1955",
                "YearOfEnactment": 1956
            },
            {
                "id": 191,
                "CitationOfAct": "1956PN13",
                "name": "Punjab Revenue Appeals and Proceedings (Disposal and Restoration) Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 192,
                "CitationOfAct": "1956PN16",
                "name": "Punjab Industrial Housing Act, 1956",
                "YearOfEnactment": 1956
            },
            {
                "id": 193,
                "CitationOfAct": "1956PN45",
                "name": "Punjab Bhudan Yagna Act, 1955",
                "YearOfEnactment": 1956
            },
            {
                "id": 194,
                "CitationOfAct": "1957AP19",
                "name": "Andhra Pradesh Commercial Crops (Assessment) Act, 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 195,
                "CitationOfAct": "1957AP20",
                "name": "Andhra Pradesh Land Revenue (Surcharge Act), 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 196,
                "CitationOfAct": "1957AS01",
                "name": "Assam Fixation of Ceiling on Land Holdings Act, 1956",
                "YearOfEnactment": 1957
            },
            {
                "id": 197,
                "CitationOfAct": "1957GOI27",
                "name": "Wealth-tax Act, 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 198,
                "CitationOfAct": "1957GOI67",
                "name": "Mines and Minerals (Development and Regulation) Act, 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 199,
                "CitationOfAct": "1957PN26",
                "name": "Punjab Co-operative Land Mortgage Banks Act, 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 200,
                "CitationOfAct": "1957PN39",
                "name": "Punjab Resumption of Jagirs Act, 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 201,
                "CitationOfAct": "1958GJ56",
                "name": "Bombay Requisitioned Property (Continuance of Powers) (Saurashtra Area) Act, 1958",
                "YearOfEnactment": 1958
            },
            {
                "id": 202,
                "CitationOfAct": "1958GOI24",
                "name": "Ancient Monuments and Archaeological Sites and Remains Act, 1958",
                "YearOfEnactment": 1958
            },
            {
                "id": 203,
                "CitationOfAct": "1958PN06",
                "name": "Punjab Land Revenue (Special Charges) Act, 1958",
                "YearOfEnactment": 1958
            },
            {
                "id": 204,
                "CitationOfAct": "1958PN11",
                "name": "East Punjab Distressed Persons (Facilities for Loans) Act, 1958",
                "YearOfEnactment": 1958
            },
            {
                "id": 205,
                "CitationOfAct": "1958PN20",
                "name": "Nangal Township (Periphery) Control Act, 1958",
                "YearOfEnactment": 1958
            },
            {
                "id": 206,
                "CitationOfAct": "1959AP01",
                "name": "Andhra Pradesh Scheduled Areas Land Transfer Regulation, 1959",
                "YearOfEnactment": 1959
            },
            {
                "id": 207,
                "CitationOfAct": "1959AP35",
                "name": "Andhra Pradesh Panchayat Samithis and Zilla Parishads Act, 1959",
                "YearOfEnactment": 1959
            },
            {
                "id": 208,
                "CitationOfAct": "1959AS25",
                "name": "The Assam Ancient Monuments and Records Act, 1959",
                "YearOfEnactment": 1959
            },
            {
                "id": 209,
                "CitationOfAct": "1959BH28",
                "name": "Bihar Irrigation and Flood Protection (Betterment Contribution) Act, 1959",
                "YearOfEnactment": 1959
            },
            {
                "id": 210,
                "CitationOfAct": "1959BOM35",
                "name": "Bombay Bandhijama, Udhad and Ugadia Tenures Abolition Act, 1959",
                "YearOfEnactment": 1959
            },
            {
                "id": 211,
                "CitationOfAct": "1959PN21",
                "name": "Punjab Reclamation of Land Act, 1959",
                "YearOfEnactment": 1959
            },
            {
                "id": 212,
                "CitationOfAct": "1960AP01",
                "name": "Andhra Pradesh Scheduled Areas Money-Lenders Regulation, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 213,
                "CitationOfAct": "1960AP07",
                "name": "Andhra Pradesh Ancient and Historical Monuments and Archaeological Sites and Remains Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 214,
                "CitationOfAct": "1960AP15",
                "name": "Andhra Pradesh Buildings (Lease, Rent and Eviction) Control Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 215,
                "CitationOfAct": "1960AS02",
                "name": "Assam Town and Country Planning Act, 1959",
                "YearOfEnactment": 1960
            },
            {
                "id": 216,
                "CitationOfAct": "1960GJ11",
                "name": "Gujarat Evacuee Interest (Separation) Supplementary Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 217,
                "CitationOfAct": "1960GOI24",
                "name": "Delhi Land Holdings (Ceiling) Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 218,
                "CitationOfAct": "1960GOI33",
                "name": "Manipur Land Revenue and Land Reforms Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 219,
                "CitationOfAct": "1960GOI43",
                "name": "Tripura Land Revenue and Land Reforms Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 220,
                "CitationOfAct": "1960JH16",
                "name": "Jharkhand Agricultural Produce Markets Act, 2000",
                "YearOfEnactment": 1960
            },
            {
                "id": 221,
                "CitationOfAct": "1960PN02",
                "name": "Punjab New Mandi Townships (Development and Regulation) Act, 1960 ",
                "YearOfEnactment": 1960
            },
            {
                "id": 222,
                "CitationOfAct": "1960PN38",
                "name": "Punjab Land Revenue (Additional Surcharge) Act, 1960",
                "YearOfEnactment": 1960
            },
            {
                "id": 223,
                "CitationOfAct": "1961AP10",
                "name": "Andhra Pradesh Ceiling on Agricultural Holdings Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 224,
                "CitationOfAct": "1961AP36",
                "name": "Andhra Pradesh (Telangana Area) ljara and Kowli Land Cancellation of Irregular Pattas and Abolition of Concessional Assessment Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 225,
                "CitationOfAct": "1961AP39",
                "name": "Andhra Pradesh Housing Schemes (Acquisition of Land) Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 226,
                "CitationOfAct": "1961GJ27",
                "name": "Gujarat Agricultural Lands Ceilling Act, 1960",
                "YearOfEnactment": 1961
            },
            {
                "id": 227,
                "CitationOfAct": "1961GJ28",
                "name": "Gujarat Housing Board Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 228,
                "CitationOfAct": "1961GJ44",
                "name": "Gujarat Land Tenures Abolition (Extension of Period for Claiming Compensation) Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 229,
                "CitationOfAct": "1961GJ48",
                "name": "Gujarat Patel Watans Abolition Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 230,
                "CitationOfAct": "1961GOI30",
                "name": "Delhi (Urban Areas) Tenant's Relief Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 231,
                "CitationOfAct": "1961PN17",
                "name": "Punjab Abolition of Village Cess (Malba) Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 232,
                "CitationOfAct": "1961PN18",
                "name": "Punjab Village Common Lands (Regulation) Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 233,
                "CitationOfAct": "1961PN23",
                "name": "Punjab Agricultural Produce Markets Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 234,
                "CitationOfAct": "1961PN24",
                "name": "Punjab Slum Areas (Improvement and Clearance) Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 235,
                "CitationOfAct": "1961PN25",
                "name": "Punjab Co-operative Societies Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 236,
                "CitationOfAct": "1961PN34",
                "name": "Talwara Township (Periphery) Control Act, 1961",
                "YearOfEnactment": 1961
            },
            {
                "id": 237,
                "CitationOfAct": "1962AP22",
                "name": "Andhra Pradesh Land Revenue (Additional Assessment) and Cess Revision Act, 1962",
                "YearOfEnactment": 1962
            },
            {
                "id": 238,
                "CitationOfAct": "1962AS01",
                "name": "The Assam Gramdan Act, 1961",
                "YearOfEnactment": 1962
            },
            {
                "id": 239,
                "CitationOfAct": "1962AS21",
                "name": "Assam Board of Revenue Act, 1962",
                "YearOfEnactment": 1962
            },
            {
                "id": 240,
                "CitationOfAct": "1962BH12",
                "name": "Bihar Land Reforms (Fixation of Ceiling Area and Acquisition of Surplus Land) Act, 1961",
                "YearOfEnactment": 1962
            },
            {
                "id": 241,
                "CitationOfAct": "1962GJ10",
                "name": "Gujarat Co-operative Societies Act, 1961",
                "YearOfEnactment": 1962
            },
            {
                "id": 242,
                "CitationOfAct": "1962GJ23",
                "name": "Gujarat Industrial Development Act, 1962",
                "YearOfEnactment": 1962
            },
            {
                "id": 243,
                "CitationOfAct": "1962GOI33",
                "name": "Atomic Energy Act, 1962",
                "YearOfEnactment": 1962
            },
            {
                "id": 244,
                "CitationOfAct": "1962GOI50",
                "name": "Petroleum and Minerals Pipelines (Acquisition of Right of User in Land) Act, 1962",
                "YearOfEnactment": 1962
            },
            {
                "id": 245,
                "CitationOfAct": "1962PN07",
                "name": "Punjab Land Revenue Special Assessment (Exemption) Act, 1962",
                "YearOfEnactment": 1962
            },
            {
                "id": 246,
                "CitationOfAct": "1963GJ33",
                "name": "Gujarat Surviving Alienations Abolition Act, 1963",
                "YearOfEnactment": 1963
            },
            {
                "id": 247,
                "CitationOfAct": "1963GJ37",
                "name": "Gujarat Court of Wards Act, 1963",
                "YearOfEnactment": 1963
            },
            {
                "id": 248,
                "CitationOfAct": "1963GOI54",
                "name": "The Central Boards of Revenue Act, 1963",
                "YearOfEnactment": 1963
            },
            {
                "id": 249,
                "CitationOfAct": "1963PN12",
                "name": "Punjab Commercial Crops Cess Act, 1963 ",
                "YearOfEnactment": 1963
            },
            {
                "id": 250,
                "CitationOfAct": "1963PN23",
                "name": "Punjab Land Improvement Schemes Act, 1963",
                "YearOfEnactment": 1963
            },
            {
                "id": 251,
                "CitationOfAct": "1963PN25",
                "name": "Punjab Thur and Sem Lands (Reclamation) Act, 1963",
                "YearOfEnactment": 1963
            },
            {
                "id": 252,
                "CitationOfAct": "1963PN41",
                "name": "Punjab Scheduled Roads and Controlled Areas Restriction of Unregulated Development Act, 1963",
                "YearOfEnactment": 1963
            },
            {
                "id": 253,
                "CitationOfAct": "1964AS15",
                "name": "Assam Land (Requisition and Acquisition) Act, 1964",
                "YearOfEnactment": 1964
            },
            {
                "id": 254,
                "CitationOfAct": "1964GJ20",
                "name": "Gujarat Agricultural Produce Markets Act, 1963",
                "YearOfEnactment": 1964
            },
            {
                "id": 255,
                "CitationOfAct": "1964GJ34",
                "name": "Gujarat Municipalities Act, 1963",
                "YearOfEnactment": 1964
            },
            {
                "id": 256,
                "CitationOfAct": "1964PN20",
                "name": "Punjab Ancient and Historical Monuments and Archaeological Sites and Remains Act, 1964",
                "YearOfEnactment": 1964
            },
            {
                "id": 257,
                "CitationOfAct": "1964PN22",
                "name": "Punjab Urban Estates (Development and Regulation) Act, 1964",
                "YearOfEnactment": 1964
            },
            {
                "id": 258,
                "CitationOfAct": "1965AP02",
                "name": "Andhra Pradesh Splitting up of Joint Pattas Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 259,
                "CitationOfAct": "1965AP06",
                "name": "Andhra Pradesh Municipalities Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 260,
                "CitationOfAct": "1965AP12",
                "name": "Andhra Pradesh Irrigation (Construction and Maintenance of Water Courses) Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 261,
                "CitationOfAct": "1965AP13",
                "name": "Andhra Pradesh Bhoodan and Gramdan Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 262,
                "CitationOfAct": "1965GJ25",
                "name": "Gujarat Ancient Monuments and Archaeological Sites and Remains Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 263,
                "CitationOfAct": "1965GOI44",
                "name": "Metal Corporation of India (Acquisition of Undertaking) Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 264,
                "CitationOfAct": "1966AS23",
                "name": "Assam Bhoodan Act, 1965",
                "YearOfEnactment": 1965
            },
            {
                "id": 265,
                "CitationOfAct": "1966BH04",
                "name": "Bihar Gramdan Act, 1965",
                "YearOfEnactment": 1966
            },
            {
                "id": 266,
                "CitationOfAct": "1966BH05",
                "name": "Bihar Urban Land Tax Act, 1965",
                "YearOfEnactment": 1966
            },
            {
                "id": 267,
                "CitationOfAct": "1967AP01",
                "name": "Andhra Pradesh Forest Act, 1967",
                "YearOfEnactment": 1967
            },
            {
                "id": 268,
                "CitationOfAct": "1967AP08",
                "name": "Andhra Pradesh Land Revenue (Enhancement) Act, 1967",
                "YearOfEnactment": 1967
            },
            {
                "id": 269,
                "CitationOfAct": "1967AP09",
                "name": "Andhra Pradesh (Telangana Area) Abolition of Inams Act, 1967",
                "YearOfEnactment": 1967
            },
            {
                "id": 270,
                "CitationOfAct": "1968AP20",
                "name": "Andhra Pradesh Public Premises (Eviction of Unauthorised Occupants) Act, 1968",
                "YearOfEnactment": 1968
            },
            {
                "id": 271,
                "CitationOfAct": "1968GOI34",
                "name": "Enemy Property Act, 1968",
                "YearOfEnactment": 1968
            },
            {
                "id": 272,
                "CitationOfAct": "1969GJ07",
                "name": "Gujarat Electricity Supply Undertakings (Acquisition) Act, 1969",
                "YearOfEnactment": 1969
            },
            {
                "id": 273,
                "CitationOfAct": "1969GJ16",
                "name": "Gujarat Devasthan Inams Abolition Act, 1969",
                "YearOfEnactment": 1969
            },
            {
                "id": 274,
                "CitationOfAct": "1969GOI22",
                "name": "Banking Companies (Acquisition and Transfer of Undertakings) Act, 1969",
                "YearOfEnactment": 1969
            },
            {
                "id": 275,
                "CitationOfAct": "1970AS10",
                "name": "Assam Land Revenue and Rent (Surcharge) Act, 1970",
                "YearOfEnactment": 1970
            },
            {
                "id": 276,
                "CitationOfAct": "1970BH12",
                "name": "Bihar Soil and Water Conservation and Land Development Act, 1970",
                "YearOfEnactment": 1970
            },
            {
                "id": 277,
                "CitationOfAct": "1970PN09",
                "name": "Punjab Scheduled Castes Land Development and Finance Corporation Act, 1970",
                "YearOfEnactment": 1970
            },
            {
                "id": 278,
                "CitationOfAct": "1971AP26",
                "name": "Andhra Pradesh Record of Rights in Land Act, 1971",
                "YearOfEnactment": 1971
            },
            {
                "id": 279,
                "CitationOfAct": "1971AS23",
                "name": "Assam (Temporarily Settled Areas) Tenancy Act, 1971",
                "YearOfEnactment": 1971
            },
            {
                "id": 280,
                "CitationOfAct": "1971GOI40",
                "name": "Public Premises (Eviction of Unauthorised Occupants) Act, 1971",
                "YearOfEnactment": 1971
            },
            {
                "id": 281,
                "CitationOfAct": "1971GOI76",
                "name": "Manipur (Hill Areas) District Councils Act, 1971",
                "YearOfEnactment": 1971
            },
            {
                "id": 282,
                "CitationOfAct": "1971GOI82",
                "name": "Delhi Sikh Gurudwaras Act, 1971",
                "YearOfEnactment": 1971
            },
            {
                "id": 283,
                "CitationOfAct": "1972AS17",
                "name": "Assam Urban Areas Rent Control Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 284,
                "CitationOfAct": "1972GJ05",
                "name": "Gujarat Tribal Development Corporation Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 285,
                "CitationOfAct": "1972GJ12",
                "name": "Gujarat Vacant Lands in Urban Areas (Prohibition of Alienation) Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 286,
                "CitationOfAct": "1972GJ22",
                "name": "Gujarat Rural Housing Board Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 287,
                "CitationOfAct": "1972GOI30",
                "name": "Delhi Lands (Restrictions on Transfer) Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 288,
                "CitationOfAct": "1972GOI36",
                "name": "Coking Coal Mines (Nationalisation) Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 289,
                "CitationOfAct": "1972GOI53",
                "name": "Wild Life (Protection) Act, 1972",
                "YearOfEnactment": 1972
            },
            {
                "id": 290,
                "CitationOfAct": "1972ME01",
                "name": "Meghalaya Transfer of Land (Regulation) Act, 1971",
                "YearOfEnactment": 1971
            },
            {
                "id": 291,
                "CitationOfAct": "1973AP01",
                "name": "Andhra Pradesh Land Reforms (Ceiling on Agricultural Holdings) Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 292,
                "CitationOfAct": "1973AS08",
                "name": "Assam Agricultural Farming Corporations Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 293,
                "CitationOfAct": "1973GJ11",
                "name": "Gujarat Slum Areas (Improvement, Clearance and Redevelopment) Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 294,
                "CitationOfAct": "1973GJ12",
                "name": "Gujarat Public Premises (Eviction of Unauthorised Occupants) Act, 1972",
                "YearOfEnactment": 1973
            },
            {
                "id": 295,
                "CitationOfAct": "1973GJ13",
                "name": "Gujarat Ownership Flats Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 296,
                "CitationOfAct": "1973GJ14",
                "name": "Gujarat Private Forests (Acquisition) Act, 1972",
                "YearOfEnactment": 1973
            },
            {
                "id": 297,
                "CitationOfAct": "1973GOI26",
                "name": "Coal Mines (Nationalisation) Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 298,
                "CitationOfAct": "1973ME03",
                "name": "Meghalaya Municipal Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 299,
                "CitationOfAct": "1973MExx",
                "name": "Meghalaya Town and Country Planning Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 300,
                "CitationOfAct": "1973PN06",
                "name": "Punjab Housing Development Board Act, 1972",
                "YearOfEnactment": 1973
            },
            {
                "id": 301,
                "CitationOfAct": "1973PN10",
                "name": "Punjab Land Reforms Act, 1972",
                "YearOfEnactment": 1973
            },
            {
                "id": 302,
                "CitationOfAct": "1973PN31",
                "name": "Punjab Public Premises and Land (Eviction and Rent Recovery) Act, 1973",
                "YearOfEnactment": 1973
            },
            {
                "id": 303,
                "CitationOfAct": "1974AS23",
                "name": "Assam Agricultural Produce Market Act, 1972",
                "YearOfEnactment": 1974
            },
            {
                "id": 304,
                "CitationOfAct": "1974BH05",
                "name": "Bihar Kendu Leaves (Control of Trade) Act, 1973",
                "YearOfEnactment": 1974
            },
            {
                "id": 305,
                "CitationOfAct": "1974BH16",
                "name": "Bihar Industrial Area Development Authority Act, 1974",
                "YearOfEnactment": 1974
            },
            {
                "id": 306,
                "CitationOfAct": "1974GOI28",
                "name": "Coal Mines (Conservation and Development) Act, 1974",
                "YearOfEnactment": 1974
            },
            {
                "id": 307,
                "CitationOfAct": "1974GOI47",
                "name": "Oil Industry (Development) Act, 1974",
                "YearOfEnactment": 1974
            },
            {
                "id": 308,
                "CitationOfAct": "1974GOI57",
                "name": "Sick Textile Undertakings (Nationalisation) Act, 1974",
                "YearOfEnactment": 1974
            },
            {
                "id": 309,
                "CitationOfAct": "1975AP01",
                "name": "Andhra Pradesh Urban Areas (Development) Act, 1975",
                "YearOfEnactment": 1975
            },
            {
                "id": 310,
                "CitationOfAct": "1975AP02",
                "name": "Andhra Pradesh Land Revenue (Additional Wet Assessment) Act, 1975",
                "YearOfEnactment": 1975
            },
            {
                "id": 311,
                "CitationOfAct": "1975AS01",
                "name": "The Assam Land Holdings (Adoption of relationship under the Assam Land and Revenue Regulation, 1886 in the acquired permanently Settled Estates) Act, 1974",
                "YearOfEnactment": 1975
            },
            {
                "id": 312,
                "CitationOfAct": "1975GOI04",
                "name": "Tobacco Board Act, 1975",
                "YearOfEnactment": 1975
            },
            {
                "id": 313,
                "CitationOfAct": "1976AP24",
                "name": "Andhra Pradesh Indebted Agriculturists, Landless Labourers and Artisans (Temporary Relief) Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 314,
                "CitationOfAct": "1976AP39",
                "name": "Andhra Pradesh Tenants and Ryots Protection Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 315,
                "CitationOfAct": "1976AR01",
                "name": "Arunachal Pradesh Anchal Forest Reserve (Constitution and Maintenance) Act, 1975",
                "YearOfEnactment": 1976
            },
            {
                "id": 316,
                "CitationOfAct": "1976AS20",
                "name": "Assam Public Premises (Eviction of Unauthorised Occupants) Act, 1971",
                "YearOfEnactment": 1976
            },
            {
                "id": 317,
                "CitationOfAct": "1976BHxx",
                "name": "Bihar Sugar Undertakings (Acquisition) Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 318,
                "CitationOfAct": "1976GJ27",
                "name": "Gujarat Town Planning and Urban Development Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 319,
                "CitationOfAct": "1976ME13",
                "name": "Meghalaya Tree (Preservation) Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 320,
                "CitationOfAct": "1976PN20",
                "name": "Punjab Gram Panchayat (Common Purposes Land) Eviction and Rent Recovery Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 321,
                "CitationOfAct": "1976PN27",
                "name": "Punjab Backward Classes Land Development and Finance Corporation Act, 1976",
                "YearOfEnactment": 1976
            },
            {
                "id": 322,
                "CitationOfAct": "1977AP08",
                "name": "Andhra Pradesh Board of Revenue (Replacement by Commissions) Act, 1977",
                "YearOfEnactment": 1977
            },
            {
                "id": 323,
                "CitationOfAct": "1977AP09",
                "name": "Andhra Pradesh Assigned Lands (Prohibition of Transfers) Act, 1977",
                "YearOfEnactment": 1977
            },
            {
                "id": 324,
                "CitationOfAct": "1977AS07",
                "name": "Assam Agricultural Credit Operations and Miscellaneous Provisions (Banks) Act, 1977",
                "YearOfEnactment": 1977
            },
            {
                "id": 325,
                "CitationOfAct": "1977GJ24",
                "name": "Gujarat Tax on Luxuries (Hotels and Lodging Houses) Act, 1977",
                "YearOfEnactment": 1977
            },
            {
                "id": 326,
                "CitationOfAct": "1978AP10",
                "name": "Andhra Pradesh Watans (Abolition) Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 327,
                "CitationOfAct": "1978AS10",
                "name": "Assam Farmers (Group Irrigation) Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 328,
                "CitationOfAct": "1978BH10",
                "name": "Bihar Premises and Vehicles (Requisitions) Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 329,
                "CitationOfAct": "1978GOI30",
                "name": "Coast Guard Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 330,
                "CitationOfAct": "1978GOI33",
                "name": "Metro Railways (Construction of Works) Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 331,
                "CitationOfAct": "1978ME07",
                "name": "Meghalaya Municipal (Garo Hills Autonomous District) Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 332,
                "CitationOfAct": "1978PN35",
                "name": "Punjab Requisitioning and Acquisition of Movable Property Act, 1978",
                "YearOfEnactment": 1978
            },
            {
                "id": 333,
                "CitationOfAct": "1979AP06",
                "name": "Andhra Pradesh Tenants and Ryots Protection Act, 1979",
                "YearOfEnactment": 1979
            },
            {
                "id": 334,
                "CitationOfAct": "1979BH03",
                "name": "Bihar Agricultural and Rural Area Development Agency Act, 1978",
                "YearOfEnactment": 1979
            },
            {
                "id": 335,
                "CitationOfAct": "1979BH05",
                "name": "Bihar Electricity Supply Undertakings (Acquisition) Act, 1979",
                "YearOfEnactment": 1979
            },
            {
                "id": 336,
                "CitationOfAct": "1980AS01",
                "name": "Assam Alienation of Land (Regulation) Act, 1980",
                "YearOfEnactment": 1980
            },
            {
                "id": 337,
                "CitationOfAct": "1980GOI69",
                "name": "Forest (Conservation) Act, 1980",
                "YearOfEnactment": 1980
            },
            {
                "id": 338,
                "CitationOfAct": "1980ME07",
                "name": "Gauripur (Gholla) Zamindars' Annuity Rights Abolition and Extinguishment Act, 1979",
                "YearOfEnactment": 1980
            },
            {
                "id": 339,
                "CitationOfAct": "1980ME11",
                "name": "Meghalaya Land Survey and Records Preparation Act, 1980",
                "YearOfEnactment": 1980
            },
            {
                "id": 340,
                "CitationOfAct": "1980ME15",
                "name": "Meghalaya Public Premises (Eviction of Unauthorised Occupants) Act, 1980",
                "YearOfEnactment": 1980
            },
            {
                "id": 341,
                "CitationOfAct": "1980ME24",
                "name": "Meghalaya (Benami Transactions Prohibition) Act, 1980",
                "YearOfEnactment": 1980
            },
            {
                "id": 342,
                "CitationOfAct": "1981ME11",
                "name": "Meghalaya Removal of Restrictions on Use and Enjoyment of Land Act, 1980",
                "YearOfEnactment": 1981
            },
            {
                "id": 343,
                "CitationOfAct": "1981ME12",
                "name": "Meghalaya Forest (Removal of Timber) (Regulation) Act, 1981",
                "YearOfEnactment": 1981
            },
            {
                "id": 344,
                "CitationOfAct": "1982AP12",
                "name": "Andhra Pradesh Land Grabbing (Prohibition) Act, 1982",
                "YearOfEnactment": 1982
            },
            {
                "id": 345,
                "CitationOfAct": "1982BH07",
                "name": "Bihar Agricultural Credit Operations and Miscellaneous Provisions (Banks) Act, 1977",
                "YearOfEnactment": 1982
            },
            {
                "id": 346,
                "CitationOfAct": "1982BH57",
                "name": "Bihar State Housing Board Act, 1982",
                "YearOfEnactment": 1982
            },
            {
                "id": 347,
                "CitationOfAct": "1982GOI36",
                "name": "Chaparmukh-Silghat Railway Line and the Katakhal-Lalabazar Railway Line (Nationalisation) Act, 1982",
                "YearOfEnactment": 1982
            },
            {
                "id": 348,
                "CitationOfAct": "1983AS11",
                "name": "Assam Tribal Development Authority Act, 1983",
                "YearOfEnactment": 1983
            },
            {
                "id": 349,
                "CitationOfAct": "1983BH04",
                "name": "Bihar Buildings (Lease, Rent and Eviction) Control Act, 1982",
                "YearOfEnactment": 1983
            },
            {
                "id": 350,
                "CitationOfAct": "1983GOI32",
                "name": "Punjab Disturbed Areas Act, 1983",
                "YearOfEnactment": 1983
            },
            {
                "id": 351,
                "CitationOfAct": "1983JH04",
                "name": "Jharkhand Buildings (Lease, Rent and Eviction) Control Act, 2000",
                "YearOfEnactment": 1983
            },
            {
                "id": 352,
                "CitationOfAct": "1984AP15",
                "name": "Andhra Pradesh Irrigation Utilisation and Command Area Development Act, 1984",
                "YearOfEnactment": 1984
            },
            {
                "id": 353,
                "CitationOfAct": "1985GOI02",
                "name": "National Capital Region Planning Board Act, 1985",
                "YearOfEnactment": 1985
            },
            {
                "id": 354,
                "CitationOfAct": "1986AP01",
                "name": "Andhra Pradesh Prevention of Dangerous Activities of Boot-Leggers, Dacoits, Goondas, Immoral Traffic Offenders and Land-Grabbers Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 355,
                "CitationOfAct": "1986AP18",
                "name": "Andhra Pradesh Government Lands and Buildings (Termination of Leases) Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 356,
                "CitationOfAct": "1986AP20",
                "name": "Andhra Pradesh (Non-Delta Area) Drainage Cess Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 357,
                "CitationOfAct": "1986AP31",
                "name": "Andhra Pradesh Mandala Praja Parishads, Zilla Praja Parishads and Zilla Pranalika Abhivrudhi Mandals Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 358,
                "CitationOfAct": "1986BH09",
                "name": "Bihar (Coal Mining) Area Development Authority Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 359,
                "CitationOfAct": "1986GOI58",
                "name": "Delhi Apartment Ownership Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 360,
                "CitationOfAct": "1986ME07",
                "name": "Meghalaya State Housing Board Act, 1986",
                "YearOfEnactment": 1986
            },
            {
                "id": 361,
                "CitationOfAct": "1987AS22",
                "name": "Assam Forest Protection Force Act, 1986",
                "YearOfEnactment": 1987
            },
            {
                "id": 362,
                "CitationOfAct": "1987BH05",
                "name": "Bihar Prevention of Defacement of Property Act, 1987",
                "YearOfEnactment": 1987
            },
            {
                "id": 363,
                "CitationOfAct": "1987GOI16",
                "name": "Goa, Daman and Diu Mining Concessions (Abolition and Declaration as Mining Leases) Act, 1987",
                "YearOfEnactment": 1987
            },
            {
                "id": 364,
                "CitationOfAct": "1987GOI53",
                "name": "National Housing Bank Act, 1987",
                "YearOfEnactment": 1987
            },
            {
                "id": 365,
                "CitationOfAct": "1987PN06",
                "name": "Punjab Rural Development Act, 1987",
                "YearOfEnactment": 1987
            },
            {
                "id": 366,
                "CitationOfAct": "1988AP11",
                "name": "The Andhra Pradesh Water Tax Act, 1988",
                "YearOfEnactment": 1988
            },
            {
                "id": 367,
                "CitationOfAct": "1988GOI45",
                "name": "Prohibition of Benami Property Transactions Act, 1988",
                "YearOfEnactment": 1988
            },
            {
                "id": 368,
                "CitationOfAct": "1988GOI68",
                "name": "National Highways Authority of India Act, 1988",
                "YearOfEnactment": 1988
            },
            {
                "id": 369,
                "CitationOfAct": "1989AP(HYD)15",
                "name": "The Hyderabad Metropolitan Water Supply and Sewerage Act, 1989",
                "YearOfEnactment": 1989
            },
            {
                "id": 370,
                "CitationOfAct": "1989AS08",
                "name": "Assam Irrigation Act, 1983",
                "YearOfEnactment": 1989
            },
            {
                "id": 371,
                "CitationOfAct": "1989GOI24",
                "name": "Railways Act, 1989",
                "YearOfEnactment": 1989
            },
            {
                "id": 372,
                "CitationOfAct": "1989GOI33",
                "name": "Scheduled Castes and Scheduled Tribes (Prevention of Atrocities) Act, 1989",
                "YearOfEnactment": 1989
            },
            {
                "id": 373,
                "CitationOfAct": "1990AS12",
                "name": "Assam Taxation (On Specified Lands) Act, 1990",
                "YearOfEnactment": 1990
            },
            {
                "id": 374,
                "CitationOfAct": "1991AS01",
                "name": "Assam Industrial Infrastructure Development Corporation Act, 1990",
                "YearOfEnactment": 1991
            },
            {
                "id": 375,
                "CitationOfAct": "1991GJ12",
                "name": "Gujarat Prohibition of Transfer of Immovable Property and Provision for Protection of Tenants from Eviction from Premises in Disturbed Areas Act, 1991",
                "YearOfEnactment": 1991
            },
            {
                "id": 376,
                "CitationOfAct": "1991ME03",
                "name": "Meghalaya Forest Authority Act, 1991",
                "YearOfEnactment": 1991
            },
            {
                "id": 377,
                "CitationOfAct": "1992AP15",
                "name": "Azamabad Industrial Area (Termination and Regulation of Leases) Act, 1992",
                "YearOfEnactment": 1992
            },
            {
                "id": 378,
                "CitationOfAct": "1992BH12",
                "name": "Bihar Restoration and Improvement of Degraded Forest Land Taxation Act, 1992",
                "YearOfEnactment": 1992
            },
            {
                "id": 379,
                "CitationOfAct": "1992ME04",
                "name": "The Meghalaya Protection of Catchment Areas Act, 1990",
                "YearOfEnactment": 1992
            },
            {
                "id": 380,
                "CitationOfAct": "1993GJ18",
                "name": "Gujarat Panchayats Act, 1993",
                "YearOfEnactment": 1993
            },
            {
                "id": 381,
                "CitationOfAct": "1993GOI33",
                "name": "Acquisition of Certain Area at Ayodhya Act, 1993",
                "YearOfEnactment": 1993
            },
            {
                "id": 382,
                "CitationOfAct": "1994AP13",
                "name": "Andhra Pradesh Panchayat Raj Act, 1994",
                "YearOfEnactment": 1994
            },
            {
                "id": 383,
                "CitationOfAct": "1994AP25",
                "name": "Andhra Pradesh Municipal Corporations Act, 1994",
                "YearOfEnactment": 1994
            },
            {
                "id": 384,
                "CitationOfAct": "1994GOI44",
                "name": "New Delhi Municipal Council Act, 1994",
                "YearOfEnactment": 1994
            },
            {
                "id": 385,
                "CitationOfAct": "1994GOI55",
                "name": "Airports Authority of India Act, 1994",
                "YearOfEnactment": 1994
            },
            {
                "id": 386,
                "CitationOfAct": "1994JH13",
                "name": "Jharkhand Area Autonomous Council Act, 1994",
                "YearOfEnactment": 1994
            },
            {
                "id": 387,
                "CitationOfAct": "1994PN09",
                "name": "Punjab Panchayati Raj Act, 1994",
                "YearOfEnactment": 1994
            },
            {
                "id": 388,
                "CitationOfAct": "1995AS18",
                "name": "Assam Highways Act, 1989",
                "YearOfEnactment": 1995
            },
            {
                "id": 389,
                "CitationOfAct": "1995GOI33",
                "name": "Delhi Rent Act, 1995",
                "YearOfEnactment": 1995
            },
            {
                "id": 390,
                "CitationOfAct": "1995PN11",
                "name": "Punjab Regional and Town Planning and Development Act, 1995",
                "YearOfEnactment": 1995
            },
            {
                "id": 391,
                "CitationOfAct": "1995PN13",
                "name": "Punjab Apartment Ownership Act, 1995",
                "YearOfEnactment": 1995
            },
            {
                "id": 392,
                "CitationOfAct": "1995PN14",
                "name": "Punjab Apartment and Property Regulation Act, 1995",
                "YearOfEnactment": 1995
            },
            {
                "id": 393,
                "CitationOfAct": "1996AP07",
                "name": "Andhra Pradesh Ground Water (Regulation for Drinking Water Purposes) Act, 1996",
                "YearOfEnactment": 1996
            },
            {
                "id": 394,
                "CitationOfAct": "1996GOI40",
                "name": "Provisions of the Panchayats (Extension to the Scheduled Areas) Act, 1996",
                "YearOfEnactment": 1996
            },
            {
                "id": 395,
                "CitationOfAct": "1996PN09",
                "name": "Punjab Minerals (Vesting of Rights) Act, 1994",
                "YearOfEnactment": 1996
            },
            {
                "id": 396,
                "CitationOfAct": "1997AP12",
                "name": "Andhra Pradesh Water Resources Development Corporation Act, 1997",
                "YearOfEnactment": 1997
            },
            {
                "id": 397,
                "CitationOfAct": "1997BHxx",
                "name": "Bihar Irrigation Act, 1997",
                "YearOfEnactment": 1997
            },
            {
                "id": 398,
                "CitationOfAct": "1997PN08",
                "name": "Punjab Land Revenue (Abolition) Act, 1997",
                "YearOfEnactment": 1997
            },
            {
                "id": 399,
                "CitationOfAct": "1998AP01",
                "name": "Andhra Pradesh Road Development Corporation Act, 1998",
                "YearOfEnactment": 1998
            },
            {
                "id": 400,
                "CitationOfAct": "1998AP37",
                "name": "Andhra Pradesh Infrastructure Development Corporation Act, 1998",
                "YearOfEnactment": 1998
            },
            {
                "id": 401,
                "CitationOfAct": "1998PN04",
                "name": "Punjab Religious Premises and Land (Eviction and Rent Recovery) Act, 1997",
                "YearOfEnactment": 1998
            },
            {
                "id": 402,
                "CitationOfAct": "1998PN07",
                "name": "Punjab Prevention of Defacement of Property Act, 1997 ",
                "YearOfEnactment": 1998
            },
            {
                "id": 403,
                "CitationOfAct": "1998PN22",
                "name": "Punjab Roads and Bridges Development Board Act, 1998",
                "YearOfEnactment": 1998
            },
            {
                "id": 404,
                "CitationOfAct": "1999AP01",
                "name": "Andhra Pradesh Land Revenue Code, 1999",
                "YearOfEnactment": 1999
            },
            {
                "id": 405,
                "CitationOfAct": "1999GJ11",
                "name": "Gujarat Infrastructure Development Act, 1999",
                "YearOfEnactment": 1999
            },
            {
                "id": 406,
                "CitationOfAct": "2000GJ05",
                "name": "Gujarat Water and Gas Pipelines (Acquisition of Right of User in Land) Act, 2000",
                "YearOfEnactment": 2000
            },
            {
                "id": 407,
                "CitationOfAct": "1971AP26",
                "name": "Andhra Pradesh Rights in Land and Pattadar Pass Books Act, 1971  ",
                "YearOfEnactment": 1971
            },
            {
                "id": 408,
                "CitationOfAct": "2001JH06",
                "name": "Jharkhand Panchayat Raj Act, 2001",
                "YearOfEnactment": 2001
            },
            {
                "id": 409,
                "CitationOfAct": "2002AP10",
                "name": "Andhra Pradesh Water, Land and Trees Act, 2002",
                "YearOfEnactment": 2002
            },
            {
                "id": 410,
                "CitationOfAct": "2003GOI13",
                "name": "Control of National Highways (Land and Traffic Act), 2002",
                "YearOfEnactment": 2002
            },
            {
                "id": 411,
                "CitationOfAct": "2002GOI60",
                "name": "Metro Railways (Operation and Maintenance) Act, 2002",
                "YearOfEnactment": 2002
            },
            {
                "id": 412,
                "CitationOfAct": "2002PN08",
                "name": "Punjab Infrastructure (Development and Regulation) Act, 2002",
                "YearOfEnactment": 2002
            },
            {
                "id": 413,
                "CitationOfAct": "2003AP06",
                "name": "Andhra Pradesh Regularisation of the Unauthorised Constructions in Municipal Corporations, Municipalities and Urban Development Authorities Act, 2003",
                "YearOfEnactment": 2003
            },
            {
                "id": 414,
                "CitationOfAct": "2003GOI17",
                "name": "Offshore Areas Mineral (Development and Regulation) Act, 2002",
                "YearOfEnactment": 2003
            },
            {
                "id": 415,
                "CitationOfAct": "2004GJ11",
                "name": "Gujarat Special Economic Zone Act, 2004",
                "YearOfEnactment": 2004
            },
            {
                "id": 416,
                "CitationOfAct": "2004JH06",
                "name": "Jharkhand Restriction on Construction in Unsafe Areas Act, 2002",
                "YearOfEnactment": 2004
            },
            {
                "id": 417,
                "CitationOfAct": "2005AP38",
                "name": "Andhra Pradesh Mineral Bearing Lands (Infrastructure) Cess Act, 2005",
                "YearOfEnactment": 2005
            },
            {
                "id": 418,
                "CitationOfAct": "2005GOI24",
                "name": "Coastal Aquaculture Authority Act, 2005",
                "YearOfEnactment": 2005
            },
            {
                "id": 419,
                "CitationOfAct": "2005GOI28",
                "name": "Special Economic Zones Act, 2005",
                "YearOfEnactment": 2005
            },
            {
                "id": 420,
                "CitationOfAct": "2005JH10",
                "name": "Jharkhand Rural Infrastructure and Socio-Economic Development Act, 2005",
                "YearOfEnactment": 2005
            },
            {
                "id": 421,
                "CitationOfAct": "2005JH11",
                "name": "Jharkhand Apartment (Regulation and Title of Property) Act, 2005",
                "YearOfEnactment": 2005
            },
            {
                "id": 422,
                "CitationOfAct": "2005PN22",
                "name": "Punjab District Planning Committees Act, 2005",
                "YearOfEnactment": 2005
            },
            {
                "id": 423,
                "CitationOfAct": "2006AP03",
                "name": "Andhra Pradesh Agricultural Land (Conversion for Non-Agricultural Purposes) Act, 2006",
                "YearOfEnactment": 2006
            },
            {
                "id": 424,
                "CitationOfAct": "2006AS07",
                "name": "Majuli Cultural Landscape Region Act, 2006",
                "YearOfEnactment": 2006
            },
            {
                "id": 425,
                "CitationOfAct": "2006BH06",
                "name": "Bihar Panchayat Raj Act, 2006 ",
                "YearOfEnactment": 2006
            },
            {
                "id": 426,
                "CitationOfAct": "2006BH28",
                "name": "Bihar Apartment Ownership Act, 2006",
                "YearOfEnactment": 2006
            },
            {
                "id": 427,
                "CitationOfAct": "2006BHxx",
                "name": "Bihar Infrastructure Development Enabling Act, 2006",
                "YearOfEnactment": 2006
            },
            {
                "id": 428,
                "CitationOfAct": "2006GJ28",
                "name": "Champaner-Pavagadh Archaeological Park World Heritage Area Management Authority Act, 2006",
                "YearOfEnactment": 2006
            },
            {
                "id": 429,
                "CitationOfAct": "2006GOI41",
                "name": "Cantonments Act, 2006",
                "YearOfEnactment": 2006
            },
            {
                "id": 430,
                "CitationOfAct": "2006JH07",
                "name": "Jharkhand Highways Act, 2005",
                "YearOfEnactment": 2006
            },
            {
                "id": 431,
                "CitationOfAct": "2007AP11",
                "name": "Andhra Pradesh Government Property (Preservation, Protection and Resumption) Act, 2007",
                "YearOfEnactment": 2007
            },
            {
                "id": 432,
                "CitationOfAct": "2007AP32",
                "name": "Andhra Pradesh Metropolitan Planning Committee Act, 2007",
                "YearOfEnactment": 2007
            },
            {
                "id": 433,
                "CitationOfAct": "2007AS01",
                "name": "Guwahati Prevention of Defacement of Property Act, 2006",
                "YearOfEnactment": 2007
            },
            {
                "id": 434,
                "CitationOfAct": "2007AS05",
                "name": "Assam Hill Land and Ecological Sites (Protection and Management) Act, 2006",
                "YearOfEnactment": 2007
            },
            {
                "id": 435,
                "CitationOfAct": "2007AS21",
                "name": "Assam Apartments (Construction and Transfer of Ownership) Act, 2006",
                "YearOfEnactment": 2007
            },
            {
                "id": 436,
                "CitationOfAct": "2007BH04",
                "name": "The Bihar Ground Water (Regulation and Control of Development and Management) Act, 2006",
                "YearOfEnactment": 2007
            },
            {
                "id": 437,
                "CitationOfAct": "2007GOI02",
                "name": "Scheduled Tribes and Other Traditional Forest Dwellers (Recognition of Forest Rights) Act, 2006",
                "YearOfEnactment": 2007
            },
            {
                "id": 438,
                "CitationOfAct": "2008AP08",
                "name": "Hyderabad Metropolitan Development Authority Act, 2008",
                "YearOfEnactment": 2008
            },
            {
                "id": 439,
                "CitationOfAct": "2008JH12",
                "name": "State Highways Authority of Jharkhand Act, 2007",
                "YearOfEnactment": 2008
            },
            {
                "id": 440,
                "CitationOfAct": "2009BH09",
                "name": "Bihar Land Tribunal Act, 2009",
                "YearOfEnactment": 2009
            },
            {
                "id": 441,
                "CitationOfAct": "2009GJ02",
                "name": "Gujarat Special Investment Region Act, 2009",
                "YearOfEnactment": 2009
            },
            {
                "id": 442,
                "CitationOfAct": "2009PN06",
                "name": "The Punjab Preservation of Subsoil Water Act, 2009",
                "YearOfEnactment": 2009
            },
            {
                "id": 443,
                "CitationOfAct": "2010AS17",
                "name": "Assam Public Works (Regulation of Road Development and Road Transport) Act, 2010",
                "YearOfEnactment": 2010
            },
            {
                "id": 444,
                "CitationOfAct": "2010BH04",
                "name": "Bihar Land Disputes Resolution Act, 2009",
                "YearOfEnactment": 2010
            },
            {
                "id": 445,
                "CitationOfAct": "2010BH11",
                "name": "Bihar Agriculture Land (Conversion for Non-Agriculture Purposes) Act, 2010",
                "YearOfEnactment": 2010
            },
            {
                "id": 446,
                "CitationOfAct": "2010BH14",
                "name": "Digha Acquired Land Settlement Act, 2010",
                "YearOfEnactment": 2011
            },
            {
                "id": 447,
                "CitationOfAct": "2010GOI31",
                "name": "Land Ports Authority of India Act, 2010",
                "YearOfEnactment": 2010
            },
            {
                "id": 448,
                "CitationOfAct": "2011AP18",
                "name": "Andhra Pradesh Land Licensed Cultivators Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 449,
                "CitationOfAct": "2011AS21",
                "name": "Assam Land Grabbing (Prohibition) Act, 2010",
                "YearOfEnactment": 2011
            },
            {
                "id": 450,
                "CitationOfAct": "2011BH15",
                "name": "Bihar Underground Pipelines (Acquisition of Right of User in Land) Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 451,
                "CitationOfAct": "2011BH23",
                "name": "Bihar Land Mutation Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 452,
                "CitationOfAct": "2011BH24",
                "name": "Bihar Special Survey and Settlement Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 453,
                "CitationOfAct": "2011GJ26",
                "name": "Gujarat Regularisation of Unauthorised Development Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 454,
                "CitationOfAct": "2011GOI20",
                "name": "National Capital Territory of Delhi Laws (Special Provisions) Second Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 455,
                "CitationOfAct": "2011PN09",
                "name": "Punjab (Institutions and Other Buildings) Tax Act, 2011",
                "YearOfEnactment": 2011
            },
            {
                "id": 456,
                "CitationOfAct": "2012BH20",
                "name": "Bihar Urban Planning and Development Act, 2012",
                "YearOfEnactment": 2012
            },
            {
                "id": 457,
                "CitationOfAct": "2012JH01",
                "name": "Jharkhand Apartment (Flat) Ownership Act, 2011",
                "YearOfEnactment": 2012
            },
            {
                "id": 458,
                "CitationOfAct": "2012JH04",
                "name": "Jharkhand Regularization of Unauthorized/Deviated Constructions through Compounding in Urban Areas Act, 2011",
                "YearOfEnactment": 2012
            },
            {
                "id": 459,
                "CitationOfAct": "2012JH06",
                "name": "Jharkhand Building (Lease, Rent and Eviction) Control Act, 2011",
                "YearOfEnactment": 2012
            },
            {
                "id": 460,
                "CitationOfAct": "2012JH07",
                "name": "Jharkhand Municipal Act, 2011",
                "YearOfEnactment": 2012
            },
            {
                "id": 461,
                "CitationOfAct": "2012ME09",
                "name": "Meghalaya Heritage Act, 2012",
                "YearOfEnactment": 2012
            },
            {
                "id": 462,
                "CitationOfAct": "2012PN13",
                "name": "Punjab Rent Act, 1995",
                "YearOfEnactment": 2012
            },
            {
                "id": 463,
                "CitationOfAct": "2013GOI30",
                "name": "Right to Fair Compensation and Transparency in Land Acquisition, Rehabilitation and Resettlement Act, 2013",
                "YearOfEnactment": 2013
            },
            {
                "id": 464,
                "CitationOfAct": "2014AP11",
                "name": "Andhra Pradesh Capital Region Development Authority Act, 2014",
                "YearOfEnactment": 2014
            },
            {
                "id": 465,
                "CitationOfAct": "2014GOI30",
                "name": "Indian Institutes of Information Technology Act, 2014",
                "YearOfEnactment": 2014
            },
            {
                "id": 466,
                "CitationOfAct": "2015AS26",
                "name": "Assam Agricultural Land (Regulation of Reclassification and Transfer for Non-Agricultural Purpose) Act, 2015",
                "YearOfEnactment": 2015
            },
            {
                "id": 467,
                "CitationOfAct": "2015GOI11",
                "name": "Coal Mines (Special Provisions) Act, 2015",
                "YearOfEnactment": 2015
            },
            {
                "id": 468,
                "CitationOfAct": "2015JH16",
                "name": "Jharkhand Tourist Places (Protection and Maintenance) Act, 2015",
                "YearOfEnactment": 2015
            },
            {
                "id": 469,
                "CitationOfAct": "2015TE06",
                "name": "Telangana Domestic and Industrial Water Grid pipelines (Acquisition of Right of User in Land) Act, 2015",
                "YearOfEnactment": 2015
            },
            {
                "id": 470,
                "CitationOfAct": "2016AP05",
                "name": "Andhra Pradesh Metropolitan Region and Urban Development Authorities Act, 2016",
                "YearOfEnactment": 2016
            },
            {
                "id": 471,
                "CitationOfAct": "2016GOI16",
                "name": "Real Estate (Regulation and Development) Act, 2016",
                "YearOfEnactment": 2016
            },
            {
                "id": 472,
                "CitationOfAct": "2016GOI38",
                "name": "Compensatory Afforestation Fund Act, 2016",
                "YearOfEnactment": 2016
            },
            {
                "id": 473,
                "CitationOfAct": "2016PN13",
                "name": "Amritsar Walled City (Recognition of Usage) Act, 2016",
                "YearOfEnactment": 2016
            },
            {
                "id": 474,
                "CitationOfAct": "2016PN54",
                "name": "Punjab Allotment of State Government Land Act, 2016",
                "YearOfEnactment": 2016
            },
            {
                "id": 475,
                "CitationOfAct": "2016PN58",
                "name": "Punjab Bhagwan Valmik Ji Tirath Sthal (Ram Tirath) Shrine Board Act, 2016",
                "YearOfEnactment": 2016
            },
            {
                "id": 476,
                "CitationOfAct": "2017PN04",
                "name": "Punjab Prevention of Damage to Public and Private Property Act, 2014",
                "YearOfEnactment": 2017
            },
            {
                "id": 477,
                "CitationOfAct": "2019PN06",
                "name": "Punjab One-time Voluntary Disclosure and Settlement of Building constructed in Violations of the Building Bye-laws Act, 2019",
                "YearOfEnactment": 2019
            },
            {
                "id": 478,
                "CitationOfAct": "1886GOI11",
                "name": "Indian Tramways Act, 1886",
                "YearOfEnactment": 1886
            },
            {
                "id": 479,
                "CitationOfAct": "1948GOI14",
                "name": "Damodar Valley Corporation Act, 1948",
                "YearOfEnactment": 1948
            },
            {
                "id": 480,
                "CitationOfAct": "1957GOI20",
                "name": "Coal Bearing Areas (Acquisition and Development) Act, 1957",
                "YearOfEnactment": 1957
            },
            {
                "id": 481,
                "CitationOfAct": "2003GOI36",
                "name": "Electricity Act, 2003",
                "YearOfEnactment": 2003
            },
            {
                "id": 482,
                "CitationOfAct": "1911PN03",
                "name": "Punjab Municipal Act, 1911",
                "YearOfEnactment": 1911
            }
        ]
    },
    {
        "title": "Keywords",
        "suggestions": [
            {
                "name": "usage"
            },
            {
                "name": "directors"
            },
            {
                "name": "carriage"
            },
            {
                "name": "earning"
            },
            {
                "name": "extends"
            },
            {
                "name": "consequently"
            },
            {
                "name": "investments"
            },
            {
                "name": "batta"
            },
            {
                "name": "mines"
            },
            {
                "name": "arise"
            },
            {
                "name": "durations"
            },
            {
                "name": "parcel"
            },
            {
                "name": "minors"
            },
            {
                "name": "perecentage"
            },
            {
                "name": "culverts"
            },
            {
                "name": "yards"
            },
            {
                "name": "completeness"
            },
            {
                "name": "depots"
            },
            {
                "name": "inspected"
            },
            {
                "name": "impliedly"
            },
            {
                "name": "immovable"
            },
            {
                "name": "contents"
            },
            {
                "name": "archaeological"
            },
            {
                "name": "1974"
            },
            {
                "name": "revising"
            },
            {
                "name": "endeavours"
            },
            {
                "name": "drains"
            },
            {
                "name": "allotments"
            },
            {
                "name": "curtail"
            },
            {
                "name": "transported"
            },
            {
                "name": "subsoil"
            },
            {
                "name": "hue"
            },
            {
                "name": "shrine"
            },
            {
                "name": "faunal"
            },
            {
                "name": "sublease"
            },
            {
                "name": "compost"
            },
            {
                "name": "restoring"
            },
            {
                "name": "consequences"
            },
            {
                "name": "denial"
            },
            {
                "name": "history"
            },
            {
                "name": "machine"
            },
            {
                "name": "punishable"
            },
            {
                "name": "1895"
            },
            {
                "name": "historical"
            },
            {
                "name": "insurers"
            },
            {
                "name": "subjects"
            },
            {
                "name": "ensuring"
            },
            {
                "name": "maharashtra"
            },
            {
                "name": "override"
            },
            {
                "name": "partly"
            },
            {
                "name": "innovation"
            },
            {
                "name": "causeway"
            },
            {
                "name": "1848"
            },
            {
                "name": "authorizing"
            },
            {
                "name": "rajasthan"
            },
            {
                "name": "officiate"
            },
            {
                "name": "enabling"
            },
            {
                "name": "1999"
            },
            {
                "name": "ordering"
            },
            {
                "name": "1956"
            },
            {
                "name": "absents"
            },
            {
                "name": "commercial"
            },
            {
                "name": "buddhist"
            },
            {
                "name": "controlling"
            },
            {
                "name": "arbitrators"
            },
            {
                "name": "communicate"
            },
            {
                "name": "differ"
            },
            {
                "name": "entitle"
            },
            {
                "name": "percolation"
            },
            {
                "name": "spouse"
            },
            {
                "name": "reservoir"
            },
            {
                "name": "annuity"
            },
            {
                "name": "minimum"
            },
            {
                "name": "xv"
            },
            {
                "name": "thur"
            },
            {
                "name": "benefitted"
            },
            {
                "name": "shrubs"
            },
            {
                "name": "sts"
            },
            {
                "name": "demonstrations"
            },
            {
                "name": "satisfied"
            },
            {
                "name": "enforceable"
            },
            {
                "name": "redevelop"
            },
            {
                "name": "clarity"
            },
            {
                "name": "petition"
            },
            {
                "name": "product"
            },
            {
                "name": "procurement"
            },
            {
                "name": "poisoning"
            },
            {
                "name": "lessees"
            },
            {
                "name": "preserved"
            },
            {
                "name": "ceiling"
            },
            {
                "name": "remittance"
            },
            {
                "name": "married"
            },
            {
                "name": "president"
            },
            {
                "name": "country"
            },
            {
                "name": "links"
            },
            {
                "name": "allocated"
            },
            {
                "name": "tumulus"
            },
            {
                "name": "resign"
            },
            {
                "name": "peace"
            },
            {
                "name": "address"
            },
            {
                "name": "dung"
            },
            {
                "name": "hinders"
            },
            {
                "name": "entries"
            },
            {
                "name": "utilise"
            },
            {
                "name": "concluded"
            },
            {
                "name": "providing"
            },
            {
                "name": "merits"
            },
            {
                "name": "1881"
            },
            {
                "name": "willingness"
            },
            {
                "name": "distrainer"
            },
            {
                "name": "comprehensive"
            },
            {
                "name": "option"
            },
            {
                "name": "independence"
            },
            {
                "name": "intimation"
            },
            {
                "name": "parks"
            },
            {
                "name": "correctness"
            },
            {
                "name": "spoiling"
            },
            {
                "name": "dg"
            },
            {
                "name": "discontinued"
            },
            {
                "name": "mount"
            },
            {
                "name": "dedicated"
            },
            {
                "name": "empowered"
            },
            {
                "name": "holing"
            },
            {
                "name": "governors"
            },
            {
                "name": "expediting"
            },
            {
                "name": "opening"
            },
            {
                "name": "economic"
            },
            {
                "name": "accommodated"
            },
            {
                "name": "appear"
            },
            {
                "name": "offers"
            },
            {
                "name": "particulars"
            },
            {
                "name": "dispossession"
            },
            {
                "name": "enforced"
            },
            {
                "name": "worship"
            },
            {
                "name": "inform"
            },
            {
                "name": "tracts"
            },
            {
                "name": "examined"
            },
            {
                "name": "blasting"
            },
            {
                "name": "advocate"
            },
            {
                "name": "contains"
            },
            {
                "name": "growing"
            },
            {
                "name": "tramways"
            },
            {
                "name": "import"
            },
            {
                "name": "instruction"
            },
            {
                "name": "scrutiny"
            },
            {
                "name": "february"
            },
            {
                "name": "function"
            },
            {
                "name": "apartment"
            },
            {
                "name": "1800"
            },
            {
                "name": "guarantees"
            },
            {
                "name": "compensations"
            },
            {
                "name": "huf"
            },
            {
                "name": "purposes"
            },
            {
                "name": "proving"
            },
            {
                "name": "image"
            },
            {
                "name": "depending"
            },
            {
                "name": "heriy"
            },
            {
                "name": "auditors"
            },
            {
                "name": "inquiry"
            },
            {
                "name": "regulate"
            },
            {
                "name": "relics"
            },
            {
                "name": "selecting"
            },
            {
                "name": "mismanaging"
            },
            {
                "name": "logging"
            },
            {
                "name": "legality"
            },
            {
                "name": "consolidating"
            },
            {
                "name": "tempest"
            },
            {
                "name": "lieu"
            },
            {
                "name": "lose"
            },
            {
                "name": "consumed"
            },
            {
                "name": "games"
            },
            {
                "name": "valleys"
            },
            {
                "name": "manager"
            },
            {
                "name": "trying"
            },
            {
                "name": "1940"
            },
            {
                "name": "x"
            },
            {
                "name": "houses"
            },
            {
                "name": "crossing"
            },
            {
                "name": "pending"
            },
            {
                "name": "regularly"
            },
            {
                "name": "transport"
            },
            {
                "name": "waterwork"
            },
            {
                "name": "especially"
            },
            {
                "name": "handpump"
            },
            {
                "name": "khesra"
            },
            {
                "name": "fruits"
            },
            {
                "name": "mafigoraiti"
            },
            {
                "name": "acquirer"
            },
            {
                "name": "mouzadar"
            },
            {
                "name": "chapter"
            },
            {
                "name": "installment"
            },
            {
                "name": "meeting"
            },
            {
                "name": "restoration"
            },
            {
                "name": "ribbon"
            },
            {
                "name": "xvii"
            },
            {
                "name": "demine"
            },
            {
                "name": "elder"
            },
            {
                "name": "obtained"
            },
            {
                "name": "obey"
            },
            {
                "name": "system"
            },
            {
                "name": "chosen"
            },
            {
                "name": "plumbers"
            },
            {
                "name": "exceptions"
            },
            {
                "name": "hich"
            },
            {
                "name": "guardian"
            },
            {
                "name": "legislative"
            },
            {
                "name": "regularise"
            },
            {
                "name": "reduction"
            },
            {
                "name": "distraints"
            },
            {
                "name": "texture"
            },
            {
                "name": "expenditures"
            },
            {
                "name": "cognisable"
            },
            {
                "name": "entity"
            },
            {
                "name": "business"
            },
            {
                "name": "refuses"
            },
            {
                "name": "withhold"
            },
            {
                "name": "acceptable"
            },
            {
                "name": "severally"
            },
            {
                "name": "2004"
            },
            {
                "name": "updating"
            },
            {
                "name": "dropped"
            },
            {
                "name": "retains"
            },
            {
                "name": "list"
            },
            {
                "name": "facilities"
            },
            {
                "name": "clarified"
            },
            {
                "name": "continuous"
            },
            {
                "name": "apprehend"
            },
            {
                "name": "tree"
            },
            {
                "name": "kinds"
            },
            {
                "name": "cognates"
            },
            {
                "name": "panch"
            },
            {
                "name": "h"
            },
            {
                "name": "1996"
            },
            {
                "name": "possibility"
            },
            {
                "name": "burning"
            },
            {
                "name": "vessel"
            },
            {
                "name": "casuarina"
            },
            {
                "name": "mode"
            },
            {
                "name": "provisions"
            },
            {
                "name": "pattadas"
            },
            {
                "name": "consisting"
            },
            {
                "name": "role"
            },
            {
                "name": "disconnected"
            },
            {
                "name": "sc"
            },
            {
                "name": "membership"
            },
            {
                "name": "enemy"
            },
            {
                "name": "offered"
            },
            {
                "name": "villagers"
            },
            {
                "name": "capacity"
            },
            {
                "name": "maliks"
            },
            {
                "name": "1941"
            },
            {
                "name": "conceptualise"
            },
            {
                "name": "sue"
            },
            {
                "name": "bar"
            },
            {
                "name": "establishment"
            },
            {
                "name": "dairies"
            },
            {
                "name": "estimated"
            },
            {
                "name": "awarding"
            },
            {
                "name": "foodstuffs"
            },
            {
                "name": "secretariat"
            },
            {
                "name": "injuring"
            },
            {
                "name": "proven"
            },
            {
                "name": "pertains"
            },
            {
                "name": "yield"
            },
            {
                "name": "enlisted"
            },
            {
                "name": "disregarded"
            },
            {
                "name": "ell"
            },
            {
                "name": "preservation"
            },
            {
                "name": "awarded"
            },
            {
                "name": "1950"
            },
            {
                "name": "extending"
            },
            {
                "name": "bye"
            },
            {
                "name": "diminution"
            },
            {
                "name": "residential"
            },
            {
                "name": "scs"
            },
            {
                "name": "dmf"
            },
            {
                "name": "involving"
            },
            {
                "name": "farmer"
            },
            {
                "name": "fine"
            },
            {
                "name": "wait"
            },
            {
                "name": "reviewing"
            },
            {
                "name": "midnapore"
            },
            {
                "name": "Daman and Diu"
            },
            {
                "name": "beneficiary"
            },
            {
                "name": "1964"
            },
            {
                "name": "alienable"
            },
            {
                "name": "offences"
            },
            {
                "name": "reclamation"
            },
            {
                "name": "pavements"
            },
            {
                "name": "reconsidered"
            },
            {
                "name": "depend"
            },
            {
                "name": "dead"
            },
            {
                "name": "bequeathing"
            },
            {
                "name": "irrigable"
            },
            {
                "name": "consent"
            },
            {
                "name": "communication"
            },
            {
                "name": "bridges"
            },
            {
                "name": "assertion"
            },
            {
                "name": "landowner"
            },
            {
                "name": "remunerated"
            },
            {
                "name": "implemented"
            },
            {
                "name": "declaration"
            },
            {
                "name": "requisition"
            },
            {
                "name": "taking"
            },
            {
                "name": "dissatisfied"
            },
            {
                "name": "validity"
            },
            {
                "name": "exclusively"
            },
            {
                "name": "independent"
            },
            {
                "name": "repaired"
            },
            {
                "name": "subject"
            },
            {
                "name": "composition"
            },
            {
                "name": "dissolved"
            },
            {
                "name": "lead"
            },
            {
                "name": "immediate"
            },
            {
                "name": "superintend"
            },
            {
                "name": "suffering"
            },
            {
                "name": "infirmaries"
            },
            {
                "name": "jagirs"
            },
            {
                "name": "1960"
            },
            {
                "name": "floating"
            },
            {
                "name": "access"
            },
            {
                "name": "solvency"
            },
            {
                "name": "deviation"
            },
            {
                "name": "permanently"
            },
            {
                "name": "pasturage"
            },
            {
                "name": "landlords"
            },
            {
                "name": "sustainable"
            },
            {
                "name": "tahsildar"
            },
            {
                "name": "councillor"
            },
            {
                "name": "objected"
            },
            {
                "name": "pipelines"
            },
            {
                "name": "apart"
            },
            {
                "name": "event"
            },
            {
                "name": "preside"
            },
            {
                "name": "disadvantage"
            },
            {
                "name": "corrected"
            },
            {
                "name": "technological"
            },
            {
                "name": "sufficiency"
            },
            {
                "name": "start"
            },
            {
                "name": "displacement"
            },
            {
                "name": "sendhi"
            },
            {
                "name": "annex"
            },
            {
                "name": "1978"
            },
            {
                "name": "reasons"
            },
            {
                "name": "bhudan"
            },
            {
                "name": "displaced"
            },
            {
                "name": "position"
            },
            {
                "name": "hire"
            },
            {
                "name": "talks"
            },
            {
                "name": "threatens"
            },
            {
                "name": "mean"
            },
            {
                "name": "backdrop"
            },
            {
                "name": "ranging"
            },
            {
                "name": "expected"
            },
            {
                "name": "dangerous"
            },
            {
                "name": "assistant"
            },
            {
                "name": "look"
            },
            {
                "name": "regularize"
            },
            {
                "name": "debris"
            },
            {
                "name": "neighbourhood"
            },
            {
                "name": "unpaid"
            },
            {
                "name": "settle"
            },
            {
                "name": "interests"
            },
            {
                "name": "monsoon"
            },
            {
                "name": "cremation"
            },
            {
                "name": "adhered"
            },
            {
                "name": "delimit"
            },
            {
                "name": "presume"
            },
            {
                "name": "desire"
            },
            {
                "name": "plantains"
            },
            {
                "name": "repair"
            },
            {
                "name": "fishing"
            },
            {
                "name": "announcing"
            },
            {
                "name": "deal"
            },
            {
                "name": "forwarded"
            },
            {
                "name": "departure"
            },
            {
                "name": "akharas"
            },
            {
                "name": "pensions"
            },
            {
                "name": "initiated"
            },
            {
                "name": "chairperson"
            },
            {
                "name": "bhunidhar"
            },
            {
                "name": "pendency"
            },
            {
                "name": "showing"
            },
            {
                "name": "despite"
            },
            {
                "name": "cessation"
            },
            {
                "name": "finalise"
            },
            {
                "name": "kankar"
            },
            {
                "name": "run"
            },
            {
                "name": "fourth"
            },
            {
                "name": "govern"
            },
            {
                "name": "1918"
            },
            {
                "name": "relinquish"
            },
            {
                "name": "1947"
            },
            {
                "name": "endanger"
            },
            {
                "name": "contested"
            },
            {
                "name": "marumakkatayam"
            },
            {
                "name": "fifths"
            },
            {
                "name": "payee"
            },
            {
                "name": "kalumalupara"
            },
            {
                "name": "reassign"
            },
            {
                "name": "enacted"
            },
            {
                "name": "ejecting"
            },
            {
                "name": "phases"
            },
            {
                "name": "declared"
            },
            {
                "name": "doubt"
            },
            {
                "name": "award"
            },
            {
                "name": "1957"
            },
            {
                "name": "died"
            },
            {
                "name": "n"
            },
            {
                "name": "accepts"
            },
            {
                "name": "cost"
            },
            {
                "name": "palms"
            },
            {
                "name": "organised"
            },
            {
                "name": "acceding"
            },
            {
                "name": "enhancement"
            },
            {
                "name": "association"
            },
            {
                "name": "nuisances"
            },
            {
                "name": "locked"
            },
            {
                "name": "administering"
            },
            {
                "name": "circle"
            },
            {
                "name": "truth"
            },
            {
                "name": "ponies"
            },
            {
                "name": "disturb"
            },
            {
                "name": "dies"
            },
            {
                "name": "felling"
            },
            {
                "name": "aligned"
            },
            {
                "name": "habits"
            },
            {
                "name": "economy"
            },
            {
                "name": "champaner"
            },
            {
                "name": "propagating"
            },
            {
                "name": "interest"
            },
            {
                "name": "season"
            },
            {
                "name": "vi"
            },
            {
                "name": "fishery"
            },
            {
                "name": "comes"
            },
            {
                "name": "pull"
            },
            {
                "name": "museums"
            },
            {
                "name": "lambs"
            },
            {
                "name": "posted"
            },
            {
                "name": "jharkhand"
            },
            {
                "name": "ditches"
            },
            {
                "name": "collapse"
            },
            {
                "name": "recover"
            },
            {
                "name": "acquiescence"
            },
            {
                "name": "levied"
            },
            {
                "name": "disallow"
            },
            {
                "name": "free"
            },
            {
                "name": "gymnasia"
            },
            {
                "name": "indicated"
            },
            {
                "name": "engineering"
            },
            {
                "name": "aai"
            },
            {
                "name": "buffaloes"
            },
            {
                "name": "1812"
            },
            {
                "name": "statute"
            },
            {
                "name": "intensity"
            },
            {
                "name": "prosecute"
            },
            {
                "name": "money"
            },
            {
                "name": "science"
            },
            {
                "name": "diminish"
            },
            {
                "name": "spraying"
            },
            {
                "name": "test"
            },
            {
                "name": "planted"
            },
            {
                "name": "central"
            },
            {
                "name": "mistake"
            },
            {
                "name": "reliefs"
            },
            {
                "name": "goats"
            },
            {
                "name": "sikh"
            },
            {
                "name": "managed"
            },
            {
                "name": "increasing"
            },
            {
                "name": "impounding"
            },
            {
                "name": "accept"
            },
            {
                "name": "nursery"
            },
            {
                "name": "rape"
            },
            {
                "name": "firstly"
            },
            {
                "name": "curing"
            },
            {
                "name": "kashmir"
            },
            {
                "name": "creates"
            },
            {
                "name": "got"
            },
            {
                "name": "pujaris"
            },
            {
                "name": "contracted"
            },
            {
                "name": "camels"
            },
            {
                "name": "relations"
            },
            {
                "name": "principle"
            },
            {
                "name": "mulraiyat"
            },
            {
                "name": "inconsistent"
            },
            {
                "name": "narrowness"
            },
            {
                "name": "colts"
            },
            {
                "name": "defacing"
            },
            {
                "name": "allotting"
            },
            {
                "name": "profits"
            },
            {
                "name": "try"
            },
            {
                "name": "donations"
            },
            {
                "name": "nallas"
            },
            {
                "name": "selected"
            },
            {
                "name": "title"
            },
            {
                "name": "goondas"
            },
            {
                "name": "registered"
            },
            {
                "name": "grants"
            },
            {
                "name": "1935"
            },
            {
                "name": "final"
            },
            {
                "name": "inedible"
            },
            {
                "name": "birth"
            },
            {
                "name": "stationary"
            },
            {
                "name": "circulation"
            },
            {
                "name": "survivorship"
            },
            {
                "name": "directed"
            },
            {
                "name": "unsecured"
            },
            {
                "name": "separated"
            },
            {
                "name": "appraised"
            },
            {
                "name": "integration"
            },
            {
                "name": "making"
            },
            {
                "name": "sales"
            },
            {
                "name": "ipc"
            },
            {
                "name": "letting"
            },
            {
                "name": "perpetrator"
            },
            {
                "name": "wilful"
            },
            {
                "name": "boards"
            },
            {
                "name": "successions"
            },
            {
                "name": "anticipation"
            },
            {
                "name": "agriculture"
            },
            {
                "name": "altered"
            },
            {
                "name": "cuts"
            },
            {
                "name": "connections"
            },
            {
                "name": "properties"
            },
            {
                "name": "admissions"
            },
            {
                "name": "criminal"
            },
            {
                "name": "secunderabad"
            },
            {
                "name": "1887"
            },
            {
                "name": "agencies"
            },
            {
                "name": "approach"
            },
            {
                "name": "gates"
            },
            {
                "name": "2006"
            },
            {
                "name": "goa"
            },
            {
                "name": "determined"
            },
            {
                "name": "milkiyat"
            },
            {
                "name": "acquittal"
            },
            {
                "name": "wall"
            },
            {
                "name": "eviction"
            },
            {
                "name": "contesting"
            },
            {
                "name": "cash"
            },
            {
                "name": "convert"
            },
            {
                "name": "wastes"
            },
            {
                "name": "manufacturers"
            },
            {
                "name": "citizen"
            },
            {
                "name": "highway"
            },
            {
                "name": "decentralization"
            },
            {
                "name": "occupancy"
            },
            {
                "name": "proprietors"
            },
            {
                "name": "execution"
            },
            {
                "name": "purported"
            },
            {
                "name": "previously"
            },
            {
                "name": "hartals"
            },
            {
                "name": "alkalinity"
            },
            {
                "name": "adult"
            },
            {
                "name": "deduced"
            },
            {
                "name": "attainment"
            },
            {
                "name": "appeals"
            },
            {
                "name": "requested"
            },
            {
                "name": "inserted"
            },
            {
                "name": "betterment"
            },
            {
                "name": "pisciculture"
            },
            {
                "name": "assigns"
            },
            {
                "name": "scavenging"
            },
            {
                "name": "states"
            },
            {
                "name": "privies"
            },
            {
                "name": "advantages"
            },
            {
                "name": "support"
            },
            {
                "name": "1963"
            },
            {
                "name": "alienated"
            },
            {
                "name": "rejection"
            },
            {
                "name": "consanguinity"
            },
            {
                "name": "calculate"
            },
            {
                "name": "alterations"
            },
            {
                "name": "compel"
            },
            {
                "name": "express"
            },
            {
                "name": "proceeded"
            },
            {
                "name": "promotor"
            },
            {
                "name": "1948"
            },
            {
                "name": "parts"
            },
            {
                "name": "subordination"
            },
            {
                "name": "withheld"
            },
            {
                "name": "parsis"
            },
            {
                "name": "vacated"
            },
            {
                "name": "pools"
            },
            {
                "name": "carry"
            },
            {
                "name": "rivers"
            },
            {
                "name": "experts"
            },
            {
                "name": "discretion"
            },
            {
                "name": "public"
            },
            {
                "name": "justify"
            },
            {
                "name": "degree"
            },
            {
                "name": "abetment"
            },
            {
                "name": "gholla"
            },
            {
                "name": "maybe"
            },
            {
                "name": "reason"
            },
            {
                "name": "divorce"
            },
            {
                "name": "partitioned"
            },
            {
                "name": "marriage"
            },
            {
                "name": "effecting"
            },
            {
                "name": "coffee"
            },
            {
                "name": "registrations"
            },
            {
                "name": "cottage"
            },
            {
                "name": "licensors"
            },
            {
                "name": "avalanches"
            },
            {
                "name": "modify"
            },
            {
                "name": "maps"
            },
            {
                "name": "survey"
            },
            {
                "name": "finalize"
            },
            {
                "name": "landholdings"
            },
            {
                "name": "effectuate"
            },
            {
                "name": "suspend"
            },
            {
                "name": "treating"
            },
            {
                "name": "mare"
            },
            {
                "name": "prospecting"
            },
            {
                "name": "girdling"
            },
            {
                "name": "compromise"
            },
            {
                "name": "dilution"
            },
            {
                "name": "reorganizing"
            },
            {
                "name": "executed"
            },
            {
                "name": "amend"
            },
            {
                "name": "incumbency"
            },
            {
                "name": "annul"
            },
            {
                "name": "reach"
            },
            {
                "name": "tasks"
            },
            {
                "name": "aesthetic"
            },
            {
                "name": "prejudicial"
            },
            {
                "name": "office"
            },
            {
                "name": "calcutta"
            },
            {
                "name": "willing"
            },
            {
                "name": "2013"
            },
            {
                "name": "cloth"
            },
            {
                "name": "zoo"
            },
            {
                "name": "contingencies"
            },
            {
                "name": "contraventions"
            },
            {
                "name": "heights"
            },
            {
                "name": "drawbacks"
            },
            {
                "name": "reports"
            },
            {
                "name": "fares"
            },
            {
                "name": "acquisition"
            },
            {
                "name": "1879"
            },
            {
                "name": "organizing"
            },
            {
                "name": "inams"
            },
            {
                "name": "stopping"
            },
            {
                "name": "started"
            },
            {
                "name": "delineation"
            },
            {
                "name": "wealth"
            },
            {
                "name": "sends"
            },
            {
                "name": "assess"
            },
            {
                "name": "misuse"
            },
            {
                "name": "prefer"
            },
            {
                "name": "clearance"
            },
            {
                "name": "hospital"
            },
            {
                "name": "seven"
            },
            {
                "name": "come"
            },
            {
                "name": "paper"
            },
            {
                "name": "gardening"
            },
            {
                "name": "definitions"
            },
            {
                "name": "livestock"
            },
            {
                "name": "study"
            },
            {
                "name": "reception"
            },
            {
                "name": "twice"
            },
            {
                "name": "showroom"
            },
            {
                "name": "officer"
            },
            {
                "name": "evaluate"
            },
            {
                "name": "grassland"
            },
            {
                "name": "basically"
            },
            {
                "name": "flag"
            },
            {
                "name": "girasdars"
            },
            {
                "name": "prawn"
            },
            {
                "name": "distance"
            },
            {
                "name": "compelling"
            },
            {
                "name": "legal"
            },
            {
                "name": "define"
            },
            {
                "name": "aware"
            },
            {
                "name": "shareholder"
            },
            {
                "name": "diu"
            },
            {
                "name": "calamities"
            },
            {
                "name": "apartments"
            },
            {
                "name": "1975"
            },
            {
                "name": "opposition"
            },
            {
                "name": "resigned"
            },
            {
                "name": "efforts"
            },
            {
                "name": "fittings"
            },
            {
                "name": "improve"
            },
            {
                "name": "Goa"
            },
            {
                "name": "parcels"
            },
            {
                "name": "adopt"
            },
            {
                "name": "launched"
            },
            {
                "name": "baluchistan"
            },
            {
                "name": "despoilment"
            },
            {
                "name": "chairpersons"
            },
            {
                "name": "walls"
            },
            {
                "name": "density"
            },
            {
                "name": "promulgates"
            },
            {
                "name": "statement"
            },
            {
                "name": "1955"
            },
            {
                "name": "began"
            },
            {
                "name": "suitability"
            },
            {
                "name": "sustained"
            },
            {
                "name": "mob"
            },
            {
                "name": "difficulties"
            },
            {
                "name": "future"
            },
            {
                "name": "grain"
            },
            {
                "name": "lunatic"
            },
            {
                "name": "bazaars"
            },
            {
                "name": "panchayat"
            },
            {
                "name": "prioritized"
            },
            {
                "name": "consulted"
            },
            {
                "name": "repeals"
            },
            {
                "name": "gazette"
            },
            {
                "name": "interferes"
            },
            {
                "name": "discontinuation"
            },
            {
                "name": "threshold"
            },
            {
                "name": "builder"
            },
            {
                "name": "prepared"
            },
            {
                "name": "breaking"
            },
            {
                "name": "rainfall"
            },
            {
                "name": "advice"
            },
            {
                "name": "thanas"
            },
            {
                "name": "mla"
            },
            {
                "name": "nuisance"
            },
            {
                "name": "ownership"
            },
            {
                "name": "l"
            },
            {
                "name": "donor"
            },
            {
                "name": "circles"
            },
            {
                "name": "homesteads"
            },
            {
                "name": "market"
            },
            {
                "name": "omit"
            },
            {
                "name": "regard"
            },
            {
                "name": "painting"
            },
            {
                "name": "carrying"
            },
            {
                "name": "passbooks"
            },
            {
                "name": "extinguishes"
            },
            {
                "name": "discount"
            },
            {
                "name": "revocation"
            },
            {
                "name": "safeguard"
            },
            {
                "name": "1871"
            },
            {
                "name": "playground"
            },
            {
                "name": "slip"
            },
            {
                "name": "characterizes"
            },
            {
                "name": "inmates"
            },
            {
                "name": "stadia"
            },
            {
                "name": "establishes"
            },
            {
                "name": "2010"
            },
            {
                "name": "officers"
            },
            {
                "name": "invoices"
            },
            {
                "name": "command"
            },
            {
                "name": "paddy"
            },
            {
                "name": "photocopy"
            },
            {
                "name": "sarais"
            },
            {
                "name": "submitting"
            },
            {
                "name": "determinative"
            },
            {
                "name": "practice"
            },
            {
                "name": "activity"
            },
            {
                "name": "coke"
            },
            {
                "name": "claiming"
            },
            {
                "name": "libraries"
            },
            {
                "name": "aggrieved"
            },
            {
                "name": "remove"
            },
            {
                "name": "slope"
            },
            {
                "name": "resume"
            },
            {
                "name": "enlarging"
            },
            {
                "name": "chance"
            },
            {
                "name": "recognition"
            },
            {
                "name": "replacement"
            },
            {
                "name": "co"
            },
            {
                "name": "including"
            },
            {
                "name": "storms"
            },
            {
                "name": "barring"
            },
            {
                "name": "care"
            },
            {
                "name": "authorised"
            },
            {
                "name": "approaches"
            },
            {
                "name": "quorum"
            },
            {
                "name": "adhyaksha"
            },
            {
                "name": "valued"
            },
            {
                "name": "canals"
            },
            {
                "name": "utilization"
            },
            {
                "name": "cenotaph"
            },
            {
                "name": "conveyed"
            },
            {
                "name": "avoidance"
            },
            {
                "name": "succeed"
            },
            {
                "name": "remitted"
            },
            {
                "name": "k"
            },
            {
                "name": "varying"
            },
            {
                "name": "leprosy"
            },
            {
                "name": "1856"
            },
            {
                "name": "bighori"
            },
            {
                "name": "syphon"
            },
            {
                "name": "papers"
            },
            {
                "name": "indulge"
            },
            {
                "name": "rams"
            },
            {
                "name": "enjoy"
            },
            {
                "name": "adhiar"
            },
            {
                "name": "floral"
            },
            {
                "name": "interfering"
            },
            {
                "name": "elect"
            },
            {
                "name": "engage"
            },
            {
                "name": "violence"
            },
            {
                "name": "factory"
            },
            {
                "name": "established"
            },
            {
                "name": "restaurants"
            },
            {
                "name": "ascertain"
            },
            {
                "name": "constructions"
            },
            {
                "name": "confiscated"
            },
            {
                "name": "redetermination"
            },
            {
                "name": "shafts"
            },
            {
                "name": "lamp"
            },
            {
                "name": "sums"
            },
            {
                "name": "furnishing"
            },
            {
                "name": "interestingly"
            },
            {
                "name": "invalidated"
            },
            {
                "name": "sunset"
            },
            {
                "name": "happened"
            },
            {
                "name": "appertaining"
            },
            {
                "name": "sources"
            },
            {
                "name": "cases"
            },
            {
                "name": "low"
            },
            {
                "name": "mosque"
            },
            {
                "name": "illustrations"
            },
            {
                "name": "fountains"
            },
            {
                "name": "suit"
            },
            {
                "name": "inquire"
            },
            {
                "name": "workshops"
            },
            {
                "name": "housing"
            },
            {
                "name": "1977"
            },
            {
                "name": "furthering"
            },
            {
                "name": "aquatic"
            },
            {
                "name": "radius"
            },
            {
                "name": "hut"
            },
            {
                "name": "fallow"
            },
            {
                "name": "budgetary"
            },
            {
                "name": "citizenship"
            },
            {
                "name": "arrears"
            },
            {
                "name": "defend"
            },
            {
                "name": "joining"
            },
            {
                "name": "negligently"
            },
            {
                "name": "heard"
            },
            {
                "name": "prior"
            },
            {
                "name": "enclaves"
            },
            {
                "name": "attachments"
            },
            {
                "name": "semi"
            },
            {
                "name": "2003"
            },
            {
                "name": "epidemic"
            },
            {
                "name": "advances"
            },
            {
                "name": "processed"
            },
            {
                "name": "resolved"
            },
            {
                "name": "attained"
            },
            {
                "name": "regrant"
            },
            {
                "name": "cess"
            },
            {
                "name": "courses"
            },
            {
                "name": "prescribe"
            },
            {
                "name": "raiyat"
            },
            {
                "name": "reserved"
            },
            {
                "name": "dwellings"
            },
            {
                "name": "detrimental"
            },
            {
                "name": "seventy"
            },
            {
                "name": "incidence"
            },
            {
                "name": "incurred"
            },
            {
                "name": "sine"
            },
            {
                "name": "requirement"
            },
            {
                "name": "movable"
            },
            {
                "name": "animal"
            },
            {
                "name": "included"
            },
            {
                "name": "heritage"
            },
            {
                "name": "administrative"
            },
            {
                "name": "disobedience"
            },
            {
                "name": "nazims"
            },
            {
                "name": "reclassified"
            },
            {
                "name": "organising"
            },
            {
                "name": "appealed"
            },
            {
                "name": "drought"
            },
            {
                "name": "trades"
            },
            {
                "name": "bequeathed"
            },
            {
                "name": "vero"
            },
            {
                "name": "vide"
            },
            {
                "name": "collect"
            },
            {
                "name": "nil"
            },
            {
                "name": "arrest"
            },
            {
                "name": "hygiene"
            },
            {
                "name": "bir"
            },
            {
                "name": "vacating"
            },
            {
                "name": "arrange"
            },
            {
                "name": "station"
            },
            {
                "name": "cag"
            },
            {
                "name": "habitation"
            },
            {
                "name": "disagreement"
            },
            {
                "name": "abatement"
            },
            {
                "name": "believe"
            },
            {
                "name": "registers"
            },
            {
                "name": "bound"
            },
            {
                "name": "barkhalidars"
            },
            {
                "name": "Meghalaya"
            },
            {
                "name": "monitor"
            },
            {
                "name": "ayacut"
            },
            {
                "name": "subways"
            },
            {
                "name": "Tripura"
            },
            {
                "name": "feeding"
            },
            {
                "name": "paragraph"
            },
            {
                "name": "people"
            },
            {
                "name": "carcasses"
            },
            {
                "name": "mares"
            },
            {
                "name": "maintaining"
            },
            {
                "name": "lenders"
            },
            {
                "name": "laid"
            },
            {
                "name": "disability"
            },
            {
                "name": "Andhra Pradesh"
            },
            {
                "name": "rescue"
            },
            {
                "name": "issuance"
            },
            {
                "name": "subsist"
            },
            {
                "name": "visitors"
            },
            {
                "name": "pre"
            },
            {
                "name": "contained"
            },
            {
                "name": "mamlatdar"
            },
            {
                "name": "gratuity"
            },
            {
                "name": "abadis"
            },
            {
                "name": "inflation"
            },
            {
                "name": "reasoning"
            },
            {
                "name": "lighted"
            },
            {
                "name": "bhuinhar"
            },
            {
                "name": "neeradi"
            },
            {
                "name": "charges"
            },
            {
                "name": "agriculturalist"
            },
            {
                "name": "starting"
            },
            {
                "name": "availability"
            },
            {
                "name": "manage"
            },
            {
                "name": "sethsindhies"
            },
            {
                "name": "mutations"
            },
            {
                "name": "recourse"
            },
            {
                "name": "tenancies"
            },
            {
                "name": "muslim"
            },
            {
                "name": "gate"
            },
            {
                "name": "effected"
            },
            {
                "name": "sale"
            },
            {
                "name": "insured"
            },
            {
                "name": "institution"
            },
            {
                "name": "adjustment"
            },
            {
                "name": "wetland"
            },
            {
                "name": "salary"
            },
            {
                "name": "mortgagees"
            },
            {
                "name": "cotton"
            },
            {
                "name": "rejects"
            },
            {
                "name": "furtherance"
            },
            {
                "name": "alienations"
            },
            {
                "name": "divided"
            },
            {
                "name": "subdivisions"
            },
            {
                "name": "sets"
            },
            {
                "name": "incapable"
            },
            {
                "name": "bore"
            },
            {
                "name": "1987"
            },
            {
                "name": "huk"
            },
            {
                "name": "residing"
            },
            {
                "name": "remedy"
            },
            {
                "name": "happens"
            },
            {
                "name": "railways"
            },
            {
                "name": "paved"
            },
            {
                "name": "interment"
            },
            {
                "name": "istimrar"
            },
            {
                "name": "keeper"
            },
            {
                "name": "subheading"
            },
            {
                "name": "hill"
            },
            {
                "name": "distribution"
            },
            {
                "name": "prevention"
            },
            {
                "name": "personal"
            },
            {
                "name": "molests"
            },
            {
                "name": "prescribes"
            },
            {
                "name": "sovereignty"
            },
            {
                "name": "dacoity"
            },
            {
                "name": "easements"
            },
            {
                "name": "proper"
            },
            {
                "name": "authorise"
            },
            {
                "name": "dust"
            },
            {
                "name": "fill"
            },
            {
                "name": "disturbance"
            },
            {
                "name": "saved"
            },
            {
                "name": "regularisation"
            },
            {
                "name": "fencing"
            },
            {
                "name": "lists"
            },
            {
                "name": "found"
            },
            {
                "name": "erection"
            },
            {
                "name": "damage"
            },
            {
                "name": "judgement"
            },
            {
                "name": "dispensary"
            },
            {
                "name": "thoks"
            },
            {
                "name": "sugar"
            },
            {
                "name": "precondition"
            },
            {
                "name": "entered"
            },
            {
                "name": "distributaries"
            },
            {
                "name": "proprietary"
            },
            {
                "name": "physical"
            },
            {
                "name": "projection"
            },
            {
                "name": "listed"
            },
            {
                "name": "organisation"
            },
            {
                "name": "foundations"
            },
            {
                "name": "case"
            },
            {
                "name": "slaves"
            },
            {
                "name": "restricting"
            },
            {
                "name": "seizing"
            },
            {
                "name": "1859"
            },
            {
                "name": "misconduct"
            },
            {
                "name": "organizer"
            },
            {
                "name": "dividends"
            },
            {
                "name": "promoting"
            },
            {
                "name": "protect"
            },
            {
                "name": "ratio"
            },
            {
                "name": "police"
            },
            {
                "name": "reopened"
            },
            {
                "name": "leaseholds"
            },
            {
                "name": "average"
            },
            {
                "name": "published"
            },
            {
                "name": "prosecuted"
            },
            {
                "name": "contemplation"
            },
            {
                "name": "imperilling"
            },
            {
                "name": "Arunachal Pradesh"
            },
            {
                "name": "disabled"
            },
            {
                "name": "depreciation"
            },
            {
                "name": "scenario"
            },
            {
                "name": "cancellation"
            },
            {
                "name": "forwarding"
            },
            {
                "name": "arrived"
            },
            {
                "name": "controlled"
            },
            {
                "name": "godavari"
            },
            {
                "name": "Punjab"
            },
            {
                "name": "arisen"
            },
            {
                "name": "harmed"
            },
            {
                "name": "untrue"
            },
            {
                "name": "expansions"
            },
            {
                "name": "withholds"
            },
            {
                "name": "action"
            },
            {
                "name": "christian"
            },
            {
                "name": "vacation"
            },
            {
                "name": "suitable"
            },
            {
                "name": "defence"
            },
            {
                "name": "unauthorised"
            },
            {
                "name": "stead"
            },
            {
                "name": "watch"
            },
            {
                "name": "detriment"
            },
            {
                "name": "slums"
            },
            {
                "name": "bringing"
            },
            {
                "name": "children"
            },
            {
                "name": "pesa"
            },
            {
                "name": "solve"
            },
            {
                "name": "issuing"
            },
            {
                "name": "abwab"
            },
            {
                "name": "dharnas"
            },
            {
                "name": "niti"
            },
            {
                "name": "things"
            },
            {
                "name": "scope"
            },
            {
                "name": "cowardice"
            },
            {
                "name": "votes"
            },
            {
                "name": "altering"
            },
            {
                "name": "raise"
            },
            {
                "name": "confirmation"
            },
            {
                "name": "urinals"
            },
            {
                "name": "formation"
            },
            {
                "name": "pledge"
            },
            {
                "name": "parganas"
            },
            {
                "name": "revenues"
            },
            {
                "name": "lessors"
            },
            {
                "name": "passbook"
            },
            {
                "name": "changing"
            },
            {
                "name": "watercourse"
            },
            {
                "name": "grave"
            },
            {
                "name": "post"
            },
            {
                "name": "ceiled"
            },
            {
                "name": "mitigation"
            },
            {
                "name": "cracks"
            },
            {
                "name": "length"
            },
            {
                "name": "tenders"
            },
            {
                "name": "blocking"
            },
            {
                "name": "users"
            },
            {
                "name": "supposed"
            },
            {
                "name": "neglects"
            },
            {
                "name": "andaman"
            },
            {
                "name": "ordinances"
            },
            {
                "name": "parti"
            },
            {
                "name": "incorporeal"
            },
            {
                "name": "padugais"
            },
            {
                "name": "requisitioning"
            },
            {
                "name": "causing"
            },
            {
                "name": "classifying"
            },
            {
                "name": "diseased"
            },
            {
                "name": "1863"
            },
            {
                "name": "subordinates"
            },
            {
                "name": "informed"
            },
            {
                "name": "usually"
            },
            {
                "name": "inspections"
            },
            {
                "name": "ranchi"
            },
            {
                "name": "recorded"
            },
            {
                "name": "resettled"
            },
            {
                "name": "rearrangement"
            },
            {
                "name": "difference"
            },
            {
                "name": "march"
            },
            {
                "name": "insanitary"
            },
            {
                "name": "discontinues"
            },
            {
                "name": "scaffolding"
            },
            {
                "name": "engineers"
            },
            {
                "name": "tabled"
            },
            {
                "name": "1965"
            },
            {
                "name": "executor"
            },
            {
                "name": "affixed"
            },
            {
                "name": "refusal"
            },
            {
                "name": "officials"
            },
            {
                "name": "respectively"
            },
            {
                "name": "invalidate"
            },
            {
                "name": "multi"
            },
            {
                "name": "sanitary"
            },
            {
                "name": "casual"
            },
            {
                "name": "Gujarat"
            },
            {
                "name": "marries"
            },
            {
                "name": "norms"
            },
            {
                "name": "indebted"
            },
            {
                "name": "beneficial"
            },
            {
                "name": "tourists"
            },
            {
                "name": "disqualification"
            },
            {
                "name": "apparel"
            },
            {
                "name": "arising"
            },
            {
                "name": "processions"
            },
            {
                "name": "metalled"
            },
            {
                "name": "technology"
            },
            {
                "name": "intermediaries"
            },
            {
                "name": "idiot"
            },
            {
                "name": "survived"
            },
            {
                "name": "managers"
            },
            {
                "name": "freed"
            },
            {
                "name": "entertain"
            },
            {
                "name": "1899"
            },
            {
                "name": "ordinating"
            },
            {
                "name": "area"
            },
            {
                "name": "definition"
            },
            {
                "name": "petitions"
            },
            {
                "name": "review"
            },
            {
                "name": "fields"
            },
            {
                "name": "successful"
            },
            {
                "name": "orphanage"
            },
            {
                "name": "licensees"
            },
            {
                "name": "claimed"
            },
            {
                "name": "titles"
            },
            {
                "name": "injustice"
            },
            {
                "name": "antiquities"
            },
            {
                "name": "memory"
            },
            {
                "name": "expressions"
            },
            {
                "name": "mukhiya"
            },
            {
                "name": "sandalwood"
            },
            {
                "name": "divorced"
            },
            {
                "name": "royalty"
            },
            {
                "name": "airways"
            },
            {
                "name": "presented"
            },
            {
                "name": "basic"
            },
            {
                "name": "draft"
            },
            {
                "name": "military"
            },
            {
                "name": "aquifer"
            },
            {
                "name": "assessed"
            },
            {
                "name": "qua"
            },
            {
                "name": "grantor"
            },
            {
                "name": "plantations"
            },
            {
                "name": "penalties"
            },
            {
                "name": "posts"
            },
            {
                "name": "equality"
            },
            {
                "name": "surveys"
            },
            {
                "name": "replies"
            },
            {
                "name": "proportionate"
            },
            {
                "name": "courts"
            },
            {
                "name": "obtaining"
            },
            {
                "name": "share"
            },
            {
                "name": "operative"
            },
            {
                "name": "eat"
            },
            {
                "name": "sued"
            },
            {
                "name": "wills"
            },
            {
                "name": "incumbrances"
            },
            {
                "name": "assessees"
            },
            {
                "name": "taluks"
            },
            {
                "name": "succession"
            },
            {
                "name": "xvi"
            },
            {
                "name": "initiative"
            },
            {
                "name": "trust"
            },
            {
                "name": "recognizes"
            },
            {
                "name": "agnate"
            },
            {
                "name": "erect"
            },
            {
                "name": "periphery"
            },
            {
                "name": "guilty"
            },
            {
                "name": "redelivered"
            },
            {
                "name": "grantee"
            },
            {
                "name": "settlement"
            },
            {
                "name": "commit"
            },
            {
                "name": "sheep"
            },
            {
                "name": "1828"
            },
            {
                "name": "steams"
            },
            {
                "name": "satisfies"
            },
            {
                "name": "bullion"
            },
            {
                "name": "convey"
            },
            {
                "name": "areas"
            },
            {
                "name": "employed"
            },
            {
                "name": "install"
            },
            {
                "name": "victims"
            },
            {
                "name": "expended"
            },
            {
                "name": "referee"
            },
            {
                "name": "build"
            },
            {
                "name": "1823"
            },
            {
                "name": "underground"
            },
            {
                "name": "self"
            },
            {
                "name": "channel"
            },
            {
                "name": "subedar"
            },
            {
                "name": "conducive"
            },
            {
                "name": "imposed"
            },
            {
                "name": "2005"
            },
            {
                "name": "accessibility"
            },
            {
                "name": "followed"
            },
            {
                "name": "trusts"
            },
            {
                "name": "follows"
            },
            {
                "name": "consequential"
            },
            {
                "name": "continue"
            },
            {
                "name": "divisions"
            },
            {
                "name": "subordinate"
            },
            {
                "name": "carelessness"
            },
            {
                "name": "ongoing"
            },
            {
                "name": "imported"
            },
            {
                "name": "1891"
            },
            {
                "name": "met"
            },
            {
                "name": "apportioned"
            },
            {
                "name": "enlargement"
            },
            {
                "name": "describes"
            },
            {
                "name": "safety"
            },
            {
                "name": "subsisting"
            },
            {
                "name": "punishment"
            },
            {
                "name": "pollution"
            },
            {
                "name": "mains"
            },
            {
                "name": "encroachment"
            },
            {
                "name": "atrocities"
            },
            {
                "name": "demand"
            },
            {
                "name": "desecration"
            },
            {
                "name": "accumulation"
            },
            {
                "name": "running"
            },
            {
                "name": "computer"
            },
            {
                "name": "executors"
            },
            {
                "name": "deaf"
            },
            {
                "name": "prevent"
            },
            {
                "name": "proceedings"
            },
            {
                "name": "enjoyment"
            },
            {
                "name": "deems"
            },
            {
                "name": "inheriting"
            },
            {
                "name": "sealed"
            },
            {
                "name": "wastelands"
            },
            {
                "name": "beating"
            },
            {
                "name": "offence"
            },
            {
                "name": "consider"
            },
            {
                "name": "zinc"
            },
            {
                "name": "tractors"
            },
            {
                "name": "indirectly"
            },
            {
                "name": "sanctuaries"
            },
            {
                "name": "investigation"
            },
            {
                "name": "biodiversity"
            },
            {
                "name": "debentures"
            },
            {
                "name": "suggestion"
            },
            {
                "name": "afcs"
            },
            {
                "name": "assume"
            },
            {
                "name": "piece"
            },
            {
                "name": "1979"
            },
            {
                "name": "devotees"
            },
            {
                "name": "imbibed"
            },
            {
                "name": "appropriated"
            },
            {
                "name": "operate"
            },
            {
                "name": "attaching"
            },
            {
                "name": "1885"
            },
            {
                "name": "stalled"
            },
            {
                "name": "shrimp"
            },
            {
                "name": "senate"
            },
            {
                "name": "punjab"
            },
            {
                "name": "assist"
            },
            {
                "name": "reconstitution"
            },
            {
                "name": "hereditament"
            },
            {
                "name": "passage"
            },
            {
                "name": "dominions"
            },
            {
                "name": "dams"
            },
            {
                "name": "facilitate"
            },
            {
                "name": "sufficient"
            },
            {
                "name": "paid"
            },
            {
                "name": "midanpore"
            },
            {
                "name": "determine"
            },
            {
                "name": "khudkasht"
            },
            {
                "name": "sole"
            },
            {
                "name": "achieving"
            },
            {
                "name": "serve"
            },
            {
                "name": "current"
            },
            {
                "name": "winding"
            },
            {
                "name": "installments"
            },
            {
                "name": "darbhanga"
            },
            {
                "name": "party"
            },
            {
                "name": "vest"
            },
            {
                "name": "seizure"
            },
            {
                "name": "ghatwals"
            },
            {
                "name": "tank"
            },
            {
                "name": "default"
            },
            {
                "name": "exemptions"
            },
            {
                "name": "thinks"
            },
            {
                "name": "continuing"
            },
            {
                "name": "objective"
            },
            {
                "name": "1998"
            },
            {
                "name": "covenant"
            },
            {
                "name": "signages"
            },
            {
                "name": "1952"
            },
            {
                "name": "completion"
            },
            {
                "name": "specify"
            },
            {
                "name": "thermal"
            },
            {
                "name": "hundis"
            },
            {
                "name": "point"
            },
            {
                "name": "firearms"
            },
            {
                "name": "dates"
            },
            {
                "name": "fulfils"
            },
            {
                "name": "kidnapping"
            },
            {
                "name": "mandate"
            },
            {
                "name": "evict"
            },
            {
                "name": "creditors"
            },
            {
                "name": "shelter"
            },
            {
                "name": "sair"
            },
            {
                "name": "sonthals"
            },
            {
                "name": "secured"
            },
            {
                "name": "tampering"
            },
            {
                "name": "contravention"
            },
            {
                "name": "mangrol"
            },
            {
                "name": "decide"
            },
            {
                "name": "collector"
            },
            {
                "name": "pasture"
            },
            {
                "name": "identifying"
            },
            {
                "name": "accumulations"
            },
            {
                "name": "r"
            },
            {
                "name": "unit"
            },
            {
                "name": "expressed"
            },
            {
                "name": "dispatched"
            },
            {
                "name": "grandson"
            },
            {
                "name": "girdawars"
            },
            {
                "name": "supersession"
            },
            {
                "name": "eligibility"
            },
            {
                "name": "enforcing"
            },
            {
                "name": "auditing"
            },
            {
                "name": "leaving"
            },
            {
                "name": "waiting"
            },
            {
                "name": "crops"
            },
            {
                "name": "represented"
            },
            {
                "name": "snowstorms"
            },
            {
                "name": "forestry"
            },
            {
                "name": "countrysides"
            },
            {
                "name": "solely"
            },
            {
                "name": "rehabilitate"
            },
            {
                "name": "suspends"
            },
            {
                "name": "fisheries"
            },
            {
                "name": "additionally"
            },
            {
                "name": "lime"
            },
            {
                "name": "generations"
            },
            {
                "name": "finality"
            },
            {
                "name": "presumptions"
            },
            {
                "name": "meant"
            },
            {
                "name": "seeks"
            },
            {
                "name": "midst"
            },
            {
                "name": "minutes"
            },
            {
                "name": "allowed"
            },
            {
                "name": "accorded"
            },
            {
                "name": "currency"
            },
            {
                "name": "meet"
            },
            {
                "name": "trustees"
            },
            {
                "name": "surrendering"
            },
            {
                "name": "constitutions"
            },
            {
                "name": "enhance"
            },
            {
                "name": "accurate"
            },
            {
                "name": "tripura"
            },
            {
                "name": "holders"
            },
            {
                "name": "substances"
            },
            {
                "name": "winning"
            },
            {
                "name": "dispute"
            },
            {
                "name": "answerable"
            },
            {
                "name": "extent"
            },
            {
                "name": "comprised"
            },
            {
                "name": "Karnataka"
            },
            {
                "name": "wasteland"
            },
            {
                "name": "addressed"
            },
            {
                "name": "quarries"
            },
            {
                "name": "fulfilling"
            },
            {
                "name": "distress"
            },
            {
                "name": "tent"
            },
            {
                "name": "administer"
            },
            {
                "name": "occupier"
            },
            {
                "name": "men"
            },
            {
                "name": "removes"
            },
            {
                "name": "occupant"
            },
            {
                "name": "deduct"
            },
            {
                "name": "stage"
            },
            {
                "name": "servant"
            },
            {
                "name": "pattadars"
            },
            {
                "name": "deterioration"
            },
            {
                "name": "tribunal"
            },
            {
                "name": "raj"
            },
            {
                "name": "supersede"
            },
            {
                "name": "refunds"
            },
            {
                "name": "settlee"
            },
            {
                "name": "highest"
            },
            {
                "name": "wife"
            },
            {
                "name": "active"
            },
            {
                "name": "1997"
            },
            {
                "name": "endorse"
            },
            {
                "name": "pumping"
            },
            {
                "name": "octroi"
            },
            {
                "name": "agricultural"
            },
            {
                "name": "unnecessary"
            },
            {
                "name": "formulate"
            },
            {
                "name": "examining"
            },
            {
                "name": "determination"
            },
            {
                "name": "architect"
            },
            {
                "name": "request"
            },
            {
                "name": "hindu"
            },
            {
                "name": "gauripur"
            },
            {
                "name": "table"
            },
            {
                "name": "godown"
            },
            {
                "name": "laws"
            },
            {
                "name": "tear"
            },
            {
                "name": "mafia"
            },
            {
                "name": "patiala"
            },
            {
                "name": "feasibility"
            },
            {
                "name": "jew"
            },
            {
                "name": "executable"
            },
            {
                "name": "distributed"
            },
            {
                "name": "bandhs"
            },
            {
                "name": "verified"
            },
            {
                "name": "agreements"
            },
            {
                "name": "long"
            },
            {
                "name": "vacant"
            },
            {
                "name": "carving"
            },
            {
                "name": "\u00e0"
            },
            {
                "name": "entail"
            },
            {
                "name": "classify"
            },
            {
                "name": "lender"
            },
            {
                "name": "society"
            },
            {
                "name": "receipt"
            },
            {
                "name": "based"
            },
            {
                "name": "industries"
            },
            {
                "name": "enters"
            },
            {
                "name": "adjacent"
            },
            {
                "name": "saving"
            },
            {
                "name": "purchaser"
            },
            {
                "name": "reclaimed"
            },
            {
                "name": "awareness"
            },
            {
                "name": "injunction"
            },
            {
                "name": "pleasure"
            },
            {
                "name": "attend"
            },
            {
                "name": "vote"
            },
            {
                "name": "permittee"
            },
            {
                "name": "possessors"
            },
            {
                "name": "cl"
            },
            {
                "name": "vesting"
            },
            {
                "name": "job"
            },
            {
                "name": "contractors"
            },
            {
                "name": "hills"
            },
            {
                "name": "types"
            },
            {
                "name": "pilgrims"
            },
            {
                "name": "redetermined"
            },
            {
                "name": "belonged"
            },
            {
                "name": "surpassed"
            },
            {
                "name": "mahals"
            },
            {
                "name": "1970"
            },
            {
                "name": "defective"
            },
            {
                "name": "grandfather"
            },
            {
                "name": "coverage"
            },
            {
                "name": "varied"
            },
            {
                "name": "abandoned"
            },
            {
                "name": "fence"
            },
            {
                "name": "assisting"
            },
            {
                "name": "operation"
            },
            {
                "name": "punished"
            },
            {
                "name": "residence"
            },
            {
                "name": "consultation"
            },
            {
                "name": "premise"
            },
            {
                "name": "waste"
            },
            {
                "name": "avail"
            },
            {
                "name": "requires"
            },
            {
                "name": "cheapening"
            },
            {
                "name": "tribe"
            },
            {
                "name": "luxury"
            },
            {
                "name": "birbhum"
            },
            {
                "name": "draining"
            },
            {
                "name": "fined"
            },
            {
                "name": "avert"
            },
            {
                "name": "door"
            },
            {
                "name": "entry"
            },
            {
                "name": "collieries"
            },
            {
                "name": "drain"
            },
            {
                "name": "confer"
            },
            {
                "name": "provisionsof"
            },
            {
                "name": "aforesaid"
            },
            {
                "name": "result"
            },
            {
                "name": "briefly"
            },
            {
                "name": "illustrating"
            },
            {
                "name": "influence"
            },
            {
                "name": "policies"
            },
            {
                "name": "furnished"
            },
            {
                "name": "cisterns"
            },
            {
                "name": "holds"
            },
            {
                "name": "watans"
            },
            {
                "name": "needed"
            },
            {
                "name": "subventions"
            },
            {
                "name": "complaint"
            },
            {
                "name": "transferred"
            },
            {
                "name": "mining"
            },
            {
                "name": "theory"
            },
            {
                "name": "intervention"
            },
            {
                "name": "obligated"
            },
            {
                "name": "resumption"
            },
            {
                "name": "ludhiana"
            },
            {
                "name": "erected"
            },
            {
                "name": "unauthorized"
            },
            {
                "name": "tomb"
            },
            {
                "name": "mukhia"
            },
            {
                "name": "simultaneously"
            },
            {
                "name": "received"
            },
            {
                "name": "considerations"
            },
            {
                "name": "faridabad"
            },
            {
                "name": "robbery"
            },
            {
                "name": "identification"
            },
            {
                "name": "pattadar"
            },
            {
                "name": "settlements"
            },
            {
                "name": "predecessor"
            },
            {
                "name": "refusing"
            },
            {
                "name": "diminishing"
            },
            {
                "name": "restriction"
            },
            {
                "name": "transferring"
            },
            {
                "name": "proceeding"
            },
            {
                "name": "1916"
            },
            {
                "name": "socio"
            },
            {
                "name": "north"
            },
            {
                "name": "availing"
            },
            {
                "name": "diminished"
            },
            {
                "name": "foreclose"
            },
            {
                "name": "disposition"
            },
            {
                "name": "lifts"
            },
            {
                "name": "inam"
            },
            {
                "name": "benamidar"
            },
            {
                "name": "amounting"
            },
            {
                "name": "withdrawal"
            },
            {
                "name": "registrar"
            },
            {
                "name": "births"
            },
            {
                "name": "zoos"
            },
            {
                "name": "st"
            },
            {
                "name": "note"
            },
            {
                "name": "judges"
            },
            {
                "name": "2017"
            },
            {
                "name": "relationship"
            },
            {
                "name": "deaths"
            },
            {
                "name": "management"
            },
            {
                "name": "inviting"
            },
            {
                "name": "waiver"
            },
            {
                "name": "epidemics"
            },
            {
                "name": "election"
            },
            {
                "name": "conversion"
            },
            {
                "name": "parameters"
            },
            {
                "name": "banners"
            },
            {
                "name": "term"
            },
            {
                "name": "disturbed"
            },
            {
                "name": "asses"
            },
            {
                "name": "acquiring"
            },
            {
                "name": "ewes"
            },
            {
                "name": "impact"
            },
            {
                "name": "trouble"
            },
            {
                "name": "derogation"
            },
            {
                "name": "collusion"
            },
            {
                "name": "wheels"
            },
            {
                "name": "later"
            },
            {
                "name": "costs"
            },
            {
                "name": "heritability"
            },
            {
                "name": "interpretation"
            },
            {
                "name": "derived"
            },
            {
                "name": "limited"
            },
            {
                "name": "hotels"
            },
            {
                "name": "rehousing"
            },
            {
                "name": "endowments"
            },
            {
                "name": "finance"
            },
            {
                "name": "ordinance"
            },
            {
                "name": "exercised"
            },
            {
                "name": "curtails"
            },
            {
                "name": "jaanch"
            },
            {
                "name": "fra"
            },
            {
                "name": "tehsildar"
            },
            {
                "name": "assisted"
            },
            {
                "name": "credit"
            },
            {
                "name": "1793"
            },
            {
                "name": "debts"
            },
            {
                "name": "screening"
            },
            {
                "name": "hostels"
            },
            {
                "name": "excavate"
            },
            {
                "name": "census"
            },
            {
                "name": "intimating"
            },
            {
                "name": "gurdwaras"
            },
            {
                "name": "relate"
            },
            {
                "name": "thecollector"
            },
            {
                "name": "associations"
            },
            {
                "name": "interested"
            },
            {
                "name": "extinction"
            },
            {
                "name": "delegation"
            },
            {
                "name": "2015"
            },
            {
                "name": "pits"
            },
            {
                "name": "wildlife"
            },
            {
                "name": "age"
            },
            {
                "name": "budget"
            },
            {
                "name": "contiguous"
            },
            {
                "name": "occupants"
            },
            {
                "name": "oil"
            },
            {
                "name": "box"
            },
            {
                "name": "accident"
            },
            {
                "name": "appurtenant"
            },
            {
                "name": "villages"
            },
            {
                "name": "longer"
            },
            {
                "name": "thok"
            },
            {
                "name": "category"
            },
            {
                "name": "failing"
            },
            {
                "name": "amritsar"
            },
            {
                "name": "aggregate"
            },
            {
                "name": "level"
            },
            {
                "name": "customary"
            },
            {
                "name": "disclosure"
            },
            {
                "name": "early"
            },
            {
                "name": "appointed"
            },
            {
                "name": "assigned"
            },
            {
                "name": "wilfully"
            },
            {
                "name": "privileges"
            },
            {
                "name": "vested"
            },
            {
                "name": "mechanical"
            },
            {
                "name": "feed"
            },
            {
                "name": "rooms"
            },
            {
                "name": "attached"
            },
            {
                "name": "unfit"
            },
            {
                "name": "usable"
            },
            {
                "name": "landless"
            },
            {
                "name": "ascertaining"
            },
            {
                "name": "surrenders"
            },
            {
                "name": "mind"
            },
            {
                "name": "subscribe"
            },
            {
                "name": "marking"
            },
            {
                "name": "promised"
            },
            {
                "name": "ordination"
            },
            {
                "name": "indigo"
            },
            {
                "name": "passengers"
            },
            {
                "name": "endangers"
            },
            {
                "name": "reconstituted"
            },
            {
                "name": "Manipur"
            },
            {
                "name": "equipment"
            },
            {
                "name": "constructs"
            },
            {
                "name": "filth"
            },
            {
                "name": "commissioner"
            },
            {
                "name": "naval"
            },
            {
                "name": "commissioners"
            },
            {
                "name": "continuance"
            },
            {
                "name": "extensions"
            },
            {
                "name": "reserve"
            },
            {
                "name": "administrator"
            },
            {
                "name": "gurudwaras"
            },
            {
                "name": "exchanging"
            },
            {
                "name": "borrowers"
            },
            {
                "name": "g"
            },
            {
                "name": "penalty"
            },
            {
                "name": "fish"
            },
            {
                "name": "subjection"
            },
            {
                "name": "ferries"
            },
            {
                "name": "antiquity"
            },
            {
                "name": "hiring"
            },
            {
                "name": "proposes"
            },
            {
                "name": "hold"
            },
            {
                "name": "bathing"
            },
            {
                "name": "equity"
            },
            {
                "name": "reside"
            },
            {
                "name": "succeeds"
            },
            {
                "name": "toddy"
            },
            {
                "name": "control"
            },
            {
                "name": "standing"
            },
            {
                "name": "adjudicated"
            },
            {
                "name": "obliterates"
            },
            {
                "name": "muhammadan"
            },
            {
                "name": "required"
            },
            {
                "name": "vazifdar"
            },
            {
                "name": "jagir"
            },
            {
                "name": "endowed"
            },
            {
                "name": "shed"
            },
            {
                "name": "necessities"
            },
            {
                "name": "said"
            },
            {
                "name": "hunting"
            },
            {
                "name": "bodies"
            },
            {
                "name": "entrust"
            },
            {
                "name": "immoral"
            },
            {
                "name": "person"
            },
            {
                "name": "legger"
            },
            {
                "name": "1962"
            },
            {
                "name": "losses"
            },
            {
                "name": "recording"
            },
            {
                "name": "direction"
            },
            {
                "name": "1934"
            },
            {
                "name": "tendered"
            },
            {
                "name": "complete"
            },
            {
                "name": "route"
            },
            {
                "name": "council"
            },
            {
                "name": "closure"
            },
            {
                "name": "mill"
            },
            {
                "name": "harvest"
            },
            {
                "name": "resists"
            },
            {
                "name": "applied"
            },
            {
                "name": "waterways"
            },
            {
                "name": "discharged"
            },
            {
                "name": "jeopardise"
            },
            {
                "name": "defined"
            },
            {
                "name": "disallowed"
            },
            {
                "name": "evacuee"
            },
            {
                "name": "non"
            },
            {
                "name": "widows"
            },
            {
                "name": "occasion"
            },
            {
                "name": "purports"
            },
            {
                "name": "streams"
            },
            {
                "name": "frame"
            },
            {
                "name": "contain"
            },
            {
                "name": "conserving"
            },
            {
                "name": "connection"
            },
            {
                "name": "carriageways"
            },
            {
                "name": "concern"
            },
            {
                "name": "handed"
            },
            {
                "name": "impede"
            },
            {
                "name": "expedient"
            },
            {
                "name": "higher"
            },
            {
                "name": "consolidation"
            },
            {
                "name": "oversee"
            },
            {
                "name": "intend"
            },
            {
                "name": "reads"
            },
            {
                "name": "carriages"
            },
            {
                "name": "issue"
            },
            {
                "name": "prosecution"
            },
            {
                "name": "limitation"
            },
            {
                "name": "hardship"
            },
            {
                "name": "adjudicate"
            },
            {
                "name": "arriving"
            },
            {
                "name": "haryana"
            },
            {
                "name": "till"
            },
            {
                "name": "bhumidar"
            },
            {
                "name": "temporarily"
            },
            {
                "name": "extinguishing"
            },
            {
                "name": "localization"
            },
            {
                "name": "services"
            },
            {
                "name": "1968"
            },
            {
                "name": "abandons"
            },
            {
                "name": "manufacturer"
            },
            {
                "name": "commenced"
            },
            {
                "name": "persisted"
            },
            {
                "name": "repealed"
            },
            {
                "name": "caused"
            },
            {
                "name": "carvings"
            },
            {
                "name": "homes"
            },
            {
                "name": "ceased"
            },
            {
                "name": "width"
            },
            {
                "name": "augmentation"
            },
            {
                "name": "drug"
            },
            {
                "name": "channels"
            },
            {
                "name": "coming"
            },
            {
                "name": "estates"
            },
            {
                "name": "weeds"
            },
            {
                "name": "beams"
            },
            {
                "name": "sanctioning"
            },
            {
                "name": "insolvent"
            },
            {
                "name": "entirety"
            },
            {
                "name": "mandal"
            },
            {
                "name": "preferred"
            },
            {
                "name": "1991"
            },
            {
                "name": "lets"
            },
            {
                "name": "create"
            },
            {
                "name": "developed"
            },
            {
                "name": "evoked"
            },
            {
                "name": "baseline"
            },
            {
                "name": "inheritance"
            },
            {
                "name": "holder"
            },
            {
                "name": "alienate"
            },
            {
                "name": "utensils"
            },
            {
                "name": "generation"
            },
            {
                "name": "disfigurement"
            },
            {
                "name": "applicant"
            },
            {
                "name": "let"
            },
            {
                "name": "jagirdars"
            },
            {
                "name": "commerce"
            },
            {
                "name": "khadi"
            },
            {
                "name": "supplement"
            },
            {
                "name": "dismiss"
            },
            {
                "name": "income"
            },
            {
                "name": "happen"
            },
            {
                "name": "goshalas"
            },
            {
                "name": "inactivity"
            },
            {
                "name": "inquiries"
            },
            {
                "name": "intestates"
            },
            {
                "name": "sittings"
            },
            {
                "name": "roads"
            },
            {
                "name": "secure"
            },
            {
                "name": "forests"
            },
            {
                "name": "specifies"
            },
            {
                "name": "prevents"
            },
            {
                "name": "pleader"
            },
            {
                "name": "extinct"
            },
            {
                "name": "registrars"
            },
            {
                "name": "corpses"
            },
            {
                "name": "asami"
            },
            {
                "name": "son"
            },
            {
                "name": "1925"
            },
            {
                "name": "auditor"
            },
            {
                "name": "seed"
            },
            {
                "name": "improved"
            },
            {
                "name": "simplify"
            },
            {
                "name": "dwelling"
            },
            {
                "name": "library"
            },
            {
                "name": "residents"
            },
            {
                "name": "includes"
            },
            {
                "name": "rent"
            },
            {
                "name": "town"
            },
            {
                "name": "abuses"
            },
            {
                "name": "expenses"
            },
            {
                "name": "abducting"
            },
            {
                "name": "inherit"
            },
            {
                "name": "requiring"
            },
            {
                "name": "license"
            },
            {
                "name": "harmonise"
            },
            {
                "name": "estimate"
            },
            {
                "name": "dacoit"
            },
            {
                "name": "undisputed"
            },
            {
                "name": "agency"
            },
            {
                "name": "assumed"
            },
            {
                "name": "sealing"
            },
            {
                "name": "device"
            },
            {
                "name": "assembly"
            },
            {
                "name": "revokes"
            },
            {
                "name": "barred"
            },
            {
                "name": "utilisation"
            },
            {
                "name": "bought"
            },
            {
                "name": "ascertained"
            },
            {
                "name": "fulfill"
            },
            {
                "name": "insurer"
            },
            {
                "name": "suo"
            },
            {
                "name": "inclusion"
            },
            {
                "name": "sundarbans"
            },
            {
                "name": "jagirdari"
            },
            {
                "name": "ex"
            },
            {
                "name": "1852"
            },
            {
                "name": "parliament"
            },
            {
                "name": "devasthan"
            },
            {
                "name": "unencumbered"
            },
            {
                "name": "locality"
            },
            {
                "name": "irrespective"
            },
            {
                "name": "roll"
            },
            {
                "name": "zamindar"
            },
            {
                "name": "1841"
            },
            {
                "name": "approval"
            },
            {
                "name": "daughter"
            },
            {
                "name": "gaushala"
            },
            {
                "name": "turmeric"
            },
            {
                "name": "supply"
            },
            {
                "name": "burdened"
            },
            {
                "name": "buyer"
            },
            {
                "name": "architectural"
            },
            {
                "name": "emergencies"
            },
            {
                "name": "application"
            },
            {
                "name": "Delhi"
            },
            {
                "name": "stable"
            },
            {
                "name": "codify"
            },
            {
                "name": "burring"
            },
            {
                "name": "allows"
            },
            {
                "name": "return"
            },
            {
                "name": "1930"
            },
            {
                "name": "supplied"
            },
            {
                "name": "obligation"
            },
            {
                "name": "1907"
            },
            {
                "name": "appears"
            },
            {
                "name": "architects"
            },
            {
                "name": "defines"
            },
            {
                "name": "ending"
            },
            {
                "name": "named"
            },
            {
                "name": "recognise"
            },
            {
                "name": "growers"
            },
            {
                "name": "disseminate"
            },
            {
                "name": "conformance"
            },
            {
                "name": "abused"
            },
            {
                "name": "ijara"
            },
            {
                "name": "opened"
            },
            {
                "name": "notices"
            },
            {
                "name": "boundaries"
            },
            {
                "name": "fourthly"
            },
            {
                "name": "monies"
            },
            {
                "name": "investigate"
            },
            {
                "name": "contrary"
            },
            {
                "name": "director"
            },
            {
                "name": "districts"
            },
            {
                "name": "resources"
            },
            {
                "name": "reappointment"
            },
            {
                "name": "ropeway"
            },
            {
                "name": "interruption"
            },
            {
                "name": "mischief"
            },
            {
                "name": "entrepreneurs"
            },
            {
                "name": "Dadra and Nagar Haveli"
            },
            {
                "name": "degrees"
            },
            {
                "name": "adhiars"
            },
            {
                "name": "sublet"
            },
            {
                "name": "designing"
            },
            {
                "name": "commute"
            },
            {
                "name": "nominated"
            },
            {
                "name": "crop"
            },
            {
                "name": "goal"
            },
            {
                "name": "earmarked"
            },
            {
                "name": "realizable"
            },
            {
                "name": "shamilat"
            },
            {
                "name": "improvements"
            },
            {
                "name": "indicating"
            },
            {
                "name": "purnea"
            },
            {
                "name": "lower"
            },
            {
                "name": "neg"
            },
            {
                "name": "essential"
            },
            {
                "name": "stand"
            },
            {
                "name": "considering"
            },
            {
                "name": "sown"
            },
            {
                "name": "traceable"
            },
            {
                "name": "magistrates"
            },
            {
                "name": "keepers"
            },
            {
                "name": "lumpsum"
            },
            {
                "name": "benches"
            },
            {
                "name": "fallen"
            },
            {
                "name": "security"
            },
            {
                "name": "agrees"
            },
            {
                "name": "protection"
            },
            {
                "name": "defray"
            },
            {
                "name": "order"
            },
            {
                "name": "savings"
            },
            {
                "name": "soon"
            },
            {
                "name": "undiscovered"
            },
            {
                "name": "sanctioned"
            },
            {
                "name": "recommended"
            },
            {
                "name": "dispensaries"
            },
            {
                "name": "damaged"
            },
            {
                "name": "1864"
            },
            {
                "name": "developmental"
            },
            {
                "name": "trials"
            },
            {
                "name": "realm"
            },
            {
                "name": "collected"
            },
            {
                "name": "etc"
            },
            {
                "name": "prescription"
            },
            {
                "name": "room"
            },
            {
                "name": "testamentary"
            },
            {
                "name": "calculating"
            },
            {
                "name": "mere"
            },
            {
                "name": "instrument"
            },
            {
                "name": "deleted"
            },
            {
                "name": "defining"
            },
            {
                "name": "failure"
            },
            {
                "name": "covenants"
            },
            {
                "name": "revoke"
            },
            {
                "name": "electricity"
            },
            {
                "name": "adjusted"
            },
            {
                "name": "pay"
            },
            {
                "name": "impose"
            },
            {
                "name": "handicapped"
            },
            {
                "name": "records"
            },
            {
                "name": "enrolled"
            },
            {
                "name": "commutable"
            },
            {
                "name": "strike"
            },
            {
                "name": "furnishes"
            },
            {
                "name": "explanation"
            },
            {
                "name": "1923"
            },
            {
                "name": "recommend"
            },
            {
                "name": "initially"
            },
            {
                "name": "operational"
            },
            {
                "name": "ankadedar"
            },
            {
                "name": "furthermore"
            },
            {
                "name": "spot"
            },
            {
                "name": "assam"
            },
            {
                "name": "information"
            },
            {
                "name": "accuracy"
            },
            {
                "name": "surveying"
            },
            {
                "name": "inspectors"
            },
            {
                "name": "ix"
            },
            {
                "name": "units"
            },
            {
                "name": "statistics"
            },
            {
                "name": "realizes"
            },
            {
                "name": "relaying"
            },
            {
                "name": "conserve"
            },
            {
                "name": "revision"
            },
            {
                "name": "attorney"
            },
            {
                "name": "repeal"
            },
            {
                "name": "organizations"
            },
            {
                "name": "transfer"
            },
            {
                "name": "sowing"
            },
            {
                "name": "colonizer"
            },
            {
                "name": "straw"
            },
            {
                "name": "attestation"
            },
            {
                "name": "surveyed"
            },
            {
                "name": "wet"
            },
            {
                "name": "necessity"
            },
            {
                "name": "decided"
            },
            {
                "name": "boring"
            },
            {
                "name": "bijni"
            },
            {
                "name": "engineer"
            },
            {
                "name": "prescribed"
            },
            {
                "name": "imposes"
            },
            {
                "name": "leakage"
            },
            {
                "name": "adjournment"
            },
            {
                "name": "hats"
            },
            {
                "name": "posting"
            },
            {
                "name": "bilmakta"
            },
            {
                "name": "aids"
            },
            {
                "name": "subsequent"
            },
            {
                "name": "freely"
            },
            {
                "name": "affidavits"
            },
            {
                "name": "khatian"
            },
            {
                "name": "personally"
            },
            {
                "name": "standards"
            },
            {
                "name": "settlees"
            },
            {
                "name": "introduce"
            },
            {
                "name": "colour"
            },
            {
                "name": "concurrence"
            },
            {
                "name": "setback"
            },
            {
                "name": "demarcated"
            },
            {
                "name": "Chandigarh"
            },
            {
                "name": "situated"
            },
            {
                "name": "kadim"
            },
            {
                "name": "distribute"
            },
            {
                "name": "sons"
            },
            {
                "name": "manipur"
            },
            {
                "name": "weight"
            },
            {
                "name": "sericulture"
            },
            {
                "name": "owner"
            },
            {
                "name": "degradation"
            },
            {
                "name": "geldings"
            },
            {
                "name": "poultry"
            },
            {
                "name": "company"
            },
            {
                "name": "institute"
            },
            {
                "name": "project"
            },
            {
                "name": "guardians"
            },
            {
                "name": "reasonably"
            },
            {
                "name": "establishing"
            },
            {
                "name": "rejected"
            },
            {
                "name": "assessor"
            },
            {
                "name": "opportunity"
            },
            {
                "name": "subadar"
            },
            {
                "name": "bidding"
            },
            {
                "name": "oath"
            },
            {
                "name": "charging"
            },
            {
                "name": "cardamom"
            },
            {
                "name": "tea"
            },
            {
                "name": "inspectorate"
            },
            {
                "name": "recreational"
            },
            {
                "name": "councils"
            },
            {
                "name": "infringement"
            },
            {
                "name": "artificial"
            },
            {
                "name": "agriculturalists"
            },
            {
                "name": "migrated"
            },
            {
                "name": "clerks"
            },
            {
                "name": "lot"
            },
            {
                "name": "cesses"
            },
            {
                "name": "guarantee"
            },
            {
                "name": "herding"
            },
            {
                "name": "manages"
            },
            {
                "name": "schools"
            },
            {
                "name": "quarrying"
            },
            {
                "name": "dill"
            },
            {
                "name": "territories"
            },
            {
                "name": "intends"
            },
            {
                "name": "eldest"
            },
            {
                "name": "gains"
            },
            {
                "name": "ones"
            },
            {
                "name": "meetings"
            },
            {
                "name": "contravened"
            },
            {
                "name": "imprisoning"
            },
            {
                "name": "latrine"
            },
            {
                "name": "disapprove"
            },
            {
                "name": "realized"
            },
            {
                "name": "redeeming"
            },
            {
                "name": "absolves"
            },
            {
                "name": "streamlining"
            },
            {
                "name": "sezs"
            },
            {
                "name": "transit"
            },
            {
                "name": "finally"
            },
            {
                "name": "deceit"
            },
            {
                "name": "actions"
            },
            {
                "name": "minister"
            },
            {
                "name": "declarations"
            },
            {
                "name": "acquisitions"
            },
            {
                "name": "finds"
            },
            {
                "name": "repairs"
            },
            {
                "name": "false"
            },
            {
                "name": "disturbances"
            },
            {
                "name": "sharers"
            },
            {
                "name": "completing"
            },
            {
                "name": "decrees"
            },
            {
                "name": "contributions"
            },
            {
                "name": "scale"
            },
            {
                "name": "1936"
            },
            {
                "name": "managing"
            },
            {
                "name": "disclose"
            },
            {
                "name": "accommodation"
            },
            {
                "name": "document"
            },
            {
                "name": "cadet"
            },
            {
                "name": "ordinated"
            },
            {
                "name": "referring"
            },
            {
                "name": "prevalence"
            },
            {
                "name": "Himachal Pradesh"
            },
            {
                "name": "gandhinagar"
            },
            {
                "name": "lessor"
            },
            {
                "name": "disbursement"
            },
            {
                "name": "proceed"
            },
            {
                "name": "unlawful"
            },
            {
                "name": "admitted"
            },
            {
                "name": "involve"
            },
            {
                "name": "sewered"
            },
            {
                "name": "grounds"
            },
            {
                "name": "dealing"
            },
            {
                "name": "measuring"
            },
            {
                "name": "release"
            },
            {
                "name": "constitutes"
            },
            {
                "name": "documentation"
            },
            {
                "name": "weir"
            },
            {
                "name": "stoppage"
            },
            {
                "name": "dissolution"
            },
            {
                "name": "taxes"
            },
            {
                "name": "parte"
            },
            {
                "name": "causes"
            },
            {
                "name": "unaffected"
            },
            {
                "name": "designed"
            },
            {
                "name": "pandal"
            },
            {
                "name": "sculptures"
            },
            {
                "name": "night"
            },
            {
                "name": "involves"
            },
            {
                "name": "Odisha"
            },
            {
                "name": "ajmer"
            },
            {
                "name": "kamdar"
            },
            {
                "name": "bamboos"
            },
            {
                "name": "engine"
            },
            {
                "name": "promotion"
            },
            {
                "name": "sewerage"
            },
            {
                "name": "concessions"
            },
            {
                "name": "removals"
            },
            {
                "name": "brigade"
            },
            {
                "name": "yachts"
            },
            {
                "name": "restrained"
            },
            {
                "name": "disallowing"
            },
            {
                "name": "handling"
            },
            {
                "name": "transparency"
            },
            {
                "name": "valley"
            },
            {
                "name": "vary"
            },
            {
                "name": "recognized"
            },
            {
                "name": "nominations"
            },
            {
                "name": "collaboration"
            },
            {
                "name": "shared"
            },
            {
                "name": "borne"
            },
            {
                "name": "floors"
            },
            {
                "name": "notwithstanding"
            },
            {
                "name": "excise"
            },
            {
                "name": "indication"
            },
            {
                "name": "Chhattisgarh"
            },
            {
                "name": "presided"
            },
            {
                "name": "bank"
            },
            {
                "name": "gujarat"
            },
            {
                "name": "1927"
            },
            {
                "name": "search"
            },
            {
                "name": "giving"
            },
            {
                "name": "cadre"
            },
            {
                "name": "execute"
            },
            {
                "name": "executing"
            },
            {
                "name": "anti"
            },
            {
                "name": "ahmedabad"
            },
            {
                "name": "statements"
            },
            {
                "name": "township"
            },
            {
                "name": "technical"
            },
            {
                "name": "discontinue"
            },
            {
                "name": "layout"
            },
            {
                "name": "calculations"
            },
            {
                "name": "labour"
            },
            {
                "name": "doubts"
            },
            {
                "name": "granting"
            },
            {
                "name": "replace"
            },
            {
                "name": "taps"
            },
            {
                "name": "invested"
            },
            {
                "name": "exempt"
            },
            {
                "name": "bonus"
            },
            {
                "name": "p"
            },
            {
                "name": "disbursing"
            },
            {
                "name": "refuse"
            },
            {
                "name": "inflicted"
            },
            {
                "name": "possessed"
            },
            {
                "name": "triable"
            },
            {
                "name": "transactions"
            },
            {
                "name": "superintendence"
            },
            {
                "name": "opinion"
            },
            {
                "name": "filling"
            },
            {
                "name": "levying"
            },
            {
                "name": "infection"
            },
            {
                "name": "value"
            },
            {
                "name": "panchayats"
            },
            {
                "name": "karmachari"
            },
            {
                "name": "damages"
            },
            {
                "name": "grades"
            },
            {
                "name": "construed"
            },
            {
                "name": "applicable"
            },
            {
                "name": "mutation"
            },
            {
                "name": "unification"
            },
            {
                "name": "guided"
            },
            {
                "name": "cellar"
            },
            {
                "name": "gathered"
            },
            {
                "name": "minor"
            },
            {
                "name": "aims"
            },
            {
                "name": "intimate"
            },
            {
                "name": "nationalized"
            },
            {
                "name": "disobeys"
            },
            {
                "name": "declaring"
            },
            {
                "name": "vehicular"
            },
            {
                "name": "monitoring"
            },
            {
                "name": "integrated"
            },
            {
                "name": "advanced"
            },
            {
                "name": "lessen"
            },
            {
                "name": "suspension"
            },
            {
                "name": "subserve"
            },
            {
                "name": "death"
            },
            {
                "name": "salami"
            },
            {
                "name": "adjusting"
            },
            {
                "name": "amounts"
            },
            {
                "name": "pasted"
            },
            {
                "name": "allocating"
            },
            {
                "name": "berms"
            },
            {
                "name": "demarcate"
            },
            {
                "name": "flats"
            },
            {
                "name": "placing"
            },
            {
                "name": "correct"
            },
            {
                "name": "xxv"
            },
            {
                "name": "measures"
            },
            {
                "name": "goes"
            },
            {
                "name": "insurance"
            },
            {
                "name": "speed"
            },
            {
                "name": "locust"
            },
            {
                "name": "sabha"
            },
            {
                "name": "vidyarthies"
            },
            {
                "name": "fertility"
            },
            {
                "name": "submitted"
            },
            {
                "name": "exports"
            },
            {
                "name": "neglect"
            },
            {
                "name": "remit"
            },
            {
                "name": "reflected"
            },
            {
                "name": "manufactories"
            },
            {
                "name": "renewal"
            },
            {
                "name": "aside"
            },
            {
                "name": "june"
            },
            {
                "name": "produces"
            },
            {
                "name": "reasonable"
            },
            {
                "name": "closing"
            },
            {
                "name": "entertaining"
            },
            {
                "name": "ordinate"
            },
            {
                "name": "bidder"
            },
            {
                "name": "cities"
            },
            {
                "name": "bhoodan"
            },
            {
                "name": "religion"
            },
            {
                "name": "similarly"
            },
            {
                "name": "chalk"
            },
            {
                "name": "instituting"
            },
            {
                "name": "undertaken"
            },
            {
                "name": "federation"
            },
            {
                "name": "1847"
            },
            {
                "name": "remedies"
            },
            {
                "name": "constructing"
            },
            {
                "name": "ruler"
            },
            {
                "name": "convicted"
            },
            {
                "name": "revealed"
            },
            {
                "name": "grovers"
            },
            {
                "name": "explain"
            },
            {
                "name": "containing"
            },
            {
                "name": "genesis"
            },
            {
                "name": "amenities"
            },
            {
                "name": "features"
            },
            {
                "name": "rabhas"
            },
            {
                "name": "panjrapole"
            },
            {
                "name": "abolishes"
            },
            {
                "name": "fault"
            },
            {
                "name": "fragmentation"
            },
            {
                "name": "ceasing"
            },
            {
                "name": "alienee"
            },
            {
                "name": "dikes"
            },
            {
                "name": "realignment"
            },
            {
                "name": "november"
            },
            {
                "name": "omission"
            },
            {
                "name": "injure"
            },
            {
                "name": "arrival"
            },
            {
                "name": "investigations"
            },
            {
                "name": "tolls"
            },
            {
                "name": "discontinuance"
            },
            {
                "name": "excess"
            },
            {
                "name": "carefully"
            },
            {
                "name": "2008"
            },
            {
                "name": "standardisation"
            },
            {
                "name": "structuring"
            },
            {
                "name": "delegate"
            },
            {
                "name": "way"
            },
            {
                "name": "incorrect"
            },
            {
                "name": "sub"
            },
            {
                "name": "permits"
            },
            {
                "name": "provide"
            },
            {
                "name": "psu"
            },
            {
                "name": "climate"
            },
            {
                "name": "different"
            },
            {
                "name": "affidavit"
            },
            {
                "name": "lives"
            },
            {
                "name": "xii"
            },
            {
                "name": "coupled"
            },
            {
                "name": "village"
            },
            {
                "name": "colleges"
            },
            {
                "name": "intended"
            },
            {
                "name": "trespass"
            },
            {
                "name": "accidents"
            },
            {
                "name": "erecting"
            },
            {
                "name": "taken"
            },
            {
                "name": "expelled"
            },
            {
                "name": "examine"
            },
            {
                "name": "horses"
            },
            {
                "name": "division"
            },
            {
                "name": "defraying"
            },
            {
                "name": "inhabitants"
            },
            {
                "name": "advance"
            },
            {
                "name": "sick"
            },
            {
                "name": "assistants"
            },
            {
                "name": "quarters"
            },
            {
                "name": "medicines"
            },
            {
                "name": "steady"
            },
            {
                "name": "encroachments"
            },
            {
                "name": "sewer"
            },
            {
                "name": "paint"
            },
            {
                "name": "uprooting"
            },
            {
                "name": "religious"
            },
            {
                "name": "relies"
            },
            {
                "name": "liable"
            },
            {
                "name": "invest"
            },
            {
                "name": "fodder"
            },
            {
                "name": "contracts"
            },
            {
                "name": "deduction"
            },
            {
                "name": "tanks"
            },
            {
                "name": "functioning"
            },
            {
                "name": "women"
            },
            {
                "name": "bootleggers"
            },
            {
                "name": "dacoits"
            },
            {
                "name": "outlets"
            },
            {
                "name": "embedded"
            },
            {
                "name": "murder"
            },
            {
                "name": "applicability"
            },
            {
                "name": "visakhapatnam"
            },
            {
                "name": "firms"
            },
            {
                "name": "leads"
            },
            {
                "name": "air"
            },
            {
                "name": "Kerala"
            },
            {
                "name": "regulating"
            },
            {
                "name": "rural"
            },
            {
                "name": "garden"
            },
            {
                "name": "cattle"
            },
            {
                "name": "disorder"
            },
            {
                "name": "district"
            },
            {
                "name": "send"
            },
            {
                "name": "obstructs"
            },
            {
                "name": "selling"
            },
            {
                "name": "forms"
            },
            {
                "name": "billboards"
            },
            {
                "name": "exploration"
            },
            {
                "name": "implements"
            },
            {
                "name": "club"
            },
            {
                "name": "foreclosure"
            },
            {
                "name": "gullies"
            },
            {
                "name": "compact"
            },
            {
                "name": "entering"
            },
            {
                "name": "reference"
            },
            {
                "name": "majority"
            },
            {
                "name": "opinions"
            },
            {
                "name": "authentication"
            },
            {
                "name": "1911"
            },
            {
                "name": "network"
            },
            {
                "name": "festivals"
            },
            {
                "name": "accountability"
            },
            {
                "name": "economically"
            },
            {
                "name": "derogatory"
            },
            {
                "name": "amalgamate"
            },
            {
                "name": "1908"
            },
            {
                "name": "autonomy"
            },
            {
                "name": "endorsement"
            },
            {
                "name": "ultimately"
            },
            {
                "name": "vegetation"
            },
            {
                "name": "minority"
            },
            {
                "name": "kacharis"
            },
            {
                "name": "scheduled"
            },
            {
                "name": "laccadive"
            },
            {
                "name": "developer"
            },
            {
                "name": "municipality"
            },
            {
                "name": "added"
            },
            {
                "name": "invoked"
            },
            {
                "name": "grown"
            },
            {
                "name": "bid"
            },
            {
                "name": "irregularity"
            },
            {
                "name": "delay"
            },
            {
                "name": "copies"
            },
            {
                "name": "fear"
            },
            {
                "name": "documents"
            },
            {
                "name": "debenture"
            },
            {
                "name": "fuel"
            },
            {
                "name": "service"
            },
            {
                "name": "poramboke"
            },
            {
                "name": "1982"
            },
            {
                "name": "municipalities"
            },
            {
                "name": "fragment"
            },
            {
                "name": "discussed"
            },
            {
                "name": "depot"
            },
            {
                "name": "shamlat"
            },
            {
                "name": "rational"
            },
            {
                "name": "lawyer"
            },
            {
                "name": "bill"
            },
            {
                "name": "law"
            },
            {
                "name": "cultivators"
            },
            {
                "name": "whichever"
            },
            {
                "name": "extinguish"
            },
            {
                "name": "cultivate"
            },
            {
                "name": "submit"
            },
            {
                "name": "sentences"
            },
            {
                "name": "local"
            },
            {
                "name": "perform"
            },
            {
                "name": "questions"
            },
            {
                "name": "restrain"
            },
            {
                "name": "trespasser"
            },
            {
                "name": "lie"
            },
            {
                "name": "employee"
            },
            {
                "name": "combination"
            },
            {
                "name": "workmen"
            },
            {
                "name": "separation"
            },
            {
                "name": "initiate"
            },
            {
                "name": "emergency"
            },
            {
                "name": "buyers"
            },
            {
                "name": "ink"
            },
            {
                "name": "perpetuity"
            },
            {
                "name": "occupy"
            },
            {
                "name": "entertained"
            },
            {
                "name": "revise"
            },
            {
                "name": "wide"
            },
            {
                "name": "exceeded"
            },
            {
                "name": "claims"
            },
            {
                "name": "negotiate"
            },
            {
                "name": "iii"
            },
            {
                "name": "mauzawar"
            },
            {
                "name": "progeny"
            },
            {
                "name": "zamindari"
            },
            {
                "name": "lessee"
            },
            {
                "name": "demarcations"
            },
            {
                "name": "1989"
            },
            {
                "name": "vii"
            },
            {
                "name": "expeditiously"
            },
            {
                "name": "belongs"
            },
            {
                "name": "industrial"
            },
            {
                "name": "husband"
            },
            {
                "name": "1883"
            },
            {
                "name": "landholders"
            },
            {
                "name": "primogeniture"
            },
            {
                "name": "republication"
            },
            {
                "name": "setting"
            },
            {
                "name": "sewers"
            },
            {
                "name": "subjecting"
            },
            {
                "name": "promote"
            },
            {
                "name": "approve"
            },
            {
                "name": "province"
            },
            {
                "name": "coercion"
            },
            {
                "name": "karbala"
            },
            {
                "name": "communicated"
            },
            {
                "name": "conducts"
            },
            {
                "name": "pardons"
            },
            {
                "name": "custodians"
            },
            {
                "name": "purchased"
            },
            {
                "name": "mathat"
            },
            {
                "name": "land"
            },
            {
                "name": "1913"
            },
            {
                "name": "kind"
            },
            {
                "name": "statutes"
            },
            {
                "name": "occupiers"
            },
            {
                "name": "debt"
            },
            {
                "name": "omitted"
            },
            {
                "name": "debtor"
            },
            {
                "name": "travelling"
            },
            {
                "name": "path"
            },
            {
                "name": "outlet"
            },
            {
                "name": "travel"
            },
            {
                "name": "liquidation"
            },
            {
                "name": "backlanes"
            },
            {
                "name": "notice"
            },
            {
                "name": "enumeration"
            },
            {
                "name": "violate"
            },
            {
                "name": "evading"
            },
            {
                "name": "khandesh"
            },
            {
                "name": "construct"
            },
            {
                "name": "portions"
            },
            {
                "name": "imperil"
            },
            {
                "name": "livelihood"
            },
            {
                "name": "allottees"
            },
            {
                "name": "abeyance"
            },
            {
                "name": "tenants"
            },
            {
                "name": "koches"
            },
            {
                "name": "neighbours"
            },
            {
                "name": "street"
            },
            {
                "name": "commuted"
            },
            {
                "name": "replaced"
            },
            {
                "name": "small"
            },
            {
                "name": "govt"
            },
            {
                "name": "purchase"
            },
            {
                "name": "recovered"
            },
            {
                "name": "hereditary"
            },
            {
                "name": "amalgamation"
            },
            {
                "name": "court"
            },
            {
                "name": "claimable"
            },
            {
                "name": "specified"
            },
            {
                "name": "vacancy"
            },
            {
                "name": "realisation"
            },
            {
                "name": "irrigation"
            },
            {
                "name": "lies"
            },
            {
                "name": "dispose"
            },
            {
                "name": "mortgagee"
            },
            {
                "name": "secretaries"
            },
            {
                "name": "bearers"
            },
            {
                "name": "act"
            },
            {
                "name": "authorises"
            },
            {
                "name": "place"
            },
            {
                "name": "intercept"
            },
            {
                "name": "rule"
            },
            {
                "name": "appoint"
            },
            {
                "name": "clarifies"
            },
            {
                "name": "bed"
            },
            {
                "name": "pote"
            },
            {
                "name": "usefulness"
            },
            {
                "name": "fishermen"
            },
            {
                "name": "body"
            },
            {
                "name": "exclusions"
            },
            {
                "name": "approvals"
            },
            {
                "name": "ventilation"
            },
            {
                "name": "subscribing"
            },
            {
                "name": "goonda"
            },
            {
                "name": "backward"
            },
            {
                "name": "storages"
            },
            {
                "name": "descent"
            },
            {
                "name": "builds"
            },
            {
                "name": "meters"
            },
            {
                "name": "increments"
            },
            {
                "name": "entrance"
            },
            {
                "name": "discharging"
            },
            {
                "name": "takya"
            },
            {
                "name": "national"
            },
            {
                "name": "unanimity"
            },
            {
                "name": "city"
            },
            {
                "name": "vehicle"
            },
            {
                "name": "guarding"
            },
            {
                "name": "deviations"
            },
            {
                "name": "excluding"
            },
            {
                "name": "living"
            },
            {
                "name": "jhuggi"
            },
            {
                "name": "summon"
            },
            {
                "name": "vocation"
            },
            {
                "name": "pens"
            },
            {
                "name": "defendant"
            },
            {
                "name": "excessive"
            },
            {
                "name": "born"
            },
            {
                "name": "factors"
            },
            {
                "name": "inoculation"
            },
            {
                "name": "code"
            },
            {
                "name": "enquiries"
            },
            {
                "name": "equivalent"
            },
            {
                "name": "ensure"
            },
            {
                "name": "preference"
            },
            {
                "name": "lease"
            },
            {
                "name": "adds"
            },
            {
                "name": "kilns"
            },
            {
                "name": "spend"
            },
            {
                "name": "ad"
            },
            {
                "name": "mineral"
            },
            {
                "name": "moto"
            },
            {
                "name": "reserves"
            },
            {
                "name": "advise"
            },
            {
                "name": "urgency"
            },
            {
                "name": "prove"
            },
            {
                "name": "anymovable"
            },
            {
                "name": "kamrup"
            },
            {
                "name": "1917"
            },
            {
                "name": "tehsils"
            },
            {
                "name": "decision"
            },
            {
                "name": "pipes"
            },
            {
                "name": "hectares"
            },
            {
                "name": "offering"
            },
            {
                "name": "electric"
            },
            {
                "name": "identifies"
            },
            {
                "name": "lay"
            },
            {
                "name": "duration"
            },
            {
                "name": "wishes"
            },
            {
                "name": "divides"
            },
            {
                "name": "incidents"
            },
            {
                "name": "sugarcane"
            },
            {
                "name": "ap"
            },
            {
                "name": "farm"
            },
            {
                "name": "lays"
            },
            {
                "name": "obstructed"
            },
            {
                "name": "kanungos"
            },
            {
                "name": "undertake"
            },
            {
                "name": "inspection"
            },
            {
                "name": "diversion"
            },
            {
                "name": "impairment"
            },
            {
                "name": "maximum"
            },
            {
                "name": "repealing"
            },
            {
                "name": "bhuinhari"
            },
            {
                "name": "wells"
            },
            {
                "name": "visit"
            },
            {
                "name": "granted"
            },
            {
                "name": "detained"
            },
            {
                "name": "audit"
            },
            {
                "name": "signify"
            },
            {
                "name": "adding"
            },
            {
                "name": "refused"
            },
            {
                "name": "inspect"
            },
            {
                "name": "1972"
            },
            {
                "name": "1862"
            },
            {
                "name": "aircraft"
            },
            {
                "name": "declares"
            },
            {
                "name": "nomination"
            },
            {
                "name": "availed"
            },
            {
                "name": "draught"
            },
            {
                "name": "exporters"
            },
            {
                "name": "enhanced"
            },
            {
                "name": "modes"
            },
            {
                "name": "buying"
            },
            {
                "name": "odisha"
            },
            {
                "name": "field"
            },
            {
                "name": "examples"
            },
            {
                "name": "defaulting"
            },
            {
                "name": "limit"
            },
            {
                "name": "located"
            },
            {
                "name": "inferior"
            },
            {
                "name": "process"
            },
            {
                "name": "regions"
            },
            {
                "name": "booking"
            },
            {
                "name": "blocks"
            },
            {
                "name": "orchards"
            },
            {
                "name": "1994"
            },
            {
                "name": "restrict"
            },
            {
                "name": "overpower"
            },
            {
                "name": "scientific"
            },
            {
                "name": "dispatch"
            },
            {
                "name": "handing"
            },
            {
                "name": "wakf"
            },
            {
                "name": "undertakes"
            },
            {
                "name": "meter"
            },
            {
                "name": "amalgamates"
            },
            {
                "name": "payments"
            },
            {
                "name": "deface"
            },
            {
                "name": "record"
            },
            {
                "name": "referred"
            },
            {
                "name": "flagged"
            },
            {
                "name": "representations"
            },
            {
                "name": "sites"
            },
            {
                "name": "counci"
            },
            {
                "name": "sent"
            },
            {
                "name": "credited"
            },
            {
                "name": "consists"
            },
            {
                "name": "quarter"
            },
            {
                "name": "exporter"
            },
            {
                "name": "plan"
            },
            {
                "name": "diverting"
            },
            {
                "name": "pipeline"
            },
            {
                "name": "socially"
            },
            {
                "name": "1954"
            },
            {
                "name": "cuttack"
            },
            {
                "name": "playgrounds"
            },
            {
                "name": "real"
            },
            {
                "name": "sanction"
            },
            {
                "name": "counteract"
            },
            {
                "name": "apprehension"
            },
            {
                "name": "spirit"
            },
            {
                "name": "unassessed"
            },
            {
                "name": "cease"
            },
            {
                "name": "tending"
            },
            {
                "name": "instances"
            },
            {
                "name": "bequeath"
            },
            {
                "name": "1897"
            },
            {
                "name": "social"
            },
            {
                "name": "habitat"
            },
            {
                "name": "arrear"
            },
            {
                "name": "moved"
            },
            {
                "name": "enlarge"
            },
            {
                "name": "restore"
            },
            {
                "name": "wholly"
            },
            {
                "name": "disputes"
            },
            {
                "name": "permitted"
            },
            {
                "name": "parrekalva"
            },
            {
                "name": "resisted"
            },
            {
                "name": "owing"
            },
            {
                "name": "certified"
            },
            {
                "name": "reclassify"
            },
            {
                "name": "broadly"
            },
            {
                "name": "mad"
            },
            {
                "name": "goshala"
            },
            {
                "name": "increase"
            },
            {
                "name": "refund"
            },
            {
                "name": "want"
            },
            {
                "name": "reclaimable"
            },
            {
                "name": "complied"
            },
            {
                "name": "fitters"
            },
            {
                "name": "supervision"
            },
            {
                "name": "educational"
            },
            {
                "name": "budgets"
            },
            {
                "name": "deposits"
            },
            {
                "name": "distraint"
            },
            {
                "name": "picking"
            },
            {
                "name": "appointing"
            },
            {
                "name": "principles"
            },
            {
                "name": "incompetent"
            },
            {
                "name": "remanding"
            },
            {
                "name": "convenient"
            },
            {
                "name": "challenge"
            },
            {
                "name": "concepts"
            },
            {
                "name": "geomorphological"
            },
            {
                "name": "reversed"
            },
            {
                "name": "lieutenant"
            },
            {
                "name": "Maharashtra"
            },
            {
                "name": "monghyr"
            },
            {
                "name": "synchronize"
            },
            {
                "name": "leasing"
            },
            {
                "name": "placed"
            },
            {
                "name": "roadsides"
            },
            {
                "name": "utility"
            },
            {
                "name": "multiples"
            },
            {
                "name": "advertisements"
            },
            {
                "name": "redistribution"
            },
            {
                "name": "left"
            },
            {
                "name": "receiver"
            },
            {
                "name": "transplanted"
            },
            {
                "name": "1904"
            },
            {
                "name": "sequestrators"
            },
            {
                "name": "conform"
            },
            {
                "name": "trees"
            },
            {
                "name": "flange"
            },
            {
                "name": "rivulets"
            },
            {
                "name": "measurer"
            },
            {
                "name": "accompanied"
            },
            {
                "name": "held"
            },
            {
                "name": "fire"
            },
            {
                "name": "reconnaissance"
            },
            {
                "name": "absence"
            },
            {
                "name": "drawl"
            },
            {
                "name": "permission"
            },
            {
                "name": "ignorance"
            },
            {
                "name": "watercourses"
            },
            {
                "name": "elects"
            },
            {
                "name": "desirous"
            },
            {
                "name": "structural"
            },
            {
                "name": "attempted"
            },
            {
                "name": "disinfect"
            },
            {
                "name": "ryot"
            },
            {
                "name": "violation"
            },
            {
                "name": "restrictions"
            },
            {
                "name": "settling"
            },
            {
                "name": "splitting"
            },
            {
                "name": "tender"
            },
            {
                "name": "sanitation"
            },
            {
                "name": "lights"
            },
            {
                "name": "join"
            },
            {
                "name": "inspecting"
            },
            {
                "name": "introduced"
            },
            {
                "name": "leviable"
            },
            {
                "name": "roof"
            },
            {
                "name": "malzamin"
            },
            {
                "name": "tribunals"
            },
            {
                "name": "existence"
            },
            {
                "name": "asked"
            },
            {
                "name": "possible"
            },
            {
                "name": "ores"
            },
            {
                "name": "emolument"
            },
            {
                "name": "appliances"
            },
            {
                "name": "advancement"
            },
            {
                "name": "allowance"
            },
            {
                "name": "bengal"
            },
            {
                "name": "charcoal"
            },
            {
                "name": "desirable"
            },
            {
                "name": "seek"
            },
            {
                "name": "adjudged"
            },
            {
                "name": "shareholders"
            },
            {
                "name": "recommendations"
            },
            {
                "name": "obstructions"
            },
            {
                "name": "applying"
            },
            {
                "name": "vessels"
            },
            {
                "name": "break"
            },
            {
                "name": "refrains"
            },
            {
                "name": "lane"
            },
            {
                "name": "coconut"
            },
            {
                "name": "defect"
            },
            {
                "name": "dividend"
            },
            {
                "name": "sector"
            },
            {
                "name": "workman"
            },
            {
                "name": "acted"
            },
            {
                "name": "warning"
            },
            {
                "name": "serves"
            },
            {
                "name": "confirm"
            },
            {
                "name": "minerals"
            },
            {
                "name": "rights"
            },
            {
                "name": "defaults"
            },
            {
                "name": "repayment"
            },
            {
                "name": "provincial"
            },
            {
                "name": "lying"
            },
            {
                "name": "roadway"
            },
            {
                "name": "crest"
            },
            {
                "name": "organize"
            },
            {
                "name": "preclude"
            },
            {
                "name": "chiefs"
            },
            {
                "name": "functions"
            },
            {
                "name": "issued"
            },
            {
                "name": "jain"
            },
            {
                "name": "henceforth"
            },
            {
                "name": "health"
            },
            {
                "name": "invalid"
            },
            {
                "name": "recreation"
            },
            {
                "name": "exercise"
            },
            {
                "name": "shop"
            },
            {
                "name": "quantum"
            },
            {
                "name": "deciding"
            },
            {
                "name": "enlists"
            },
            {
                "name": "regardless"
            },
            {
                "name": "reservoirs"
            },
            {
                "name": "demolition"
            },
            {
                "name": "groups"
            },
            {
                "name": "abetting"
            },
            {
                "name": "participative"
            },
            {
                "name": "destruction"
            },
            {
                "name": "usufruct"
            },
            {
                "name": "attested"
            },
            {
                "name": "situation"
            },
            {
                "name": "change"
            },
            {
                "name": "licensing"
            },
            {
                "name": "response"
            },
            {
                "name": "sandy"
            },
            {
                "name": "ward"
            },
            {
                "name": "supplies"
            },
            {
                "name": "deports"
            },
            {
                "name": "priority"
            },
            {
                "name": "cultivating"
            },
            {
                "name": "stayed"
            },
            {
                "name": "exploitation"
            },
            {
                "name": "lakes"
            },
            {
                "name": "irrigated"
            },
            {
                "name": "judge"
            },
            {
                "name": "circumstances"
            },
            {
                "name": "coordination"
            },
            {
                "name": "yielded"
            },
            {
                "name": "stone"
            },
            {
                "name": "burnt"
            },
            {
                "name": "committed"
            },
            {
                "name": "number"
            },
            {
                "name": "plutonium"
            },
            {
                "name": "converted"
            },
            {
                "name": "acquired"
            },
            {
                "name": "investment"
            },
            {
                "name": "ceases"
            },
            {
                "name": "producer"
            },
            {
                "name": "divert"
            },
            {
                "name": "sought"
            },
            {
                "name": "allotted"
            },
            {
                "name": "licence"
            },
            {
                "name": "passed"
            },
            {
                "name": "slaughter"
            },
            {
                "name": "pendal"
            },
            {
                "name": "cruelty"
            },
            {
                "name": "awards"
            },
            {
                "name": "istamrari"
            },
            {
                "name": "councillors"
            },
            {
                "name": "heirs"
            },
            {
                "name": "parting"
            },
            {
                "name": "1929"
            },
            {
                "name": "pramukh"
            },
            {
                "name": "engagement"
            },
            {
                "name": "view"
            },
            {
                "name": "destroys"
            },
            {
                "name": "pigs"
            },
            {
                "name": "implied"
            },
            {
                "name": "highways"
            },
            {
                "name": "successors"
            },
            {
                "name": "slavery"
            },
            {
                "name": "confirmed"
            },
            {
                "name": "endangering"
            },
            {
                "name": "enumerations"
            },
            {
                "name": "fostering"
            },
            {
                "name": "calling"
            },
            {
                "name": "photographs"
            },
            {
                "name": "licences"
            },
            {
                "name": "foot"
            },
            {
                "name": "recovery"
            },
            {
                "name": "gifts"
            },
            {
                "name": "assign"
            },
            {
                "name": "partnership"
            },
            {
                "name": "agents"
            },
            {
                "name": "requirements"
            },
            {
                "name": "indicate"
            },
            {
                "name": "completed"
            },
            {
                "name": "feels"
            },
            {
                "name": "1973"
            },
            {
                "name": "changed"
            },
            {
                "name": "xi"
            },
            {
                "name": "governance"
            },
            {
                "name": "trustee"
            },
            {
                "name": "training"
            },
            {
                "name": "stored"
            },
            {
                "name": "cutting"
            },
            {
                "name": "range"
            },
            {
                "name": "Puducherry"
            },
            {
                "name": "plaintiff"
            },
            {
                "name": "alleges"
            },
            {
                "name": "tenure"
            },
            {
                "name": "ecological"
            },
            {
                "name": "atrocity"
            },
            {
                "name": "classes"
            },
            {
                "name": "acts"
            },
            {
                "name": "believes"
            },
            {
                "name": "1959"
            },
            {
                "name": "appertain"
            },
            {
                "name": "occupied"
            },
            {
                "name": "fall"
            },
            {
                "name": "approved"
            },
            {
                "name": "housefed"
            },
            {
                "name": "retired"
            },
            {
                "name": "molestation"
            },
            {
                "name": "null"
            },
            {
                "name": "sattra"
            },
            {
                "name": "promoted"
            },
            {
                "name": "borrowing"
            },
            {
                "name": "authorizes"
            },
            {
                "name": "overseen"
            },
            {
                "name": "open"
            },
            {
                "name": "speedy"
            },
            {
                "name": "adjudication"
            },
            {
                "name": "unfair"
            },
            {
                "name": "broach"
            },
            {
                "name": "retaining"
            },
            {
                "name": "commission"
            },
            {
                "name": "engines"
            },
            {
                "name": "valuation"
            },
            {
                "name": "partible"
            },
            {
                "name": "obligatory"
            },
            {
                "name": "khalihans"
            },
            {
                "name": "denomination"
            },
            {
                "name": "avoiding"
            },
            {
                "name": "surcharge"
            },
            {
                "name": "tend"
            },
            {
                "name": "facets"
            },
            {
                "name": "effects"
            },
            {
                "name": "impounded"
            },
            {
                "name": "pursuant"
            },
            {
                "name": "termed"
            },
            {
                "name": "shillong"
            },
            {
                "name": "transmission"
            },
            {
                "name": "resigning"
            },
            {
                "name": "ejected"
            },
            {
                "name": "great"
            },
            {
                "name": "inconvenience"
            },
            {
                "name": "performance"
            },
            {
                "name": "winds"
            },
            {
                "name": "salvage"
            },
            {
                "name": "expense"
            },
            {
                "name": "precincts"
            },
            {
                "name": "portion"
            },
            {
                "name": "beat"
            },
            {
                "name": "amalgamated"
            },
            {
                "name": "opted"
            },
            {
                "name": "fees"
            },
            {
                "name": "complying"
            },
            {
                "name": "regional"
            },
            {
                "name": "agree"
            },
            {
                "name": "accepting"
            },
            {
                "name": "expiration"
            },
            {
                "name": "extended"
            },
            {
                "name": "customs"
            },
            {
                "name": "enquiry"
            },
            {
                "name": "leased"
            },
            {
                "name": "procrastination"
            },
            {
                "name": "entitled"
            },
            {
                "name": "bids"
            },
            {
                "name": "penalized"
            },
            {
                "name": "species"
            },
            {
                "name": "measurements"
            },
            {
                "name": "apportionment"
            },
            {
                "name": "discipline"
            },
            {
                "name": "amending"
            },
            {
                "name": "rate"
            },
            {
                "name": "ashes"
            },
            {
                "name": "valid"
            },
            {
                "name": "1825"
            },
            {
                "name": "regulation"
            },
            {
                "name": "birds"
            },
            {
                "name": "fabrication"
            },
            {
                "name": "corrections"
            },
            {
                "name": "disbursed"
            },
            {
                "name": "regularised"
            },
            {
                "name": "proof"
            },
            {
                "name": "stores"
            },
            {
                "name": "graze"
            },
            {
                "name": "taluq"
            },
            {
                "name": "disfiguring"
            },
            {
                "name": "increased"
            },
            {
                "name": "kaira"
            },
            {
                "name": "jamabandi"
            },
            {
                "name": "businesses"
            },
            {
                "name": "data"
            },
            {
                "name": "weapons"
            },
            {
                "name": "developments"
            },
            {
                "name": "workers"
            },
            {
                "name": "exclusive"
            },
            {
                "name": "reproduction"
            },
            {
                "name": "demolish"
            },
            {
                "name": "lapse"
            },
            {
                "name": "extrication"
            },
            {
                "name": "dated"
            },
            {
                "name": "necessitates"
            },
            {
                "name": "representatives"
            },
            {
                "name": "animals"
            },
            {
                "name": "dc"
            },
            {
                "name": "agitations"
            },
            {
                "name": "apprehended"
            },
            {
                "name": "pat"
            },
            {
                "name": "ascertainable"
            },
            {
                "name": "carries"
            },
            {
                "name": "task"
            },
            {
                "name": "Assam"
            },
            {
                "name": "asamis"
            },
            {
                "name": "systems"
            },
            {
                "name": "1942"
            },
            {
                "name": "ceilings"
            },
            {
                "name": "check"
            },
            {
                "name": "achieve"
            },
            {
                "name": "furlong"
            },
            {
                "name": "qualifications"
            },
            {
                "name": "suffered"
            },
            {
                "name": "kistwar"
            },
            {
                "name": "zila"
            },
            {
                "name": "press"
            },
            {
                "name": "sentence"
            },
            {
                "name": "overcrowding"
            },
            {
                "name": "irritated"
            },
            {
                "name": "noted"
            },
            {
                "name": "earmarking"
            },
            {
                "name": "fraud"
            },
            {
                "name": "1922"
            },
            {
                "name": "enquire"
            },
            {
                "name": "adaptation"
            },
            {
                "name": "preserving"
            },
            {
                "name": "sethsendhi"
            },
            {
                "name": "jungle"
            },
            {
                "name": "1905"
            },
            {
                "name": "paths"
            },
            {
                "name": "progression"
            },
            {
                "name": "offices"
            },
            {
                "name": "inquiring"
            },
            {
                "name": "renewed"
            },
            {
                "name": "interesting"
            },
            {
                "name": "breaching"
            },
            {
                "name": "deliberation"
            },
            {
                "name": "impedes"
            },
            {
                "name": "language"
            },
            {
                "name": "improvement"
            },
            {
                "name": "resignation"
            },
            {
                "name": "administration"
            },
            {
                "name": "decay"
            },
            {
                "name": "extend"
            },
            {
                "name": "prohibited"
            },
            {
                "name": "read"
            },
            {
                "name": "tahsildars"
            },
            {
                "name": "enshrines"
            },
            {
                "name": "blindness"
            },
            {
                "name": "signature"
            },
            {
                "name": "unlike"
            },
            {
                "name": "rioting"
            },
            {
                "name": "sum"
            },
            {
                "name": "chillies"
            },
            {
                "name": "raises"
            },
            {
                "name": "physically"
            },
            {
                "name": "mute"
            },
            {
                "name": "intra"
            },
            {
                "name": "cho"
            },
            {
                "name": "common"
            },
            {
                "name": "laborers"
            },
            {
                "name": "imports"
            },
            {
                "name": "sketch"
            },
            {
                "name": "proprietor"
            },
            {
                "name": "generally"
            },
            {
                "name": "defeat"
            },
            {
                "name": "tenancy"
            },
            {
                "name": "judgements"
            },
            {
                "name": "kabiz"
            },
            {
                "name": "specifically"
            },
            {
                "name": "predecessors"
            },
            {
                "name": "bridge"
            },
            {
                "name": "grow"
            },
            {
                "name": "renders"
            },
            {
                "name": "deemed"
            },
            {
                "name": "causeways"
            },
            {
                "name": "fines"
            },
            {
                "name": "correspondences"
            },
            {
                "name": "question"
            },
            {
                "name": "calculated"
            },
            {
                "name": "transferees"
            },
            {
                "name": "enquires"
            },
            {
                "name": "liberty"
            },
            {
                "name": "instalments"
            },
            {
                "name": "drawing"
            },
            {
                "name": "brooks"
            },
            {
                "name": "filing"
            },
            {
                "name": "assessee"
            },
            {
                "name": "joint"
            },
            {
                "name": "extracted"
            },
            {
                "name": "batai"
            },
            {
                "name": "passing"
            },
            {
                "name": "irredeemable"
            },
            {
                "name": "destroyed"
            },
            {
                "name": "tunnels"
            },
            {
                "name": "enter"
            },
            {
                "name": "community"
            },
            {
                "name": "fragments"
            },
            {
                "name": "program"
            },
            {
                "name": "payable"
            },
            {
                "name": "authenticity"
            },
            {
                "name": "duty"
            },
            {
                "name": "origin"
            },
            {
                "name": "dismissals"
            },
            {
                "name": "suspended"
            },
            {
                "name": "framing"
            },
            {
                "name": "amusements"
            },
            {
                "name": "enable"
            },
            {
                "name": "exists"
            },
            {
                "name": "undertakings"
            },
            {
                "name": "instance"
            },
            {
                "name": "tribals"
            },
            {
                "name": "students"
            },
            {
                "name": "kattidar"
            },
            {
                "name": "silk"
            },
            {
                "name": "totally"
            },
            {
                "name": "extinguished"
            },
            {
                "name": "morals"
            },
            {
                "name": "considered"
            },
            {
                "name": "intentionally"
            },
            {
                "name": "withdraw"
            },
            {
                "name": "month"
            },
            {
                "name": "warrant"
            },
            {
                "name": "occur"
            },
            {
                "name": "ayodhya"
            },
            {
                "name": "continuation"
            },
            {
                "name": "defeating"
            },
            {
                "name": "compensating"
            },
            {
                "name": "plying"
            },
            {
                "name": "obligations"
            },
            {
                "name": "earthquakes"
            },
            {
                "name": "garage"
            },
            {
                "name": "looping"
            },
            {
                "name": "seizures"
            },
            {
                "name": "licensee"
            },
            {
                "name": "contingent"
            },
            {
                "name": "suggestions"
            },
            {
                "name": "similar"
            },
            {
                "name": "sanad"
            },
            {
                "name": "accrue"
            },
            {
                "name": "alternative"
            },
            {
                "name": "forth"
            },
            {
                "name": "report"
            },
            {
                "name": "imparted"
            },
            {
                "name": "furthers"
            },
            {
                "name": "reorganize"
            },
            {
                "name": "booth"
            },
            {
                "name": "producing"
            },
            {
                "name": "rebuilding"
            },
            {
                "name": "aurangabad"
            },
            {
                "name": "allottee"
            },
            {
                "name": "computing"
            },
            {
                "name": "beauty"
            },
            {
                "name": "sharer"
            },
            {
                "name": "funds"
            },
            {
                "name": "resin"
            },
            {
                "name": "sliding"
            },
            {
                "name": "earlier"
            },
            {
                "name": "type"
            },
            {
                "name": "gutters"
            },
            {
                "name": "consolidate"
            },
            {
                "name": "candidates"
            },
            {
                "name": "assessment"
            },
            {
                "name": "financing"
            },
            {
                "name": "preserve"
            },
            {
                "name": "renew"
            },
            {
                "name": "questioning"
            },
            {
                "name": "refinery"
            },
            {
                "name": "redressal"
            },
            {
                "name": "defacement"
            },
            {
                "name": "measure"
            },
            {
                "name": "debtors"
            },
            {
                "name": "appropriation"
            },
            {
                "name": "ghatwal"
            },
            {
                "name": "motu"
            },
            {
                "name": "surat"
            },
            {
                "name": "dominion"
            },
            {
                "name": "supervise"
            },
            {
                "name": "specifics"
            },
            {
                "name": "disputed"
            },
            {
                "name": "obstructing"
            },
            {
                "name": "recognises"
            },
            {
                "name": "transmit"
            },
            {
                "name": "snares"
            },
            {
                "name": "penalisation"
            },
            {
                "name": "employees"
            },
            {
                "name": "protecting"
            },
            {
                "name": "total"
            },
            {
                "name": "alters"
            },
            {
                "name": "coordinate"
            },
            {
                "name": "civil"
            },
            {
                "name": "shrotriyamdars"
            },
            {
                "name": "excavating"
            },
            {
                "name": "soil"
            },
            {
                "name": "hamlet"
            },
            {
                "name": "true"
            },
            {
                "name": "permissions"
            },
            {
                "name": "dwellers"
            },
            {
                "name": "benefit"
            },
            {
                "name": "f"
            },
            {
                "name": "meted"
            },
            {
                "name": "reinforce"
            },
            {
                "name": "stipulates"
            },
            {
                "name": "tested"
            },
            {
                "name": "mulgirasia"
            },
            {
                "name": "clearances"
            },
            {
                "name": "blood"
            },
            {
                "name": "envisaged"
            },
            {
                "name": "tasked"
            },
            {
                "name": "Rajasthan"
            },
            {
                "name": "guardianship"
            },
            {
                "name": "nature"
            },
            {
                "name": "visitor"
            },
            {
                "name": "cyclone"
            },
            {
                "name": "decreased"
            },
            {
                "name": "searches"
            },
            {
                "name": "best"
            },
            {
                "name": "modifications"
            },
            {
                "name": "offerings"
            },
            {
                "name": "unwilling"
            },
            {
                "name": "circumstance"
            },
            {
                "name": "guidelines"
            },
            {
                "name": "yard"
            },
            {
                "name": "hypothecation"
            },
            {
                "name": "behalf"
            },
            {
                "name": "farms"
            },
            {
                "name": "reporting"
            },
            {
                "name": "learning"
            },
            {
                "name": "detail"
            },
            {
                "name": "urbanisation"
            },
            {
                "name": "jagirdar"
            },
            {
                "name": "conveniently"
            },
            {
                "name": "wind"
            },
            {
                "name": "scrutinise"
            },
            {
                "name": "remainder"
            },
            {
                "name": "barriers"
            },
            {
                "name": "utilized"
            },
            {
                "name": "intention"
            },
            {
                "name": "participation"
            },
            {
                "name": "seize"
            },
            {
                "name": "site"
            },
            {
                "name": "bequests"
            },
            {
                "name": "annulled"
            },
            {
                "name": "goods"
            },
            {
                "name": "drinking"
            },
            {
                "name": "population"
            },
            {
                "name": "remain"
            },
            {
                "name": "ask"
            },
            {
                "name": "converse"
            },
            {
                "name": "bricks"
            },
            {
                "name": "implement"
            },
            {
                "name": "1967"
            },
            {
                "name": "asset"
            },
            {
                "name": "require"
            },
            {
                "name": "realize"
            },
            {
                "name": "lettering"
            },
            {
                "name": "judgment"
            },
            {
                "name": "thereunder"
            },
            {
                "name": "construction"
            },
            {
                "name": "borrowed"
            },
            {
                "name": "designated"
            },
            {
                "name": "amended"
            },
            {
                "name": "1884"
            },
            {
                "name": "described"
            },
            {
                "name": "effect"
            },
            {
                "name": "jewellery"
            },
            {
                "name": "enact"
            },
            {
                "name": "pawn"
            },
            {
                "name": "escheat"
            },
            {
                "name": "khanapuri"
            },
            {
                "name": "residences"
            },
            {
                "name": "repossess"
            },
            {
                "name": "structures"
            },
            {
                "name": "structure"
            },
            {
                "name": "saline"
            },
            {
                "name": "1980"
            },
            {
                "name": "payment"
            },
            {
                "name": "resisting"
            },
            {
                "name": "substituted"
            },
            {
                "name": "bond"
            },
            {
                "name": "apportion"
            },
            {
                "name": "agreed"
            },
            {
                "name": "porambokes"
            },
            {
                "name": "fell"
            },
            {
                "name": "discussions"
            },
            {
                "name": "conceptualising"
            },
            {
                "name": "sanitize"
            },
            {
                "name": "restored"
            },
            {
                "name": "reached"
            },
            {
                "name": "legislature"
            },
            {
                "name": "unrepealed"
            },
            {
                "name": "conformity"
            },
            {
                "name": "allowing"
            },
            {
                "name": "proceeds"
            },
            {
                "name": "chapters"
            },
            {
                "name": "tehsil"
            },
            {
                "name": "da"
            },
            {
                "name": "firm"
            },
            {
                "name": "pertaining"
            },
            {
                "name": "orders"
            },
            {
                "name": "establish"
            },
            {
                "name": "licenses"
            },
            {
                "name": "furniture"
            },
            {
                "name": "pieces"
            },
            {
                "name": "procured"
            },
            {
                "name": "dry"
            },
            {
                "name": "materials"
            },
            {
                "name": "absorbed"
            },
            {
                "name": "exempted"
            },
            {
                "name": "matter"
            },
            {
                "name": "volume"
            },
            {
                "name": "carts"
            },
            {
                "name": "given"
            },
            {
                "name": "urbanization"
            },
            {
                "name": "situations"
            },
            {
                "name": "planning"
            },
            {
                "name": "growth"
            },
            {
                "name": "refer"
            },
            {
                "name": "pasturing"
            },
            {
                "name": "boot"
            },
            {
                "name": "famine"
            },
            {
                "name": "treated"
            },
            {
                "name": "classified"
            },
            {
                "name": "funded"
            },
            {
                "name": "objection"
            },
            {
                "name": "notifications"
            },
            {
                "name": "prohibit"
            },
            {
                "name": "pose"
            },
            {
                "name": "deposited"
            },
            {
                "name": "founded"
            },
            {
                "name": "concerns"
            },
            {
                "name": "profit"
            },
            {
                "name": "tourism"
            },
            {
                "name": "factor"
            },
            {
                "name": "radio"
            },
            {
                "name": "space"
            },
            {
                "name": "work"
            },
            {
                "name": "devolves"
            },
            {
                "name": "reconstructing"
            },
            {
                "name": "expedite"
            },
            {
                "name": "update"
            },
            {
                "name": "1995"
            },
            {
                "name": "evicting"
            },
            {
                "name": "monument"
            },
            {
                "name": "sheet"
            },
            {
                "name": "claimants"
            },
            {
                "name": "deducted"
            },
            {
                "name": "governor"
            },
            {
                "name": "trade"
            },
            {
                "name": "female"
            },
            {
                "name": "decline"
            },
            {
                "name": "criteria"
            },
            {
                "name": "gratuitous"
            },
            {
                "name": "unreasonable"
            },
            {
                "name": "manuals"
            },
            {
                "name": "assignee"
            },
            {
                "name": "stalls"
            },
            {
                "name": "controller"
            },
            {
                "name": "confiscation"
            },
            {
                "name": "floods"
            },
            {
                "name": "recognize"
            },
            {
                "name": "ejectment"
            },
            {
                "name": "salinity"
            },
            {
                "name": "ages"
            },
            {
                "name": "existed"
            },
            {
                "name": "need"
            },
            {
                "name": "exchanged"
            },
            {
                "name": "remission"
            },
            {
                "name": "like"
            },
            {
                "name": "exclude"
            },
            {
                "name": "ineligible"
            },
            {
                "name": "misapplied"
            },
            {
                "name": "banking"
            },
            {
                "name": "guaranteeing"
            },
            {
                "name": "surety"
            },
            {
                "name": "barrages"
            },
            {
                "name": "habitations"
            },
            {
                "name": "landslides"
            },
            {
                "name": "migration"
            },
            {
                "name": "format"
            },
            {
                "name": "defaulter"
            },
            {
                "name": "regulated"
            },
            {
                "name": "notifying"
            },
            {
                "name": "destroy"
            },
            {
                "name": "trail"
            },
            {
                "name": "obtain"
            },
            {
                "name": "acquires"
            },
            {
                "name": "custom"
            },
            {
                "name": "imprisonment"
            },
            {
                "name": "widow"
            },
            {
                "name": "art"
            },
            {
                "name": "comptroller"
            },
            {
                "name": "legatee"
            },
            {
                "name": "prefers"
            },
            {
                "name": "benefited"
            },
            {
                "name": "staircase"
            },
            {
                "name": "hand"
            },
            {
                "name": "addresses"
            },
            {
                "name": "orchard"
            },
            {
                "name": "reinstate"
            },
            {
                "name": "vendee"
            },
            {
                "name": "possessing"
            },
            {
                "name": "known"
            },
            {
                "name": "house"
            },
            {
                "name": "exaction"
            },
            {
                "name": "lands"
            },
            {
                "name": "zone"
            },
            {
                "name": "leaves"
            },
            {
                "name": "headed"
            },
            {
                "name": "deliver"
            },
            {
                "name": "projected"
            },
            {
                "name": "period"
            },
            {
                "name": "freehold"
            },
            {
                "name": "sessions"
            },
            {
                "name": "examinations"
            },
            {
                "name": "inamdar"
            },
            {
                "name": "region"
            },
            {
                "name": "institutes"
            },
            {
                "name": "pottery"
            },
            {
                "name": "assignees"
            },
            {
                "name": "fouling"
            },
            {
                "name": "abolish"
            },
            {
                "name": "expansion"
            },
            {
                "name": "forum"
            },
            {
                "name": "released"
            },
            {
                "name": "azamabad"
            },
            {
                "name": "excavated"
            },
            {
                "name": "possession"
            },
            {
                "name": "carried"
            },
            {
                "name": "manuring"
            },
            {
                "name": "xiv"
            },
            {
                "name": "hearing"
            },
            {
                "name": "tpa"
            },
            {
                "name": "observances"
            },
            {
                "name": "context"
            },
            {
                "name": "representation"
            },
            {
                "name": "specifications"
            },
            {
                "name": "branches"
            },
            {
                "name": "territory"
            },
            {
                "name": "contravene"
            },
            {
                "name": "negligence"
            },
            {
                "name": "associate"
            },
            {
                "name": "pertinent"
            },
            {
                "name": "recovering"
            },
            {
                "name": "spoken"
            },
            {
                "name": "vicinity"
            },
            {
                "name": "talukdars"
            },
            {
                "name": "conspiracy"
            },
            {
                "name": "tribal"
            },
            {
                "name": "manufactured"
            },
            {
                "name": "problems"
            },
            {
                "name": "times"
            },
            {
                "name": "far"
            },
            {
                "name": "certification"
            },
            {
                "name": "limitations"
            },
            {
                "name": "rename"
            },
            {
                "name": "parent"
            },
            {
                "name": "prohibition"
            },
            {
                "name": "Jharkhand"
            },
            {
                "name": "rubber"
            },
            {
                "name": "distinction"
            },
            {
                "name": "efficiency"
            },
            {
                "name": "impair"
            },
            {
                "name": "deny"
            },
            {
                "name": "series"
            },
            {
                "name": "include"
            },
            {
                "name": "abolition"
            },
            {
                "name": "Madhya Pradesh"
            },
            {
                "name": "forward"
            },
            {
                "name": "adjourn"
            },
            {
                "name": "provinces"
            },
            {
                "name": "available"
            },
            {
                "name": "administered"
            },
            {
                "name": "huts"
            },
            {
                "name": "notify"
            },
            {
                "name": "finalized"
            },
            {
                "name": "deepen"
            },
            {
                "name": "violative"
            },
            {
                "name": "road"
            },
            {
                "name": "equities"
            },
            {
                "name": "methane"
            },
            {
                "name": "partition"
            },
            {
                "name": "justice"
            },
            {
                "name": "endowment"
            },
            {
                "name": "institutions"
            },
            {
                "name": "genuine"
            },
            {
                "name": "begin"
            },
            {
                "name": "flat"
            },
            {
                "name": "stipulated"
            },
            {
                "name": "interim"
            },
            {
                "name": "tamper"
            },
            {
                "name": "munsif"
            },
            {
                "name": "revoked"
            },
            {
                "name": "2019"
            },
            {
                "name": "1876"
            },
            {
                "name": "incorporation"
            },
            {
                "name": "prerequisites"
            },
            {
                "name": "significantly"
            },
            {
                "name": "sarpanch"
            },
            {
                "name": "brokers"
            },
            {
                "name": "instituted"
            },
            {
                "name": "committal"
            },
            {
                "name": "grade"
            },
            {
                "name": "hydro"
            },
            {
                "name": "labor"
            },
            {
                "name": "termination"
            },
            {
                "name": "expiry"
            },
            {
                "name": "preamble"
            },
            {
                "name": "mahal"
            },
            {
                "name": "affecting"
            },
            {
                "name": "1958"
            },
            {
                "name": "prejudice"
            },
            {
                "name": "sentenced"
            },
            {
                "name": "1869"
            },
            {
                "name": "disease"
            },
            {
                "name": "likely"
            },
            {
                "name": "afforested"
            },
            {
                "name": "enclosures"
            },
            {
                "name": "prevented"
            },
            {
                "name": "actively"
            },
            {
                "name": "methods"
            },
            {
                "name": "mortgaging"
            },
            {
                "name": "2016"
            },
            {
                "name": "price"
            },
            {
                "name": "1988"
            },
            {
                "name": "applications"
            },
            {
                "name": "publish"
            },
            {
                "name": "pataris"
            },
            {
                "name": "delegated"
            },
            {
                "name": "cancer"
            },
            {
                "name": "feared"
            },
            {
                "name": "sand"
            },
            {
                "name": "rest"
            },
            {
                "name": "arrangement"
            },
            {
                "name": "draw"
            },
            {
                "name": "consequence"
            },
            {
                "name": "utilizing"
            },
            {
                "name": "daughters"
            },
            {
                "name": "transferee"
            },
            {
                "name": "validates"
            },
            {
                "name": "steel"
            },
            {
                "name": "leading"
            },
            {
                "name": "inscription"
            },
            {
                "name": "expediency"
            },
            {
                "name": "primarily"
            },
            {
                "name": "procedure"
            },
            {
                "name": "enactments"
            },
            {
                "name": "rabi"
            },
            {
                "name": "register"
            },
            {
                "name": "noticeboards"
            },
            {
                "name": "forbidden"
            },
            {
                "name": "patna"
            },
            {
                "name": "telegraph"
            },
            {
                "name": "resides"
            },
            {
                "name": "disposing"
            },
            {
                "name": "appellate"
            },
            {
                "name": "coal"
            },
            {
                "name": "proxy"
            },
            {
                "name": "face"
            },
            {
                "name": "ancillary"
            },
            {
                "name": "conduits"
            },
            {
                "name": "partitions"
            },
            {
                "name": "utilised"
            },
            {
                "name": "beneficiaries"
            },
            {
                "name": "nearly"
            },
            {
                "name": "mortgaged"
            },
            {
                "name": "centres"
            },
            {
                "name": "stones"
            },
            {
                "name": "cross"
            },
            {
                "name": "works"
            },
            {
                "name": "ensures"
            },
            {
                "name": "enquiring"
            },
            {
                "name": "material"
            },
            {
                "name": "camel"
            },
            {
                "name": "mandatory"
            },
            {
                "name": "regularized"
            },
            {
                "name": "stages"
            },
            {
                "name": "block"
            },
            {
                "name": "1971"
            },
            {
                "name": "tenant"
            },
            {
                "name": "shelters"
            },
            {
                "name": "debarred"
            },
            {
                "name": "drawn"
            },
            {
                "name": "crpc"
            },
            {
                "name": "oppose"
            },
            {
                "name": "categorized"
            },
            {
                "name": "assessing"
            },
            {
                "name": "gift"
            },
            {
                "name": "double"
            },
            {
                "name": "cantonment"
            },
            {
                "name": "devise"
            },
            {
                "name": "fulfil"
            },
            {
                "name": "creation"
            },
            {
                "name": "priorities"
            },
            {
                "name": "taluqs"
            },
            {
                "name": "patwaris"
            },
            {
                "name": "write"
            },
            {
                "name": "piercing"
            },
            {
                "name": "rendering"
            },
            {
                "name": "kathiawar"
            },
            {
                "name": "formed"
            },
            {
                "name": "letter"
            },
            {
                "name": "experienced"
            },
            {
                "name": "twentieth"
            },
            {
                "name": "prosecutions"
            },
            {
                "name": "exceed"
            },
            {
                "name": "committing"
            },
            {
                "name": "arrested"
            },
            {
                "name": "townships"
            },
            {
                "name": "descendants"
            },
            {
                "name": "punish"
            },
            {
                "name": "affixing"
            },
            {
                "name": "revised"
            },
            {
                "name": "poll"
            },
            {
                "name": "digha"
            },
            {
                "name": "purpose"
            },
            {
                "name": "declare"
            },
            {
                "name": "remuneration"
            },
            {
                "name": "entitlement"
            },
            {
                "name": "proves"
            },
            {
                "name": "hospitals"
            },
            {
                "name": "minorities"
            },
            {
                "name": "procedures"
            },
            {
                "name": "revested"
            },
            {
                "name": "presumed"
            },
            {
                "name": "acceptance"
            },
            {
                "name": "depicted"
            },
            {
                "name": "holding"
            },
            {
                "name": "status"
            },
            {
                "name": "realizations"
            },
            {
                "name": "wards"
            },
            {
                "name": "verification"
            },
            {
                "name": "committee"
            },
            {
                "name": "dues"
            },
            {
                "name": "comprise"
            },
            {
                "name": "school"
            },
            {
                "name": "realizing"
            },
            {
                "name": "telangana"
            },
            {
                "name": "grabbers"
            },
            {
                "name": "innocence"
            },
            {
                "name": "leave"
            },
            {
                "name": "rendered"
            },
            {
                "name": "exercising"
            },
            {
                "name": "accordingly"
            },
            {
                "name": "session"
            },
            {
                "name": "questioned"
            },
            {
                "name": "seized"
            },
            {
                "name": "abet"
            },
            {
                "name": "adapted"
            },
            {
                "name": "accounts"
            },
            {
                "name": "reduced"
            },
            {
                "name": "possess"
            },
            {
                "name": "qualified"
            },
            {
                "name": "shares"
            },
            {
                "name": "descriptions"
            },
            {
                "name": "deliberately"
            },
            {
                "name": "mainly"
            },
            {
                "name": "cry"
            },
            {
                "name": "vice"
            },
            {
                "name": "rotation"
            },
            {
                "name": "royalties"
            },
            {
                "name": "members"
            },
            {
                "name": "restricted"
            },
            {
                "name": "1900"
            },
            {
                "name": "dalpati"
            },
            {
                "name": "attain"
            },
            {
                "name": "summoning"
            },
            {
                "name": "entirely"
            },
            {
                "name": "energy"
            },
            {
                "name": "concealed"
            },
            {
                "name": "unalienable"
            },
            {
                "name": "directions"
            },
            {
                "name": "banks"
            },
            {
                "name": "chaparmukh"
            },
            {
                "name": "employ"
            },
            {
                "name": "culvert"
            },
            {
                "name": "escheats"
            },
            {
                "name": "consonance"
            },
            {
                "name": "exceeding"
            },
            {
                "name": "fail"
            },
            {
                "name": "live"
            },
            {
                "name": "operations"
            },
            {
                "name": "arrangements"
            },
            {
                "name": "responsible"
            },
            {
                "name": "course"
            },
            {
                "name": "pochampally"
            },
            {
                "name": "plots"
            },
            {
                "name": "negotiable"
            },
            {
                "name": "servicemen"
            },
            {
                "name": "formulation"
            },
            {
                "name": "inapplicable"
            },
            {
                "name": "divide"
            },
            {
                "name": "imambara"
            },
            {
                "name": "enacting"
            },
            {
                "name": "bearing"
            },
            {
                "name": "prohibits"
            },
            {
                "name": "fixation"
            },
            {
                "name": "shown"
            },
            {
                "name": "landholding"
            },
            {
                "name": "surveyors"
            },
            {
                "name": "believed"
            },
            {
                "name": "disposed"
            },
            {
                "name": "meaning"
            },
            {
                "name": "drawings"
            },
            {
                "name": "practising"
            },
            {
                "name": "bend"
            },
            {
                "name": "set"
            },
            {
                "name": "crossings"
            },
            {
                "name": "authorized"
            },
            {
                "name": "turn"
            },
            {
                "name": "recoup"
            },
            {
                "name": "feasible"
            },
            {
                "name": "notification"
            },
            {
                "name": "amendments"
            },
            {
                "name": "1990"
            },
            {
                "name": "food"
            },
            {
                "name": "regranted"
            },
            {
                "name": "terrace"
            },
            {
                "name": "pounds"
            },
            {
                "name": "monolith"
            },
            {
                "name": "hissedar"
            },
            {
                "name": "manoeuvres"
            },
            {
                "name": "corresponding"
            },
            {
                "name": "linkages"
            },
            {
                "name": "1893"
            },
            {
                "name": "addition"
            },
            {
                "name": "attacher"
            },
            {
                "name": "exercisable"
            },
            {
                "name": "afc"
            },
            {
                "name": "gave"
            },
            {
                "name": "returns"
            },
            {
                "name": "infringe"
            },
            {
                "name": "architecture"
            },
            {
                "name": "erosion"
            },
            {
                "name": "mother"
            },
            {
                "name": "policy"
            },
            {
                "name": "danger"
            },
            {
                "name": "fairness"
            },
            {
                "name": "proposal"
            },
            {
                "name": "stowing"
            },
            {
                "name": "metropolitan"
            },
            {
                "name": "filled"
            },
            {
                "name": "acquire"
            },
            {
                "name": "colony"
            },
            {
                "name": "matadar"
            },
            {
                "name": "assessable"
            },
            {
                "name": "grasses"
            },
            {
                "name": "natural"
            },
            {
                "name": "levy"
            },
            {
                "name": "cum"
            },
            {
                "name": "1946"
            },
            {
                "name": "lanka"
            },
            {
                "name": "compilation"
            },
            {
                "name": "verbally"
            },
            {
                "name": "india"
            },
            {
                "name": "environment"
            },
            {
                "name": "rectified"
            },
            {
                "name": "falls"
            },
            {
                "name": "references"
            },
            {
                "name": "harness"
            },
            {
                "name": "hierarchy"
            },
            {
                "name": "relinquishment"
            },
            {
                "name": "spread"
            },
            {
                "name": "immoveable"
            },
            {
                "name": "gas"
            },
            {
                "name": "extinguishment"
            },
            {
                "name": "method"
            },
            {
                "name": "licensed"
            },
            {
                "name": "transferable"
            },
            {
                "name": "testator"
            },
            {
                "name": "takes"
            },
            {
                "name": "disqualifications"
            },
            {
                "name": "societies"
            },
            {
                "name": "expenditure"
            },
            {
                "name": "objector"
            },
            {
                "name": "sind"
            },
            {
                "name": "conclude"
            },
            {
                "name": "traders"
            },
            {
                "name": "supplier"
            },
            {
                "name": "select"
            },
            {
                "name": "corrupting"
            },
            {
                "name": "metes"
            },
            {
                "name": "iv"
            },
            {
                "name": "directs"
            },
            {
                "name": "recommending"
            },
            {
                "name": "condition"
            },
            {
                "name": "size"
            },
            {
                "name": "details"
            },
            {
                "name": "develops"
            },
            {
                "name": "abroad"
            },
            {
                "name": "registering"
            },
            {
                "name": "2009"
            },
            {
                "name": "lawful"
            },
            {
                "name": "c"
            },
            {
                "name": "callings"
            },
            {
                "name": "conducted"
            },
            {
                "name": "cleanse"
            },
            {
                "name": "sadarjama"
            },
            {
                "name": "upkeep"
            },
            {
                "name": "efficient"
            },
            {
                "name": "coordinated"
            },
            {
                "name": "supplemental"
            },
            {
                "name": "stipulation"
            },
            {
                "name": "dilluvion"
            },
            {
                "name": "gram"
            },
            {
                "name": "ceremonies"
            },
            {
                "name": "sell"
            },
            {
                "name": "seal"
            },
            {
                "name": "motion"
            },
            {
                "name": "bifurcation"
            },
            {
                "name": "projects"
            },
            {
                "name": "horticulture"
            },
            {
                "name": "cause"
            },
            {
                "name": "jointly"
            },
            {
                "name": "holdings"
            },
            {
                "name": "grievances"
            },
            {
                "name": "exchange"
            },
            {
                "name": "timber"
            },
            {
                "name": "directly"
            },
            {
                "name": "emption"
            },
            {
                "name": "talukdar"
            },
            {
                "name": "inclines"
            },
            {
                "name": "discovered"
            },
            {
                "name": "examination"
            },
            {
                "name": "percentage"
            },
            {
                "name": "tarram"
            },
            {
                "name": "memorandum"
            },
            {
                "name": "auctioned"
            },
            {
                "name": "constructed"
            },
            {
                "name": "following"
            },
            {
                "name": "surrender"
            },
            {
                "name": "firing"
            },
            {
                "name": "trading"
            },
            {
                "name": "attended"
            },
            {
                "name": "decree"
            },
            {
                "name": "arbitrator"
            },
            {
                "name": "conduct"
            },
            {
                "name": "adjoining"
            },
            {
                "name": "enforcement"
            },
            {
                "name": "surface"
            },
            {
                "name": "reassessment"
            },
            {
                "name": "factum"
            },
            {
                "name": "clauses"
            },
            {
                "name": "absent"
            },
            {
                "name": "secular"
            },
            {
                "name": "warehousing"
            },
            {
                "name": "insolvency"
            },
            {
                "name": "breach"
            },
            {
                "name": "1810"
            },
            {
                "name": "empower"
            },
            {
                "name": "lent"
            },
            {
                "name": "relieved"
            },
            {
                "name": "propriety"
            },
            {
                "name": "emptive"
            },
            {
                "name": "reported"
            },
            {
                "name": "wound"
            },
            {
                "name": "inability"
            },
            {
                "name": "alternations"
            },
            {
                "name": "1931"
            },
            {
                "name": "postponed"
            },
            {
                "name": "vests"
            },
            {
                "name": "operating"
            },
            {
                "name": "v"
            },
            {
                "name": "partitioning"
            },
            {
                "name": "force"
            },
            {
                "name": "homestead"
            },
            {
                "name": "1890"
            },
            {
                "name": "class"
            },
            {
                "name": "state"
            },
            {
                "name": "exploit"
            },
            {
                "name": "appointments"
            },
            {
                "name": "1992"
            },
            {
                "name": "obstruct"
            },
            {
                "name": "stock"
            },
            {
                "name": "begun"
            },
            {
                "name": "professions"
            },
            {
                "name": "considers"
            },
            {
                "name": "ensured"
            },
            {
                "name": "father"
            },
            {
                "name": "flowers"
            },
            {
                "name": "add"
            },
            {
                "name": "raiyats"
            },
            {
                "name": "gramdan"
            },
            {
                "name": "bidis"
            },
            {
                "name": "injurious"
            },
            {
                "name": "book"
            },
            {
                "name": "danpatra"
            },
            {
                "name": "help"
            },
            {
                "name": "inquired"
            },
            {
                "name": "performing"
            },
            {
                "name": "allot"
            },
            {
                "name": "embankment"
            },
            {
                "name": "threat"
            },
            {
                "name": "publishing"
            },
            {
                "name": "deducting"
            },
            {
                "name": "direct"
            },
            {
                "name": "retain"
            },
            {
                "name": "repairing"
            },
            {
                "name": "assented"
            },
            {
                "name": "fitted"
            },
            {
                "name": "treatment"
            },
            {
                "name": "duties"
            },
            {
                "name": "assistance"
            },
            {
                "name": "abolished"
            },
            {
                "name": "alignment"
            },
            {
                "name": "abandonment"
            },
            {
                "name": "liquidator"
            },
            {
                "name": "newspapers"
            },
            {
                "name": "reviewed"
            },
            {
                "name": "pumps"
            },
            {
                "name": "reckoning"
            },
            {
                "name": "grabbed"
            },
            {
                "name": "equitable"
            },
            {
                "name": "dealt"
            },
            {
                "name": "belong"
            },
            {
                "name": "capable"
            },
            {
                "name": "shooting"
            },
            {
                "name": "fillies"
            },
            {
                "name": "line"
            },
            {
                "name": "terminated"
            },
            {
                "name": "facilitation"
            },
            {
                "name": "decides"
            },
            {
                "name": "purchases"
            },
            {
                "name": "accrued"
            },
            {
                "name": "1969"
            },
            {
                "name": "lodged"
            },
            {
                "name": "consult"
            },
            {
                "name": "prevalent"
            },
            {
                "name": "reafforestation"
            },
            {
                "name": "formulated"
            },
            {
                "name": "creating"
            },
            {
                "name": "lakhimpur"
            },
            {
                "name": "landholder"
            },
            {
                "name": "balances"
            },
            {
                "name": "legislations"
            },
            {
                "name": "sez"
            },
            {
                "name": "usages"
            },
            {
                "name": "parishad"
            },
            {
                "name": "depth"
            },
            {
                "name": "Bihar"
            },
            {
                "name": "bearer"
            },
            {
                "name": "accepted"
            },
            {
                "name": "assets"
            },
            {
                "name": "continues"
            },
            {
                "name": "activities"
            },
            {
                "name": "specifying"
            },
            {
                "name": "outbreak"
            },
            {
                "name": "principal"
            },
            {
                "name": "dealers"
            },
            {
                "name": "affording"
            },
            {
                "name": "hyderabad"
            },
            {
                "name": "retire"
            },
            {
                "name": "modification"
            },
            {
                "name": "stability"
            },
            {
                "name": "boulders"
            },
            {
                "name": "resident"
            },
            {
                "name": "occupies"
            },
            {
                "name": "smooth"
            },
            {
                "name": "fixture"
            },
            {
                "name": "content"
            },
            {
                "name": "qualify"
            },
            {
                "name": "unable"
            },
            {
                "name": "shortfall"
            },
            {
                "name": "assignment"
            },
            {
                "name": "marketing"
            },
            {
                "name": "manner"
            },
            {
                "name": "fund"
            },
            {
                "name": "patwari"
            },
            {
                "name": "refers"
            },
            {
                "name": "admit"
            },
            {
                "name": "abridge"
            },
            {
                "name": "thing"
            },
            {
                "name": "aquaculture"
            },
            {
                "name": "strikes"
            },
            {
                "name": "disconnecting"
            },
            {
                "name": "facts"
            },
            {
                "name": "partner"
            },
            {
                "name": "riots"
            },
            {
                "name": "reclaiming"
            },
            {
                "name": "securities"
            },
            {
                "name": "suffer"
            },
            {
                "name": "preventing"
            },
            {
                "name": "persons"
            },
            {
                "name": "disposal"
            },
            {
                "name": "instruments"
            },
            {
                "name": "hotel"
            },
            {
                "name": "surcharges"
            },
            {
                "name": "advantage"
            },
            {
                "name": "performed"
            },
            {
                "name": "distrait"
            },
            {
                "name": "mouth"
            },
            {
                "name": "recoverable"
            },
            {
                "name": "abuse"
            },
            {
                "name": "pension"
            },
            {
                "name": "petroleum"
            },
            {
                "name": "cultivates"
            },
            {
                "name": "acquit"
            },
            {
                "name": "assault"
            },
            {
                "name": "despatched"
            },
            {
                "name": "attempt"
            },
            {
                "name": "convenience"
            },
            {
                "name": "prices"
            },
            {
                "name": "agriculturists"
            },
            {
                "name": "returning"
            },
            {
                "name": "cleared"
            },
            {
                "name": "relates"
            },
            {
                "name": "cultivator"
            },
            {
                "name": "deviated"
            },
            {
                "name": "imams"
            },
            {
                "name": "foodstuff"
            },
            {
                "name": "releasable"
            },
            {
                "name": "custodian"
            },
            {
                "name": "negligent"
            },
            {
                "name": "cultivation"
            },
            {
                "name": "hailstorms"
            },
            {
                "name": "appeal"
            },
            {
                "name": "contempt"
            },
            {
                "name": "contribute"
            },
            {
                "name": "All India (except Jammu and Kashmir)"
            },
            {
                "name": "tenth"
            },
            {
                "name": "convinced"
            },
            {
                "name": "securing"
            },
            {
                "name": "provides"
            },
            {
                "name": "cognizable"
            },
            {
                "name": "restrooms"
            },
            {
                "name": "merely"
            },
            {
                "name": "rivals"
            },
            {
                "name": "rs"
            },
            {
                "name": "permit"
            },
            {
                "name": "un"
            },
            {
                "name": "provision"
            },
            {
                "name": "abets"
            },
            {
                "name": "2000"
            },
            {
                "name": "tannery"
            },
            {
                "name": "excuse"
            },
            {
                "name": "redevelopment"
            },
            {
                "name": "casualties"
            },
            {
                "name": "premium"
            },
            {
                "name": "rehabilitation"
            },
            {
                "name": "nominee"
            },
            {
                "name": "1912"
            },
            {
                "name": "articles"
            },
            {
                "name": "perfect"
            },
            {
                "name": "retained"
            },
            {
                "name": "proximity"
            },
            {
                "name": "inaccuracy"
            },
            {
                "name": "detention"
            },
            {
                "name": "claim"
            },
            {
                "name": "lag"
            },
            {
                "name": "tarai"
            },
            {
                "name": "assesses"
            },
            {
                "name": "effective"
            },
            {
                "name": "shut"
            },
            {
                "name": "riot"
            },
            {
                "name": "artefacts"
            },
            {
                "name": "hilly"
            },
            {
                "name": "develop"
            },
            {
                "name": "rented"
            },
            {
                "name": "rules"
            },
            {
                "name": "trucks"
            },
            {
                "name": "results"
            },
            {
                "name": "subsidies"
            },
            {
                "name": "half"
            },
            {
                "name": "conferred"
            },
            {
                "name": "immature"
            },
            {
                "name": "utilize"
            },
            {
                "name": "reclaim"
            },
            {
                "name": "installations"
            },
            {
                "name": "determines"
            },
            {
                "name": "storage"
            },
            {
                "name": "offenses"
            },
            {
                "name": "mane"
            },
            {
                "name": "favour"
            },
            {
                "name": "implementing"
            },
            {
                "name": "covered"
            },
            {
                "name": "agnates"
            },
            {
                "name": "1839"
            },
            {
                "name": "gives"
            },
            {
                "name": "tenures"
            },
            {
                "name": "follow"
            },
            {
                "name": "raising"
            },
            {
                "name": "surrendered"
            },
            {
                "name": "means"
            },
            {
                "name": "instead"
            },
            {
                "name": "disqualified"
            },
            {
                "name": "constitution"
            },
            {
                "name": "buildings"
            },
            {
                "name": "devolution"
            },
            {
                "name": "authority"
            },
            {
                "name": "reclassification"
            },
            {
                "name": "sewage"
            },
            {
                "name": "permittees"
            },
            {
                "name": "ministry"
            },
            {
                "name": "good"
            },
            {
                "name": "guidance"
            },
            {
                "name": "canal"
            },
            {
                "name": "existing"
            },
            {
                "name": "explains"
            },
            {
                "name": "dismissed"
            },
            {
                "name": "attachment"
            },
            {
                "name": "foreclosed"
            },
            {
                "name": "oudh"
            },
            {
                "name": "barkhalidar"
            },
            {
                "name": "visiting"
            },
            {
                "name": "led"
            },
            {
                "name": "2002"
            },
            {
                "name": "renovate"
            },
            {
                "name": "donate"
            },
            {
                "name": "fairs"
            },
            {
                "name": "insert"
            },
            {
                "name": "delivered"
            },
            {
                "name": "2007"
            },
            {
                "name": "character"
            },
            {
                "name": "pursuance"
            },
            {
                "name": "design"
            },
            {
                "name": "cantonments"
            },
            {
                "name": "legislated"
            },
            {
                "name": "1951"
            },
            {
                "name": "implementation"
            },
            {
                "name": "routes"
            },
            {
                "name": "publication"
            },
            {
                "name": "item"
            },
            {
                "name": "certificate"
            },
            {
                "name": "onus"
            },
            {
                "name": "neeradies"
            },
            {
                "name": "accused"
            },
            {
                "name": "annoyance"
            },
            {
                "name": "compounded"
            },
            {
                "name": "quartered"
            },
            {
                "name": "commutation"
            },
            {
                "name": "evicted"
            },
            {
                "name": "consideration"
            },
            {
                "name": "engaged"
            },
            {
                "name": "laying"
            },
            {
                "name": "wages"
            },
            {
                "name": "protected"
            },
            {
                "name": "All India (except Part B States and Jammu and Kashmir)"
            },
            {
                "name": "interference"
            },
            {
                "name": "relief"
            },
            {
                "name": "b"
            },
            {
                "name": "revival"
            },
            {
                "name": "height"
            },
            {
                "name": "near"
            },
            {
                "name": "cocoa"
            },
            {
                "name": "intestate"
            },
            {
                "name": "ascertainment"
            },
            {
                "name": "family"
            },
            {
                "name": "culturable"
            },
            {
                "name": "newspaper"
            },
            {
                "name": "discusses"
            },
            {
                "name": "consulting"
            },
            {
                "name": "bequest"
            },
            {
                "name": "poor"
            },
            {
                "name": "tract"
            },
            {
                "name": "life"
            },
            {
                "name": "exercises"
            },
            {
                "name": "donated"
            },
            {
                "name": "informing"
            },
            {
                "name": "plans"
            },
            {
                "name": "receivers"
            },
            {
                "name": "matters"
            },
            {
                "name": "lesser"
            },
            {
                "name": "writing"
            },
            {
                "name": "agriculturist"
            },
            {
                "name": "close"
            },
            {
                "name": "compensate"
            },
            {
                "name": "advancing"
            },
            {
                "name": "aiding"
            },
            {
                "name": "plants"
            },
            {
                "name": "zones"
            },
            {
                "name": "supporting"
            },
            {
                "name": "watandar"
            },
            {
                "name": "mature"
            },
            {
                "name": "conducting"
            },
            {
                "name": "1938"
            },
            {
                "name": "1855"
            },
            {
                "name": "kindred"
            },
            {
                "name": "occurred"
            },
            {
                "name": "unoccupied"
            },
            {
                "name": "partially"
            },
            {
                "name": "proportion"
            },
            {
                "name": "relation"
            },
            {
                "name": "berm"
            },
            {
                "name": "headquarters"
            },
            {
                "name": "obstruction"
            },
            {
                "name": "transportation"
            },
            {
                "name": "securitisation"
            },
            {
                "name": "brickfield"
            },
            {
                "name": "production"
            },
            {
                "name": "products"
            },
            {
                "name": "subdivided"
            },
            {
                "name": "rents"
            },
            {
                "name": "development"
            },
            {
                "name": "fourths"
            },
            {
                "name": "forced"
            },
            {
                "name": "blockades"
            },
            {
                "name": "child"
            },
            {
                "name": "finalised"
            },
            {
                "name": "stream"
            },
            {
                "name": "affect"
            },
            {
                "name": "sequestration"
            },
            {
                "name": "getting"
            },
            {
                "name": "nagar"
            },
            {
                "name": "suits"
            },
            {
                "name": "leasehold"
            },
            {
                "name": "exclusion"
            },
            {
                "name": "bills"
            },
            {
                "name": "words"
            },
            {
                "name": "abated"
            },
            {
                "name": "invite"
            },
            {
                "name": "applicants"
            },
            {
                "name": "captivity"
            },
            {
                "name": "gair"
            },
            {
                "name": "compulsory"
            },
            {
                "name": "1986"
            },
            {
                "name": "publicised"
            },
            {
                "name": "executive"
            },
            {
                "name": "cut"
            },
            {
                "name": "precedence"
            },
            {
                "name": "deals"
            },
            {
                "name": "officio"
            },
            {
                "name": "assessors"
            },
            {
                "name": "cleaning"
            },
            {
                "name": "remaining"
            },
            {
                "name": "incorporated"
            },
            {
                "name": "1984"
            },
            {
                "name": "numbering"
            },
            {
                "name": "encumbrances"
            },
            {
                "name": "knowing"
            },
            {
                "name": "according"
            },
            {
                "name": "machinery"
            },
            {
                "name": "location"
            },
            {
                "name": "expired"
            },
            {
                "name": "alternatively"
            },
            {
                "name": "charged"
            },
            {
                "name": "destructive"
            },
            {
                "name": "adduced"
            },
            {
                "name": "maintain"
            },
            {
                "name": "poses"
            },
            {
                "name": "realization"
            },
            {
                "name": "classification"
            },
            {
                "name": "procure"
            },
            {
                "name": "1939"
            },
            {
                "name": "presentation"
            },
            {
                "name": "lengthen"
            },
            {
                "name": "computed"
            },
            {
                "name": "1914"
            },
            {
                "name": "union"
            },
            {
                "name": "knowledge"
            },
            {
                "name": "problem"
            },
            {
                "name": "plant"
            },
            {
                "name": "subsequently"
            },
            {
                "name": "ryotwari"
            },
            {
                "name": "ordered"
            },
            {
                "name": "constitute"
            },
            {
                "name": "dismissal"
            },
            {
                "name": "mechanism"
            },
            {
                "name": "clause"
            },
            {
                "name": "irrevocable"
            },
            {
                "name": "sunk"
            },
            {
                "name": "washing"
            },
            {
                "name": "attempts"
            },
            {
                "name": "mules"
            },
            {
                "name": "sia"
            },
            {
                "name": "apply"
            },
            {
                "name": "alter"
            },
            {
                "name": "pointed"
            },
            {
                "name": "2011"
            },
            {
                "name": "Haryana"
            },
            {
                "name": "zamindars"
            },
            {
                "name": "affected"
            },
            {
                "name": "bonuses"
            },
            {
                "name": "reservation"
            },
            {
                "name": "findings"
            },
            {
                "name": "iron"
            },
            {
                "name": "able"
            },
            {
                "name": "water"
            },
            {
                "name": "historic"
            },
            {
                "name": "rice"
            },
            {
                "name": "reconstructed"
            },
            {
                "name": "ordinary"
            },
            {
                "name": "needs"
            },
            {
                "name": "new"
            },
            {
                "name": "attach"
            },
            {
                "name": "secondly"
            },
            {
                "name": "understood"
            },
            {
                "name": "enforce"
            },
            {
                "name": "implies"
            },
            {
                "name": "marks"
            },
            {
                "name": "ravines"
            },
            {
                "name": "markers"
            },
            {
                "name": "admitting"
            },
            {
                "name": "taxed"
            },
            {
                "name": "contractor"
            },
            {
                "name": "regularity"
            },
            {
                "name": "drink"
            },
            {
                "name": "mortgagor"
            },
            {
                "name": "conformed"
            },
            {
                "name": "marked"
            },
            {
                "name": "allocation"
            },
            {
                "name": "sunrise"
            },
            {
                "name": "cleanliness"
            },
            {
                "name": "fastened"
            },
            {
                "name": "determining"
            },
            {
                "name": "prohibitions"
            },
            {
                "name": "unfitness"
            },
            {
                "name": "comply"
            },
            {
                "name": "sewering"
            },
            {
                "name": "paying"
            },
            {
                "name": "1985"
            },
            {
                "name": "sort"
            },
            {
                "name": "possessor"
            },
            {
                "name": "chak"
            },
            {
                "name": "power"
            },
            {
                "name": "respect"
            },
            {
                "name": "conditions"
            },
            {
                "name": "connected"
            },
            {
                "name": "cesspool"
            },
            {
                "name": "mortgagors"
            },
            {
                "name": "mamlatdars"
            },
            {
                "name": "hirer"
            },
            {
                "name": "concerned"
            },
            {
                "name": "advertising"
            },
            {
                "name": "diluvion"
            },
            {
                "name": "outlined"
            },
            {
                "name": "warehouses"
            },
            {
                "name": "heritable"
            },
            {
                "name": "manure"
            },
            {
                "name": "taluqdar"
            },
            {
                "name": "vendor"
            },
            {
                "name": "resettlement"
            },
            {
                "name": "promulgated"
            },
            {
                "name": "consumers"
            },
            {
                "name": "navigation"
            },
            {
                "name": "indulging"
            },
            {
                "name": "shops"
            },
            {
                "name": "ambit"
            },
            {
                "name": "trial"
            },
            {
                "name": "complement"
            },
            {
                "name": "transaction"
            },
            {
                "name": "grabber"
            },
            {
                "name": "tenement"
            },
            {
                "name": "rtfc"
            },
            {
                "name": "moving"
            },
            {
                "name": "appurtenances"
            },
            {
                "name": "renters"
            },
            {
                "name": "ineffectual"
            },
            {
                "name": "lack"
            },
            {
                "name": "e"
            },
            {
                "name": "version"
            },
            {
                "name": "financial"
            },
            {
                "name": "aforementioned"
            },
            {
                "name": "daman"
            },
            {
                "name": "resistance"
            },
            {
                "name": "maintenance"
            },
            {
                "name": "conveniences"
            },
            {
                "name": "grass"
            },
            {
                "name": "industry"
            },
            {
                "name": "incident"
            },
            {
                "name": "adjoin"
            },
            {
                "name": "dig"
            },
            {
                "name": "misuses"
            },
            {
                "name": "traffic"
            },
            {
                "name": "illegal"
            },
            {
                "name": "womb"
            },
            {
                "name": "excellence"
            },
            {
                "name": "relaxed"
            },
            {
                "name": "repugnant"
            },
            {
                "name": "1981"
            },
            {
                "name": "vehicles"
            },
            {
                "name": "receipts"
            },
            {
                "name": "deficiency"
            },
            {
                "name": "pipe"
            },
            {
                "name": "deprived"
            },
            {
                "name": "demands"
            },
            {
                "name": "1966"
            },
            {
                "name": "reinstatement"
            },
            {
                "name": "mundari"
            },
            {
                "name": "admission"
            },
            {
                "name": "allowances"
            },
            {
                "name": "levies"
            },
            {
                "name": "convene"
            },
            {
                "name": "arbitration"
            },
            {
                "name": "designate"
            },
            {
                "name": "footpath"
            },
            {
                "name": "farmers"
            },
            {
                "name": "worked"
            },
            {
                "name": "fair"
            },
            {
                "name": "planting"
            },
            {
                "name": "cognizance"
            },
            {
                "name": "tax"
            },
            {
                "name": "removal"
            },
            {
                "name": "modifying"
            },
            {
                "name": "revocable"
            },
            {
                "name": "conveyance"
            },
            {
                "name": "appanage"
            },
            {
                "name": "issues"
            },
            {
                "name": "colouring"
            },
            {
                "name": "deductions"
            },
            {
                "name": "treasury"
            },
            {
                "name": "time"
            },
            {
                "name": "reparation"
            },
            {
                "name": "peaceably"
            },
            {
                "name": "pretext"
            },
            {
                "name": "reconstitute"
            },
            {
                "name": "adopted"
            },
            {
                "name": "day"
            },
            {
                "name": "places"
            },
            {
                "name": "loans"
            },
            {
                "name": "freezing"
            },
            {
                "name": "prospective"
            },
            {
                "name": "designation"
            },
            {
                "name": "curb"
            },
            {
                "name": "mention"
            },
            {
                "name": "separate"
            },
            {
                "name": "earth"
            },
            {
                "name": "sink"
            },
            {
                "name": "relating"
            },
            {
                "name": "exceeds"
            },
            {
                "name": "moneys"
            },
            {
                "name": "mortgage"
            },
            {
                "name": "observed"
            },
            {
                "name": "watan"
            },
            {
                "name": "staff"
            },
            {
                "name": "jodi"
            },
            {
                "name": "mark"
            },
            {
                "name": "extension"
            },
            {
                "name": "1949"
            },
            {
                "name": "responsibilities"
            },
            {
                "name": "misrepresentation"
            },
            {
                "name": "government"
            },
            {
                "name": "serving"
            },
            {
                "name": "claimant"
            },
            {
                "name": "mortgages"
            },
            {
                "name": "apparent"
            },
            {
                "name": "photograph"
            },
            {
                "name": "impermissible"
            },
            {
                "name": "ways"
            },
            {
                "name": "companies"
            },
            {
                "name": "reject"
            },
            {
                "name": "voids"
            },
            {
                "name": "promotes"
            },
            {
                "name": "inaccurate"
            },
            {
                "name": "particular"
            },
            {
                "name": "municipal"
            },
            {
                "name": "revisions"
            },
            {
                "name": "excluded"
            },
            {
                "name": "offender"
            },
            {
                "name": "proclamation"
            },
            {
                "name": "official"
            },
            {
                "name": "signalling"
            },
            {
                "name": "alienation"
            },
            {
                "name": "misusing"
            },
            {
                "name": "map"
            },
            {
                "name": "warrants"
            },
            {
                "name": "aim"
            },
            {
                "name": "negotiation"
            },
            {
                "name": "inamdars"
            },
            {
                "name": "fails"
            },
            {
                "name": "workshop"
            },
            {
                "name": "improving"
            },
            {
                "name": "fences"
            },
            {
                "name": "communications"
            },
            {
                "name": "misused"
            },
            {
                "name": "explosives"
            },
            {
                "name": "purporting"
            },
            {
                "name": "corner"
            },
            {
                "name": "derogate"
            },
            {
                "name": "conservation"
            },
            {
                "name": "passages"
            },
            {
                "name": "displayed"
            },
            {
                "name": "incapacity"
            },
            {
                "name": "extract"
            },
            {
                "name": "expert"
            },
            {
                "name": "qualification"
            },
            {
                "name": "outside"
            },
            {
                "name": "embankments"
            },
            {
                "name": "framework"
            },
            {
                "name": "perquisite"
            },
            {
                "name": "healthy"
            },
            {
                "name": "elected"
            },
            {
                "name": "ordinarily"
            },
            {
                "name": "rebate"
            },
            {
                "name": "defects"
            },
            {
                "name": "april"
            },
            {
                "name": "verifying"
            },
            {
                "name": "manavadar"
            },
            {
                "name": "parsi"
            },
            {
                "name": "keeping"
            },
            {
                "name": "grazes"
            },
            {
                "name": "use"
            },
            {
                "name": "cremations"
            },
            {
                "name": "West Bengal"
            },
            {
                "name": "relocation"
            },
            {
                "name": "original"
            },
            {
                "name": "afford"
            },
            {
                "name": "disclosed"
            },
            {
                "name": "subjected"
            },
            {
                "name": "difficulty"
            },
            {
                "name": "legislation"
            },
            {
                "name": "contest"
            },
            {
                "name": "practicable"
            },
            {
                "name": "guiding"
            },
            {
                "name": "optional"
            },
            {
                "name": "contract"
            },
            {
                "name": "affirmed"
            },
            {
                "name": "halka"
            },
            {
                "name": "useful"
            },
            {
                "name": "regularization"
            },
            {
                "name": "signed"
            },
            {
                "name": "summons"
            },
            {
                "name": "belonging"
            },
            {
                "name": "rectangulation"
            },
            {
                "name": "ancient"
            },
            {
                "name": "institutional"
            },
            {
                "name": "home"
            },
            {
                "name": "intermediary"
            },
            {
                "name": "lamps"
            },
            {
                "name": "working"
            },
            {
                "name": "ready"
            },
            {
                "name": "undertaking"
            },
            {
                "name": "improinge"
            },
            {
                "name": "manners"
            },
            {
                "name": "encamp"
            },
            {
                "name": "purview"
            },
            {
                "name": "years"
            },
            {
                "name": "stay"
            },
            {
                "name": "discovers"
            },
            {
                "name": "dereliction"
            },
            {
                "name": "putting"
            },
            {
                "name": "citizens"
            },
            {
                "name": "prepare"
            },
            {
                "name": "capital"
            },
            {
                "name": "airports"
            },
            {
                "name": "violated"
            },
            {
                "name": "decrease"
            },
            {
                "name": "rates"
            },
            {
                "name": "grazing"
            },
            {
                "name": "breeding"
            },
            {
                "name": "pass"
            },
            {
                "name": "crowded"
            },
            {
                "name": "maintained"
            },
            {
                "name": "requesting"
            },
            {
                "name": "ground"
            },
            {
                "name": "patta"
            },
            {
                "name": "fifth"
            },
            {
                "name": "satisfy"
            },
            {
                "name": "welfare"
            },
            {
                "name": "taluka"
            },
            {
                "name": "krishna"
            },
            {
                "name": "situate"
            },
            {
                "name": "employments"
            },
            {
                "name": "groundnut"
            },
            {
                "name": "1903"
            },
            {
                "name": "compliance"
            },
            {
                "name": "ammunition"
            },
            {
                "name": "understanding"
            },
            {
                "name": "binding"
            },
            {
                "name": "drylands"
            },
            {
                "name": "1961"
            },
            {
                "name": "contractual"
            },
            {
                "name": "prospectus"
            },
            {
                "name": "expresses"
            },
            {
                "name": "attributable"
            },
            {
                "name": "derive"
            },
            {
                "name": "objections"
            },
            {
                "name": "filed"
            },
            {
                "name": "katakhal"
            },
            {
                "name": "save"
            },
            {
                "name": "sold"
            },
            {
                "name": "2001"
            },
            {
                "name": "noc"
            },
            {
                "name": "alternatives"
            },
            {
                "name": "sahan"
            },
            {
                "name": "identify"
            },
            {
                "name": "article"
            },
            {
                "name": "desiring"
            },
            {
                "name": "deltas"
            },
            {
                "name": "seller"
            },
            {
                "name": "seen"
            },
            {
                "name": "collective"
            },
            {
                "name": "copy"
            },
            {
                "name": "tariff"
            },
            {
                "name": "served"
            },
            {
                "name": "renewing"
            },
            {
                "name": "appearance"
            },
            {
                "name": "burials"
            },
            {
                "name": "timeperiod"
            },
            {
                "name": "produce"
            },
            {
                "name": "yarn"
            },
            {
                "name": "forming"
            },
            {
                "name": "year"
            },
            {
                "name": "thirty"
            },
            {
                "name": "failed"
            },
            {
                "name": "uniform"
            },
            {
                "name": "equitably"
            },
            {
                "name": "culture"
            },
            {
                "name": "metro"
            },
            {
                "name": "dredging"
            },
            {
                "name": "rail"
            },
            {
                "name": "demolished"
            },
            {
                "name": "thirdly"
            },
            {
                "name": "mandated"
            },
            {
                "name": "assigning"
            },
            {
                "name": "minimize"
            },
            {
                "name": "codification"
            },
            {
                "name": "sign"
            },
            {
                "name": "guntur"
            },
            {
                "name": "postpone"
            },
            {
                "name": "exception"
            },
            {
                "name": "prescribing"
            },
            {
                "name": "floriculture"
            },
            {
                "name": "evaluation"
            },
            {
                "name": "landowners"
            },
            {
                "name": "owned"
            },
            {
                "name": "descendant"
            },
            {
                "name": "removing"
            },
            {
                "name": "workings"
            },
            {
                "name": "damaging"
            },
            {
                "name": "loss"
            },
            {
                "name": "realise"
            },
            {
                "name": "demanded"
            },
            {
                "name": "obtainable"
            },
            {
                "name": "obtains"
            },
            {
                "name": "convening"
            },
            {
                "name": "instructions"
            },
            {
                "name": "uranium"
            },
            {
                "name": "hope"
            },
            {
                "name": "fixing"
            },
            {
                "name": "waterholes"
            },
            {
                "name": "applies"
            },
            {
                "name": "thirds"
            },
            {
                "name": "burial"
            },
            {
                "name": "placement"
            },
            {
                "name": "target"
            },
            {
                "name": "reoccupying"
            },
            {
                "name": "headman"
            },
            {
                "name": "squalid"
            },
            {
                "name": "kazis"
            },
            {
                "name": "1926"
            },
            {
                "name": "user"
            },
            {
                "name": "seeking"
            },
            {
                "name": "suspected"
            },
            {
                "name": "exemption"
            },
            {
                "name": "processes"
            },
            {
                "name": "appellant"
            },
            {
                "name": "encroach"
            },
            {
                "name": "transacting"
            },
            {
                "name": "tramway"
            },
            {
                "name": "arises"
            },
            {
                "name": "bad"
            },
            {
                "name": "trespassing"
            },
            {
                "name": "creeks"
            },
            {
                "name": "rapid"
            },
            {
                "name": "substitute"
            },
            {
                "name": "drainage"
            },
            {
                "name": "collectable"
            },
            {
                "name": "jungles"
            },
            {
                "name": "surrounded"
            },
            {
                "name": "pulling"
            },
            {
                "name": "measurers"
            },
            {
                "name": "naming"
            },
            {
                "name": "entails"
            },
            {
                "name": "localities"
            },
            {
                "name": "measured"
            },
            {
                "name": "objecting"
            },
            {
                "name": "defaces"
            },
            {
                "name": "consequent"
            },
            {
                "name": "consultants"
            },
            {
                "name": "inoperative"
            },
            {
                "name": "abide"
            },
            {
                "name": "cutherry"
            },
            {
                "name": "godowns"
            },
            {
                "name": "unassignable"
            },
            {
                "name": "nationality"
            },
            {
                "name": "private"
            },
            {
                "name": "collectorships"
            },
            {
                "name": "pertain"
            },
            {
                "name": "presumption"
            },
            {
                "name": "tigers"
            },
            {
                "name": "committees"
            },
            {
                "name": "ifcs"
            },
            {
                "name": "taxation"
            },
            {
                "name": "dispossess"
            },
            {
                "name": "reconsideration"
            },
            {
                "name": "kept"
            },
            {
                "name": "retention"
            },
            {
                "name": "intervals"
            },
            {
                "name": "diverted"
            },
            {
                "name": "boundary"
            },
            {
                "name": "built"
            },
            {
                "name": "conservancy"
            },
            {
                "name": "column"
            },
            {
                "name": "programmes"
            },
            {
                "name": "landlord"
            },
            {
                "name": "agreement"
            },
            {
                "name": "compelled"
            },
            {
                "name": "contravenes"
            },
            {
                "name": "terminating"
            },
            {
                "name": "virtue"
            },
            {
                "name": "girasdar"
            },
            {
                "name": "inclusive"
            },
            {
                "name": "gidb"
            },
            {
                "name": "lifetime"
            },
            {
                "name": "affirmation"
            },
            {
                "name": "consist"
            },
            {
                "name": "absolute"
            },
            {
                "name": "gravity"
            },
            {
                "name": "censure"
            },
            {
                "name": "continued"
            },
            {
                "name": "computation"
            },
            {
                "name": "cpc"
            },
            {
                "name": "enumerated"
            },
            {
                "name": "finalization"
            },
            {
                "name": "pakistan"
            },
            {
                "name": "registration"
            },
            {
                "name": "lake"
            },
            {
                "name": "modified"
            },
            {
                "name": "respond"
            },
            {
                "name": "va"
            },
            {
                "name": "streets"
            },
            {
                "name": "embody"
            },
            {
                "name": "happening"
            },
            {
                "name": "interfere"
            },
            {
                "name": "engages"
            },
            {
                "name": "redeem"
            },
            {
                "name": "enjoying"
            },
            {
                "name": "ministries"
            },
            {
                "name": "scheme"
            },
            {
                "name": "disbursements"
            },
            {
                "name": "incidental"
            },
            {
                "name": "breached"
            },
            {
                "name": "concentration"
            },
            {
                "name": "flow"
            },
            {
                "name": "gardens"
            },
            {
                "name": "transfers"
            },
            {
                "name": "allocations"
            },
            {
                "name": "corporeal"
            },
            {
                "name": "form"
            },
            {
                "name": "1858"
            },
            {
                "name": "hydroelectric"
            },
            {
                "name": "excavation"
            },
            {
                "name": "parking"
            },
            {
                "name": "importantly"
            },
            {
                "name": "donation"
            },
            {
                "name": "residue"
            },
            {
                "name": "afforestation"
            },
            {
                "name": "letters"
            },
            {
                "name": "entrusted"
            },
            {
                "name": "towns"
            },
            {
                "name": "rewarded"
            },
            {
                "name": "1976"
            },
            {
                "name": "light"
            },
            {
                "name": "notified"
            },
            {
                "name": "notes"
            },
            {
                "name": "sides"
            },
            {
                "name": "terminate"
            },
            {
                "name": "steps"
            },
            {
                "name": "assumes"
            },
            {
                "name": "watershed"
            },
            {
                "name": "profession"
            },
            {
                "name": "erroneous"
            },
            {
                "name": "alteration"
            },
            {
                "name": "grove"
            },
            {
                "name": "bonds"
            },
            {
                "name": "uses"
            },
            {
                "name": "advising"
            },
            {
                "name": "ii"
            },
            {
                "name": "temporary"
            },
            {
                "name": "obnoxious"
            },
            {
                "name": "benefits"
            },
            {
                "name": "forces"
            },
            {
                "name": "commodity"
            },
            {
                "name": "quantity"
            },
            {
                "name": "hereinafter"
            },
            {
                "name": "watering"
            },
            {
                "name": "discovery"
            },
            {
                "name": "successor"
            },
            {
                "name": "parties"
            },
            {
                "name": "invitation"
            },
            {
                "name": "stop"
            },
            {
                "name": "escape"
            },
            {
                "name": "acknowledgement"
            },
            {
                "name": "beds"
            },
            {
                "name": "colonisation"
            },
            {
                "name": "correction"
            },
            {
                "name": "jama"
            },
            {
                "name": "written"
            },
            {
                "name": "brought"
            },
            {
                "name": "competent"
            },
            {
                "name": "repossession"
            },
            {
                "name": "corporations"
            },
            {
                "name": "misappropriated"
            },
            {
                "name": "allegations"
            },
            {
                "name": "commence"
            },
            {
                "name": "days"
            },
            {
                "name": "levelling"
            },
            {
                "name": "inter"
            },
            {
                "name": "owns"
            },
            {
                "name": "lien"
            },
            {
                "name": "malguzar"
            },
            {
                "name": "gratuities"
            },
            {
                "name": "simplification"
            },
            {
                "name": "sinking"
            },
            {
                "name": "alienating"
            },
            {
                "name": "december"
            },
            {
                "name": "imprisoned"
            },
            {
                "name": "milestones"
            },
            {
                "name": "abided"
            },
            {
                "name": "annuls"
            },
            {
                "name": "raised"
            },
            {
                "name": "charge"
            },
            {
                "name": "finding"
            },
            {
                "name": "past"
            },
            {
                "name": "equal"
            },
            {
                "name": "riding"
            },
            {
                "name": "lastly"
            },
            {
                "name": "summarily"
            },
            {
                "name": "preparing"
            },
            {
                "name": "destitutes"
            },
            {
                "name": "entertainment"
            },
            {
                "name": "apparatus"
            },
            {
                "name": "onwards"
            },
            {
                "name": "salaries"
            },
            {
                "name": "khumt"
            },
            {
                "name": "mentioned"
            },
            {
                "name": "desires"
            },
            {
                "name": "borrow"
            },
            {
                "name": "individuals"
            },
            {
                "name": "trespassers"
            },
            {
                "name": "lengthened"
            },
            {
                "name": "creditor"
            },
            {
                "name": "markets"
            },
            {
                "name": "waterlogging"
            },
            {
                "name": "hear"
            },
            {
                "name": "disconnect"
            },
            {
                "name": "adhering"
            },
            {
                "name": "enactment"
            },
            {
                "name": "injunctions"
            },
            {
                "name": "buy"
            },
            {
                "name": "underpin"
            },
            {
                "name": "fulfilment"
            },
            {
                "name": "inspector"
            },
            {
                "name": "member"
            },
            {
                "name": "multiple"
            },
            {
                "name": "contiguity"
            },
            {
                "name": "immediately"
            },
            {
                "name": "planned"
            },
            {
                "name": "schedule"
            },
            {
                "name": "additions"
            },
            {
                "name": "offenders"
            },
            {
                "name": "deposit"
            },
            {
                "name": "evidence"
            },
            {
                "name": "introduction"
            },
            {
                "name": "liability"
            },
            {
                "name": "conviction"
            },
            {
                "name": "eligible"
            },
            {
                "name": "lost"
            },
            {
                "name": "quasi"
            },
            {
                "name": "consumption"
            },
            {
                "name": "continuously"
            },
            {
                "name": "1993"
            },
            {
                "name": "jhum"
            },
            {
                "name": "thereof"
            },
            {
                "name": "manufacturing"
            },
            {
                "name": "assumption"
            },
            {
                "name": "receiving"
            },
            {
                "name": "extraction"
            },
            {
                "name": "pound"
            },
            {
                "name": "collectors"
            },
            {
                "name": "townland"
            },
            {
                "name": "occupying"
            },
            {
                "name": "deed"
            },
            {
                "name": "exchanges"
            },
            {
                "name": "retrieved"
            },
            {
                "name": "conduit"
            },
            {
                "name": "chandigarh"
            },
            {
                "name": "boats"
            },
            {
                "name": "aimed"
            },
            {
                "name": "bailable"
            },
            {
                "name": "limits"
            },
            {
                "name": "display"
            },
            {
                "name": "dispossesses"
            },
            {
                "name": "2014"
            },
            {
                "name": "object"
            },
            {
                "name": "dairy"
            },
            {
                "name": "framed"
            },
            {
                "name": "concomitant"
            },
            {
                "name": "intent"
            },
            {
                "name": "burden"
            },
            {
                "name": "impossible"
            },
            {
                "name": "motels"
            },
            {
                "name": "transferor"
            },
            {
                "name": "certainty"
            },
            {
                "name": "audited"
            },
            {
                "name": "sight"
            },
            {
                "name": "puja"
            },
            {
                "name": "promoter"
            },
            {
                "name": "september"
            },
            {
                "name": "split"
            },
            {
                "name": "standard"
            },
            {
                "name": "springs"
            },
            {
                "name": "fruit"
            },
            {
                "name": "storing"
            },
            {
                "name": "All India"
            },
            {
                "name": "dargah"
            },
            {
                "name": "grantees"
            },
            {
                "name": "stopped"
            },
            {
                "name": "personhimself"
            },
            {
                "name": "penalization"
            },
            {
                "name": "organization"
            },
            {
                "name": "building"
            },
            {
                "name": "1873"
            },
            {
                "name": "heir"
            },
            {
                "name": "favor"
            },
            {
                "name": "accordance"
            },
            {
                "name": "breaks"
            },
            {
                "name": "stacked"
            },
            {
                "name": "bars"
            },
            {
                "name": "perishables"
            },
            {
                "name": "secretary"
            },
            {
                "name": "makes"
            },
            {
                "name": "satisfaction"
            },
            {
                "name": "forfeited"
            },
            {
                "name": "centre"
            },
            {
                "name": "measurement"
            },
            {
                "name": "authorization"
            },
            {
                "name": "transparent"
            },
            {
                "name": "erasing"
            },
            {
                "name": "enjoyed"
            },
            {
                "name": "facilitating"
            },
            {
                "name": "refrain"
            },
            {
                "name": "hailstorm"
            },
            {
                "name": "upgradation"
            },
            {
                "name": "custody"
            },
            {
                "name": "ornamental"
            },
            {
                "name": "lines"
            },
            {
                "name": "tributaries"
            },
            {
                "name": "demarcation"
            },
            {
                "name": "irsali"
            },
            {
                "name": "floor"
            },
            {
                "name": "hindus"
            },
            {
                "name": "general"
            },
            {
                "name": "endeavour"
            },
            {
                "name": "sluice"
            },
            {
                "name": "directing"
            },
            {
                "name": "1953"
            },
            {
                "name": "loan"
            },
            {
                "name": "ineligibility"
            },
            {
                "name": "dam"
            },
            {
                "name": "borrower"
            },
            {
                "name": "easement"
            },
            {
                "name": "achieved"
            },
            {
                "name": "opposed"
            },
            {
                "name": "furnish"
            },
            {
                "name": "mandals"
            },
            {
                "name": "de"
            },
            {
                "name": "withdraws"
            },
            {
                "name": "calculation"
            },
            {
                "name": "possesses"
            },
            {
                "name": "crushing"
            },
            {
                "name": "allow"
            },
            {
                "name": "kiln"
            },
            {
                "name": "j"
            },
            {
                "name": "sayar"
            },
            {
                "name": "taluk"
            },
            {
                "name": "names"
            },
            {
                "name": "defaulters"
            },
            {
                "name": "challenged"
            },
            {
                "name": "clerk"
            },
            {
                "name": "enchantment"
            },
            {
                "name": "scrutinizing"
            },
            {
                "name": "artisan"
            },
            {
                "name": "middle"
            },
            {
                "name": "fact"
            },
            {
                "name": "adoption"
            },
            {
                "name": "unfounded"
            },
            {
                "name": "plot"
            },
            {
                "name": "alleged"
            },
            {
                "name": "schedules"
            },
            {
                "name": "refrained"
            },
            {
                "name": "regarded"
            },
            {
                "name": "books"
            },
            {
                "name": "discharge"
            },
            {
                "name": "infrastructure"
            },
            {
                "name": "revenue"
            },
            {
                "name": "spearheaded"
            },
            {
                "name": "sem"
            },
            {
                "name": "going"
            },
            {
                "name": "destroying"
            },
            {
                "name": "foreseeable"
            },
            {
                "name": "fit"
            },
            {
                "name": "identified"
            },
            {
                "name": "find"
            },
            {
                "name": "remains"
            },
            {
                "name": "produced"
            },
            {
                "name": "betting"
            },
            {
                "name": "distrain"
            },
            {
                "name": "constituency"
            },
            {
                "name": "changes"
            },
            {
                "name": "rulers"
            },
            {
                "name": "sports"
            },
            {
                "name": "proviso"
            },
            {
                "name": "eject"
            },
            {
                "name": "encumbrance"
            },
            {
                "name": "soundness"
            },
            {
                "name": "abandon"
            },
            {
                "name": "treasurer"
            },
            {
                "name": "sea"
            },
            {
                "name": "executions"
            },
            {
                "name": "1882"
            },
            {
                "name": "avoid"
            },
            {
                "name": "second"
            },
            {
                "name": "closed"
            },
            {
                "name": "defaulted"
            },
            {
                "name": "audits"
            },
            {
                "name": "corporation"
            },
            {
                "name": "dissolve"
            },
            {
                "name": "settled"
            },
            {
                "name": "reservations"
            },
            {
                "name": "respects"
            },
            {
                "name": "presence"
            },
            {
                "name": "justification"
            },
            {
                "name": "artisans"
            },
            {
                "name": "broker"
            },
            {
                "name": "evictions"
            },
            {
                "name": "imperfect"
            },
            {
                "name": "taluqa"
            },
            {
                "name": "draws"
            },
            {
                "name": "violating"
            },
            {
                "name": "invited"
            },
            {
                "name": "adjourned"
            },
            {
                "name": "surplus"
            },
            {
                "name": "embarrassment"
            },
            {
                "name": "attendance"
            },
            {
                "name": "voting"
            },
            {
                "name": "developing"
            },
            {
                "name": "disadvantages"
            },
            {
                "name": "rectification"
            },
            {
                "name": "Uttar Pradesh"
            },
            {
                "name": "meghalaya"
            },
            {
                "name": "difficult"
            },
            {
                "name": "education"
            },
            {
                "name": "prohibiting"
            },
            {
                "name": "attempting"
            },
            {
                "name": "suing"
            },
            {
                "name": "premises"
            },
            {
                "name": "infringed"
            },
            {
                "name": "communities"
            },
            {
                "name": "sanctuary"
            },
            {
                "name": "lending"
            },
            {
                "name": "necessary"
            },
            {
                "name": "related"
            },
            {
                "name": "decisions"
            },
            {
                "name": "dishonesty"
            },
            {
                "name": "intervene"
            },
            {
                "name": "affairs"
            },
            {
                "name": "establishments"
            },
            {
                "name": "clear"
            },
            {
                "name": "dower"
            },
            {
                "name": "merger"
            },
            {
                "name": "bombay"
            },
            {
                "name": "head"
            },
            {
                "name": "puts"
            },
            {
                "name": "converting"
            },
            {
                "name": "nistar"
            },
            {
                "name": "forest"
            },
            {
                "name": "importance"
            },
            {
                "name": "reduce"
            },
            {
                "name": "encourage"
            },
            {
                "name": "alignments"
            },
            {
                "name": "inventory"
            },
            {
                "name": "objectives"
            },
            {
                "name": "purport"
            },
            {
                "name": "presidency"
            },
            {
                "name": "ss"
            },
            {
                "name": "unsuitable"
            },
            {
                "name": "concerning"
            },
            {
                "name": "ousts"
            },
            {
                "name": "digging"
            },
            {
                "name": "channelled"
            },
            {
                "name": "suppression"
            },
            {
                "name": "dairying"
            },
            {
                "name": "arms"
            },
            {
                "name": "liens"
            },
            {
                "name": "bhagalpur"
            },
            {
                "name": "returned"
            },
            {
                "name": "file"
            },
            {
                "name": "domicile"
            },
            {
                "name": "river"
            },
            {
                "name": "forbidding"
            },
            {
                "name": "categories"
            },
            {
                "name": "ryots"
            },
            {
                "name": "enclosure"
            },
            {
                "name": "mayor"
            },
            {
                "name": "permanent"
            },
            {
                "name": "exposing"
            },
            {
                "name": "accrual"
            },
            {
                "name": "variation"
            },
            {
                "name": "chairman"
            },
            {
                "name": "represent"
            },
            {
                "name": "auction"
            },
            {
                "name": "fulfilled"
            },
            {
                "name": "abate"
            },
            {
                "name": "compensated"
            },
            {
                "name": "description"
            },
            {
                "name": "owners"
            },
            {
                "name": "appertained"
            },
            {
                "name": "grant"
            },
            {
                "name": "satisfying"
            },
            {
                "name": "diagrams"
            },
            {
                "name": "bear"
            },
            {
                "name": "asylums"
            },
            {
                "name": "bhumidhars"
            },
            {
                "name": "participate"
            },
            {
                "name": "resolution"
            },
            {
                "name": "assent"
            },
            {
                "name": "solatium"
            },
            {
                "name": "consistent"
            },
            {
                "name": "bring"
            },
            {
                "name": "answer"
            },
            {
                "name": "called"
            },
            {
                "name": "piscicultural"
            },
            {
                "name": "surfacing"
            },
            {
                "name": "depositing"
            },
            {
                "name": "flood"
            },
            {
                "name": "analysis"
            },
            {
                "name": "annulment"
            },
            {
                "name": "borrowings"
            },
            {
                "name": "amenity"
            },
            {
                "name": "excavations"
            },
            {
                "name": "acreage"
            },
            {
                "name": "graveyard"
            },
            {
                "name": "coparcener"
            },
            {
                "name": "objects"
            },
            {
                "name": "fixtures"
            },
            {
                "name": "restraining"
            },
            {
                "name": "refunded"
            },
            {
                "name": "summary"
            },
            {
                "name": "depreciates"
            },
            {
                "name": "arrests"
            },
            {
                "name": "experience"
            },
            {
                "name": "approving"
            },
            {
                "name": "commencement"
            },
            {
                "name": "renunciation"
            },
            {
                "name": "lanes"
            },
            {
                "name": "coordinating"
            },
            {
                "name": "sculpture"
            },
            {
                "name": "empowers"
            },
            {
                "name": "mechanisms"
            },
            {
                "name": "regards"
            },
            {
                "name": "magistrate"
            },
            {
                "name": "expression"
            },
            {
                "name": "contravening"
            },
            {
                "name": "chaur"
            },
            {
                "name": "rank"
            },
            {
                "name": "agent"
            },
            {
                "name": "programme"
            },
            {
                "name": "collection"
            },
            {
                "name": "identity"
            },
            {
                "name": "minicoy"
            },
            {
                "name": "powers"
            },
            {
                "name": "pathways"
            },
            {
                "name": "cancelled"
            },
            {
                "name": "fiat"
            },
            {
                "name": "receive"
            },
            {
                "name": "comprises"
            },
            {
                "name": "present"
            },
            {
                "name": "fee"
            },
            {
                "name": "movement"
            },
            {
                "name": "kaul"
            },
            {
                "name": "demolishing"
            },
            {
                "name": "practices"
            },
            {
                "name": "conclusive"
            },
            {
                "name": "delhi"
            },
            {
                "name": "attention"
            },
            {
                "name": "Tamil Nadu"
            },
            {
                "name": "proposing"
            },
            {
                "name": "voidable"
            },
            {
                "name": "red"
            },
            {
                "name": "stating"
            },
            {
                "name": "shall"
            },
            {
                "name": "drop"
            },
            {
                "name": "violates"
            },
            {
                "name": "unwillingness"
            },
            {
                "name": "british"
            },
            {
                "name": "withdrawing"
            },
            {
                "name": "incur"
            },
            {
                "name": "knows"
            },
            {
                "name": "promulgation"
            },
            {
                "name": "occupation"
            },
            {
                "name": "bhumidhar"
            },
            {
                "name": "injured"
            },
            {
                "name": "advertisement"
            },
            {
                "name": "witnesses"
            },
            {
                "name": "terms"
            },
            {
                "name": "withdrawn"
            },
            {
                "name": "manufacture"
            },
            {
                "name": "cultivated"
            },
            {
                "name": "encroached"
            },
            {
                "name": "concession"
            },
            {
                "name": "basis"
            },
            {
                "name": "accord"
            },
            {
                "name": "aid"
            },
            {
                "name": "levels"
            },
            {
                "name": "property"
            },
            {
                "name": "finances"
            },
            {
                "name": "estate"
            },
            {
                "name": "noon"
            },
            {
                "name": "disentitle"
            },
            {
                "name": "promoters"
            },
            {
                "name": "champaran"
            },
            {
                "name": "vacate"
            },
            {
                "name": "witness"
            },
            {
                "name": "endeavoured"
            },
            {
                "name": "remissions"
            },
            {
                "name": "superintendent"
            },
            {
                "name": "imposing"
            },
            {
                "name": "vis"
            },
            {
                "name": "views"
            },
            {
                "name": "example"
            },
            {
                "name": "zk"
            },
            {
                "name": "nominate"
            },
            {
                "name": "schemes"
            },
            {
                "name": "withholding"
            },
            {
                "name": "erections"
            },
            {
                "name": "mmdr"
            },
            {
                "name": "distinct"
            },
            {
                "name": "export"
            },
            {
                "name": "authorities"
            },
            {
                "name": "brick"
            },
            {
                "name": "hands"
            },
            {
                "name": "scenarios"
            },
            {
                "name": "slave"
            },
            {
                "name": "sra"
            },
            {
                "name": "acting"
            },
            {
                "name": "requisitioned"
            },
            {
                "name": "purchasers"
            },
            {
                "name": "employer"
            },
            {
                "name": "harvested"
            },
            {
                "name": "board"
            },
            {
                "name": "widening"
            },
            {
                "name": "dilapidation"
            },
            {
                "name": "enlarged"
            },
            {
                "name": "submission"
            },
            {
                "name": "alluvion"
            },
            {
                "name": "hour"
            },
            {
                "name": "moveable"
            },
            {
                "name": "railway"
            },
            {
                "name": "purchasing"
            },
            {
                "name": "remunerations"
            },
            {
                "name": "deceased"
            },
            {
                "name": "khas"
            },
            {
                "name": "recommendation"
            },
            {
                "name": "kattubadi"
            },
            {
                "name": "defrayed"
            },
            {
                "name": "domestic"
            },
            {
                "name": "clearing"
            },
            {
                "name": "render"
            },
            {
                "name": "1886"
            },
            {
                "name": "curators"
            },
            {
                "name": "candidate"
            },
            {
                "name": "prospect"
            },
            {
                "name": "exist"
            },
            {
                "name": "dissent"
            },
            {
                "name": "responsibility"
            },
            {
                "name": "liabilities"
            },
            {
                "name": "imposition"
            },
            {
                "name": "forfeiture"
            },
            {
                "name": "proved"
            },
            {
                "name": "abolishing"
            },
            {
                "name": "vacancies"
            },
            {
                "name": "elections"
            },
            {
                "name": "partners"
            },
            {
                "name": "2012"
            },
            {
                "name": "u"
            },
            {
                "name": "viii"
            },
            {
                "name": "wood"
            },
            {
                "name": "department"
            },
            {
                "name": "All India (except Part B States)"
            },
            {
                "name": "1821"
            },
            {
                "name": "quality"
            },
            {
                "name": "whitewashing"
            },
            {
                "name": "detailing"
            },
            {
                "name": "traditions"
            },
            {
                "name": "resided"
            },
            {
                "name": "infancy"
            },
            {
                "name": "aqua"
            },
            {
                "name": "certain"
            },
            {
                "name": "temple"
            },
            {
                "name": "viability"
            },
            {
                "name": "watandars"
            },
            {
                "name": "leases"
            },
            {
                "name": "contributed"
            },
            {
                "name": "website"
            },
            {
                "name": "dirt"
            },
            {
                "name": "stated"
            },
            {
                "name": "charitable"
            },
            {
                "name": "employment"
            },
            {
                "name": "botanical"
            },
            {
                "name": "tiles"
            },
            {
                "name": "account"
            },
            {
                "name": "explaining"
            },
            {
                "name": "drained"
            },
            {
                "name": "substance"
            },
            {
                "name": "behaviour"
            },
            {
                "name": "indemnity"
            },
            {
                "name": "detain"
            },
            {
                "name": "parity"
            },
            {
                "name": "publicity"
            },
            {
                "name": "allotment"
            },
            {
                "name": "delivery"
            },
            {
                "name": "balance"
            },
            {
                "name": "investigated"
            },
            {
                "name": "reconstruction"
            },
            {
                "name": "amendment"
            },
            {
                "name": "processing"
            },
            {
                "name": "offer"
            },
            {
                "name": "marches"
            },
            {
                "name": "soils"
            },
            {
                "name": "appealable"
            },
            {
                "name": "rubbish"
            },
            {
                "name": "governed"
            },
            {
                "name": "proposals"
            },
            {
                "name": "connivance"
            },
            {
                "name": "urinal"
            },
            {
                "name": "end"
            },
            {
                "name": "monuments"
            },
            {
                "name": "drum"
            },
            {
                "name": "1920"
            },
            {
                "name": "alienor"
            },
            {
                "name": "date"
            },
            {
                "name": "instalment"
            },
            {
                "name": "minhadars"
            },
            {
                "name": "chargeable"
            },
            {
                "name": "cutcherries"
            },
            {
                "name": "Telangana"
            },
            {
                "name": "integrity"
            },
            {
                "name": "wrongdoer"
            },
            {
                "name": "permissible"
            },
            {
                "name": "seats"
            },
            {
                "name": "jammu"
            },
            {
                "name": "tobacco"
            },
            {
                "name": "uphold"
            },
            {
                "name": "complies"
            },
            {
                "name": "regulations"
            },
            {
                "name": "injury"
            },
            {
                "name": "tahsil"
            },
            {
                "name": "fix"
            },
            {
                "name": "requisitions"
            },
            {
                "name": "funding"
            },
            {
                "name": "kiosk"
            },
            {
                "name": "void"
            },
            {
                "name": "operated"
            },
            {
                "name": "individual"
            },
            {
                "name": "constituted"
            },
            {
                "name": "rebuild"
            },
            {
                "name": "injuries"
            },
            {
                "name": "selection"
            },
            {
                "name": "appointment"
            },
            {
                "name": "compensation"
            },
            {
                "name": "groundwater"
            },
            {
                "name": "servants"
            },
            {
                "name": "source"
            },
            {
                "name": "jurisdiction"
            },
            {
                "name": "involved"
            },
            {
                "name": "estimates"
            },
            {
                "name": "voters"
            },
            {
                "name": "incharge"
            },
            {
                "name": "violations"
            },
            {
                "name": "intending"
            },
            {
                "name": "ponds"
            },
            {
                "name": "cave"
            },
            {
                "name": "removed"
            },
            {
                "name": "quintessential"
            },
            {
                "name": "reconsider"
            },
            {
                "name": "proposed"
            },
            {
                "name": "play"
            },
            {
                "name": "authorize"
            },
            {
                "name": "stipulate"
            },
            {
                "name": "cultural"
            },
            {
                "name": "created"
            },
            {
                "name": "tried"
            },
            {
                "name": "exproprietary"
            },
            {
                "name": "punishments"
            },
            {
                "name": "lighting"
            },
            {
                "name": "fixed"
            },
            {
                "name": "amends"
            },
            {
                "name": "commodities"
            },
            {
                "name": "1983"
            },
            {
                "name": "group"
            },
            {
                "name": "encroacher"
            },
            {
                "name": "preparation"
            },
            {
                "name": "provided"
            },
            {
                "name": "periods"
            },
            {
                "name": "bark"
            },
            {
                "name": "cancel"
            },
            {
                "name": "right"
            },
            {
                "name": "cutcheries"
            }
        ]
    },




]