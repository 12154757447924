import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import MapView from "./MapView";
import TagView from "./TagView";
import HistoView from "./HistoView";
import AboutDialog from "./AboutDialog";
import ColorByService from "./ColorByService";
import { attrs, attrs_categorical } from "../../datautils";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { fade } from "@material-ui/core/styles/colorManipulator";
import domtoimage from "dom-to-image";
import HelpIcon from "@material-ui/icons/Help";
import FaceIcon from "@material-ui/icons/Face";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useAuth } from "../../contexts/AuthContext";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  viewContainer: {
    display: "none",
    flex: 1,
    flexFlow: "column",
    padding: "0px",
    marginTop: "0px",
    textAlign: "center",
    position: "relative",
    color: theme.palette.text.default,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  viewContent: {
    display: "flex",
    flex: "1 1",
  },
  viewHeader: {
    marginTop: "10px",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    width: "100%",
    minHeight: 0,
    zIndex: 700,
    flex: 1,
    paddingRight: "10px",
  },
  viewToggle: {
    flex: "0 0 140px",
  },
  viewOptions: {
    flex: 2,
  },
  formControlRoot: {
    marginLeft: "2px",
    minWidth: "75px",
  },
  inputLabelRoot: {
    color: theme.palette.primary.light,
    fontSize: "0.75rem",
  },
  iconButton: {
    // height: '48px',
    /* margin-top: 6px; */
    margin: "2px",
    padding: "8px",
    backgroundColor: fade(theme.palette.primary.main, 0.2),
    color: theme.palette.secondary.main,
  },

  selectRoot: {
    // backgroundColor: fade(theme.palette.primary.main, 0.1),
    "& .outlined": {
      paddingLeft: "10px",
      paddingTop: "9px",
      paddingBottom: "9px",
      color: theme.palette.text.default,
      "&: focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    "& .select ": {
      color: theme.palette.text.default,
      paddingLeft: "9px",
    },
  },

  selectSmallRoot: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.secondary.main
        : "rgba(255, 255, 255, 1)",
    backgroundColor: fade(theme.palette.primary.light, 0.1),
    fontSize: "0.625rem",
    textTransform: "none",
    padding: "2px",
    marginLeft: "4px",
    "&:focus": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.secondary.main
          : "rgba(255, 255, 255, 1)!important",
    },
    "& .outlined": {
      paddingLeft: "10px",
      paddingTop: "9px",
      paddingBottom: "9px",
      color: theme.palette.text.default,
      "&: focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    "& .select ": {
      color: theme.palette.text.default,
      paddingLeft: "9px",
    },
  },
  // toggleBtnRoot: {
  //     color: 'blue', // theme.palette.primary.main,
  //     backgroundColor: 'green' // theme.palette.background.default
  // },
  // toggleBtnSelected: {
  //     color: 'red', //theme.palette.primary.main,
  //     backgroundColor: 'yellow', // theme.palette.background.default
  // }
}));

// const showMapStateLayers = []
// const showMapDistrictLayers = []
const showMapStateLayers = [
  "Acts",
  "Forest Cover",
  "Dams",
  "Mines",
  "Scheduled Areas",
];
const showMapDistrictLayers = [
  "Acts",
  "Forest Cover",
  // "Dams",
  "Scheduled Areas",
];

export default function ViewPanel({
  elems,
  inSelect,
  inFocus,
  theme,
  showIntroAtStart,
  showTourAfterIntro,
  UIOptions,
  toggleTourStateHandler,
  setSelectionHandler,
  setFocusHandler,
  setThemeHandler,
}) {
  const classes = useStyles();
  const [viewType, setViewType] = React.useState("geo-view");
  const [partitionBy, setPartitionBy] = React.useState(null);
  const [colorBy, setColorBy] = React.useState("StatesWhereApplicable");
  const [filterBy, setFilterBy] = React.useState("All");
  const [filterOptions, setFilterOptions] = React.useState(["All"]);
  const [histoData, setHistoData] = React.useState(elems);
  const [selectionData, setSelectionData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const { logout, currentUser, currentUserProfile } = useAuth();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const history = useHistory();

  const handleChange = (event) => {
    setViewType(event.target.value);
  };

  const [stateLayer, setStateLayer] = useState("Acts");
  const handleStateLayerChange = (newValue) => {
    // console.log(newValue, stateLayer)
    if (newValue && newValue.length) {
      setStateLayer(newValue);
      if (!["Mines", "Dams"].includes(newValue)) {
        setDistrictLayer(newValue);
      } else {
        setDistrictLayer("Acts");
      }
    }
  };

  const [districtLayer, setDistrictLayer] = useState("Acts");
  const handleDistrictLayerChange = (newValue) => {
    // console.log(newValue, districtLayer)
    if (newValue && newValue.length) {
      setDistrictLayer(newValue);
      setStateLayer(newValue);
    }
  };

  //NOTE: SWITCH TO TURN ON / OFF DISTRICT
  const [geoResolution, setGeoResolution] = React.useState("state");
  // const [geoResolution, setGeoResolution] = React.useState("district");

  const handleGeoResolutionChange = (event, newValue) => {
    // console.log(newValue, geoResolution)
    if (newValue && newValue.length) {
      setGeoResolution(newValue);
    }
  };

  const filterDataByOption = (data, filterByOption) => {
    let filterData = {};
    // console.log(data)
    _.forEach(data, (v, k) => {
      // console.log(v[colorBy], filterByOption)
      if (typeof v[colorBy] === "string" || v[colorBy] instanceof String) {
        if (v[colorBy] === filterByOption) {
          filterData[k] = v;
        }
      } else {
        if (v[colorBy].includes(filterByOption)) {
          filterData[k] = v;
        }
      }
    });
    return filterData;
  };

  const filterDataByIds = (data, ids) => {
    let filterData = _.pick(elems, inSelect.ids);
    return filterData;
  };

  useEffect(() => {
    let x =
      inSelect.ids.length === 0 ? elems : filterDataByIds(elems, inSelect.ids);
    setSelectionData(x);
    setPartitionBy("TimePeriod");
  }, []);

  useEffect(() => {
    // console.log(`[view-panel] filterBy change ${filterBy}`)
    if (filterBy !== "All") {
      //filter group
      let filterData = filterDataByOption(elems, filterBy);
      console.log(filterData);
      let x = _.keys(filterData).map((id) => parseInt(id));
      setSelectionHandler(x, "view-panel", "reset");
    } else {
      setSelectionHandler([], "view-panel", "reset");
    }
  }, [filterBy]);

  useEffect(() => {
    // on colorBy change - update the filterby options.
    // and reset actual filterby to 'all'
    // console.log(`viewPanel.colorby change ${colorBy}`)
    const colorByGroups = ColorByService(histoData, colorBy);
    var filterOptions = _.map(_.values(colorByGroups), (x) => {
      return { id: x.id, nActs: x.numOfActs };
    });
    setFilterOptions(filterOptions);
    setFilterBy("All");
  }, [colorBy]);

  useEffect(() => {
    // console.log(`[view-panel] selection change ${selectionData.length}`)
    let x = _.keys(selectionData);
    setSelectionHandler(x, "view-panel", "reset");
    // console.log('ids', x)
  }, [selectionData]);

  const split = (text) => {
    return (
      text
        // insert a space before all caps, &, |, /
        .replace(/([A-Z&|/])/g, " $1")
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
        // remove leading, trailing spaces
        .trim()
    );
  };

  const getViewContent = (viewType) => {
    switch (viewType) {
      case "geo-view":
        return (
          <MapView
            theme={theme}
            inFocus={inFocus}
            inSelect={inSelect}
            geoResolution={geoResolution}
            stateLayer={stateLayer}
            districtLayer={districtLayer}
            selectionData={selectionData}
            setFocusHandler={setFocusHandler}
            setSelectionHandler={setSelectionHandler}
          />
        );
      case "histo-view":
        return (
          <HistoView
            selectionData={selectionData}
            partitionBy={attrs.YearOfEnactment}
            colorBy={colorBy}
            filterBy={filterBy}
            setFilterBy={setFilterBy}
            setHistoData={setHistoData}
            inFocus={inFocus}
            inSelect={inSelect}
            setFocusHandler={setFocusHandler}
            setSelectionHandler={setSelectionHandler}
          />
        );

      // return <CanvasWindow
      //     partitionBy={attrs.YearOfEnactment}
      //     colorBy={colorBy}
      //     inSelect={inSelect}
      //     setFocusHandler={setFocusHandler}
      //     setSelectionHandler={setSelectionHandler}
      // />
      case "tag-view":
        return (
          <TagView
            partitionBy={partitionBy}
            colorBy={colorBy}
            inFocus={inFocus}
            inSelect={inSelect}
            selectionData={selectionData}
            setFocusHandler={setFocusHandler}
            setSelectionHandler={setSelectionHandler}
          />
        );
      // case 'net-view':
      //     return <div> Network View </div>
      default:
        break;
    }
  };

  const getViewParams = (viewType) => {
    switch (viewType) {
      case "geo-view":
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            height="100%"
          >
            <ToggleButtonGroup
              // classes={{ root: classes.toggleBtnRoot }}
              value={geoResolution}
              exclusive
              onChange={handleGeoResolutionChange}
              size="small"
              aria-label="layer visibility"
            >
              {showMapStateLayers.length > 0 ? (
                <ToggleButton
                  color="primary"
                  // classes={{ root: classes.toggleBtnRoot, selected: classes.toggleBtnSelected }}
                  value="state"
                  aria-label="show state"
                >
                  State
                  {geoResolution === "state" ? (
                    <FormControl
                      size="small"
                      className={classes.formControlRoot}
                      id="geo-params-form"
                    >
                      <Select
                        classes={{ root: classes.selectSmallRoot }}
                        labelId="geo-state-filterby-label"
                        id="geo-state-filterby-select"
                        value={stateLayer}
                        onChange={(event) =>
                          handleStateLayerChange(event.target.value)
                        }
                        label="geo-state-colorby"
                        disableUnderline={true}
                      >
                        {showMapStateLayers.map((opt_key) => {
                          const key = `geo-state-filterby-${opt_key}`;
                          return (
                            <MenuItem key={key} value={opt_key}>
                              {opt_key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : null}
                </ToggleButton>
              ) : null}
              {showMapDistrictLayers.length > 0 ? (
                <ToggleButton
                  color="primary"
                  // classes={{ root: classes.toggleBtnRoot, selected: classes.toggleBtnSelected }}
                  value="district"
                  aria-label="show district"
                >
                  District
                  {geoResolution === "district" ? (
                    <FormControl
                      size="small"
                      className={classes.formControlRoot}
                      id="geo-params-form"
                    >
                      <Select
                        classes={{ root: classes.selectSmallRoot }}
                        labelId="geo-filterby-label"
                        id="geo-filterby-select"
                        value={districtLayer}
                        onChange={(event) =>
                          handleDistrictLayerChange(event.target.value)
                        }
                        label="geo-colorby"
                        disableUnderline={true}
                      >
                        {showMapDistrictLayers.map((opt_key) => {
                          const key = `geo-filterby-${opt_key}`;
                          return (
                            <MenuItem key={key} value={opt_key}>
                              {opt_key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : null}
                </ToggleButton>
              ) : null}
            </ToggleButtonGroup>
          </Box>
        );
      // return null;
      case "histo-view":
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            height="100%"
          >
            <Typography variant="caption">Variable: </Typography>
            <FormControl
              className={classes.formControlRoot}
              id="histo-params-form"
            >
              <Select
                classes={{ root: classes.selectRoot }}
                labelId="histo-colorby-label"
                id="histo-colorby-select"
                value={colorBy}
                onChange={(event) => setColorBy(event.target.value)}
                label="histo-colorby"
                disableUnderline={true}
              >
                {_.keys(attrs_categorical).map((attr_key) => {
                  const key = `histo-colorby-${attr_key}`;
                  return (
                    <MenuItem key={key} value={attr_key}>
                      {split(attr_key)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {filterOptions.length > 2 ? (
              <>
                <Typography variant="caption">Filter by: </Typography>
                <FormControl
                  className={classes.formControlRoot}
                  id="histo-params-form"
                >
                  <Select
                    classes={{ root: classes.selectRoot }}
                    labelId="histo-filterby-label"
                    id="histo-filterby-select"
                    value={filterBy}
                    onChange={(event) => setFilterBy(event.target.value)}
                    label="histo-colorby"
                    disableUnderline={true}
                  >
                    {filterOptions.map((opt_key) => {
                      const key = `histo-filterby-${opt_key.id}`;
                      return (
                        <MenuItem key={key} value={opt_key.id}>
                          {split(opt_key.id)}{" "}
                          {opt_key.id === "None" ? null : `(${opt_key.nActs})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            ) : null}
          </Box>
        );
      case "tag-view":
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            height="100%"
          >
            {/* <Typography>Partition by: </Typography>
                        <FormControl className={classes.formControlRoot}>
                            <Select
                                classes={{ root: classes.selectRoot }}
                                labelId="tag-partition-label"
                                id="tag-partition-select"
                                value={partitionBy}
                                onChange={event => setPartitionBy(event.target.value)}
                                label="tag-partby"
                                disableUnderline={true}
                            >
                                {
                                    _.keys(attrs_categorical).map((attr_key) => {
                                        const key = `tag-partition-${attr_key}`
                                        return (
                                            <MenuItem key={key} value={attr_key}>{attr_key}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl> */}
          </Box>
        );
      case "net-view":
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
          ></Box>
        );
      default:
        break;
    }
  };

  async function handleLogout(e) {
    e.preventDefault();
    // console.log({
    //     email: currentUser.email
    // })

    try {
      // setError('')
      setLoading(true);
      await logout().then(() => history.push("/"));
    } catch {
      // setError('Failed to logout')
    }
    setLoading(false);
    setOpenLogoutDialog(false);
  }

  function handleLogin(e) {
    e.preventDefault();
    history.push("/signin");
    setLoading(false);
  }

  const getDisplayName = () => {
    if (currentUserProfile) {
      return currentUserProfile.firstname;
    } else {
      return currentUser.email;
    }
  };

  return (
    <div
      className={classes.viewContainer}
      id="view-container"
      name="view-container"
    >
      <div className={classes.viewHeader} name="view-header">
        <Tooltip
          title="change mapping"
          TransitionComponent={Zoom}
          placement="right"
          aria-label="Change Mapping Type"
        >
          <div name="view-toggle" className={classes.viewToggle + " noselect"}>
            <FormControl
              classes={{ root: classes.formControlRoot }}
              variant="outlined"
              size="small"
            >
              <InputLabel
                classes={{ root: classes.inputLabelRoot }}
                id="demo-simple-select-outlined-label"
              >
                Mapping
              </InputLabel>
              <Select
                classes={{ root: classes.selectRoot }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={viewType}
                onChange={handleChange}
                label="Map Type"
              >
                <MenuItem value="geo-view">Geographic</MenuItem>
                <MenuItem value="tag-view">Category</MenuItem>
                <MenuItem value="histo-view">Timeperiod</MenuItem>
                {/* <MenuItem value='net-view'>Network</MenuItem> */}
              </Select>
            </FormControl>
          </div>
        </Tooltip>
        <div className={classes.viewOptions} name="view-options">
          {getViewParams(viewType)}
        </div>
        {false && viewType !== "geo-view" ? (
          <Tooltip
            title="take screenshot"
            TransitionComponent={Zoom}
            placement="left"
            aria-label="take screenshot"
          >
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                var copyright =
                  '<div id="copyright" style="position: absolute;margin-left: 20px;margin-top: 30px;text-align: left;"><span style="font-size:10px">Citation Reference:</span><br>Namita Wahi (2020). Mapping Land Laws. Retrieved from http://landlawsofindia.com</div>';
                var div = document.getElementById("view-content");
                div.insertAdjacentHTML("afterBegin", copyright);
                return domtoimage
                  .toSvg(div, {
                    bgcolor: theme === "dark" ? "#0C3754" : "#ffffff",
                  })
                  .then(function (dataUrl) {
                    let copyrightEle = document.getElementById("copyright");
                    copyrightEle.remove();
                    var link = document.createElement("a");
                    link.download = "MILL-capture.svg";
                    link.href = dataUrl;
                    link.click();
                  });
              }}
              aria-label="capture"
            >
              <PhotoCameraIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        {currentUser ? (
          <Tooltip
            // title={"signed in as " + currentUser.email}
            title={"signed in as " + getDisplayName()}
            TransitionComponent={Zoom}
            placement="left"
            aria-label="current user"
          >
            <IconButton
              name="current user"
              className={classes.iconButton}
              aria-label="current user"
              // onClick={() => setOpenLogoutDialog(true)}
              onClick={() => history.push("/profile")}
            >
              <FaceIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            title="sign in"
            TransitionComponent={Zoom}
            placement="left"
            aria-label="current user"
          >
            <IconButton
              name="current user"
              className={classes.iconButton}
              aria-label="current user"
              onClick={handleLogin}
            >
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title="interface tour"
          TransitionComponent={Zoom}
          placement="left"
          aria-label="Interface Tour"
        >
          <IconButton
            name="guided-tour"
            className={classes.iconButton}
            onClick={() => toggleTourStateHandler()}
            aria-label="guided tour"
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={theme === "light" ? "dark theme" : "light theme"}
          TransitionComponent={Zoom}
          placement="left"
          aria-label="Change Theme"
        >
          <IconButton
            name="change-theme"
            className={classes.iconButton}
            onClick={setThemeHandler}
            aria-label="theme"
          >
            {theme === "light" ? <Brightness3Icon /> : <WbSunnyIcon />}
          </IconButton>
        </Tooltip>
        {isBrowser ? (
          <AboutDialog
            showIntroAtStart={showIntroAtStart}
            showTourAfterIntro={showTourAfterIntro}
            toggleTourStateHandler={toggleTourStateHandler}
            isLoggedIn={currentUser ? true : false}
          />
        ) : null}
      </div>
      {currentUser ? (
        <Dialog
          open={openLogoutDialog}
          maxWidth="xs"
          onClose={() => setOpenLogoutDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Hi " + getDisplayName() + ", do you really want to sign out?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You can continue to access documents (pdfs and links) for the acts
              only if you are signed in.
            </DialogContentText>
          </DialogContent>
          <DialogActions align="end">
            <div style={{ flex: "1 0 0" }} />
            <Button
              onClick={handleLogout}
              variant="contained"
              color="secondary"
            >
              Sign out
            </Button>
            <Button
              onClick={() => setOpenLogoutDialog(false)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <div
        name="view-content"
        id={"view-content"}
        className={classes.viewContent + " view-content"}
      >
        {UIOptions.showViews && getViewContent(viewType)}
      </div>
    </div>
  );
}
