import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import * as _ from "lodash";
import * as d3 from "d3";
import { stateLatLngData } from "../../states_new.js";
import {
  getStateObj,
  getStateId,
  getStateFromDistrict,
  stateData,
  districtData,
  getDistrictIdArrFromStateId,
  getDistrictIdArrFromState,
  getDistrictObjArrFromState,
  getStateAttrFromLayer,
  getDistrictAttrFromLayer,
} from "../../state_district_map.js";
import MapGL, { Source, Layer } from "react-map-gl";
import MapMarker from "./MapMarker";
import MapLegend from "./MapLegend";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { states_applicable_values } from "../../datautils";
import { elemsData } from "../../lawsdict";
// import { stateId } from '../../states_id'

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoia2F1c3R1dmRlYmlzd2FzIiwiYSI6ImNrZTFmaHFzMzAxd2YyeG81a2lieWM1ZnUifQ.tB8Vs-Ij8OAVFMXIkJTs-A";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,

    "& .mapboxgl-control-container": {
      display: "none",
    },
  },
  actsList: {
    width: "340px",
    height: "90px",
    backgroundColor: "red",
    position: "fixed",
    bottom: 96,
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    marginLeft: "20px",
    padding: "10px 20px 10px 20px",
    transition: "0.2s",
  },
  tooltipRoot: {
    color: theme.palette.primary.contrastTextLight,
    position: "absolute",
    textAlign: "left",
    width: "auto",
    height: "auto",
    padding: "5px",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "0.875rem",
    background: theme.palette.primary.main,
    border: "0px",
    borderRadius: "4px",
    pointerEvents: "none",
    display: "flex",
    flexDirection: "column",
    lineHeight: "0.825rem",
    opacity: 0.8,
  },

  tooltipSub: {
    fontSize: "0.625rem",
  },
  tooltipSubLight: {
    fontSize: "0.625rem",
    fontWeight: 400,
  },
}));

// const lightStyle = 'mapbox://styles/kaustuvdebiswas/ckfqgs3mq00p919ndzuoyh36c'
const lightStyle = "mapbox://styles/kaustuvdebiswas/cl8yotkaz004515prlc0wx3p2"; //"mapbox://styles/kaustuvdebiswas/ckgiwv1x36vl819qk0p0zu7ms"; //lightmode-fill
const darkStyle = "mapbox://styles/kaustuvdebiswas/ckf8msgga53ae19qo1zp1zgzk"; //darkmode mapbox://styles/kaustuvdebiswas/ckf8msgga53ae19qo1zp1zgzk
const vectorTileSet = "mapbox://kaustuvdebiswas.chrhz11w"; //"mapbox://kaustuvdebiswas.cind37sq";
const vectorTileSetDistricts = "mapbox://kaustuvdebiswas.7dx00gzt";

// Reference: https://source.opennews.org/articles/how-we-made-our-broadband-map-using-mapbox/
// https://docs.mapbox.com/help/troubleshooting/large-data-tippecanoe/
// brew install tippecanoe
// tippecanoe \
//  -o india.mbtiles \
//  -Z3 \
//  -z6 \
//  -P \
//  --drop-smallest-as-needed \
//  india_telengana.geojson
//

// district cood bounds 68.19,6.76,97.42,37.08
// state cood bounds 68.19,6.75,97.42,35.5

export default function MapView({
  theme,
  inSelect,
  inFocus,
  geoResolution,
  stateLayer,
  districtLayer,
  setFocusHandler,
  setSelectionHandler,
}) {
  const classes = useStyles();
  const themeCols = useTheme();
  const colorFade = 0.9;
  const _map = useRef();

  //selection
  const [stateActArray, setStateActArray] = useState(null);
  const [stateValues, setStateValues] = useState(null);
  const [districtValues, setDistrictValues] = useState(null);
  const [attrValues, setAttrValues] = useState(null);

  const [key, setkey] = useState(0);

  const [UIObj, setUIObj] = useState({
    x: null,
    y: null,
    hoverInfo: null,
    viewport: {
      latitude: 22.5,
      longitude: 81,
      zoom: 4,
      maxZoom: 5,
      minZoom: 4,
      bearing: 0,
      pitch: 0,
    },
  });

  const [stateColorBy, setStateColorBy] = useState({
    id: "states",
    type: "fill",
    source: "india-states",
    "source-layer": "india_telengana",
    paint: {
      "fill-antialias": true,
      // 'fill-outline-color': themeCols.palette.background.paperDark,
      // 'fill-color': themeCols.palette.primary.main,
      "fill-color": fade(themeCols.palette.primary.main, colorFade),
      "fill-opacity": 1,
    },
  });
  const [districtColorBy, setDistrictColorBy] = useState({
    id: "districts",
    type: "fill",
    source: "india-districts",
    "source-layer": "India_2011",
    paint: {
      "fill-antialias": true,
      // 'fill-outline-color': themeCols.palette.background.paperDark, //themeCols.palette.secondary.main
      // 'fill-color': themeCols.palette.primary.main,
      "fill-color": fade(themeCols.palette.primary.main, colorFade),
      "fill-opacity": 1,
    },
  });
  const [stateFilter, setStateFilter] = useState(["in", "ID_1"]);
  const [districtFilter, setDistrictFilter] = useState(["in", "censuscode"]);

  const [stateHighlightFilter, setStateHighlightFilter] = useState([
    "in",
    "ID_1",
  ]);
  const [districtHighlightFilter, setDistrictHighlightFilter] = useState([
    "in",
    "censuscode",
  ]);

  const [stateMarkerFilter, setStateMarkerFilter] = useState(["in", "Name"]);

  const _setStateAndDistrictFilters = () => {
    // console.info('[map] _setStateAndDistrictFilters')

    //set filters
    let state_filter_arr = ["in", "ID_1"];
    let district_filter_arr = ["in", "censuscode"];
    _.forEach(_.keys(stateActArray), function (st_name) {
      let { error: state_err, result: stateId } = getStateId(st_name);
      if (stateId) state_filter_arr = state_filter_arr.concat(stateId);
      let { error: district_err, result: district_ids } =
        getDistrictIdArrFromStateId(stateId);
      if (district_ids)
        district_filter_arr = district_filter_arr.concat(district_ids);
    });
    setStateFilter(state_filter_arr);
    setDistrictFilter(district_filter_arr);
    // console.log('state_filter_arr', state_filter_arr)
    // console.log('district_filter_arr', district_filter_arr)
  };

  const _setDistrictValues = () => {
    // console.log('_setDistrictValues')
    let dattr = getDistrictAttrFromLayer(districtLayer);

    // normalize vals
    let max_val = -1;
    let min_val = 9999999999999999;
    let val = 0;
    let districtValues = {};

    // let { error: district_err, result: districtObjs } = getDistrictObjArrFromState(stateName)
    _.forEach(districtData, (dobj) => {
      let stateActs = stateActArray && stateActArray[dobj["State"]]; // ORISSA ODISHA ERROR
      let actCount = stateActs ? stateActs.length : 0;
      if (dattr === "acts") {
        val = actCount;
      } else {
        val = dobj[dattr];
      }
      districtValues[dobj["District Code"]] = {
        districtName: dobj["District"],
        stateName: dobj["State"],
        val: val,
        actCount: actCount,
      };
      if (val && val > max_val) max_val = val;
      if (val && val < min_val) min_val = val;
    });

    let districtAttrValues = {
      resolution: "district",
      layer: districtLayer,
      attrName: dattr,
      max: max_val,
      min: min_val,
    };
    setDistrictValues(districtValues);
    setAttrValues(districtAttrValues);

    // console.log("districtValues", districtValues);
    // console.log("districtAttrValues", districtAttrValues);
  };

  const _setStateValues = () => {
    // console.log('_setStateValues')

    let attr = getStateAttrFromLayer(stateLayer);

    // normalize vals
    let max_val = -1;
    let min_val = 9999999999999999;
    let val = 0;
    let stateValues = {};

    _.forEach(stateActArray, function (act_id_arr, stateName) {
      let { error: state_err, result: stateObj } = getStateObj(stateName);
      if (!state_err) {
        let actCount = act_id_arr.length;
        if (attr === "acts") {
          val = actCount;
        } else {
          val = stateObj[attr];
        }
        stateValues[stateObj["state_ut_index"]] = {
          stateName: stateName,
          val: val,
          actCount: actCount,
        };
        if (val && val > max_val) max_val = val;
        if (val && val < min_val) min_val = val;
      } else {
        // console.warn(state_err)
      }
    });
    let attrValues = {
      resolution: "state",
      layer: stateLayer,
      attrName: attr,
      max: max_val,
      min: min_val,
    };

    setStateValues(stateValues);
    setAttrValues(attrValues);

    // console.log('stateValues', stateValues)
    // console.log('attrValues', attrValues)
  };

  useEffect(() => {
    _setStateAndDistrictFilters();
    if (geoResolution === "state") {
      console.log("mapview load: state");
      _setStateValues();
    } else {
      console.log("mapview load: district");
      _setDistrictValues();
    }
  }, [geoResolution]);

  useEffect(() => {
    // console.info('focus change', inFocus.ids)
    let filter_arr = ["in", "ID_1"];
    let district_filter_arr = ["in", "censuscode"];
    let stateMarker_filter_arr = ["in", "NAME"];
    _.forEach(inFocus.ids, function (val) {
      const st = elemsData[val].StatesWhereApplicable;
      // console.log(st)
      _.forEach(st, function (st_name) {
        stateMarker_filter_arr = stateMarker_filter_arr.concat(st_name);
        let { error: state_err, result: stateId } = getStateId(st_name);
        if (stateId) filter_arr = filter_arr.concat(stateId);
        let { error: district_err, result: district_ids } =
          getDistrictIdArrFromState(st_name);
        if (district_ids)
          district_filter_arr = district_filter_arr.concat(district_ids);
      });
    });
    // console.log("[infocus] stateHighlightFilter ", filter_arr)
    // console.log("distric filter_arr", district_filter_arr)
    // console.log("stateMarker_filter_arr", stateMarker_filter_arr)

    setStateHighlightFilter(filter_arr);
    setStateMarkerFilter(stateMarker_filter_arr);
    // setDistrictHighlightFilter(district_filter_arr)
  }, [inFocus]);

  useEffect(() => {
    // console.info('[map] inSelect change')
    // key for map view. has to be unique per selection
    if (inSelect.ids.length > 0) {
      setkey(inSelect.ids.join());
    } else {
      setkey("all");
    }

    let statesWithActs = null;
    if (inSelect.ids.length > 0) {
      statesWithActs = states_applicable_values(
        _.pick(elemsData, inSelect.ids)
      );
    } else {
      // no selection
      statesWithActs = states_applicable_values(elemsData);
      //inject state names missing from the dataset
      _.forEach(stateData, (sobj) => {
        if (!_.keys(statesWithActs).includes(sobj["state_ut_name"])) {
          statesWithActs[sobj["state_ut_name"]] = [];
          // console.log('add missing state->', sobj['state_ut_name'])
        }
      });
    }
    // console.log('States with selected acts:', statesWithActs)
    setStateActArray(statesWithActs);
  }, [inSelect]);

  useEffect(() => {
    // console.log('[map] stateActArray update')
    _setStateAndDistrictFilters();
    if (geoResolution === "state") {
      // console.log('state act change: state')
      _setStateValues();
    } else {
      // console.log('state act change: district')
      _setDistrictValues();
    }
  }, [stateActArray]);

  useEffect(() => {
    // console.log('theme change', theme)
    setStateColorBy((prev) => ({
      id: "states",
      type: "fill",
      source: "india-states",
      "source-layer": "india_telengana",
      paint: {
        "fill-antialias": true,
        // 'fill-outline-color': themeCols.palette.background.paperDark,
        // 'fill-color': themeCols.palette.primary.main,
        "fill-color": fade(themeCols.palette.primary.main, colorFade),
        "fill-opacity": prev["paint"]["fill-opacity"],
      },
    }));
    setDistrictColorBy((prev) => ({
      id: "districts",
      type: "fill",
      source: "india-districts",
      "source-layer": "India_2011",
      paint: {
        "fill-antialias": true,
        // 'fill-outline-color': themeCols.palette.background.paperDark,
        // 'fill-color': themeCols.palette.primary.main,
        "fill-color": fade(themeCols.palette.primary.main, colorFade),
        "fill-opacity": prev["paint"]["fill-opacity"],
      },
    }));
  }, [theme]);

  const _updateColors = () => {
    // console.info('update colors:', attrValues)
    if (attrValues) {
      const opacityScale = d3
        .scaleSqrt()
        .domain([0, attrValues["max"]])
        .range([0, 0.9]);

      if (geoResolution === "state") {
        let stateOpacityExpr = ["match", ["get", "ID_1"]];
        let stateOpacityVal = 0;

        _.forEach(stateValues, function (sobj, state_ut_index) {
          stateOpacityVal = opacityScale(sobj["val"]);
          stateOpacityExpr.push(parseInt(state_ut_index), stateOpacityVal);
        });
        stateOpacityExpr.push(0);
        // console.log('stateOpacityExpr', stateOpacityExpr.length)
        setStateColorBy({
          id: "states",
          type: "fill",
          source: "india-states",
          "source-layer": "india_telengana",
          paint: {
            "fill-antialias": true,
            // 'fill-outline-color': themeCols.palette.background.paperDark,
            // 'fill-color': themeCols.palette.primary.main,
            "fill-color": fade(themeCols.palette.primary.main, colorFade),
            "fill-opacity": stateOpacityExpr.length < 4 ? 1 : stateOpacityExpr,
          },
        });
      } else {
        let districtOpacityExpr = ["match", ["get", "censuscode"]];
        let districtOpacityVal = 0;
        _.forEach(districtValues, function (dobj, district_code) {
          districtOpacityVal = opacityScale(dobj["val"]);
          districtOpacityExpr.push(parseInt(district_code), districtOpacityVal);
        });
        districtOpacityExpr.push(0);
        // console.log(districtOpacityExpr)
        setDistrictColorBy({
          id: "districts",
          type: "fill",
          source: "india-districts",
          "source-layer": "India_2011",
          paint: {
            "fill-antialias": true,
            // 'fill-outline-color': themeCols.palette.background.paperDark,
            // 'fill-color': themeCols.palette.primary.main,
            "fill-color": fade(themeCols.palette.primary.main, colorFade),
            "fill-opacity":
              districtOpacityExpr.length < 4 ? 1 : districtOpacityExpr,
          },
        });
      }
    } else {
      // console.warn('no attr')
    }
  };

  useEffect(() => {
    if (geoResolution === "state") {
      // console.info('[map] layer change: state')
      _setStateValues();
    } else {
      // console.info('[map] layer change: district')
      _setDistrictValues();
    }
  }, [stateLayer, districtLayer]);

  useEffect(() => {
    // console.info('[map] attr change')
    _updateColors();
  }, [attrValues]);

  useEffect(() => {
    return () => {
      console.log("[map-view] unmount");
      _map.current = null;
    };
  }, []);

  // const { viewport } = UIObj;

  const _onViewportChange = (viewport) =>
    setUIObj({ ...UIObj, viewport: viewport });

  const _onHover = (event) => {
    // console.log(event)
    let stateName = "";
    let districtName = null;
    let hoverInfo = null;
    const { x, y } = event.offsetCenter;

    const objectHover = event.features && event.features[0];
    // console.log(objectHover)
    if (objectHover && geoResolution === "state") {
      // const stateHover = event.features && event.features[0]
      let stateId = objectHover.properties.ID_1;
      // console.log(stateId, stateValues[stateId])
      districtName = null;
      hoverInfo =
        stateId && stateValues[stateId]
          ? {
              lngLat: event.lngLat,
              state: stateValues[stateId].stateName,
              stateId: stateId,
              val: stateValues[stateId].val,
              actCount: stateValues[stateId].actCount,
              district: null,
              districtCensusCode: null,
              error: null,
            }
          : console.warn(`no hoverinfo set for state ${stateId}`);
      console.assert(stateId !== null, objectHover.properties);
      setStateHighlightFilter(["in", "ID_1", stateId]);
      setStateMarkerFilter(["in", "Name", stateName]);
    } else if (objectHover && geoResolution === "district") {
      // const districtHover = event.features && event.features[0]
      districtName = objectHover.properties.DISTRICT;
      let districtCensusCode = objectHover.properties.censuscode;
      let { error, stateName, stateId } = getStateFromDistrict(districtName);
      if (stateName && stateId) {
        // console.assert(stateName !== districtStateName, 'state names dont match', stateName, districtStateName, error);
        hoverInfo =
          districtName && districtValues[districtCensusCode]
            ? {
                lngLat: event.lngLat,
                state: stateName,
                stateId: stateId,
                val: districtValues[districtCensusCode].val,
                actCount: districtValues[districtCensusCode].actCount,
                district: districtName,
                districtCensusCode: districtCensusCode,
                error: error,
              }
            : console.warn(
                `no hoverinfo found for districtname: ${districtName} (${districtCensusCode})`
              );

        console.assert(stateId !== null, error);
        setStateHighlightFilter(["in", "ID_1", stateId]);
        setStateMarkerFilter(["in", "Name", stateName]);
        setDistrictHighlightFilter(["in", "censuscode", districtCensusCode]);
        // console.log(districtCensusCode)
      }
    } else {
      setStateHighlightFilter(["in", "ID_1"]);
      setDistrictHighlightFilter(["in", "censuscode"]);
      setStateMarkerFilter(["in", "Name"]);
    }
    // console.log('hoverInfo', hoverInfo)
    // const stateHover = event.features && event.features[1]

    setUIObj({
      ...UIObj,
      x: x,
      y: y,
      hoverInfo: hoverInfo,
    });
  };

  const _onClick = (event) => {
    if (geoResolution === "state") {
      //state
      const stateHover = event.features && event.features[0];
      if (stateHover !== undefined) {
        // selectionFilter: ['in', 'NAME_1', stateName]
        const ids = stateActArray[stateHover.properties.NAME_1] || [];
        setSelectionHandler(ids, "geo-view", "reset");
      } else {
        setSelectionHandler([], "geo-view", "reset");
      }
    } else {
      //district
      const districtHover = event.features && event.features[0];
      if (districtHover) {
        //get container state
        let districtName = districtHover && districtHover.properties.DISTRICT;
        let result = districtHover && getStateFromDistrict(districtName);
        const ids = result ? stateActArray[result["stateName"]] || [] : [];
        setSelectionHandler(ids, "geo-view", "reset");
      } else {
        setSelectionHandler([], "geo-view", "reset");
      }
    }
  };

  const getTooltipText = (val) => {
    const colorAttr = geoResolution === "state" ? stateLayer : districtLayer;
    let txt = "";
    // console.log(colorAttr)
    switch (colorAttr) {
      case "Forest Cover":
        txt =
          Math.round((val * 100 + Number.EPSILON) * 100) / 100 +
          "% of " +
          geoResolution +
          " area";
        break;
      case "Dams":
        txt = val + " dams (2019)";
        break;
      case "Mines":
        txt = "Rs." + val + " Cr of mineral production (2016)";
        break;
      case "Scheduled Areas":
        txt = (val === 1 ? "" : "Not a ") + "Scheduled Area";
        break;
      default:
        txt = val + " Act" + (val > 1 ? "s" : "") + " applicable";
        break;
    }
    return txt;
  };

  const _renderTooltip = () => {
    const { hoverInfo, x, y } = UIObj;
    // console.log(hoverInfo, x, y)
    let val = 0;
    let actCount = 0;
    let stateName = null;
    let districtName = null;

    if (hoverInfo) {
      // console.log(hoverInfo)
      if (geoResolution === "district") {
        districtName = hoverInfo.district;
        // const st = getStateFromDistrict(hoverInfo.district)
        // // console.log(districtValues)
        // stateName = st['stateName']
        // const dsObj = _.find(districtValues, function (dobj) { return dobj['districtName'] === districtName })
        // val = dsObj ? dsObj['val'] : 0
        // actCount = dsObj ? dsObj['actCount'] : 0
        val = hoverInfo.val;
        actCount = hoverInfo.actCount;
        stateName = hoverInfo.state;
        // console.log(dsObj, hoverInfo)
      } else {
        //state
        val = hoverInfo.val;
        actCount = hoverInfo.actCount;
        stateName = hoverInfo.state;
      }
    }

    return hoverInfo ? (
      <div
        className={classes.tooltipRoot}
        style={{ left: x + 10, top: y + 10 }}
      >
        <div className={classes.tooltipSub}>
          {stateName && districtName ? districtName + " in " : null}
          {stateName}
        </div>
        {val !== null && getTooltipText(val)}
        <div className={classes.tooltipSubLight}>
          {actCount
            ? inSelect.ids.length > 0
              ? actCount < inSelect.ids.length
                ? `subselect ${actCount} of ${inSelect.ids.length} acts`
                : `${actCount} Act${actCount > 1 ? "s" : ""} in selection`
              : `click to select ${actCount} acts`
            : inSelect.ids.length > 0
            ? `click to deselect`
            : `no applicable Acts`}
        </div>
      </div>
    ) : inSelect.ids.length > 0 ? (
      <div
        className={classes.tooltipRoot}
        style={{ left: x + 10, top: y + 10 }}
      >
        <div className={classes.tooltipSubLight}>click to deselect</div>
      </div>
    ) : null;
  };

  const stateHighlightByLayer = {
    id: "states-highlighted",
    type: "line",
    source: "states",
    "source-layer": "india_telengana",
    paint: {
      "line-color": themeCols.palette.secondary.main,
      "line-opacity": 1,
      "line-width": 1.5,
      "line-offset": 0,
    },
  };

  const districtOutlineByLayer = {
    id: "districts-highlighted",
    type: "line",
    source: "india-districts",
    "source-layer": "India_2011",
    paint: {
      "line-color": themeCols.palette.background.paperDarkest,
      "line-opacity": 0.3,
      "line-width": 0.5,
      "line-offset": 0,
    },
  };

  const districtHighlightByLayer = {
    id: "districts-highlighted2",
    type: "line",
    source: "india-districts",
    "source-layer": "India_2011",
    paint: {
      "line-color": themeCols.palette.secondary.main,
      "line-opacity": 1,
      "line-width": 1,
      "line-offset": 0,
    },
  };

  console.assert(
    stateHighlightFilter.length <= 2 ||
      (stateHighlightFilter.length > 2 &&
        stateHighlightFilter[2] !== undefined),
    stateHighlightFilter
  );
  // console.log('stateColorBy', stateColorBy)
  // console.log('stateHighlightFilter', stateHighlightFilter)
  // console.log('districtColorBy', districtColorBy)
  // console.log('districtHighlightByLayer', districtHighlightByLayer)
  // console.log('districtHighlightFilter', districtHighlightFilter)
  return (
    <div className={classes.mapContainer}>
      <MapGL
        ref={_map}
        {...UIObj["viewport"]}
        width="100%"
        height="100%"
        mapStyle={theme === "dark" ? darkStyle : lightStyle}
        onViewportChange={_onViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onHover={_onHover}
        onClick={_onClick}
        interactiveLayerIds={
          geoResolution === "district" ? ["districts"] : ["states"]
        }
      >
        <MapMarker
          key="map_markers"
          data={stateLatLngData}
          selData={stateActArray}
          inFocusArr={stateMarkerFilter}
          setSelectionHandler={setSelectionHandler}
        />

        {geoResolution === "district" ? (
          <>
            <Source
              key={key + "-districts"}
              id={key + "india-districts"}
              type="vector"
              url={vectorTileSetDistricts}
            >
              <Layer
                key={key + "district-base"}
                {...districtColorBy}
                filter={districtFilter}
              />
              <Layer
                key={key + "district-outline"}
                {...districtOutlineByLayer}
              />
              <Layer
                key={key + "district-highlights"}
                {...districtHighlightByLayer}
                filter={districtHighlightFilter}
              />
            </Source>
            <Source
              key={key}
              id={key + "-district-highlight"}
              type="vector"
              url={vectorTileSet}
            >
              <Layer
                key={key + "highlight-district"}
                {...stateHighlightByLayer}
                filter={stateHighlightFilter}
              />
            </Source>
          </>
        ) : (
          <Source
            key={key}
            id={key + "-india-states"}
            type="vector"
            url={vectorTileSet}
          >
            <Layer key={key + "base"} {...stateColorBy} filter={stateFilter} />
            <Layer
              key={key + "highlight-state"}
              {...stateHighlightByLayer}
              filter={stateHighlightFilter}
            />
          </Source>
        )}

        {UIObj["x"] > 50 && UIObj["y"] > 70 ? _renderTooltip() : null}
      </MapGL>
      {/* <div className={classes.actsList}>This is a placeholder for an Act Name</div>  */}
      <MapLegend
        theme={theme}
        colorFade={colorFade}
        geoResolution={geoResolution}
        stateLayer={stateLayer}
        districtLayer={districtLayer}
        attrValues={attrValues}
      />
    </div>
  );
}
