import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from './Copyright';
import Paper from '@material-ui/core/Paper';
import * as EmailValidator from 'email-validator';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import GoogleIcon from '@mui/icons-material/Google';

const useStyles = makeStyles((theme) => ({
    container: {
        // padding: theme.spacing(4),
        // backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(4),
        // marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        margin: theme.spacing(1),
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.paperSecondary,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    smalltext: {
        fontSize: '12px',
        color: theme.palette.primary.main
    },
    copytext: {
        // padding: theme.spacing(4),
        color: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    googleButton: {
        margin: "20px 0px 24px 0px"
    }
}));

export default function Signin() {
    const classes = useStyles();
    const { currentUser, signinLink, signUpWithGoogle } = useAuth()
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState()
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    useEffect(() => {
        if (currentUser && currentUser.email) {
            // setMessage('Signed in as ' + currentUser.email)
            setMessage(null)
        }
    }, [currentUser])

    async function handleSubmit(e) {
        e.preventDefault();

        if (currentUser) {
            history.push('/?intro=false')
        } else {
            console.log({
                email: emailRef.current.value
            })

            // var errText = ''
            if (emailRef.current.value === '') {
                setError("Email is missing.")
                return false
            }

            if (!EmailValidator.validate(emailRef.current.value)) {
                setError("Email is not valid.")
                return false
            }

            try {
                setLoading(true)
                await signinLink(emailRef.current.value).then(() => {
                    setMessage('Please check your email for the sign in link. May take upto a minute..')
                    window.localStorage.setItem('emailForSignIn', emailRef.current.value);
                })
            } catch {
                setError('Failed to login')
            }

            setLoading(false)
        }
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={6} style={isBrowser ? { maxWidth: '50%' } : { maxWidth: '95%' }}>
                <CssBaseline />
                <Box mb={3} display="flex" justifyContent="center" alignItems="center" onClick={() => { history.push('/?intro=false') }} >
                    <KeyboardBackspaceIcon className={classes.copytext} fontSize="small" />
                    <Typography className={classes.copytext} variant="caption" display="block">
                        Back to {isBrowser ? 'map' : 'archive'}
                    </Typography>
                </Box>
                <Paper elevation={3} className={classes.paper}>
                    {error && <Alert severity="error">{error}</Alert>}
                    {message && <Alert severity="success">{message}</Alert>}
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {currentUser ? 'Signed in as' : 'Sign In to View Laws'}
                    </Typography>
                    {
                        currentUser ?
                            null
                            : <>
                                <Button
                                    className={classes.googleButton}
                                    size="large"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => signUpWithGoogle()}
                                    startIcon={<GoogleIcon />}>
                                    Signin with google
                                </Button>
                                <Typography variant="overline">
                                    or
                                </Typography>
                            </>
                    }
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required={!currentUser}
                            fullWidth
                            id="email"
                            label={currentUser ? currentUser.email : "Email Address"}
                            InputLabelProps={{ shrink: !currentUser }}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            disabled={!!currentUser}
                            inputRef={emailRef}
                        />
                        {/* <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            inputRef={passwordRef}
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={handleSubmit}
                        >
                            {currentUser ? 'Go to map' : 'Sign In'}
                        </Button>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                {/* <CheckBoxIcon className={classes.smalltext} fontSize="small" /> */}
                                <Typography className={classes.smalltext} variant="caption" display="block">
                                    By clicking above to sign in, you're agreeing to our <Link className={classes.smalltext} to="/policy" rel="noopener noreferrer">terms of service</Link> and <Link className={classes.smalltext} to="/policy?page=privacy" rel="noopener noreferrer">privacy policy</Link>
                                </Typography>
                            </Grid>
                            {/* <Grid item xs>
                                <Link to="/forgot-password">
                                    <div className={classes.smalltext}>
                                        Forgot Password?
                                    </div>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/signup">
                                    <div className={classes.smalltext}>
                                        Sign Out?
                                </div>
                                </Link>
                            </Grid> */}
                        </Grid>
                    </form>
                </Paper>
                <Box mt={3}>
                    <Copyright />
                </Box>
            </Grid>
        </Grid>
    );
}
