import * as _ from 'lodash';

export const attrs = {
    id: 'id',
    CitationOfAct: 'CitationOfAct',
    NameOfAct: 'NameOfAct',
    TypeOfAct: 'TypeOfAct',
    ReferenceAct: 'ReferenceAct',
    LanguageOfAct: 'LanguageOfAct',
    NatureOfLaw: 'NatureOfLaw',
    YearOfEnactment: 'YearOfEnactment',
    TimePeriod: 'TimePeriod',
    YearOfGazetteNotification: 'YearOfGazetteNotification',
    StatusOfAct: 'StatusOfAct',
    StatesWhereApplicable: 'StatesWhereApplicable',
    StatesWhereApplicableOrig: 'StatesWhereApplicableOrig',
    SourceFileName: 'SourceFileName',
    UploadReadyStatus: 'UploadReadyStatus',
    Categories: 'Categories',
    CategoriesPrimary: 'CategoriesPrimary',
    CategoriesOrig: 'CategoriesOrig',

}

export const attrs_categorical = {
    // TypeOfAct: 'TypeOfAct',
    LanguageOfAct: 'LanguageOfAct',
    // NatureOfLaw: 'NatureOfLaw',
    // YearOfEnactment: 'YearOfEnactment',
    TimePeriod: 'TimePeriod',
    // YearOfGazetteNotification: 'YearOfGazetteNotification',
    StatusOfAct: 'StatusOfAct',
    StatesWhereApplicable: 'StatesWhereApplicable',
    // Categories: 'Categories',
    CategoriesPrimary: 'CategoriesPrimary',
}

export const mapViewVars = {
    state: 'StatesWhereApplicable'
}

export const vals_as_key = (data) => {

    //in -> { '0': 1977, '1': 1917, '10': 1949, '100': 1852, '101': 1960, ...
    //out -> Console { '1977': [ '0', '223', '231' ], '1800': [ '224' ], '1810': [ '217' ], ...

    return _.transform(data, (result, value, key) => {
        (result[value] || (result[value] = [])).push(parseInt(key));
    }, {})
}

export const attr_values = (data, attr) => {
    // in -> {"0": {attr1: x0, attr2: y0}, "1":{attr1: x1, attr2: y1}...}

    // extract attr values
    // out -> {"0": x0, "1": x1, "2": x2...}
    const attr_vals = _.mapValues(data, attr)

    // flip key: val => val: [keys]
    // out -> {'x0': ["0", "21", "27"], 'x1': ["1", "19", "31"]...}
    const vals = vals_as_key(attr_vals)
    return vals
}

export const attr_as_list = (attr, data) => {
    // data is expected to be an object {"0": {attr1: x, attr2: y}, "1":{attr1: x, attr2: y}...}

    const attr_as_key = attr_values(data, attr)

    // console.log(attr_as_key)
    const temp = _.transform(attr_as_key, (result, value, key) => {
        result.push({
            "name": key,
            "value": value.length,
            "ids": value
        })
    }, [])

    // console.log(temp)
    return {
        "name": attr,
        "children": temp
    }
}

export const states_applicable_values = (data) => {
    const x = attr_values(data, 'StatesWhereApplicable')
    let x_split = {}
    _.forEach(x, function (value, key) {
        const key_split = key.split(',')
        if (key_split.length > 1) {
            // console.log(key_split);
            _.forEach(key_split, function (key) {
                key = key.trim()
                // key = key.replace('.', '')
                x_split[key] = (x_split[key] || []).concat(value)
            })
        } else {
            x_split[key] = value
        }
    });

    return x_split
}
