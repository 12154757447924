import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Copyright from "./Copyright";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import queryString from "query-string";
import { useLocation } from "react-router";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: theme.spacing(4),
    // backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(4),
    // marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paperSecondary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  smalltext: {
    fontSize: "14px",
    color: theme.palette.primary.main,
  },
  copytext: {
    // padding: theme.spacing(4),
    color: theme.palette.primary.darkest,
    cursor: "pointer",
  },
  buttontext: {
    fontSize: "12px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

export default function Profile() {
  const classes = useStyles();
  const firstnameRef = useRef("");
  const lastnameRef = useRef("");
  const orgRef = useRef("");
  const researchRef = useRef("");
  const [allowExtraEmails, setAllowExtraEmails] = useState(true);
  const { logout, currentUser, currentUserProfile } = useAuth();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const history = useHistory();
  const subscribersRef = db.collection("subscribers");
  const location = useLocation();
  const qs = queryString.parse(location.search);
  let uid = null;

  useEffect(() => {
    // console.log(qs)
    // console.log(currentUser)
    uid = currentUser ? currentUser.uid : "uid" in qs ? qs["uid"] : null;
    // console.log("uid:" + uid)
    if (uid) {
      //   console.log("user found. fetching profile");
      subscribersRef
        .doc(uid)
        .get()
        .then((doc) => {
          let data = doc.data();
          if (data.email) {
            // console.log("fetched user data: ", data);
            firstnameRef.current.value =
              data.firstname || localStorage.getItem("first_name");
            lastnameRef.current.value =
              data.lastname || localStorage.getItem("last_name");
            orgRef.current.value = data.org || "";
            researchRef.current.value = data.research || "";
            setAllowExtraEmails(data.receiveUpdates || false);
            setSaveButton(false);
            setEnableButton(true);
          } else {
            console.log("new user - no associated profile");
          }
        })
        .then(() => {})
        .catch(() => {
          console.log("firebaseError: Missing or insufficient permissions.");
        });
    } else {
      console.log("no user found");
      history.push("/signin");
    }
  }, []);

  useEffect(() => {
    // propagate change on checkbox change
    handleChange();
  }, [allowExtraEmails]);

  const handleChange = () => {
    // console.log("handle change");
    setError("");
    if (
      orgRef.current.value &&
      researchRef.current.value &&
      lastnameRef.current.value &&
      firstnameRef.current.value
    ) {
      if (
        currentUserProfile &&
        orgRef.current.value === currentUserProfile.org &&
        researchRef.current.value === currentUserProfile.research &&
        firstnameRef.current.value === currentUserProfile.firstname &&
        lastnameRef.current.value === currentUserProfile.lastname &&
        allowExtraEmails === currentUserProfile.receiveUpdates
      ) {
        // values == profile. prevent save. enable go-to-map.
        // console.log("values == profile");
        setEnableButton(true);
        setSaveButton(false);
      } else {
        // values != profile. needs save.
        // console.log("values != profile");
        setEnableButton(true);
        setSaveButton(true);
      }
    } else {
      //   console.log("one or more of the fields are empty");
      // one or more of the fields are empty. prevent save.
      setEnableButton(false);
      setSaveButton(true);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log({
    //   error: error,
    //   loading: loading,
    // });
    var errText = "";
    if (researchRef.current.value === "")
      errText = "Research Interest is missing.";
    if (orgRef.current.value === "") errText = "Affiliation is missing.";
    if (lastnameRef.current.value === "") errText = "Lastname is missing.";
    if (firstnameRef.current.value === "") errText = "Firstname is missing.";

    if (errText !== "") {
      setError(errText);
      return false;
    } else {
      setError("");
    }

    setLoading(true);
    await subscribersRef
      .doc(currentUser.uid)
      .set(
        {
          email: currentUser.email,
          firstname: firstnameRef.current.value,
          lastname: lastnameRef.current.value,
          org: orgRef.current.value,
          research: researchRef.current.value,
          receiveUpdates: allowExtraEmails,
        },
        { merge: true }
      )
      .then(() => {
        //update current profile to match database
        currentUserProfile.org = orgRef.current.value;
        currentUserProfile.research = researchRef.current.value;
        currentUserProfile.firstname = firstnameRef.current.value;
        currentUserProfile.lastname = lastnameRef.current.value;
        currentUserProfile.receiveUpdates = allowExtraEmails;
        console.log("profile updated");
        history.push("/?intro=false");
      });
    setLoading(false);
  }

  async function handleLogout() {
    let response = window.confirm("Are you sure you want to sign out?");
    if (response) {
      await logout().then(() => history.push("/?intro=false"));
    } else {
      return;
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid
        item
        xs={6}
        style={isBrowser ? { maxWidth: "50%" } : { maxWidth: "95%" }}
      >
        <CssBaseline />
        {/* <Box mb={3} display="flex" justifyContent="center" alignItems="center">
                    <KeyboardBackspaceIcon className={classes.copytext} fontSize="small" />
                    <Typography className={classes.copytext} variant="caption" display="block" align="center" onClick={() => { history.push('/?intro=false') }}>
                        Back to map
                    </Typography>
                </Box> */}
        <Paper elevation={3} className={classes.paper}>
          {error && (
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          )}
          {/* {currentUser && <Alert severity="success">{currentUser.email}</Alert>} */}
          <Avatar className={classes.avatar}>
            <FaceIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Your Profile
          </Typography>
          {/* <Chip
                        variant="outlined"
                        size="large"
                        deleteIcon={<DoneIcon />}
                        avatar={<Avatar className={classes.avatar}><FaceIcon /></Avatar>}
                        label={currentUser.email}
                    /> */}
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  autoFocus
                  value={currentUser && currentUser.email}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  inputRef={firstnameRef}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  inputRef={lastnameRef}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="org"
                  label="Affiliation"
                  name="org"
                  autoComplete="org"
                  inputRef={orgRef}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="rint"
                  label="Research Interest"
                  name="rint"
                  autoComplete="rint"
                  inputRef={researchRef}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value={allowExtraEmails} color="primary" />
                  }
                  label="Receive updates from the Land Rights Initiative?"
                  classes={{ label: classes.smalltext }}
                  checked={allowExtraEmails}
                  onClick={() => setAllowExtraEmails((prev) => !prev)}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                {saveButton ? (
                  <Button
                    disabled={!enableButton}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    disabled={!enableButton}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={() => history.push("/?intro=false")}
                  >
                    Back to {isBrowser ? "map" : "archive"}
                  </Button>
                )}
              </Grid>
              {/* <Grid item xs={1}></Grid>
                            <Grid item xs={4}>
                                <Button
                                    disabled={!enableButton}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                >
                                    Go to map
                                </Button>
                            </Grid> */}
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs>
                {currentUserProfile ? (
                  <div className={classes.buttontext} onClick={handleLogout}>
                    {"Last signed in: " +
                      (currentUserProfile && currentUserProfile.lastSignInTime)}
                  </div>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid item>
                <div className={classes.buttontext} onClick={handleLogout}>
                  Sign Out?
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Box mt={3}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
