import React, { useState, useEffect, useRef } from "react";
import DocView from "./DocView";
import * as _ from "lodash";
import { storage } from "../../firebase";
import { analytics } from "../../firebase";
import { FixedSizeList as List } from "react-window";
// import { VariableSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import ListElem from "./ListElem";
import SummaryView from "./SummaryView";

export default function ListView({
  elems,
  listOptions,
  sortStrat,
  sortOrder,
  inFocus,
  inSelect,
  isDocOpen,
  setIsDocOpen,
  setSelectionHandler,
  setFocusHandler,
  setShowViewHandler,
}) {
  const [dataInFocus, setDataInFocus] = useState([]);
  // const [blink, setBlink] = useState(false)
  const timeoutRef = useRef(null);

  // useEffect(() => {
  //     console.log('blink', blink)
  // }, [blink])

  // useEffect(() => {
  //     setBlink(true)
  //     if (timeoutRef.current !== null) {
  //         clearTimeout(timeoutRef.current);
  //     }
  //     timeoutRef.current = setTimeout(() => {
  //         timeoutRef.current = null
  //         setBlink(false)
  //     }, 1000)
  // }, [inSelect])

  useEffect(() => {
    const dataInSelection = _.map(inSelect.ids, (id) => {
      return elems[id];
    });
    let df = [];
    let sortElems = true;
    // if there is a selection. let that be the focus
    // if there is no selection - check if there are any
    // inFocus elements. if not just show everything.
    // edit: showing inFocus elements are a bit disruptive. scratching that.
    if (dataInSelection.length !== 0) {
      df = [...dataInSelection];
      // if (inSelect.source = 'search-bar') sortElems = true
    } else {
      df = _.values(elems);
    }

    if (sortElems) {
      // df = (inFocus.length === 0) ? _.values(elems) : _.map(inFocus, (id) => { return elems[id] })
      const x = sortOrder === "ASC" ? -1 : 1;

      function compareAlpha(a, b) {
        if (a.NameOfAct < b.NameOfAct) {
          return listOptions.sortAlpha ? x : -x;
        }
        if (a.NameOfAct > b.NameOfAct) {
          return listOptions.sortAlpha ? -x : x;
        }
        return 0;
      }

      function compareId(a, b) {
        if (a.id < b.id) {
          return listOptions.sortAlpha ? x : -x;
        }
        if (a.id > b.id) {
          return listOptions.sortAlpha ? -x : x;
        }
        return 0;
      }

      const sortFn =
        sortStrat === "byAlpha"
          ? compareAlpha
          : sortStrat === "byId"
          ? compareId
          : compareId;
      df = df.sort(sortFn);
    }

    handleDocClose(0);
    setDataInFocus(df);
  }, [elems, inSelect]);

  useEffect(() => {
    let df = dataInFocus;

    const x = sortOrder === "ASC" ? -1 : 1;

    function compareAlpha(a, b) {
      if (a.NameOfAct < b.NameOfAct) {
        return listOptions.sortAlpha ? x : -x;
      }
      if (a.NameOfAct > b.NameOfAct) {
        return listOptions.sortAlpha ? -x : x;
      }
      return 0;
    }

    function compareId(a, b) {
      if (a.id < b.id) {
        return listOptions.sortAlpha ? x : -x;
      }
      if (a.id > b.id) {
        return listOptions.sortAlpha ? -x : x;
      }
      return 0;
    }

    const sortFn =
      sortStrat === "byAlpha"
        ? compareAlpha
        : sortStrat === "byId"
        ? compareId
        : compareId;
    df = df.sort(sortFn);

    setDataInFocus(df);
  }, [sortOrder, sortStrat]);

  const [openDocId, setOpenDocId] = useState(null);
  const [docURL, setDocURL] = useState();

  const inferDocType = (elem) => {
    let docType = null;
    let uploadStatus = elem.UploadReadyStatus.toLowerCase();
    if (uploadStatus.includes("http") || uploadStatus.includes("www")) {
      docType = "weblink";
      // console.log(docType, elem.UploadReadyStatus)
    } else if (uploadStatus.includes("upload")) {
      docType = "pdf";
      // console.log(docType, elem.SourceFileName)
    } else if (uploadStatus.includes("manupatra")) {
      docType = "not-available";
      // console.log(docType, elem.UploadReadyStatus)
    } else {
      docType = "not-available";
      // console.log(docType, elem.UploadReadyStatus)
    }

    return docType;
  };

  const handleDocOpenNew = (elemId) => {
    const typ = inferDocType(elems[elemId]);
    // console.log("[ListElem].clickOpen.elem", typ)
    if (typ === "pdf") {
      storage
        .refFromURL(
          `gs://mill-dev.appspot.com/${elems[elemId].SourceFileName}.pdf`
        )
        .getDownloadURL()
        .then((url) => {
          var win = window.open(url, "_blank");
          win.focus();
        });
    } else if (typ === "weblink") {
      var win = window.open(elems[elemId].UploadReadyStatus, "_blank");
      win.focus();
    }
  };
  const handleDocOpen = (elemId) => {
    const elem = elems[elemId];
    console.log("[ListElem].clickOpen.elem", elem);
    analytics.logEvent("doc-open", elem.NameOfAct);
    const typ = inferDocType(elem);
    // console.log("[ListElem].clickOpen.elem", typ)
    if (typ === "pdf") {
      storage
        .refFromURL(
          `gs://mill-dev.appspot.com/${elems[elemId].SourceFileName}.pdf`
        )
        .getDownloadURL()
        .then((url) => {
          setDocURL(url);
          setOpenDocId(elemId);
          setIsDocOpen(true);
          setShowViewHandler(false);
        });
    } else if (typ === "weblink") {
      setDocURL(elems[elemId].UploadReadyStatus);
      setOpenDocId(elemId);
      setIsDocOpen(true);
      setShowViewHandler(false);
    }
  };
  const handleDocClose = (elemId) => {
    // console.log("[ListElem].clickClose.elem_id", elemId)
    setDocURL(null);
    setOpenDocId(null);
    setIsDocOpen(false);
    setShowViewHandler(true);
  };

  const handleDocOpenNewTab = (elemId) => {
    // console.log("[ListElem].clickOpen.elem_id", elemId)
    storage
      .refFromURL(
        `gs://mill-dev.appspot.com/${elems[elemId].SourceFileName}.pdf`
      )
      .getDownloadURL()
      .then((url) => {
        var win = window.open(url, "_blank");
        win.focus();
      });
  };

  const handleElemBlur = () => {
    // console.log('list-elem-blur', inFocus.ids)
    setFocusHandler([], "list-view");
  };

  useEffect(() => {
    if (!isDocOpen) {
      setOpenDocId(null);
      setShowViewHandler(true);
    }
  }, [isDocOpen]);

  const tileHeight = listOptions.compactView ? 100 : 350;

  const Row = ({ index, style }) => {
    // console.log(index, dataInFocus[index].id)
    return (
      <div style={style}>
        <ListElem
          // className={highlight ? 'flash' : ''}
          serialNum={index}
          elem={dataInFocus[index]}
          inFocus={inFocus}
          isSelected={false}
          openDocId={openDocId}
          listOptions={listOptions}
          tileHeight={tileHeight}
          setFocusHandler={setFocusHandler}
          handleDocOpen={handleDocOpen}
          handleDocClose={handleDocClose}
          handleDocOpenNewTab={handleDocOpenNewTab}
        />
      </div>
    );
  };

  return (
    <div
      key={sortStrat + sortOrder}
      name="list-view"
      className="list-view"
      onMouseLeave={() => handleElemBlur()}
    >
      <div id="list-items-container">
        <AutoSizer>
          {({ height, width }) => (
            <List
              id="list-items-container"
              height={height}
              itemCount={dataInFocus.length}
              itemSize={tileHeight}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </div>
      {openDocId !== null &&
      inferDocType(elems[openDocId]) !== "not-available" ? (
        <SummaryView
          key={sortStrat + sortOrder + "doc-summary"}
          open={openDocId !== null}
          handleDocOpenNew={handleDocOpenNew}
          handleDocClose={handleDocClose}
          elem={elems[openDocId]}
          typ={inferDocType(elems[openDocId])}
          url={docURL}
        />
      ) : null}
      {/* {
                // if a element has been clicked, handleDocOpen is triggered
                // setting the openDocId. handleDocClose sets it as null.
                (openDocId !== null && (inferDocType(elems[openDocId]) !== 'not-available'))
                    ? <DocView key={sortStrat + sortOrder + 'doc'} open={(openDocId !== null)} handleDocClose={handleDocClose} elem={elems[openDocId]} url={docURL} />
                    : null
            } */}
    </div>
  );
}
