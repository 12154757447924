import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    copytext: {
        // padding: theme.spacing(4),
        color: theme.palette.primary.darkest,
        cursor: 'pointer'
    },
}));

export default function SigninVerify() {
    const classes = useStyles();
    const { isSignInLink, signInWithLink, currentUser } = useAuth()
    const [userEmail, setUserEmail] = useState()
    const [error, setError] = useState()
    const history = useHistory()

    useEffect(() => {
        if (currentUser) {
            history.push('/?intro=false')
        } else if (isSignInLink(window.location.href)) {
            console.log('Valid Link')
            // Confirm the link is a sign-in with email link.
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.

            var email = window.localStorage.getItem('emailForSignIn');
            setUserEmail(email)
            console.log(`email for sign in ${email}`)

            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
                if (!email) {
                    setError('Email required.')
                    return
                }
            }
            // The client SDK will parse the code from the link for you.
            signInWithLink(email, window.location.href)
                .then((result) => {
                    console.log('signed in')
                    console.log(result)
                    console.log(result.additionalUserInfo.isNewUser)
                    window.localStorage.removeItem('emailForSignIn')
                    if (result.additionalUserInfo.isNewUser) {
                        history.push('/profile?uid=' + result.user.uid)
                    } else {
                        history.push('/?intro=false')
                    }
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    if (errorCode === 'auth/invalid-action-code') {
                        setError('Invalid link or link has been previously used. Please sign in again.')
                    } else {
                        // setError(errorCode + ": " + errorMessage)
                        setError(errorMessage)
                    }
                });
        } else {
            setError('Invalid Link. Please sign in again');
        }
    }, [])

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            {error ?
                <Box mt={3}>
                    <Alert severity="error">{error}</Alert>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => history.push('/signin')}
                    >
                        Sign in
                    </Button>
                </Box>
                :
                <Box mt={3}>
                    <Typography className={classes.copytext} variant="caption" display="block">
                        Verifying {userEmail}..
                    </Typography>
                </Box>
            }
        </Grid>
    );
}
