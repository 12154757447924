import * as _ from 'lodash'
import * as d3 from 'd3'

export default function LegendService(data, colorBy, filterBy, theme = 'dark', n = 20) {

    const colorScale = d3.scaleOrdinal()
        .domain(["group0", "group1", "group2", "group3", "group4", "group5", "group6", "group7", "group8", "Others"])
        .range(
            theme === 'dark'
                ? ["#307a90", "#4C506B", "#256A67", "#2291AA", "#7C81A2", "#38A09C", "#81D3E6", "#CBDFFF", "#63FFD6", "#224a6d"]
                : ["#E6E0E9", "#FFF1AE", "#B1EBDE", "#DCF6FF", "#D5D5E3", "#FFE97B", "#D8FED8", "#F6F6F6", "#E0E0E0", "#EBEBEB"]
        );

    let elemsDataSplit = []
    // console.log(data)
    _.forEach(_.values(data), (elem) => {
        let splitVals = null
        if (typeof elem[colorBy] === 'string' || elem[colorBy] instanceof String) {
            splitVals = elem[colorBy].split(',').map(name => name.trim())
        } else {
            splitVals = elem[colorBy]
        }
        if (splitVals && splitVals.length > 0) {
            _.forEach(splitVals, val => {
                elemsDataSplit.push(_.merge({}, elem, { [colorBy]: val }))
            })
        }
    })


    const getIdsFromColor = (attr, attrVal) => {
        let x = _.map(_.values(elemsDataSplit).filter(x => x[attr] === attrVal), "id")
        // console.log('getIdsFromColor', x)
        return x
    }


    const lBasis = Object.entries(_.countBy(elemsDataSplit, colorBy))
    // lBasis

    const lBasisWithIds = _.map(lBasis, (elem) => {
        elem.push(getIdsFromColor(colorBy, elem[0]))
        // console.log('lBasisWithIds', elem)
        return elem
    })
    // lBasisWithIds

    const lSorted = lBasis.sort(function (a, b) {
        //sort by size (=num of acts)
        if (a[1] > b[1]) return -1;
        if (a[1] < b[1]) return 1;
        return 0;
    });

    // dont coalesce if states
    if (colorBy === 'StatesWhereApplicable' || colorBy === 'CategoriesPrimary') n = 50


    // lSorted
    const topn = lSorted.slice(0, n)
    const rest = lSorted.slice(n, lSorted.length)
    const Others = _.reduce(rest, (acc, value, key) => {
        return ["Others", acc[1] + value[1], acc[2].concat(value[2])]
    }, ["Others", 0, []])

    const All = _.reduce(lSorted, (acc, value, key) => {
        return ["All", acc[1] + value[1], acc[2].concat(value[2])]
    }, ["All", 0, []])

    All[2] = _.uniq(All[2])
    All[1] = All[2].length

    // console.log('All', All)
    var topnWithOthers = _.union([All], topn, [Others])
    // } else {
    //     var topnWithOthers = lSorted
    // }

    // topnWithOthers

    // const legend = _.map(topnWithOthers, (elem) => {
    //     return _.union(elem, [colorScale(elem[0])])
    // })


    // y = getIdsFromColor('state', 'Assam')

    var legend = _.reduce(topnWithOthers, (acc, value, key) => {
        if (value[2].length > 0) {
            let x = {
                ids: [...value[2]],
                id: value[0],
                numOfActs: value[1],
                color: colorScale(value[0]),
            }
            acc.push(x)
        }
        return acc
    }, [])

    // if (filterBy !== 'None') {
    //     legend = _.filter(legend, { id: filterBy })
    // }

    return legend
}