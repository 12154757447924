import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import * as _ from "lodash";
import * as d3 from "d3";
import ColorByService from "./ColorByService";
import HistoLegendX from "./HistoLegendX";
import HistoLegendBottom from "./HistoLegendBottom";
// import HistoLegend from './HistoLegend'
// import { attr_as_list, attr_values, attrs_categorical, vals_as_key } from '../../datautils'
import { elemsData } from "../../lawsdict";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
// import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  histoStage: {
    flexDirection: "column",
    display: "flex",
    flex: "1 1",
    minHeight: "100vh",
  },
  histoContent: {
    position: "absolute !important",
    marginLeft: "50px",
    marginTop: "70px",

    "& .tag-rect": {
      cursor: "pointer",
      fill: fade(theme.palette.primary.main, 0.2), //theme.palette.text.hint,
      // stroke: theme.palette.background.paper,
      strokeWidth: "0.125rem",
      opacity: 0.9,
      // transition: 'fill 0.5s'
    },

    "& .focussed .tag-rect ": {
      // fill: fade(theme.palette.secondary.main, 0.2),
      stroke: theme.palette.secondary.main,
      strokeWidth: "0.0625rem",
      opacity: 1,
      // transition: 'fill 0.5s'
    },

    "& .hovered .tag-rect": {
      // fill: theme.palette.secondary.dark,
      stroke: theme.palette.secondary.main,
      strokeWidth: "0.0625rem",
      opacity: 1,
      // transition: 'opacity 0.5s'
    },

    "& .selected .tag-rect": {
      // fill: fade(theme.palette.secondary.main, 0.2),
      // stroke: theme.palette.background.paper,
      // strokeWidth: '0.125rem',
      opacity: 1,
      // transition: 'fill 0.5s'
    },

    "& .unselected .tag-rect": {
      // fill: fade(theme.palette.secondary.main, 0.2),
      // stroke: theme.palette.background.paper,
      // strokeWidth: '0.125rem',
      opacity: 0.2,
      // transition: 'fill 0.5s'
    },

    "& .clicked .tag-rect": {
      fill: theme.palette.secondary.dark,
      // stroke: theme.palette.primary.darkest,
      // strokeWidth: '0.125rem',
      opacity: 1,
    },

    "& .tag-text": {
      fill: theme.palette.text.default,
      fontSize: "0.8rem",
      fontWeight: 400,
      fillOpacity: 0,
    },

    "& .selected .tag-text": {
      fillOpacity: 1,
    },

    "& .unselected .tag-text": {
      fillOpacity: 0,
    },

    "& .focussed .tag-text": {
      fill: theme.palette.text.default,
      fillOpacity: 1,
      fontWeight: 400,
    },

    "& .hovered .tag-text": {
      fill: theme.palette.primary.contrastText,
      fillOpacity: 1,
      fontWeight: 400,
    },

    "& .clicked .tag-text": {
      fill: theme.palette.primary.contrastText,
    },

    "& .tag-text-sub": {
      fill: theme.palette.primary.main,
      fillOpacity: 0.5,
      fontWeight: 200,
      // fontSize: '0.625rem'
    },

    "& .hovered .tag-text-sub": {
      fill: theme.palette.primary.contrastText,
      // fontWeight: 400
    },

    "& .clicked .tag-text-sub": {
      fill: theme.palette.primary.contrastText,
    },

    "& .tag-rect-sel": {
      cursor: "pointer",
      fill: fade(theme.palette.secondary.main, 0.5),
    },

    "& .tag-text-sel": {
      // cursor: 'pointer',
      fill: theme.palette.secondary.main,
      fontSize: "0.8rem",
      fontWeight: 400,
      fillOpacity: 1,
    },

    "& .xaxis-bot": {
      fill: theme.palette.secondary.main,
      // stroke:
    },

    "& .yaxis-ticks": {
      color: fade(theme.palette.secondary.main, 0.5),
    },
  },
  tooltipRoot: {
    color: theme.palette.primary.contrastTextLight,
    position: "absolute",
    textAlign: "left",
    width: "auto",
    height: "auto",
    padding: "5px",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "0.875rem",
    background: theme.palette.primary.main,
    border: "0px",
    borderRadius: "4px",
    pointerEvents: "none",
    display: "flex",
    flexDirection: "column",
    lineHeight: "0.825rem",
    opacity: 0,
    zIndex: 2000,
  },
  tooltipSub: {
    fontSize: "0.625rem",
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function HistoView({
  selectionData,
  partitionBy,
  colorBy,
  filterBy,
  setFilterBy,
  setHistoData,
  inSelect,
  inFocus,
  setFocusHandler,
  setSelectionHandler,
}) {
  var size = useWindowSize();
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef();
  const tooltipRef = useRef();

  // const [win, setWin] = useState({ width: 800, height: 800, x: 0, y: 0 })
  const margin = { top: 100, right: 50, bottom: 100, left: 50 };

  const [bins, setBins] = useState([]);
  const [binsProcessed, setBinsProcessed] = useState([]);
  const [binWidth, setBinWidth] = useState(0);
  // const [maxCellHeightAll, setMaxCellHeightAll] = useState(-1)

  const [minVal, maxVal] = d3.extent(_.map(elemsData, partitionBy));

  const [colGroups, setColGroups] = useState(null);

  const binThresh = [0, 1947, 1960, 1970, 1980, 1990, 2000, 2010];
  const binDescr = [
    " < 1947",
    "1947-59",
    "1960-69",
    "1970-79",
    "1980-89",
    "1990-99",
    "2000-09",
    "2010 > ",
  ];
  const binGap = 20;
  const binOffX = 40;
  const binOffY = 4;
  const cellGap = 1;
  // var maxCellHeight = -1
  // var maxCellCount = -1
  var yScale = null;

  // selection conditions
  var FILTGROUP_CNT = 0;
  var FILTGROUP_X_SELECTION_INTERSECTION_CNT = 0;

  // const filterDataByOption = (data, filterByOption) => {
  //     let filterData = {}
  //     // console.log(data)
  //     _.forEach(data, (v, k) => {
  //         if (typeof v[colorBy] === 'string' || v[colorBy] instanceof String) {
  //             if (v[colorBy] === filterByOption) {
  //                 filterData[k] = v
  //             }
  //         } else {
  //             if (v[colorBy].includes(filterByOption)) {
  //                 filterData[k] = v
  //             }
  //         }
  //     })
  //     return filterData
  // }

  // const filterDataByIds = (data, ids) => {
  //     let filterData = _.pick(elemsData, inSelect.ids)
  //     return filterData
  // }

  // const splitVals = (data) => {
  //     let elemsDataSplit = []
  //     // console.log(data)
  //     _.forEach(_.values(data), (elem) => {
  //         let splitVals = null
  //         if (typeof elem[colorBy] === 'string' || elem[colorBy] instanceof String) {
  //             splitVals = elem[colorBy].split(',').map(name => name.trim())
  //         } else {
  //             splitVals = elem[colorBy]
  //         }
  //         // console.log(splitVals)

  //         if (splitVals && splitVals.length > 1) {
  //             _.forEach(splitVals, val => {
  //                 elemsDataSplit.push(_.merge({}, elem, { [colorBy]: val }))
  //             })
  //         } else {
  //             elemsDataSplit.push(elem)
  //         }
  //     })
  //     return elemsDataSplit
  // }

  useEffect(() => {
    console.log("[histo-view] mount");
    // setFilterBy('All')
    return () => {
      console.log("[histo-view] unmount");
      ref.current = null;
      tooltipRef.current = null;
    };
  }, []);

  useEffect(() => {
    // console.log(`[histo] bin update ${bins.length}`)
    if (bins.length > 0) processBins();
  }, [bins]);

  useEffect(() => {
    // console.log(`[histo] colorBy change ${colorBy}`)
    if (bins.length > 0) processBins();
  }, [colorBy]);

  useEffect(() => {
    // console.log(`[histo] in-select`)
    if (inSelect.ids.length === 0) {
      // deselection
      setFilterBy("All");
    } else {
      // filterBy change or view change
      // var inSelectionData = _.pick(elemsData, inSelect.ids)
    }

    // console.log(_.keys(inSelectionData))

    // histogram
    var histogram = d3
      .histogram()
      .value(function (d) {
        return d[partitionBy];
      })
      .domain([minVal, maxVal])
      .thresholds(binThresh);

    // binning all data
    var binData = histogram(_.values(elemsData)); //.filter(d => d.length > 0)
    setBins(binData);
    processBins(elemsData);
  }, [inSelect]);

  useEffect(() => {
    // console.log(`[histo] in-focus ${inFocus.ids}`)
    if (bins.length > 0) processBins();
    // redraw
  }, [inFocus]);

  useEffect(() => {
    // console.log(`[histo] size ${size[0]} x ${size[1]}`)
    if (size[0] > 0 && size[1] > 0) {
      if (bins.length > 0) processBins();
    }
  }, [size]);

  useEffect(() => {
    // console.log(`[histo] theme ${theme.palette.type}`)
    if (bins.length > 0) processBins();
  }, [theme]);

  const processBins = (filterGroupData = elemsData) => {
    // console.log('redraw')
    const { clientWidth, clientHeight } =
      document.getElementById("view-container");
    // console.log('clientWidth, clientHeight', clientWidth, clientHeight)

    if (clientWidth > 0 && clientHeight > 0) {
      const w = clientWidth - margin.left - margin.right;
      const h = clientHeight - margin.top - margin.bottom;

      // computing groups from colorby attribute
      const colorByGroups = ColorByService(
        filterGroupData,
        colorBy,
        filterBy,
        theme.palette.type
      );
      setColGroups(colorByGroups);
      const colorByGroupsCopy = _.cloneDeep(colorByGroups);
      // console.log("colorByGroups", colorByGroups);

      let maxCellCount = -1;
      let maxCellHeight = -1;

      // console.log(bins)
      let binData = _.map(bins, (bin, i) => {
        let binIds = _.map(bin, (elem) => elem.id);

        const intersect = (a, b) => {
          var setB = new Set(b);
          return [...new Set(a)].filter((x) => setB.has(x));
        };

        let binGroups = _.transform(
          colorByGroupsCopy,
          (acc, value, key) => {
            acc[value.id] = intersect(value.ids, binIds);
          },
          {}
        );

        console.log(binGroups);
        // const groups = _.groupBy(bin, colorBy);
        const groups = _.cloneDeep(binGroups);

        // console.log(`binGroup-${i}`, binGroups)
        // console.log('groups', groups)

        // pick the filtered option
        let filteredGroups = {};
        // if (filterBy !== 'None') {
        _.forEach(groups, (value, key) => {
          if (filterBy === key) {
            filteredGroups[key] = value;
          }
        });
        // } else {
        //     filteredGroups = groups
        // }
        // console.log('filteredGroups', filteredGroups)

        // let inSelectGroups = {}
        // if (inSelect.ids.length !== 'All') {

        // replicate the legend order
        // const sortedGroups = {}
        // colorByGroupsCopy.forEach(elem => {
        //     if (elem.id in groups) {
        //         sortedGroups[elem.id] = groups[elem.id]
        //     }
        // })

        // collect Others
        for (const [key, value] of Object.entries(filteredGroups)) {
          if (!(key in filteredGroups)) {
            filteredGroups["Others"] = (filteredGroups["Others"] || []).concat(
              value
            );
          }
        }

        // console.log("----")
        // console.log('sortedGroups', sortedGroups)
        bin.groups = filteredGroups;

        let len = _.keys(groups).length;
        if (len > maxCellCount) maxCellCount = len;
        let ht = d3.max(_.values(groups), function (d) {
          return d.length;
        });
        // console.log(ht, sortedGroups)
        if (ht > maxCellHeight) maxCellHeight = ht;
        // if (filterBy === 'All') setMaxCellHeightAll(maxCellHeight)

        // console.log(bin)
        return bin;
      });

      yScale = d3
        .scaleLinear()
        // .domain([0, (filterBy === 'All') ? maxCellHeight : maxCellHeightAll])
        .domain([0, maxCellHeight])
        .range([h - 50, 0]);

      var totAvailableX = w - binOffX;
      // const xOff = 0 //offset gap between the bins
      const nBins = binThresh.length;
      const binTotalWidth = totAvailableX / nBins;
      setBinWidth(binTotalWidth);

      // console.log('w, nBins, binTotalWidth, maxCells', w, nBins, binTotalWidth, maxCellCount)
      let binUnitHeight = (h - 50 - yScale(maxCellHeight)) / maxCellHeight;
      // console.log('maxCellHeight, binUnitHeight', maxCellHeight, binUnitHeight)

      binData = _.map(binData, (bin, i) => {
        bin.bx0 = binOffX + (i * totAvailableX) / nBins; //xScale(b.x0)
        bin.noOfActs = bin.length;
        bin.width = binTotalWidth - binGap;
        bin.unitHeight = binUnitHeight;
        bin.unitWidth = (binTotalWidth - binGap) / maxCellCount;
        bin.descr = binDescr[i];
        return bin;
      });

      setBinsProcessed(binData);
      // console.log('binData', binData)

      // Compute overlay
      let overlayData = [];
      const intersect = (a, b) => {
        var setB = new Set(b);
        return [...new Set(a)].filter((x) => setB.has(x));
      };

      let allBinLen = 0;
      let intersectBinLen = 0;
      // filterGroupData = filterDataByOption(elemsData, filterBy)
      _.forEach(binData, (bin, key) => {
        // console.log(bin.groups[filterBy])
        let x = intersect(inSelect.ids, bin.groups[filterBy]);
        // let x = intersect(inSelect.ids, _.map(bin, elem => elem.id))
        let noOfActs = x.length;
        intersectBinLen += noOfActs;
        if (bin.groups[filterBy] !== undefined) {
          allBinLen += bin.groups[filterBy].length;
        }
        overlayData[key] = {
          key: `${filterBy}:${bin.descr}`,
          ids: x,
          descr: bin.descr,
          binX: bin.bx0,
          width: bin.width,
          noOfActs: noOfActs,
          height: bin.unitHeight * noOfActs,
        };
      });

      FILTGROUP_X_SELECTION_INTERSECTION_CNT = intersectBinLen;
      FILTGROUP_CNT = allBinLen;

      // console.log(">>>>>>>")
      // console.log(binData)
      // console.log(overlayData)
      // console.log("<<<<<<<")

      // console.log('allBinLen', allBinLen, intersectBinLen)
      if (inSelect.ids.length > 0) {
        if (FILTGROUP_X_SELECTION_INTERSECTION_CNT === 0) {
          // selection present but no intersection with filtered group; no overlays.
          // console.log("selection present but no intersection with filtered group; no overlays.")
          draw(w, h, binData, overlayData);
        } else if (inSelect.ids.length < FILTGROUP_CNT) {
          // selection present and is a subset of the filtered group
          // console.log("selection present and is a subset of the filtered group")
          draw(w, h, binData, overlayData);
        } else {
          // selection present but select.size == FILTGROUP_CNT; no overlays
          // console.log("selection present but select.size == filterGroup.size; no overlays")
          draw(w, h, binData, overlayData);
        }
      } else {
        // there is no selection
        // console.log("there is no selection")
        draw(w, h, binData, overlayData);
      }
    }
  };

  // const t = d3.transition()
  //     .duration(1000)

  const draw = (w, h, binData, overlayData) => {
    const svg = d3
      .select(ref.current)
      .attr("width", w)
      .attr("height", h)
      .attr("id", "view-d3-histo")
      .style("font", "10px Roboto")
      .style("overflow", "hidden")
      .on("click", (e) => {
        // if click outside the elems, reset selection
        let x = _.filter(colGroups, { id: filterBy });
        if (x.length > 0) {
          // select ids associated with the filterBy option
          if (e.target.id === "view-d3-histo")
            setSelectionHandler(x[0].ids, "histo-view", "reset");
        } else {
          // unselect all
          if (e.target.id === "view-d3-histo")
            setSelectionHandler([], "histo-view", "reset");
        }
      })
      .on("mousemove", function (event, d) {
        tooltipOn(event, null);
      })
      .on("mouseout", function (event, d) {
        tooltipOff(event, null);
      });

    svg.selectAll("g").remove();
    svg.selectAll("path").remove();
    // svg.selectAll('rect').remove()

    // append the bar rectangles to the svg element
    let binContainer = svg.selectAll(".gBins").data(binData);

    binContainer.exit().remove();

    let binContainerEnter = binContainer
      .enter()
      .append("g")
      .attr("class", "gBin")
      .attr("transform", (d) => `translate(${d.bx0},${h - binOffY})`);

    binContainerEnter.exit().remove();

    let childBins = binContainerEnter
      .selectAll("g")
      .data((d) => {
        // console.log('childrects.data', d.groups[filterBy].length, d.unitHeight)
        let x = [
          {
            key: `${filterBy}:${d.descr}`,
            ids: _.values(d.groups[filterBy]),
            width: d.width - cellGap,
            height:
              (d.groups[filterBy] &&
                d.groups[filterBy].length * d.unitHeight) ||
              0,
          },
        ];
        // console.log(x)
        return x;
      })
      .enter()
      .append("g")
      .attr("class", "gChildBin");

    childBins
      .classed("clicked", false)
      .classed("focussed", false)
      .classed("hovered", false)
      .classed("selected", false)
      .classed(
        "selected",
        (d) =>
          d.ids.filter((value) => inSelect.ids.includes(parseInt(value)))
            .length > 0
      )
      .classed("unselected", (d) =>
        inSelect.ids.length === 0
          ? false
          : d.ids.filter((value) => inSelect.ids.includes(parseInt(value)))
              .length === 0
      )
      .classed(
        "focussed",
        (d) =>
          d.ids.filter((value) => inFocus.ids.includes(parseInt(value)))
            .length > 0
      )
      .classed("hovered", false)
      .on("click", function (event, d) {
        makeSelection(d);
      })
      .on("mousemove", function (event, d) {
        event.stopPropagation();
        tooltipOn(event, d);
      })
      .on("mouseover", function (event, d) {
        event.stopPropagation();
        tooltipOn(event, d);
        d3.select(this).classed("hovered", true);
      })
      .on("mouseout", function (event, d) {
        event.stopPropagation();
        tooltipOff(event, d);
        d3.selectAll(".gChildBin")
          .classed("hovered", false)
          .classed("focussed", false);
      });

    // enter / update / exit for rect, inside each container
    let childRects = childBins.append("rect");

    childRects.exit().remove();

    // console.log('childRectsExit', childRectsExit)
    if (inSelect.ids.length === 0) {
      childRects.classed("unselected", false);
    }

    childRects
      .attr("class", "tag-rect")
      .merge(childRects)
      .attr("width", function (d) {
        return d.width;
      })
      // .attr("height", 0)
      .attr("x", 0)
      // .transition(t)
      .attr("height", function (d) {
        return d.height;
      })
      .attr("y", function (d) {
        return -d.height;
      });

    if (!(inSelect.ids.length > 0 && _.keys(overlayData).length > 0)) {
      // enter / update / exit for rect, inside each container
      childBins
        .append("text")
        .attr("class", "tag-text")
        .attr("x", (d) => d.width / 2 - ("" + d.ids.length).length * 4.5)
        .attr("y", function (d) {
          return -(d.height + 20);
        })
        // .text(d => d.height)
        .text((d) => d.ids.length);
    }

    binContainerEnter
      .append("rect")
      .attr("class", "xaxis-bot")
      .attr("x", 0)
      .attr("y", (d) => -1)
      .attr("width", (d) => d.width - cellGap)
      .attr("height", (d) => 1);

    const yAxis = (g) =>
      g
        .attr("class", "yaxis-ticks")
        .attr("transform", `translate(${binOffX - 10},${50 - binOffY - 2})`)
        .call(d3.axisLeft(yScale).ticks(null, "s"))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .select(".tick:last-of-type text")
            .clone()
            .attr("x", 3)
            .attr("text-anchor", "start")
            .attr("font-weight", "bold")
            .text(selectionData.y)
        );

    svg.append("g").call(yAxis);

    // const line = d3.line()
    //     .defined(d => !isNaN(d.value))
    //     .x(d => d.binX - 2)
    //     .y(d => h - d.height - 5)

    if (inSelect.ids.length > 0) {
      //     let lines = svg.append("path")
      //         .datum(overlayData)
      //         .attr("fill", "none")
      //         .attr("stroke", "steelblue")
      //         .attr("stroke-width", 1.5)
      //         .attr("stroke-linejoin", "round")
      //         .attr("stroke-linecap", "round")
      //         .attr("d", line);

      //     lines.exit().remove()

      let selectedBinsEnter = svg
        .append("g")
        .selectAll(".selectedBins")
        .data(overlayData)
        .enter();

      selectedBinsEnter
        .append("rect")
        .attr("class", "tag-rect-sel")
        .attr("width", (d) => d.width - cellGap)
        // .attr("height", 0)
        .attr("x", (d) => d.binX)
        // .transition(t)
        .attr("height", (d) => d.height)
        .attr("y", (d) => h - d.height - 5)
        .on("click", function (event, d) {
          makeSelection(d);
        })
        .on("mousemove", function (event, d) {
          event.stopPropagation();
          tooltipOn(event, d);
        })
        .on("mouseover", function (event, d) {
          event.stopPropagation();
          tooltipOn(event, d);
          d3.select(this).classed("hovered", true);
        })
        .on("mouseout", function (event, d) {
          event.stopPropagation();
          tooltipOff(event, d);
          d3.selectAll(".tag-rect-sel")
            .classed("hovered", false)
            .classed("focussed", false);
        });

      selectedBinsEnter
        .append("text")
        .attr("class", "tag-text-sel")
        .attr(
          "x",
          (d) => d.binX + d.width / 2 - ("" + d.ids.length).length * 4.5
        )
        .attr("y", function (d) {
          return h - d.height - 24;
        })
        .text((d) => d.ids.length || "");
    }

    //------------------------------------------------------------------
    function tooltipOn(e, d = null) {
      const tooltip = d3.select(tooltipRef.current);
      let mx = e.pageX - document.getElementById("view-container").offsetLeft;
      let my = e.pageY;
      tooltip.style("opacity", 0.8);
      if (d !== null) {
        tooltip.html(
          `<span>${d.key} (${d.ids.length})</span><span style="font-size: 0.625rem">click to select</span>`
        );
      } else if (
        inSelect.ids.length > 0 &&
        FILTGROUP_X_SELECTION_INTERSECTION_CNT < FILTGROUP_CNT
      ) {
        // console.log(inSelect.ids.length)
        // console.log(FILTGROUP_X_SELECTION_INTERSECTION_CNT)
        // console.log(FILTGROUP_CNT)

        tooltip.html(
          `<span style="font-size: 0.625rem">click to deselect</span>`
        );
      } else {
        tooltipOff(e, d);
      }
      tooltip.style("left", mx + 10 + "px").style("top", my + 10 + "px");
    } //tooltipOn

    function makeSelection(d) {
      // console.log('select', d.ids)
      setSelectionHandler(d.ids, "histo-view", "reset");
    }

    function tooltipOff(e, d) {
      const tooltip = d3.select(tooltipRef.current);
      tooltip.style("opacity", 0);
    } //tooltipOff
  };

  return (
    <>
      <div key={filterBy + "-legend"} className={classes.histoStage}>
        {/* <HistoLegend legend={colGroups} filterBy={filterBy} inFocus={inFocus} setFocusHandler={setFocusHandler} inSelect={inSelect} setSelectionHandler={setSelectionHandler} className={'histo-legend'} /> */}
        <HistoLegendX
          xoff={margin.left + binOffX}
          filterBy={filterBy}
          cellWidth={binWidth}
          binGap={binGap}
          bins={binsProcessed}
          legend={colGroups}
          inFocus={inFocus}
          setFocusHandler={setFocusHandler}
          inSelect={inSelect}
          setSelectionHandler={setSelectionHandler}
          className={"histo-legend-x"}
        />
        <HistoLegendBottom theme={theme} />
      </div>
      <div key={filterBy + "-plot"} className={classes.histoContent}>
        <svg ref={ref}></svg>
      </div>
      <div className={classes.tooltipRoot} ref={tooltipRef}></div>
    </>
  );
}

// var x = HistoView({ selectionData: null, partitionBy: 'YearOfEnactment', colorBy: 'StatesWhereApplicable', filterBy: 'All', setFilterBy: function () { }, setHistoData: function () { }, inSelect: { ids: [] }, inFocus: { ids: [] }, setFocusHandler: function () { }, setSelectionHandler: function () { } })
// x
