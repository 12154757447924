import React, { useState } from "react";
import * as _ from "lodash";
import ListView from "./ListView";
import AttrView from "./AttrView";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
// import ToggleButton from '@material-ui/lab/ToggleButton';
import IconButton from "@material-ui/core/IconButton";
// import TuneSharpIcon from '@material-ui/icons/TuneRounded';
// import GetAppIcon from '@material-ui/icons/GetApp';
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
// import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FilterListIcon from "@material-ui/icons/FilterList";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filterPanelRoot: {
    display: "flex",
    flexFlow: "column",
    flex: "1 1",
    marginTop: "4px",
  },
  paperRoot: {
    backgroundColor: theme.palette.background.paperDark,
    marginLeft: "10px",
    marginBottom: "4px",
  },
  dividerRoot: {
    height: "30px",
    margin: "0px 4px 0px 4px",
    background: theme.palette.text.hint,
  },
  button: {
    margin: theme.spacing(0.5, 0.5),
  },
  filterOptionBtn: {
    // color: theme.palette.primary.contrastTextLight,
    fontSize: "0.625rem",
    maxWidth: "140px",
    marginRight: "5px",
  },
  cancelBtn: {
    color: theme.palette.primary.contrastTextLight + " !important",
    fontSize: "11px",
    maxWidth: "140px",
  },
}));

// const StyledToggleButtonGroup = withStyles((theme) => ({
//     grouped: {
//         margin: '0px',
//         border: 'none',
//         // '&:not(:first-child)': {
//         //     borderRadius: 0,//theme.shape.borderRadius,
//         // },
//         // '&:first-child': {
//         //     borderRadius: 0,//theme.shape.borderRadius,
//         // },
//     },
// }))(ToggleButtonGroup);

export default function FilterPanel({
  elems,
  inSelect,
  inFocus,
  setSelectionHandler,
  setFocusHandler,
  setShowViewHandler,
}) {
  const [viewSelect, setViewSelect] = React.useState("list");
  const handleViewSelect = (event, newView) => {
    setViewSelect(newView);
  };

  const [isDocOpen, setIsDocOpen] = useState(false);

  const sortStrategies = ["byId", "byAlpha"];
  const [sortStrat, setSortStrat] = useState("byId");
  const [sortOrder, setSortOrder] = useState("ASC");

  const toggleSortStrat = () => {
    let currIdx = sortStrategies.indexOf(sortStrat) + 1;
    if (currIdx >= sortStrategies.length) currIdx = 0;
    setSortStrat(sortStrategies[currIdx]);
  };

  const toggleSortOrder = () => {
    setSortOrder((prev) => {
      return prev === "ASC" ? "DEC" : "ASC";
    });
  };

  const [listOptions, setListOptions] = useState({
    compactView: true,
    sortAlpha: true,
  });

  const handleListOptionChange = (name) => {
    console.log(name, listOptions[name]);
    const st = {
      compactView:
        name === "compactView"
          ? !listOptions.compactView
          : listOptions.compactView,
      sortAlpha:
        name === "sortAlpha" ? !listOptions.sortAlpha : listOptions.sortAlpha,
    };
    setListOptions(st);
  };

  const classes = useStyles();
  return (
    <div className={classes.filterPanelRoot}>
      <Paper
        name="list-options"
        elevation={0}
        square
        className={classes.paperRoot}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          flexShrink={0}
          flexGrow={0}
        >
          {/* <StyledToggleButtonGroup
                        value={viewSelect}
                        size="small"
                        exclusive
                        onChange={handleViewSelect}
                        aria-label="select view"
                    >
                        <ToggleButton color="primary" value="list" aria-label="list view">
                            <Tooltip title="list tab" aria-label="list selection">
                                <CalendarViewDayIcon className={classes.button} />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton color="primary" value="filter" aria-label="filter view">
                            <Tooltip title="attribute tab" aria-label="list attributes">
                                <TuneSharpIcon className={classes.button} />
                            </Tooltip>
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                    <Divider orientation="vertical" className={classes.dividerRoot} /> */}
          {
            // filter view options
            viewSelect === "list" ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
              >
                {/* <Tooltip title={listOptions.compactView ? "expand acts" : "compress acts"} aria-label="list compact">
                                    <IconButton
                                        color={listOptions.compactView ? "primary" : "default"}
                                        aria-label="compact view"
                                        onClick={() => handleListOptionChange("compactView")}
                                    >
                                        {
                                            listOptions.compactView
                                                ? <FormatLineSpacingIcon />
                                                : <VerticalAlignCenterIcon />
                                        }
                                    </IconButton>
                                </Tooltip>
                                <Divider orientation="vertical" className={classes.dividerRoot} />
                                <Tooltip title={listOptions.sortAlpha ? "sort ascending" : "sort descending"} aria-label="list sort">
                                    <IconButton
                                        color={listOptions.sortAlpha ? "primary" : "default"}
                                        aria-label="sort aplhabetically"
                                        onClick={() => handleListOptionChange("sortAlpha")}
                                    >
                                        <SortByAlphaIcon />
                                    </IconButton>
                                </Tooltip> */}
                <Tooltip
                  title={
                    sortStrat === "byId"
                      ? "sorted by Act Index"
                      : sortStrat === "byAlpha"
                      ? "sorted by Act Name"
                      : "sorted by relevance"
                  }
                  aria-label="sort strategy"
                >
                  <IconButton
                    color="primary"
                    aria-label="sort strategy"
                    onClick={() => toggleSortStrat()}
                  >
                    {sortStrat === "byId" ? (
                      <FormatListNumberedIcon />
                    ) : sortStrat === "byAlpha" ? (
                      <SortByAlphaIcon />
                    ) : (
                      <FilterListIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    sortOrder === "ASC"
                      ? "in descending order"
                      : "in ascending order"
                  }
                  aria-label="sort strategy"
                >
                  <IconButton
                    color="primary"
                    aria-label="sort order"
                    onClick={() => toggleSortOrder()}
                  >
                    {sortOrder === "ASC" ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Tooltip>
                {/* <Divider orientation="vertical" className={classes.dividerRoot} /> */}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
              >
                <IconButton
                  color={listOptions.compactView ? "primary" : "default"}
                  aria-label="compact view"
                  onClick={() => handleListOptionChange("compactView")}
                >
                  <LowPriorityIcon />
                </IconButton>
              </Box>
            )
          }
          {
            // selection related
            isDocOpen ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                flexShrink={0}
                flexGrow={1}
              >
                <Tooltip title={"Reset Selection"} aria-label="Reset Selection">
                  <Chip
                    // variant="outlined"
                    className={classes.filterOptionBtn}
                    size="medium"
                    label={"close summary"}
                    clickable
                    color="secondary"
                    onClick={() => setIsDocOpen(false)}
                    onDelete={() => setIsDocOpen(false)}
                    deleteIcon={<CancelIcon className={classes.cancelBtn} />}
                  />
                </Tooltip>
              </Box>
            ) : inSelect.ids.length !== 0 ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                flexShrink={0}
                flexGrow={1}
              >
                <Tooltip
                  title={"Reset Selection / Filters"}
                  aria-label="Reset Selection"
                >
                  <Chip
                    className={classes.filterOptionBtn}
                    size="medium"
                    label={"deselect " + inSelect.ids.length + " Acts"}
                    clickable
                    color="secondary"
                    onClick={() =>
                      setSelectionHandler([], "filter-panel-reset")
                    }
                    onDelete={() =>
                      setSelectionHandler([], "filter-panel-reset")
                    }
                    deleteIcon={<CancelIcon className={classes.cancelBtn} />}
                  />
                </Tooltip>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                flexShrink={0}
                flexGrow={1}
              >
                {/* <Tooltip title={"Nothing in selection"} aria-label="Reset Selection"> */}
                <Chip
                  variant="outlined"
                  className={classes.filterOptionBtn}
                  size="medium"
                  label={"showing " + _.keys(elems).length + " Acts"}
                  color="primary"
                  // deleteIcon={<CancelIcon className={classes.cancelBtn} />}
                />
                {/* </Tooltip> */}
              </Box>
            )
          }
        </Box>
      </Paper>
      {viewSelect === "list" ? (
        <ListView
          elems={elems}
          listOptions={listOptions}
          sortOrder={sortOrder}
          sortStrat={sortStrat}
          inSelect={inSelect}
          inFocus={inFocus}
          isDocOpen={isDocOpen}
          setIsDocOpen={setIsDocOpen}
          setFocusHandler={setFocusHandler}
          setSelectionHandler={setSelectionHandler}
          setShowViewHandler={setShowViewHandler}
        />
      ) : (
        <AttrView
          inSelect={inSelect}
          inFocus={inFocus}
          setFocusHandler={setFocusHandler}
          setSelectionHandler={setSelectionHandler}
          setShowViewHandler={setShowViewHandler}
        />
      )}
    </div>
  );
}
