import React, { useState } from "react";
import { Marker } from "react-map-gl";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import * as d3 from "d3";

const useStyles = makeStyles((theme) => ({
  text: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    maxWidth: "10px",
    textAlign: "center",
    fontSize: "12px",
    textShadow: `-1px -1px 0.25px ${theme.palette.background.paperDark},
                    1px -1px 0.25px ${theme.palette.background.paperDark},
                    -1px 1px 0.25px ${theme.palette.background.paperDark},
                    1px 1px 0.25px ${theme.palette.background.paperDark},
                    -1px 0px 0.25px ${theme.palette.background.paperDark},
                    0px -1px 0.25px ${theme.palette.background.paperDark},
                    1px 0px 0.25px ${theme.palette.background.paperDark},
                    0px -1px 0.25px ${theme.palette.background.paperDark}`,
    // opacity: 0,
    animation: `$txtFadeIn 1000ms`,
    transition: "all 500ms",
  },
  textSmall: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    maxWidth: "10px",
    textAlign: "center",
    fontSize: "9px",
    textShadow: `-1px -1px 0.25px ${theme.palette.background.paperDark},
                    1px -1px 0.25px ${theme.palette.background.paperDark},
                    -1px 1px 0.25px ${theme.palette.background.paperDark},
                    1px 1px 0.25px ${theme.palette.background.paperDark},
                    -1px 0px 0.25px ${theme.palette.background.paperDark},
                    0px -1px 0.25px ${theme.palette.background.paperDark},
                    1px 0px 0.25px ${theme.palette.background.paperDark},
                    0px -1px 0.25px ${theme.palette.background.paperDark}`,
    animation: `$txtFadeIn 1000ms`,
    transition: "all 500ms",
  },
  textGroup: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
    padding: "2px 5px",
    borderRadius: "5px",
    maxWidth: "250px",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    fontSize: "8px",
    // textShadow: `-1px -1px 0.25px ${theme.palette.primary.main},
    //             1px -1px 0.25px ${theme.palette.primary.main},
    //             -1px 1px 0.25px ${theme.palette.primary.main},
    //             1px 1px 0.25px ${theme.palette.primary.main},
    //             -1px 0px 0.25px ${theme.palette.primary.main},
    //             0px -1px 0.25px ${theme.palette.primary.main},
    //             1px 0px 0.25px ${theme.palette.primary.main},
    //             0px -1px 0.25px ${theme.palette.primary.main}`,
    animation: `$txtFadeIn 1000ms`,
    transition: "font-size 300ms",
    "&:hover": {
      whiteSpace: "normal",
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
    },
  },

  "@keyframes txtFadeIn": {
    "0%": {
      opacity: 0,
    },

    "100%": {
      opacity: 1,
    },
  },

  tooltipRoot: {
    color: theme.palette.primary.contrastTextLight,
    position: "absolute",
    textAlign: "left",
    width: "auto",
    height: "auto",
    padding: "5px",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "0.875rem",
    background: theme.palette.primary.main,
    border: "0px",
    borderRadius: "4px",
    pointerEvents: "none",
    display: "flex",
    flexDirection: "column",
    lineHeight: "0.825rem",
    opacity: 0.8,
  },

  tooltipSub: {
    fontSize: "0.625rem",
  },
  tooltipSubLight: {
    fontSize: "0.625rem",
    fontWeight: 300,
  },
}));

// PureComponent ensures that the markers are only rerendered when data changes
export default function MapMarker({
  data,
  selData,
  inFocusArr,
  setSelectionHandler,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [tooltipShow, setTooltipShow] = useState(false);

  //   console.log("data", data);
  //   console.log("seldata", selData);
  // console.log('inFocusArr', inFocusArr)

  let maxCount = -1;
  _.forEach(selData, function (sel, stateName) {
    if (sel.length > maxCount) maxCount = sel.length;
  });
  //   console.log('maxCount', maxCount)

  const fontScale = d3.scaleLinear().domain([1, maxCount]).range([9, 18]);

  const clickHandler = (e, ids) => {
    // console.log(e)
    setSelectionHandler(ids, "geo-view", "reset");
  };

  // const _renderTooltip = () => {
  //     const { hoverInfo, x, y } = UIObj;
  //     // console.log(hoverInfo, x, y)
  //     let val = 0
  //     let actCount = 0
  //     let stateName = null
  //     let districtName = null

  //     if (hoverInfo) {
  //         console.log(hoverInfo)
  //         if (geoResolution === 'district') {
  //             districtName = hoverInfo.district
  //             // const st = getStateFromDistrict(hoverInfo.district)
  //             // // console.log(districtValues)
  //             // stateName = st['stateName']
  //             // const dsObj = _.find(districtValues, function (dobj) { return dobj['districtName'] === districtName })
  //             // val = dsObj ? dsObj['val'] : 0
  //             // actCount = dsObj ? dsObj['actCount'] : 0
  //             val = hoverInfo.val
  //             actCount = hoverInfo.actCount
  //             stateName = hoverInfo.state
  //             // console.log(dsObj, hoverInfo)
  //         } else {
  //             //state
  //             val = hoverInfo.val
  //             actCount = hoverInfo.actCount
  //             stateName = hoverInfo.state
  //         }
  //     }

  //     return (
  //         hoverInfo ? (
  //             <div className={classes.tooltipRoot} style={{ left: x + 10, top: y + 10 }}>
  //                 <div className={classes.tooltipSub}>
  //                     {stateName && districtName ? districtName + ' in ' : null}{stateName}
  //                 </div>
  //                 { val !== null && getTooltipText(val)}
  //                 < div className={classes.tooltipSubLight}>
  //                     {actCount
  //                         ? inSelect.ids.length > 0 ? (actCount < inSelect.ids.length ? `subselect ${actCount} of ${inSelect.ids.length} acts` : `${actCount} acts listed`) : `click to select ${actCount} acts`
  //                         : inSelect.ids.length > 0 ? `click to deselect` : `no applicable Acts`
  //                     }
  //                 </div>
  //             </div >
  //         ) : (inSelect.ids.length > 0) ? (
  //             <div className={classes.tooltipRoot} style={{ left: x + 10, top: y + 10 }}>
  //                 <div className={classes.tooltipSubLight}>
  //                     click to deselect
  //                     </div>
  //             </div>
  //         ) : null
  //     );
  // }

  const onMouseEnterHandler = (event, entityName, type) => {
    if (type === "group") {
      // console.log(event)
      const x = event.clientX;
      const y = event.clientY;
      const acts = selData[entityName];
      // console.log(entityName, acts);
      setTooltipShow([true, x, y]);
    } else {
      setTooltipShow([false, 0, 0]);
    }
  };

  const onMouseLeaveHandler = (event) => {
    setTooltipShow([false, 0, 0]);
  };

  const onMouseMoveHandler = (event) => {
    // console.log(event)
    setTooltipShow((prev) => [prev[0], event.clientX, event.clientY]);
  };

  const _renderTooltip = (entityName) => {
    if (tooltipShow[0]) {
      return (
        <div
          className={classes.tooltipRoot}
          style={{ left: tooltipShow[1] + 10 - 380, top: tooltipShow[2] + 10 }}
        >
          <div className={classes.tooltipSub}>{entityName}</div>
          {selData[entityName].length} Acts
          <div className={classes.tooltipSubLight}>
            {/* click to deselect : {tooltipShow[1] + 10}, {tooltipShow[2] + 10} */}
            {entityName}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return data.features.map((state, idx) => {
    // console.log(idx, state.properties.description, state.properties.type);
    const isInFocus =
      inFocusArr && inFocusArr.includes(state.properties.description);
    return _.keys(selData).includes(state.properties.description) ? (
      <Marker
        key={`marker-${idx}`}
        longitude={state.geometry.coordinates[0]}
        latitude={state.geometry.coordinates[1]}
      >
        <div
          className={
            state.properties.type === "UT"
              ? classes.textSmall
              : state.properties.type === "group"
              ? classes.textGroup
              : classes.text
          }
          style={{
            fontSize: fontScale(selData[state.properties.description].length),
            border:
              isInFocus && state.properties.type === "group"
                ? `2px solid ${theme.palette.secondary.main}`
                : null,
            color: isInFocus
              ? theme.palette.secondary.main
              : theme.palette.primary.main,
          }}
          onClick={(e) =>
            clickHandler(e, selData[state.properties.description])
          }
          // onMouseEnter={(e) => onMouseEnterHandler(e, state.properties.description, state.properties.type)}
          // onMouseLeave={(e) => onMouseLeaveHandler(e)}
          // onMouseMove={(e) => onMouseMoveHandler(e)}
          // onMouseEnter={(e) => console.log(e.clientX)}
        >
          {state.properties.description}
          {/* {tooltipShow[0] ? <div>hello</div> : null} */}
        </div>
        {/* {state.properties.type === 'group' && _renderTooltip(state.properties.description)} */}
      </Marker>
    ) : null;
  });
}
