import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    logoCard: {
        zIndex: 2000,
        cursor: 'pointer',
        width: '40px',
        height: '40px',
        margin: '4px 10px',
        background: 'none',
        borderRadius: '20px',
        backgroundImage: "url(img/logo.svg)",
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxShadow: '3px 5px 10px -5px rgba(99, 255, 214, 0.2)',
        transition: 'box-shadow 0.5s',
        willChange: 'transform',
        '&:hover': {
            border: '1px solid ' + theme.palette.secondary.main,
            boxShadow: '3px 5px 10px -5px ' + theme.palette.text.primary
        }
    }
}))

const calc = (x, y) => [-(y - window.innerHeight / 2) / 35, (window.innerWidth - x / 2) / 35, 1.2]
// const trans = (x, y, s) => `perspective(90px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`
const trans = (x, y, s) => `perspective(90px) rotateX(${0}deg) rotateY(${0}deg) scale(${s})`

export default function LogoCard({ isOpen, handleOpen }) {
    const classes = useStyles();
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 50, tension: 350, friction: 50 } }))
    return (
        <Tooltip
            title={isOpen ? "go to map" : "about"}
            TransitionComponent={Zoom}
            placement="left"
            aria-label="About"
        >
            <animated.div
                name="show-about"
                className={classes.logoCard}
                onClick={handleOpen}
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }}
            />
        </Tooltip>
    )
}