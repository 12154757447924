import * as _ from 'lodash'
// import Papa from 'papaparse'

// //"district-code-list.csv //state-code-list-excel.csv"
// Papa.parse("district-code-list.csv", {
//     download: true,
//     header: true,
//     dynamicTyping: true,
//     complete: function (results) {
//         // districtForestCover = results;
//         console.log(results);
//     }
// });

export const getStateObj = (stateName) => {
    let stateObj = _.find(stateData, { 'state_ut_name': stateName })
    if (stateObj) {
        return { error: null, result: stateObj }
    } else {
        let stateObjByAlias = _.find(stateData, { 'state_ut_alias': stateName })
        if (stateObjByAlias) {
            return { error: null, result: stateObjByAlias }
        } else {
            return { error: 'no state found for ' + stateName, result: null }
        }
    }
}

export const getStateId = (stateName) => {
    let stateObj = _.find(stateData, { 'state_ut_name': stateName })
    if (stateObj) {
        return { error: null, result: stateObj['state_ut_index'] }
    } else {
        let stateObjByAlias = _.find(stateData, { 'state_ut_alias': stateName })
        if (stateObjByAlias) {
            return { error: null, result: stateObjByAlias['state_ut_index'] }
        } else {
            return { error: 'no state found for ' + stateName, result: null }
        }
    }
}


export const getDistrictObjArrFromState = (stateName) => {
    let districtObjs = _.filter(districtData, { 'State': stateName })
    // console.log(stateObjs)
    if (districtObjs.length > 0) {
        return { error: null, result: districtObjs }
    } else {
        return { error: 'no districts found for ' + stateName, result: null }
    }
}

export const getDistrictIdArrFromState = (stateName) => {
    let stateObjs = _.filter(districtData, { 'State': stateName })
    // console.log(stateObjs)
    if (stateObjs.length > 0) {
        let districtCodes = []
        _.forEach(stateObjs, (obj) => {
            obj['District Code'] && districtCodes.push(obj['District Code'])
        })
        // console.log(districtCodes)
        return { error: null, result: districtCodes }
    } else {
        return { error: 'no districts found for ' + stateName, result: null }
    }
}

export const getDistrictIdArrFromStateId = (stateId) => {
    let stateObjs = _.filter(districtData, { 'State ID': stateId })
    // console.log(stateObjs)
    if (stateObjs.length > 0) {
        let districtCodes = []
        _.forEach(stateObjs, (obj) => {
            obj['District Code'] && districtCodes.push(obj['District Code'])
        })
        // console.log(districtCodes)
        return { error: null, result: districtCodes }
    } else {
        return { error: 'no districts found for ' + stateId, result: null }
    }
}
export const getStateFromDistrict = (districtName) => {
    let districtObj = _.find(districtData, function (dobj) { return dobj['District'] && dobj['District'].includes(districtName) })
    if (districtObj) {
        return { error: null, stateName: districtObj['State'], stateId: districtObj['State ID'] }
    } else {
        return { error: 'no state found for ' + districtName, stateName: null, stateId: null }
    }
}

export const stateData = [
    {
        "state_ut_index": 1,
        "state_ut_name": "Andaman and Nicobar Islands",
        "state_ut_alias": null,
        "state_code": 35,
        "state_abbrev": "AN",
        "geo_area_sq_km": 8249,
        "recorded_forest_area_2019": 7171,
        "recorded_forest_frac": 0.8693,
        "dams_count_2019": 2,
        "dams_count_norm_2019": 0.000348129,
        "dams_completed_2019": 2,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 2,
        "state_ut_name": "Andhra Pradesh",
        "state_ut_alias": null,
        "state_code": 28,
        "state_abbrev": "AP",
        "geo_area_sq_km": 162968,
        "recorded_forest_area_2019": 37258,
        "recorded_forest_frac": 0.2286,
        "dams_count_2019": 166,
        "dams_count_norm_2019": 0.028894691,
        "dams_completed_2019": 149,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 15274,
        "mineral_prod_norm_2015_16": 0.068955865
    },
    {
        "state_ut_index": 3,
        "state_ut_name": "Arunachal Pradesh",
        "state_ut_alias": null,
        "state_code": 12,
        "state_abbrev": "AR",
        "geo_area_sq_km": 83743,
        "recorded_forest_area_2019": 51407,
        "recorded_forest_frac": 0.6139,
        "dams_count_2019": 4,
        "dams_count_norm_2019": 0.000696258,
        "dams_completed_2019": 1,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 4,
        "state_ut_name": "Assam",
        "state_ut_alias": null,
        "state_code": 18,
        "state_abbrev": "AS",
        "geo_area_sq_km": 78438,
        "recorded_forest_area_2019": 26832,
        "recorded_forest_frac": 0.3421,
        "dams_count_2019": 4,
        "dams_count_norm_2019": 0.000696258,
        "dams_completed_2019": 3,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": 43197,
        "mineral_prod_norm_2015_16": 0.195016794
    },
    {
        "state_ut_index": 5,
        "state_ut_name": "Bihar",
        "state_ut_alias": null,
        "state_code": 10,
        "state_abbrev": "BH",
        "geo_area_sq_km": 94163,
        "recorded_forest_area_2019": 6877,
        "recorded_forest_frac": 0.073,
        "dams_count_2019": 26,
        "dams_count_norm_2019": 0.004525674,
        "dams_completed_2019": 24,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 6,
        "state_ut_name": "Chandigarh",
        "state_ut_alias": null,
        "state_code": 4,
        "state_abbrev": "CH",
        "geo_area_sq_km": 114,
        "recorded_forest_area_2019": 35,
        "recorded_forest_frac": 0.307,
        "dams_count_2019": 0,
        "dams_count_norm_2019": 0,
        "dams_completed_2019": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 7,
        "state_ut_name": "Chhattisgarh",
        "state_ut_alias": null,
        "state_code": 22,
        "state_abbrev": "CT",
        "geo_area_sq_km": 135192,
        "recorded_forest_area_2019": 59772,
        "recorded_forest_frac": 0.4421,
        "dams_count_2019": 258,
        "dams_count_norm_2019": 0.044908616,
        "dams_completed_2019": 249,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 19926,
        "mineral_prod_norm_2015_16": 0.089957743
    },
    {
        "state_ut_index": 8,
        "state_ut_name": "Dadra and Nagar Haveli",
        "state_ut_alias": "Dadra and Nagar",
        "state_code": 26,
        "state_abbrev": "DN",
        "geo_area_sq_km": 491,
        "recorded_forest_area_2019": 204,
        "recorded_forest_frac": 0.4155,
        "dams_count_2019": 0,
        "dams_count_norm_2019": 0,
        "dams_completed_2019": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 9,
        "state_ut_name": "Daman and Diu",
        "state_ut_alias": null,
        "state_code": 25,
        "state_abbrev": "DD",
        "geo_area_sq_km": 111,
        "recorded_forest_area_2019": 8,
        "recorded_forest_frac": 0.0721,
        "dams_count_2019": 0,
        "dams_count_norm_2019": 0,
        "dams_completed_2019": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 10,
        "state_ut_name": "Delhi",
        "state_ut_alias": null,
        "state_code": 7,
        "state_abbrev": "DL",
        "geo_area_sq_km": 1483,
        "recorded_forest_area_2019": 102,
        "recorded_forest_frac": 0.0688,
        "dams_count_2019": 0,
        "dams_count_norm_2019": 0,
        "dams_completed_2019": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 11,
        "state_ut_name": "Goa",
        "state_ut_alias": null,
        "state_code": 30,
        "state_abbrev": "GA",
        "geo_area_sq_km": 3702,
        "recorded_forest_area_2019": 1225,
        "recorded_forest_frac": 0.3309,
        "dams_count_2019": 5,
        "dams_count_norm_2019": 0.000870322,
        "dams_completed_2019": 5,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 12,
        "state_ut_name": "Gujarat",
        "state_ut_alias": null,
        "state_code": 24,
        "state_abbrev": "GJ",
        "geo_area_sq_km": 196244,
        "recorded_forest_area_2019": 21647,
        "recorded_forest_frac": 0.1103,
        "dams_count_2019": 632,
        "dams_count_norm_2019": 0.110008703,
        "dams_completed_2019": 620,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 17899,
        "mineral_prod_norm_2015_16": 0.080806667
    },
    {
        "state_ut_index": 13,
        "state_ut_name": "Haryana",
        "state_ut_alias": null,
        "state_code": 6,
        "state_abbrev": "HR",
        "geo_area_sq_km": 44212,
        "recorded_forest_area_2019": 1559,
        "recorded_forest_frac": 0.0353,
        "dams_count_2019": 1,
        "dams_count_norm_2019": 0.000174064,
        "dams_completed_2019": 1,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 14,
        "state_ut_name": "Himachal Pradesh",
        "state_ut_alias": null,
        "state_code": 2,
        "state_abbrev": "HP",
        "geo_area_sq_km": 55673,
        "recorded_forest_area_2019": 37033,
        "recorded_forest_frac": 0.6652,
        "dams_count_2019": 20,
        "dams_count_norm_2019": 0.003481288,
        "dams_completed_2019": 19,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 210,
        "mineral_prod_norm_2015_16": 0.000948064
    },
    {
        "state_ut_index": 15,
        "state_ut_name": "Jammu and Kashmir",
        "state_ut_alias": null,
        "state_code": 1,
        "state_abbrev": "JK",
        "geo_area_sq_km": 222236,
        "recorded_forest_area_2019": 20230,
        "recorded_forest_frac": 0.091,
        "dams_count_2019": 17,
        "dams_count_norm_2019": 0.002959095,
        "dams_completed_2019": 15,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 16,
        "state_ut_name": "Jharkhand",
        "state_ut_alias": null,
        "state_code": 20,
        "state_abbrev": "JH",
        "geo_area_sq_km": 79716,
        "recorded_forest_area_2019": 23605,
        "recorded_forest_frac": 0.2961,
        "dams_count_2019": 79,
        "dams_count_norm_2019": 0.013751088,
        "dams_completed_2019": 55,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 20710,
        "mineral_prod_norm_2015_16": 0.093497183
    },
    {
        "state_ut_index": 17,
        "state_ut_name": "Karnataka",
        "state_ut_alias": null,
        "state_code": 29,
        "state_abbrev": "KA",
        "geo_area_sq_km": 191791,
        "recorded_forest_area_2019": 38284,
        "recorded_forest_frac": 0.1996,
        "dams_count_2019": 232,
        "dams_count_norm_2019": 0.040382942,
        "dams_completed_2019": 230,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 18,
        "state_ut_name": "Kerala",
        "state_ut_alias": null,
        "state_code": 32,
        "state_abbrev": "KL",
        "geo_area_sq_km": 38852,
        "recorded_forest_area_2019": 11309,
        "recorded_forest_frac": 0.2911,
        "dams_count_2019": 61,
        "dams_count_norm_2019": 0.010617929,
        "dams_completed_2019": 61,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 19,
        "state_ut_name": "Lakshadweep Islands",
        "state_ut_alias": "Lakshadweep",
        "state_code": 31,
        "state_abbrev": "LD",
        "geo_area_sq_km": 30,
        "recorded_forest_area_2019": 0,
        "recorded_forest_frac": 0,
        "dams_count_2019": 0,
        "dams_count_norm_2019": 0,
        "dams_completed_2019": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 20,
        "state_ut_name": "Madhya Pradesh",
        "state_ut_alias": null,
        "state_code": 23,
        "state_abbrev": "MP",
        "geo_area_sq_km": 308252,
        "recorded_forest_area_2019": 94689,
        "recorded_forest_frac": 0.3072,
        "dams_count_2019": 906,
        "dams_count_norm_2019": 0.15770235,
        "dams_completed_2019": 899,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 16938,
        "mineral_prod_norm_2015_16": 0.076468145
    },
    {
        "state_ut_index": 21,
        "state_ut_name": "Maharashtra",
        "state_ut_alias": null,
        "state_code": 27,
        "state_abbrev": "MH",
        "geo_area_sq_km": 307713,
        "recorded_forest_area_2019": 61579,
        "recorded_forest_frac": 0.2001,
        "dams_count_2019": 2394,
        "dams_count_norm_2019": 0.416710183,
        "dams_completed_2019": 2117,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 8584,
        "mineral_prod_norm_2015_16": 0.038753251
    },
    {
        "state_ut_index": 22,
        "state_ut_name": "Manipur",
        "state_ut_alias": null,
        "state_code": 14,
        "state_abbrev": "MN",
        "geo_area_sq_km": 22327,
        "recorded_forest_area_2019": 17418,
        "recorded_forest_frac": 0.7801,
        "dams_count_2019": 4,
        "dams_count_norm_2019": 0.000696258,
        "dams_completed_2019": 3,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 23,
        "state_ut_name": "Meghalaya",
        "state_ut_alias": null,
        "state_code": 17,
        "state_abbrev": "ME",
        "geo_area_sq_km": 22429,
        "recorded_forest_area_2019": 9496,
        "recorded_forest_frac": 0.4234,
        "dams_count_2019": 10,
        "dams_count_norm_2019": 0.001740644,
        "dams_completed_2019": 8,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 24,
        "state_ut_name": "Mizoram",
        "state_ut_alias": null,
        "state_code": 15,
        "state_abbrev": "MI",
        "geo_area_sq_km": 21081,
        "recorded_forest_area_2019": 5641,
        "recorded_forest_frac": 0.2676,
        "dams_count_2019": 1,
        "dams_count_norm_2019": 0.000174064,
        "dams_completed_2019": 1,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 25,
        "state_ut_name": "Nagaland",
        "state_ut_alias": null,
        "state_code": 13,
        "state_abbrev": "NL",
        "geo_area_sq_km": 16579,
        "recorded_forest_area_2019": 8623,
        "recorded_forest_frac": 0.5201,
        "dams_count_2019": 1,
        "dams_count_norm_2019": 0.000174064,
        "dams_completed_2019": 1,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 26,
        "state_ut_name": "Odisha",
        "state_ut_alias": "Orissa",
        "state_code": 21,
        "state_abbrev": "OR",
        "geo_area_sq_km": 155707,
        "recorded_forest_area_2019": 61204,
        "recorded_forest_frac": 0.3931,
        "dams_count_2019": 204,
        "dams_count_norm_2019": 0.035509138,
        "dams_completed_2019": 200,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 32409,
        "mineral_prod_norm_2015_16": 0.146313385
    },
    {
        "state_ut_index": 27,
        "state_ut_name": "Pondicherry",
        "state_ut_alias": "Puducherry",
        "state_code": 34,
        "state_abbrev": "PY",
        "geo_area_sq_km": 490,
        "recorded_forest_area_2019": 13,
        "recorded_forest_frac": 0.0265,
        "dams_count_2019": 0,
        "dams_count_norm_2019": 0,
        "dams_completed_2019": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 28,
        "state_ut_name": "Punjab",
        "state_ut_alias": null,
        "state_code": 3,
        "state_abbrev": "PB",
        "geo_area_sq_km": 50362,
        "recorded_forest_area_2019": 3084,
        "recorded_forest_frac": 0.0612,
        "dams_count_2019": 16,
        "dams_count_norm_2019": 0.00278503,
        "dams_completed_2019": 14,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 29,
        "state_ut_name": "Rajasthan",
        "state_ut_alias": null,
        "state_code": 8,
        "state_abbrev": "RJ",
        "geo_area_sq_km": 342239,
        "recorded_forest_area_2019": 32737,
        "recorded_forest_frac": 0.0957,
        "dams_count_2019": 212,
        "dams_count_norm_2019": 0.036901654,
        "dams_completed_2019": 204,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 33229,
        "mineral_prod_norm_2015_16": 0.15001535
    },
    {
        "state_ut_index": 30,
        "state_ut_name": "Sikkim",
        "state_ut_alias": null,
        "state_code": 11,
        "state_abbrev": "SK",
        "geo_area_sq_km": 7096,
        "recorded_forest_area_2019": 5841,
        "recorded_forest_frac": 0.8231,
        "dams_count_2019": 2,
        "dams_count_norm_2019": 0.000348129,
        "dams_completed_2019": 2,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 31,
        "state_ut_name": "Tamil Nadu",
        "state_ut_alias": null,
        "state_code": 33,
        "state_abbrev": "TN",
        "geo_area_sq_km": 130060,
        "recorded_forest_area_2019": 22877,
        "recorded_forest_frac": 0.1759,
        "dams_count_2019": 118,
        "dams_count_norm_2019": 0.0205396,
        "dams_completed_2019": 118,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 32,
        "state_ut_name": "Tripura",
        "state_ut_alias": null,
        "state_code": 16,
        "state_abbrev": "TR",
        "geo_area_sq_km": 10486,
        "recorded_forest_area_2019": 6294,
        "recorded_forest_frac": 0.6002,
        "dams_count_2019": 1,
        "dams_count_norm_2019": 0.000174064,
        "dams_completed_2019": 1,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 33,
        "state_ut_name": "Uttar Pradesh",
        "state_ut_alias": null,
        "state_code": 9,
        "state_abbrev": "UP",
        "geo_area_sq_km": 240928,
        "recorded_forest_area_2019": 16582,
        "recorded_forest_frac": 0.0688,
        "dams_count_2019": 130,
        "dams_count_norm_2019": 0.022628372,
        "dams_completed_2019": 117,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 34,
        "state_ut_name": "Uttarakhand",
        "state_ut_alias": "Uttaranchal",
        "state_code": 5,
        "state_abbrev": "UT",
        "geo_area_sq_km": 53483,
        "recorded_forest_area_2019": 38000,
        "recorded_forest_frac": 0.7105,
        "dams_count_2019": 25,
        "dams_count_norm_2019": 0.00435161,
        "dams_completed_2019": 17,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 35,
        "state_ut_name": "West Bengal",
        "state_ut_alias": null,
        "state_code": 19,
        "state_abbrev": "WB",
        "geo_area_sq_km": 88752,
        "recorded_forest_area_2019": 11879,
        "recorded_forest_frac": 0.1338,
        "dams_count_2019": 30,
        "dams_count_norm_2019": 0.005221932,
        "dams_completed_2019": 30,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0,
        "mineral_prod_crore_2015_16": null,
        "mineral_prod_norm_2015_16": 0
    },
    {
        "state_ut_index": 36,
        "state_ut_name": "Telangana",
        "state_ut_alias": null,
        "state_code": 36,
        "state_abbrev": "TS",
        "geo_area_sq_km": 112077,
        "recorded_forest_area_2019": 26904,
        "recorded_forest_frac": 0.24,
        "dams_count_2019": 184,
        "dams_count_norm_2019": 0.03202785,
        "dams_completed_2019": 168,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1,
        "mineral_prod_crore_2015_16": 13128,
        "mineral_prod_norm_2015_16": 0.059267553
    }
]
export const districtData = [
    {
        "District": "Adilabad",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 532,
        "Geographical Area": 16128,
        "Total Fr": 6045,
        "Forest_frac": 0.374813988,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Anantapur",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 553,
        "Geographical Area": 19130,
        "Total Fr": 686,
        "Forest_frac": 0.035859906,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chittoor",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 554,
        "Geographical Area": 15151,
        "Total Fr": 2571,
        "Forest_frac": 0.16969177,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kadapa",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 551,
        "Geographical Area": 15359,
        "Total Fr": 4097,
        "Forest_frac": 0.266749137,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "East Godavari",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 545,
        "Geographical Area": 10807,
        "Total Fr": 3547,
        "Forest_frac": 0.328213195,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Guntur",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 548,
        "Geographical Area": 11391,
        "Total Fr": 864,
        "Forest_frac": 0.075849355,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hyderabad",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 536,
        "Geographical Area": 7710,
        "Total Fr": 387,
        "Forest_frac": 0.050194553,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karimnagar",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 534,
        "Geographical Area": 11823,
        "Total Fr": 1669,
        "Forest_frac": 0.141165525,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Khammam",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 541,
        "Geographical Area": 16029,
        "Total Fr": 6753,
        "Forest_frac": 0.421298896,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Krishna",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 547,
        "Geographical Area": 8727,
        "Total Fr": 313,
        "Forest_frac": 0.035865704,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kurnool",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 552,
        "Geographical Area": 17658,
        "Total Fr": 2109,
        "Forest_frac": 0.11943595,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mahbubnagar",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 538,
        "Geographical Area": 18432,
        "Total Fr": 1942,
        "Forest_frac": 0.105360243,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Medak",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 535,
        "Geographical Area": 9700,
        "Total Fr": 580,
        "Forest_frac": 0.059793814,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nalgonda",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 539,
        "Geographical Area": 14240,
        "Total Fr": 154,
        "Forest_frac": 0.010814607,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sri Potti Sriramulu Nellore",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 550,
        "Geographical Area": 13076,
        "Total Fr": 1190,
        "Forest_frac": 0.091006424,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nizamabad",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 533,
        "Geographical Area": 7956,
        "Total Fr": 1190,
        "Forest_frac": 0.14957265,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Prakasam",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 549,
        "Geographical Area": 17626,
        "Total Fr": 3301,
        "Forest_frac": 0.187280154,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rangareddy",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 537,
        "Geographical Area": 5031,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Srikakulam",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 542,
        "Geographical Area": 5837,
        "Total Fr": 616,
        "Forest_frac": 0.105533665,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Vizianagaram",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 543,
        "Geographical Area": 6539,
        "Total Fr": 749,
        "Forest_frac": 0.114543508,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Visakhapatnam",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 544,
        "Geographical Area": 11161,
        "Total Fr": 3439,
        "Forest_frac": 0.308126512,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Warangal",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 540,
        "Geographical Area": 12847,
        "Total Fr": 3039,
        "Forest_frac": 0.236553281,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "West Godavari",
        "State": "Andhra Pradesh",
        "State Code": 28,
        "State ID": 2,
        "District Code": 546,
        "Geographical Area": 7742,
        "Total Fr": 875,
        "Forest_frac": 0.113019892,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Anjaw",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 260,
        "Geographical Area": 6190,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Changlang",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 253,
        "Geographical Area": 4662,
        "Total Fr": 4231,
        "Forest_frac": 0.907550408,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dibang Valley",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 257,
        "Geographical Area": 13029,
        "Total Fr": 9322,
        "Forest_frac": 0.71548085,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "East Kameng",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 247,
        "Geographical Area": 4134,
        "Total Fr": null,
        "Forest_frac": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kurung Kumey",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 256,
        "Geographical Area": 6040,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lohit",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 259,
        "Geographical Area": 11402,
        "Total Fr": 7611,
        "Forest_frac": 0.667514471,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lower Dibang Valley",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 258,
        "Geographical Area": 3900,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Popumpare",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 248,
        "Geographical Area": 3462,
        "Total Fr": 3245,
        "Forest_frac": 0.937319469,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "East Siang",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 251,
        "Geographical Area": 3655,
        "Total Fr": 2817,
        "Forest_frac": 0.770725034,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Upper Siang",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 252,
        "Geographical Area": 7050,
        "Total Fr": 5622,
        "Forest_frac": 0.797446809,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West Siang",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 250,
        "Geographical Area": 7813,
        "Total Fr": 6768,
        "Forest_frac": 0.86624856,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lower Subansiri",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 255,
        "Geographical Area": 9548,
        "Total Fr": 8676,
        "Forest_frac": 0.908671973,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Upper Subansiri",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 249,
        "Geographical Area": 7032,
        "Total Fr": 5814,
        "Forest_frac": 0.826791809,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tawang",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 245,
        "Geographical Area": 2172,
        "Total Fr": 1226,
        "Forest_frac": 0.564456722,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tirap",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 254,
        "Geographical Area": 2362,
        "Total Fr": 1744,
        "Forest_frac": 0.738357324,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West Kameng",
        "State": "Arunachal Pradesh",
        "State Code": 12,
        "State ID": 3,
        "District Code": 246,
        "Geographical Area": 7422,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Barpeta",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 303,
        "Geographical Area": 3245,
        "Total Fr": 395,
        "Forest_frac": 0.121725732,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Baksa",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 324,
        "Geographical Area": 2400,
        "Total Fr": 556,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bongaigaon",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 319,
        "Geographical Area": 2510,
        "Total Fr": 521,
        "Forest_frac": 0.207569721,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Cachar",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 316,
        "Geographical Area": 3786,
        "Total Fr": 2241,
        "Forest_frac": 0.591917591,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chirang",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 320,
        "Geographical Area": 1923,
        "Total Fr": 694,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Darrang",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 325,
        "Geographical Area": 3481,
        "Total Fr": 468,
        "Forest_frac": 0.134444125,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dima Hasao",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 315,
        "Geographical Area": 4888,
        "Total Fr": 4270,
        "Forest_frac": 0.873567921,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dhemaji",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 308,
        "Geographical Area": 3237,
        "Total Fr": 292,
        "Forest_frac": 0.090206982,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dhubri",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 301,
        "Geographical Area": 2798,
        "Total Fr": 412,
        "Forest_frac": 0.147248034,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dibrugarh",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 310,
        "Geographical Area": 3381,
        "Total Fr": 755,
        "Forest_frac": 0.223306714,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Goalpara",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 302,
        "Geographical Area": 1824,
        "Total Fr": 337,
        "Forest_frac": 0.184758772,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Golaghat",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 313,
        "Geographical Area": 3502,
        "Total Fr": 525,
        "Forest_frac": 0.149914335,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hailakandi",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 318,
        "Geographical Area": 1327,
        "Total Fr": 789,
        "Forest_frac": 0.594574228,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jorhat",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 312,
        "Geographical Area": 2851,
        "Total Fr": 609,
        "Forest_frac": 0.21360926,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kamrup",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 321,
        "Geographical Area": 4345,
        "Total Fr": 1429,
        "Forest_frac": 0.328883774,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kamrup Metropolitan",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 322,
        "Geographical Area": 955,
        "Total Fr": 460,
        "Forest_frac": 0.481675393,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karbi Anglong",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 314,
        "Geographical Area": 10434,
        "Total Fr": 7960,
        "Forest_frac": 0.76289055,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karimganj",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 317,
        "Geographical Area": 1809,
        "Total Fr": 864,
        "Forest_frac": 0.47761194,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kokrajhar",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 300,
        "Geographical Area": 3169,
        "Total Fr": 1120,
        "Forest_frac": 0.353423793,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lakhimpur",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 307,
        "Geographical Area": 2277,
        "Total Fr": 293,
        "Forest_frac": 0.128678085,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Marigaon",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 304,
        "Geographical Area": 1704,
        "Total Fr": 133,
        "Forest_frac": 0.078051643,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nagaon",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 305,
        "Geographical Area": 3831,
        "Total Fr": 794,
        "Forest_frac": 0.207256591,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nalbari",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 323,
        "Geographical Area": 2257,
        "Total Fr": 282,
        "Forest_frac": 0.124944617,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sivasagar",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 311,
        "Geographical Area": 2668,
        "Total Fr": 692,
        "Forest_frac": 0.259370315,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sonitpur",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 306,
        "Geographical Area": 5324,
        "Total Fr": 956,
        "Forest_frac": 0.179564237,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Udalguri",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 326,
        "Geographical Area": 2012,
        "Total Fr": 403,
        "Forest_frac": 0.200298211,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tinsukia",
        "State": "Assam",
        "State Code": 18,
        "State ID": 4,
        "District Code": 309,
        "Geographical Area": 3790,
        "Total Fr": 1534,
        "Forest_frac": 0.40474934,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Araria",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 209,
        "Geographical Area": 2830,
        "Total Fr": 157,
        "Forest_frac": 0.055477032,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Arwal",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 240,
        "Geographical Area": 638,
        "Total Fr": 5,
        "Forest_frac": 0.007836991,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Aurangabad",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 235,
        "Geographical Area": 3305,
        "Total Fr": 155,
        "Forest_frac": 0.046898638,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Banka",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 225,
        "Geographical Area": 3022,
        "Total Fr": 232,
        "Forest_frac": 0.076770351,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Begusarai",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 222,
        "Geographical Area": 1918,
        "Total Fr": 83,
        "Forest_frac": 0.043274244,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhabua",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 233,
        "Geographical Area": 3381,
        "Total Fr": 1103,
        "Forest_frac": 0.326234842,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhagalpur",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 224,
        "Geographical Area": 2567,
        "Total Fr": 64,
        "Forest_frac": 0.024931827,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhojpur",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 231,
        "Geographical Area": 2390,
        "Total Fr": 36,
        "Forest_frac": 0.015062762,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Buxar",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 232,
        "Geographical Area": 1708,
        "Total Fr": 10,
        "Forest_frac": 0.005854801,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Darbhanga",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 215,
        "Geographical Area": 2279,
        "Total Fr": 137,
        "Forest_frac": 0.060114085,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gaya",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 236,
        "Geographical Area": 4976,
        "Total Fr": 606,
        "Forest_frac": 0.121784566,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gopalganj",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 217,
        "Geographical Area": 2033,
        "Total Fr": 5,
        "Forest_frac": 0.00245942,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jamui",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 238,
        "Geographical Area": 3107,
        "Total Fr": 641,
        "Forest_frac": 0.206308336,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jehanabad",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 239,
        "Geographical Area": 1569,
        "Total Fr": 5,
        "Forest_frac": 0.003186743,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Katihar",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 212,
        "Geographical Area": 3057,
        "Total Fr": 61,
        "Forest_frac": 0.019954203,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Khagaria",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 223,
        "Geographical Area": 1486,
        "Total Fr": 21,
        "Forest_frac": 0.014131898,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kishanganj",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 210,
        "Geographical Area": 1884,
        "Total Fr": 101,
        "Forest_frac": 0.053609342,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lakhisarai",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 227,
        "Geographical Area": 1356,
        "Total Fr": 201,
        "Forest_frac": 0.148230088,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Madhepura",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 213,
        "Geographical Area": 1788,
        "Total Fr": 51,
        "Forest_frac": 0.02852349,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Madhubani",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 207,
        "Geographical Area": 3501,
        "Total Fr": 198,
        "Forest_frac": 0.05655527,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Munger",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 226,
        "Geographical Area": 1347,
        "Total Fr": 267,
        "Forest_frac": 0.198218263,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Muzaffarpur",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 216,
        "Geographical Area": 3172,
        "Total Fr": 143,
        "Forest_frac": 0.045081967,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nalanda",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 229,
        "Geographical Area": 2367,
        "Total Fr": 32,
        "Forest_frac": 0.013519223,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nawada",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 237,
        "Geographical Area": 2494,
        "Total Fr": 509,
        "Forest_frac": 0.204089816,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pashchim Champaran",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 203,
        "Geographical Area": 5228,
        "Total Fr": 898,
        "Forest_frac": 0.171767406,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Patna",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 230,
        "Geographical Area": 3202,
        "Total Fr": 25,
        "Forest_frac": 0.00780762,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Purba Champaran",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 204,
        "Geographical Area": 3968,
        "Total Fr": 156,
        "Forest_frac": 0.039314516,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Purnia",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 211,
        "Geographical Area": 3229,
        "Total Fr": 53,
        "Forest_frac": 0.01641375,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rohtas",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 234,
        "Geographical Area": 3832,
        "Total Fr": 704,
        "Forest_frac": 0.183716075,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Saharsa",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 214,
        "Geographical Area": 1680,
        "Total Fr": 32,
        "Forest_frac": 0.019047619,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Samastipur",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 221,
        "Geographical Area": 2904,
        "Total Fr": 156,
        "Forest_frac": 0.053719008,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Saran",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 219,
        "Geographical Area": 2641,
        "Total Fr": 55,
        "Forest_frac": 0.020825445,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sheikhpura",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 228,
        "Geographical Area": 612,
        "Total Fr": 1,
        "Forest_frac": 0.001633987,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sheohar",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 205,
        "Geographical Area": 572,
        "Total Fr": 29,
        "Forest_frac": 0.050699301,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sitamarhi",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 206,
        "Geographical Area": 2071,
        "Total Fr": 133,
        "Forest_frac": 0.064220183,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Siwan",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 218,
        "Geographical Area": 2219,
        "Total Fr": 7,
        "Forest_frac": 0.003154574,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Supaul",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 208,
        "Geographical Area": 2432,
        "Total Fr": 132,
        "Forest_frac": 0.054276316,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Vaishali",
        "State": "Bihar",
        "State Code": 10,
        "State ID": 5,
        "District Code": 220,
        "Geographical Area": 2036,
        "Total Fr": 92,
        "Forest_frac": 0.04518664,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bastar",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 414,
        "Geographical Area": 14974,
        "Total Fr": 7992,
        "Forest_frac": 0.533725124,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Bijapur",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 417,
        "Geographical Area": 6562,
        "Total Fr": 6507,
        "Forest_frac": 0.991618409,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bilaspur",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 406,
        "Geographical Area": 8270,
        "Total Fr": 2489,
        "Forest_frac": 0.300967352,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Dakshin Bastar Dantewada",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 416,
        "Geographical Area": 17634,
        "Total Fr": 11318,
        "Forest_frac": 0.641828286,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Dhamtari",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 412,
        "Geographical Area": 4084,
        "Total Fr": 1857,
        "Forest_frac": 0.454701273,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Durg",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 409,
        "Geographical Area": 8549,
        "Total Fr": 779,
        "Forest_frac": 0.091121769,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Janjgir-Champa",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 405,
        "Geographical Area": 3852,
        "Total Fr": 155,
        "Forest_frac": 0.040238837,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jashpur",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 402,
        "Geographical Area": 5838,
        "Total Fr": 2164,
        "Forest_frac": 0.370674889,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Uttar Bastar Kanker",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 413,
        "Geographical Area": 6506,
        "Total Fr": 3085,
        "Forest_frac": 0.474177682,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Kabeerdham",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 407,
        "Geographical Area": 4223,
        "Total Fr": 1579,
        "Forest_frac": 0.373904807,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Korba",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 404,
        "Geographical Area": 6599,
        "Total Fr": 3345,
        "Forest_frac": 0.506894984,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Koriya",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 400,
        "Geographical Area": 6604,
        "Total Fr": 4107,
        "Forest_frac": 0.621895821,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Mahasamund",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 411,
        "Geographical Area": 4789,
        "Total Fr": 958,
        "Forest_frac": 0.200041762,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Narayanpur",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 415,
        "Geographical Area": 6913,
        "Total Fr": 3802,
        "Forest_frac": 0.549978302,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Raigarh",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 403,
        "Geographical Area": 7086,
        "Total Fr": 2544,
        "Forest_frac": 0.359017782,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Raipur",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 410,
        "Geographical Area": 16468,
        "Total Fr": 5460,
        "Forest_frac": 0.331552101,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Rajnandgaon",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 408,
        "Geographical Area": 8068,
        "Total Fr": 2518,
        "Forest_frac": 0.312097174,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Surguja",
        "State": "Chhattisgarh",
        "State Code": 22,
        "State ID": 7,
        "District Code": 401,
        "Geographical Area": 15731,
        "Total Fr": 7128,
        "Forest_frac": 0.453118047,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Central",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 95,
        "Geographical Area": 25,
        "Total Fr": 5.05,
        "Forest_frac": 0.202,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "East Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 93,
        "Geographical Area": 64,
        "Total Fr": 3.05,
        "Forest_frac": 0.04765625,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North-East Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 92,
        "Geographical Area": 60,
        "Total Fr": 4.02,
        "Forest_frac": 0.067,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North-West Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 90,
        "Geographical Area": 440,
        "Total Fr": 16.5,
        "Forest_frac": 0.0375,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "New Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 94,
        "Geographical Area": 35,
        "Total Fr": 16.31,
        "Forest_frac": 0.466,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 91,
        "Geographical Area": 59,
        "Total Fr": 4.81,
        "Forest_frac": 0.081525424,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South-West Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 97,
        "Geographical Area": 421,
        "Total Fr": 44.63,
        "Forest_frac": 0.106009501,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 98,
        "Geographical Area": 250,
        "Total Fr": 79.02,
        "Forest_frac": 0.31608,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West Delhi",
        "State": "Delhi",
        "State Code": 7,
        "State ID": 10,
        "District Code": 96,
        "Geographical Area": 129,
        "Total Fr": 6.42,
        "Forest_frac": 0.049767442,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North Goa",
        "State": "Goa",
        "State Code": 30,
        "State ID": 11,
        "District Code": 585,
        "Geographical Area": 1736,
        "Total Fr": 923,
        "Forest_frac": 0.531682028,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South Goa",
        "State": "Goa",
        "State Code": 30,
        "State ID": 11,
        "District Code": 586,
        "Geographical Area": 1966,
        "Total Fr": 1296,
        "Forest_frac": 0.659206511,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ahmadabad",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 474,
        "Geographical Area": 8707,
        "Total Fr": 151,
        "Forest_frac": 0.017342368,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Amreli",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 480,
        "Geographical Area": 6760,
        "Total Fr": 231,
        "Forest_frac": 0.034171598,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Anand",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 482,
        "Geographical Area": 3214,
        "Total Fr": 60,
        "Forest_frac": 0.018668326,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Banas Kantha",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 469,
        "Geographical Area": 9858,
        "Total Fr": 840,
        "Forest_frac": 0.085209982,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bharuch",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 488,
        "Geographical Area": 6458,
        "Total Fr": 310,
        "Forest_frac": 0.048002478,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Bhavnagar",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 481,
        "Geographical Area": 11155,
        "Total Fr": 279,
        "Forest_frac": 0.025011206,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dohad",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 485,
        "Geographical Area": 4405,
        "Total Fr": 702,
        "Forest_frac": 0.159364359,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Gandhinagar",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 473,
        "Geographical Area": 649,
        "Total Fr": 41,
        "Forest_frac": 0.063174114,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jamnagar",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 477,
        "Geographical Area": 14125,
        "Total Fr": 417,
        "Forest_frac": 0.029522124,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Junagadh",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 479,
        "Geographical Area": 8281,
        "Total Fr": 1608,
        "Forest_frac": 0.194179447,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kachchh",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 468,
        "Geographical Area": 45652,
        "Total Fr": 2312,
        "Forest_frac": 0.050644002,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kheda",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 483,
        "Geographical Area": 3980,
        "Total Fr": 94,
        "Forest_frac": 0.02361809,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mahesana",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 471,
        "Geographical Area": 8540,
        "Total Fr": 214,
        "Forest_frac": 0.025058548,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Narmada",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 487,
        "Geographical Area": 2580,
        "Total Fr": 959,
        "Forest_frac": 0.371705426,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Navsari",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 490,
        "Geographical Area": 2215,
        "Total Fr": 290,
        "Forest_frac": 0.130925508,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Panch Mahals",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 484,
        "Geographical Area": 4461,
        "Total Fr": 571,
        "Forest_frac": 0.127998207,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Patan",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 470,
        "Geographical Area": 3332,
        "Total Fr": 85,
        "Forest_frac": 0.025510204,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Porbandar",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 478,
        "Geographical Area": 2326,
        "Total Fr": 124,
        "Forest_frac": 0.053310404,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rajkot",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 476,
        "Geographical Area": 11203,
        "Total Fr": 142,
        "Forest_frac": 0.012675176,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sabar Kantha",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 472,
        "Geographical Area": 7390,
        "Total Fr": 805,
        "Forest_frac": 0.108930988,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Surat",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 492,
        "Geographical Area": 7657,
        "Total Fr": 1315,
        "Forest_frac": 0.171738279,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Tapi",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 493,
        "Geographical Area": 3139,
        "Total Fr": 810,
        "Forest_frac": 0.258043963,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Surendranagar",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 475,
        "Geographical Area": 10489,
        "Total Fr": 173,
        "Forest_frac": 0.016493469,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "The Dangs",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 489,
        "Geographical Area": 1762,
        "Total Fr": 1368,
        "Forest_frac": 0.776390465,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Vadodara",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 486,
        "Geographical Area": 7794,
        "Total Fr": 626,
        "Forest_frac": 0.080318193,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Valsad",
        "State": "Gujarat",
        "State Code": 24,
        "State ID": 12,
        "District Code": 491,
        "Geographical Area": 3029,
        "Total Fr": 936,
        "Forest_frac": 0.309012876,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Ambala",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 70,
        "Geographical Area": 1574,
        "Total Fr": 43,
        "Forest_frac": 0.027318933,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhiwani",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 81,
        "Geographical Area": 4778,
        "Total Fr": 150,
        "Forest_frac": 0.031393889,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Faridabad",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 88,
        "Geographical Area": 2151,
        "Total Fr": 93,
        "Forest_frac": 0.043235704,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Fatehabad",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 78,
        "Geographical Area": 2538,
        "Total Fr": 18,
        "Forest_frac": 0.007092199,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gurgaon",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 86,
        "Geographical Area": 2766,
        "Total Fr": 229,
        "Forest_frac": 0.082791034,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hisar",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 80,
        "Geographical Area": 3983,
        "Total Fr": 44,
        "Forest_frac": 0.01104695,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jhajjar",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 83,
        "Geographical Area": 2702,
        "Total Fr": 31,
        "Forest_frac": 0.011472983,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jind",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 77,
        "Geographical Area": 1834,
        "Total Fr": 20,
        "Forest_frac": 0.010905125,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kaithal",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 73,
        "Geographical Area": 2520,
        "Total Fr": 75,
        "Forest_frac": 0.029761905,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karnal",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 74,
        "Geographical Area": 2317,
        "Total Fr": 40,
        "Forest_frac": 0.017263703,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kurukshetra",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 72,
        "Geographical Area": 1530,
        "Total Fr": 27,
        "Forest_frac": 0.017647059,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mahendragarh",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 84,
        "Geographical Area": 1859,
        "Total Fr": 69,
        "Forest_frac": 0.037116729,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mewat",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 87,
        "Geographical Area": 1507,
        "Total Fr": 110,
        "Forest_frac": 0.072992701,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Panchkula",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 69,
        "Geographical Area": 1268,
        "Total Fr": 395,
        "Forest_frac": 0.311514196,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Palwal",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 89,
        "Geographical Area": 1357.82,
        "Total Fr": 14,
        "Forest_frac": 0.010310645,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Panipat",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 75,
        "Geographical Area": 898,
        "Total Fr": 15,
        "Forest_frac": 0.016703786,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rewari",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 85,
        "Geographical Area": 1745,
        "Total Fr": 53,
        "Forest_frac": 0.030372493,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rohtak",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 82,
        "Geographical Area": 1582,
        "Total Fr": 19,
        "Forest_frac": 0.012010114,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sirsa",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 79,
        "Geographical Area": 4277,
        "Total Fr": 55,
        "Forest_frac": 0.012859481,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sonipat",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 76,
        "Geographical Area": 2122,
        "Total Fr": 21,
        "Forest_frac": 0.009896324,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Yamunanagar",
        "State": "Haryana",
        "State Code": 6,
        "State ID": 13,
        "District Code": 71,
        "Geographical Area": 1768,
        "Total Fr": 189,
        "Forest_frac": 0.106900452,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bilaspur",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 30,
        "Geographical Area": 1167,
        "Total Fr": 362,
        "Forest_frac": 0.310197087,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Chamba",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 23,
        "Geographical Area": 6522,
        "Total Fr": 2437,
        "Forest_frac": 0.373658387,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Hamirpur",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 28,
        "Geographical Area": 1118,
        "Total Fr": 244,
        "Forest_frac": 0.218246869,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kangra",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 24,
        "Geographical Area": 5739,
        "Total Fr": 2064,
        "Forest_frac": 0.359644537,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kinnaur",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 34,
        "Geographical Area": 6401,
        "Total Fr": 604,
        "Forest_frac": 0.094360256,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Kullu",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 26,
        "Geographical Area": 5503,
        "Total Fr": 1959,
        "Forest_frac": 0.355987643,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lahul & Spiti",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 25,
        "Geographical Area": 13841,
        "Total Fr": 194,
        "Forest_frac": 0.014016328,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mandi",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 27,
        "Geographical Area": 3950,
        "Total Fr": 1675,
        "Forest_frac": 0.424050633,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Shimla",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 33,
        "Geographical Area": 5131,
        "Total Fr": 2386,
        "Forest_frac": 0.465016566,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sirmaur",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 32,
        "Geographical Area": 2825,
        "Total Fr": 1385,
        "Forest_frac": 0.490265487,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Solan",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 31,
        "Geographical Area": 1936,
        "Total Fr": 850,
        "Forest_frac": 0.439049587,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Una",
        "State": "Himachal Pradesh",
        "State Code": 2,
        "State ID": 14,
        "District Code": 29,
        "Geographical Area": 1540,
        "Total Fr": 523,
        "Forest_frac": 0.33961039,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Anantnag",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 14,
        "Geographical Area": 3984,
        "Total Fr": 1438,
        "Forest_frac": 0.360943775,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Baramula",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 8,
        "Geographical Area": 4588,
        "Total Fr": 1157,
        "Forest_frac": 0.252179599,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bandipore",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 9,
        "Geographical Area": 3200,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Badgam",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 2,
        "Geographical Area": 1371,
        "Total Fr": 220,
        "Forest_frac": 0.160466813,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Doda",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 16,
        "Geographical Area": 11691,
        "Total Fr": 3967,
        "Forest_frac": 0.339320845,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ganderbal",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 11,
        "Geographical Area": 1045,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jammu",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 21,
        "Geographical Area": 3097,
        "Total Fr": 882,
        "Forest_frac": 0.284791734,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kargil",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 4,
        "Geographical Area": 14037,
        "Total Fr": 24,
        "Forest_frac": 0.001709767,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kathua",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 7,
        "Geographical Area": 2651,
        "Total Fr": 1399,
        "Forest_frac": 0.527725387,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kishtwar",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 18,
        "Geographical Area": 7737,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kulgam",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 15,
        "Geographical Area": 1067,
        "Total Fr": 440.78,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kupwara",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 1,
        "Geographical Area": 2379,
        "Total Fr": 1160,
        "Forest_frac": 0.487599832,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Leh (Ladakh)",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 3,
        "Geographical Area": 45110,
        "Total Fr": 105,
        "Forest_frac": 0.002327644,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Outside LOCH",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 0,
        "Geographical Area": 120848,
        "Total Fr": 6485,
        "Forest_frac": 0.053662452,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Poonch",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 5,
        "Geographical Area": 1674,
        "Total Fr": 729,
        "Forest_frac": 0.435483871,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pulwama",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 12,
        "Geographical Area": 1398,
        "Total Fr": 294,
        "Forest_frac": 0.210300429,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rajouri",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 6,
        "Geographical Area": 2630,
        "Total Fr": 1240,
        "Forest_frac": 0.47148289,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Reasi",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 20,
        "Geographical Area": 1719,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ramban",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 17,
        "Geographical Area": 1329,
        "Total Fr": null,
        "Forest_frac": null,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Samba",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 22,
        "Geographical Area": 913.74,
        "Total Fr": 195.89,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Shupiyan",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 13,
        "Geographical Area": 612.87,
        "Total Fr": 300,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Srinagar",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 10,
        "Geographical Area": 2228,
        "Total Fr": 752,
        "Forest_frac": 0.337522442,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Udhampur",
        "State": "Jammu and Kashmir",
        "State Code": 1,
        "State ID": 15,
        "District Code": 19,
        "Geographical Area": 4550,
        "Total Fr": 2686,
        "Forest_frac": 0.59032967,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bokaro",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 355,
        "Geographical Area": 1929,
        "Total Fr": 565,
        "Forest_frac": 0.292897875,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chatra",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 347,
        "Geographical Area": 3732,
        "Total Fr": 1780,
        "Forest_frac": 0.476956056,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Deoghar",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 350,
        "Geographical Area": 2479,
        "Total Fr": 203,
        "Forest_frac": 0.081887858,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dhanbad",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 354,
        "Geographical Area": 2996,
        "Total Fr": 202,
        "Forest_frac": 0.067423231,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dumka",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 362,
        "Geographical Area": 6212,
        "Total Fr": 663,
        "Forest_frac": 0.106728912,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Garhwa",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 346,
        "Geographical Area": 4092,
        "Total Fr": 1390,
        "Forest_frac": 0.339687195,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Giridih",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 349,
        "Geographical Area": 4963,
        "Total Fr": 885,
        "Forest_frac": 0.178319565,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Godda",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 351,
        "Geographical Area": 2110,
        "Total Fr": 408,
        "Forest_frac": 0.193364929,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Gumla",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 366,
        "Geographical Area": 9077,
        "Total Fr": 2658,
        "Forest_frac": 0.292828027,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Hazaribagh",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 360,
        "Geographical Area": 5998,
        "Total Fr": 2088,
        "Forest_frac": 0.348116039,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kodarma",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 348,
        "Geographical Area": 1435,
        "Total Fr": 598,
        "Forest_frac": 0.416724739,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lohardaga",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 356,
        "Geographical Area": 1491,
        "Total Fr": 499,
        "Forest_frac": 0.334674715,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Pakaur",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 353,
        "Geographical Area": 1571,
        "Total Fr": 288,
        "Forest_frac": 0.183322724,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Palamu",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 358,
        "Geographical Area": 8657,
        "Total Fr": 3586,
        "Forest_frac": 0.414231258,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Pashchimi Singhbhum",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 368,
        "Geographical Area": 9907,
        "Total Fr": 3914,
        "Forest_frac": 0.39507419,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Purbi Singhbhum",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 357,
        "Geographical Area": 3533,
        "Total Fr": 1069,
        "Forest_frac": 0.302575715,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ranchi",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 364,
        "Geographical Area": 7698,
        "Total Fr": 2084,
        "Forest_frac": 0.270719667,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Sahibganj",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 352,
        "Geographical Area": 1834,
        "Total Fr": 593,
        "Forest_frac": 0.323336968,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ramgarh",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 361,
        "Geographical Area": 1360.08,
        "Total Fr": 329,
        "Forest_frac": null,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Khunti",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 365,
        "Geographical Area": 2611,
        "Total Fr": 904,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Simdega",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 367,
        "Geographical Area": 3761.2,
        "Total Fr": 1241,
        "Forest_frac": null,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Jamtara",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 363,
        "Geographical Area": 1811,
        "Total Fr": 97,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Latehar",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 359,
        "Geographical Area": 3622,
        "Total Fr": 2404,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Seraikela-Kharsawan",
        "State": "Jharkhand",
        "State Code": 20,
        "State ID": 16,
        "District Code": 369,
        "Geographical Area": 2724.55,
        "Total Fr": 573,
        "Forest_frac": null,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bagalkot",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 556,
        "Geographical Area": 6575,
        "Total Fr": 200,
        "Forest_frac": 0.030418251,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bengaluru Rural",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 583,
        "Geographical Area": 5815,
        "Total Fr": 812,
        "Forest_frac": 0.139638865,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bengaluru",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 572,
        "Geographical Area": 2190,
        "Total Fr": 150,
        "Forest_frac": 0.068493151,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Belgaum",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 555,
        "Geographical Area": 13415,
        "Total Fr": 1094,
        "Forest_frac": 0.081550503,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bellary",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 565,
        "Geographical Area": 8450,
        "Total Fr": 773,
        "Forest_frac": 0.09147929,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bidar",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 558,
        "Geographical Area": 5448,
        "Total Fr": 54,
        "Forest_frac": 0.009911894,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bijapur",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 557,
        "Geographical Area": 10494,
        "Total Fr": 12,
        "Forest_frac": 0.001143511,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chamarajanagar",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 578,
        "Geographical Area": 5101,
        "Total Fr": 2636,
        "Forest_frac": 0.516761419,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chikkaballapura",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 582,
        "Geographical Area": 4244,
        "Total Fr": 249,
        "Forest_frac": 0.058671065,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chikmagalur",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 570,
        "Geographical Area": 7201,
        "Total Fr": 3681,
        "Forest_frac": 0.511179003,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chitradurga",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 566,
        "Geographical Area": 8440,
        "Total Fr": 418,
        "Forest_frac": 0.049526066,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dakshina Kannada",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 575,
        "Geographical Area": 4560,
        "Total Fr": 2860,
        "Forest_frac": 0.627192982,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Davanagere",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 567,
        "Geographical Area": 5924,
        "Total Fr": 742,
        "Forest_frac": 0.125253207,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dharwad",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 562,
        "Geographical Area": 4260,
        "Total Fr": 385,
        "Forest_frac": 0.090375587,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gadag",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 561,
        "Geographical Area": 4656,
        "Total Fr": 122,
        "Forest_frac": 0.026202749,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gulbarga",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 579,
        "Geographical Area": 16224,
        "Total Fr": 296,
        "Forest_frac": 0.018244576,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hassan",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 574,
        "Geographical Area": 6814,
        "Total Fr": 1330,
        "Forest_frac": 0.195186381,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Haveri",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 564,
        "Geographical Area": 4823,
        "Total Fr": 399,
        "Forest_frac": 0.082728592,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kodagu",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 576,
        "Geographical Area": 4102,
        "Total Fr": 3339,
        "Forest_frac": 0.813993174,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kolar",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 581,
        "Geographical Area": 8223,
        "Total Fr": 506,
        "Forest_frac": 0.06153472,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Koppal",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 560,
        "Geographical Area": 7189,
        "Total Fr": 14,
        "Forest_frac": 0.00194742,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mandya",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 573,
        "Geographical Area": 4961,
        "Total Fr": 308,
        "Forest_frac": 0.062084257,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mysore",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 577,
        "Geographical Area": 6854,
        "Total Fr": 1069,
        "Forest_frac": 0.155967318,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Raichur",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 559,
        "Geographical Area": 6827,
        "Total Fr": 24,
        "Forest_frac": 0.003515453,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ramanagara",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 584,
        "Geographical Area": 3516,
        "Total Fr": 611,
        "Forest_frac": 0.173777019,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Shimoga",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 568,
        "Geographical Area": 8477,
        "Total Fr": 4347,
        "Forest_frac": 0.512799339,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tumkur",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 571,
        "Geographical Area": 10597,
        "Total Fr": 552,
        "Forest_frac": 0.052090214,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Udupi",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 569,
        "Geographical Area": 3880,
        "Total Fr": 2190,
        "Forest_frac": 0.56443299,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Yadgir",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 580,
        "Geographical Area": 5270,
        "Total Fr": 147,
        "Forest_frac": 0.027893738,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Uttara Kannada",
        "State": "Karnataka",
        "State Code": 29,
        "State ID": 17,
        "District Code": 563,
        "Geographical Area": 10291,
        "Total Fr": 7819,
        "Forest_frac": 0.759790108,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Alappuzha",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 598,
        "Geographical Area": 1414,
        "Total Fr": 113,
        "Forest_frac": 0.079915134,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ernakulam",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 595,
        "Geographical Area": 2407,
        "Total Fr": 698,
        "Forest_frac": 0.289987536,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Idukki",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 596,
        "Geographical Area": 5019,
        "Total Fr": 3852,
        "Forest_frac": 0.767483562,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kannur",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 589,
        "Geographical Area": 2966,
        "Total Fr": 866,
        "Forest_frac": 0.291975725,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kasaragod",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 588,
        "Geographical Area": 1992,
        "Total Fr": 621,
        "Forest_frac": 0.311746988,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kollam",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 600,
        "Geographical Area": 2491,
        "Total Fr": 1395,
        "Forest_frac": 0.560016058,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kottayam",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 597,
        "Geographical Area": 2203,
        "Total Fr": 890,
        "Forest_frac": 0.403994553,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kozhikode",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 591,
        "Geographical Area": 2344,
        "Total Fr": 690,
        "Forest_frac": 0.294368601,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Malappuram",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 592,
        "Geographical Area": 3550,
        "Total Fr": 1255,
        "Forest_frac": 0.353521127,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Palakkad",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 593,
        "Geographical Area": 4480,
        "Total Fr": 1628,
        "Forest_frac": 0.363392857,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pathanamthitta",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 599,
        "Geographical Area": 2642,
        "Total Fr": 1754,
        "Forest_frac": 0.663890992,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thiruvananthapuram",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 601,
        "Geographical Area": 2192,
        "Total Fr": 1316,
        "Forest_frac": 0.600364964,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thrissur",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 594,
        "Geographical Area": 3032,
        "Total Fr": 1068,
        "Forest_frac": 0.352242744,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Wayanad",
        "State": "Kerala",
        "State Code": 32,
        "State ID": 18,
        "District Code": 590,
        "Geographical Area": 2131,
        "Total Fr": 1776,
        "Forest_frac": 0.833411544,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Alirajpur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 465,
        "Geographical Area": 3182,
        "Total Fr": 675,
        "Forest_frac": 0.212130735,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Anuppur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 461,
        "Geographical Area": 3746.71,
        "Total Fr": 848,
        "Forest_frac": 0.226331902,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Balaghat",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 457,
        "Geographical Area": 9229,
        "Total Fr": 4978,
        "Forest_frac": 0.539386716,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Barwani",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 441,
        "Geographical Area": 5422,
        "Total Fr": 982,
        "Forest_frac": 0.18111398,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Betul",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 447,
        "Geographical Area": 10043,
        "Total Fr": 3570,
        "Forest_frac": 0.355471473,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Bhind",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 420,
        "Geographical Area": 4459,
        "Total Fr": 98,
        "Forest_frac": 0.021978022,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhopal",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 444,
        "Geographical Area": 2772,
        "Total Fr": 365,
        "Forest_frac": 0.131673882,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Burhanpur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 467,
        "Geographical Area": 3427,
        "Total Fr": 1309,
        "Forest_frac": 0.381966735,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Chhatarpur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 425,
        "Geographical Area": 8687,
        "Total Fr": 1743,
        "Forest_frac": 0.200644641,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chhindwara",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 455,
        "Geographical Area": 11815,
        "Total Fr": 4531,
        "Forest_frac": 0.383495556,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Damoh",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 428,
        "Geographical Area": 7306,
        "Total Fr": 2606,
        "Forest_frac": 0.356693129,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Datia",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 422,
        "Geographical Area": 2691,
        "Total Fr": 157,
        "Forest_frac": 0.058342624,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dewas",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 437,
        "Geographical Area": 7020,
        "Total Fr": 1894,
        "Forest_frac": 0.26980057,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dhar",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 438,
        "Geographical Area": 8153,
        "Total Fr": 733,
        "Forest_frac": 0.089905556,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dindori",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 453,
        "Geographical Area": 7470,
        "Total Fr": 2756,
        "Forest_frac": 0.368942436,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "East Nimar",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 466,
        "Geographical Area": 10776,
        "Total Fr": 3402,
        "Forest_frac": 0.315701559,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Guna",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 458,
        "Geographical Area": 11064,
        "Total Fr": 2102,
        "Forest_frac": 0.189985539,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ashoknagar",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 459,
        "Geographical Area": 4674,
        "Total Fr": 700,
        "Forest_frac": 0.149764656,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gwalior",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 421,
        "Geographical Area": 4560,
        "Total Fr": 1193,
        "Forest_frac": 0.261622807,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Harda",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 448,
        "Geographical Area": 3330,
        "Total Fr": 1019,
        "Forest_frac": 0.306006006,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hoshangabad",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 449,
        "Geographical Area": 6707,
        "Total Fr": 2424,
        "Forest_frac": 0.361413449,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Indore",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 439,
        "Geographical Area": 3898,
        "Total Fr": 704,
        "Forest_frac": 0.180605439,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jabalpur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 451,
        "Geographical Area": 5211,
        "Total Fr": 1170,
        "Forest_frac": 0.224525043,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jhabua",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 464,
        "Geographical Area": 6778,
        "Total Fr": 937,
        "Forest_frac": 0.138241369,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Katni",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 450,
        "Geographical Area": 4950,
        "Total Fr": 1280,
        "Forest_frac": 0.258585859,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mandla",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 454,
        "Geographical Area": 5800,
        "Total Fr": 2835,
        "Forest_frac": 0.488793103,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Mandsaur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 433,
        "Geographical Area": 5535,
        "Total Fr": 260,
        "Forest_frac": 0.046973803,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Morena",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 419,
        "Geographical Area": 4989,
        "Total Fr": 730,
        "Forest_frac": 0.146321908,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Narsimhapur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 452,
        "Geographical Area": 5133,
        "Total Fr": 1357,
        "Forest_frac": 0.264367816,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Neemuch",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 432,
        "Geographical Area": 4256,
        "Total Fr": 827,
        "Forest_frac": 0.19431391,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Panna",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 426,
        "Geographical Area": 7135,
        "Total Fr": 2652,
        "Forest_frac": 0.371688858,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Raisen",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 446,
        "Geographical Area": 8466,
        "Total Fr": 2730,
        "Forest_frac": 0.322466336,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rajgarh",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 442,
        "Geographical Area": 6153,
        "Total Fr": 153,
        "Forest_frac": 0.024865919,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ratlam",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 434,
        "Geographical Area": 4861,
        "Total Fr": 58,
        "Forest_frac": 0.011931701,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Rewa",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 430,
        "Geographical Area": 6314,
        "Total Fr": 777,
        "Forest_frac": 0.123059867,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sagar",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 427,
        "Geographical Area": 10252,
        "Total Fr": 2890,
        "Forest_frac": 0.281896215,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Satna",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 429,
        "Geographical Area": 7502,
        "Total Fr": 1733,
        "Forest_frac": 0.231005065,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sehore",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 445,
        "Geographical Area": 6578,
        "Total Fr": 1381,
        "Forest_frac": 0.209942232,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Seoni",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 456,
        "Geographical Area": 8758,
        "Total Fr": 3082,
        "Forest_frac": 0.351906828,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Shahdol",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 460,
        "Geographical Area": 9952,
        "Total Fr": 2719,
        "Forest_frac": 0.273211415,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Shajapur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 436,
        "Geographical Area": 6195,
        "Total Fr": 29,
        "Forest_frac": 0.004681195,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sheopur",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 418,
        "Geographical Area": 6606,
        "Total Fr": 3511,
        "Forest_frac": 0.531486527,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Shivpuri",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 423,
        "Geographical Area": 10277,
        "Total Fr": 2434,
        "Forest_frac": 0.236839545,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sidhi",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 462,
        "Geographical Area": 10526,
        "Total Fr": 4092,
        "Forest_frac": 0.388751663,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Singrauli",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 463,
        "Geographical Area": 5675,
        "Total Fr": 2189,
        "Forest_frac": 0.385726872,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Tikamgarh",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 424,
        "Geographical Area": 5048,
        "Total Fr": 403,
        "Forest_frac": 0.079833597,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ujjain",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 435,
        "Geographical Area": 6091,
        "Total Fr": 30,
        "Forest_frac": 0.0049253,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Umaria",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 431,
        "Geographical Area": 4076,
        "Total Fr": 2032,
        "Forest_frac": 0.498527969,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Vidisha",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 443,
        "Geographical Area": 7371,
        "Total Fr": 865,
        "Forest_frac": 0.117351784,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West Nimar",
        "State": "Madhya Pradesh",
        "State Code": 23,
        "State ID": 20,
        "District Code": 440,
        "Geographical Area": 8030,
        "Total Fr": 1298,
        "Forest_frac": 0.161643836,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Ahmadnagar",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 522,
        "Geographical Area": 17048,
        "Total Fr": 286,
        "Forest_frac": 0.016776161,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Akola",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 501,
        "Geographical Area": 5390,
        "Total Fr": 322,
        "Forest_frac": 0.05974026,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Amravati",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 503,
        "Geographical Area": 12210,
        "Total Fr": 3187,
        "Forest_frac": 0.261015561,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Aurangabad",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 515,
        "Geographical Area": 10107,
        "Total Fr": 557,
        "Forest_frac": 0.05511032,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhandara",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 506,
        "Geographical Area": 3588,
        "Total Fr": 887,
        "Forest_frac": 0.247212932,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bid",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 523,
        "Geographical Area": 10693,
        "Total Fr": 175,
        "Forest_frac": 0.016365847,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Buldana",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 500,
        "Geographical Area": 9661,
        "Total Fr": 590,
        "Forest_frac": 0.061070283,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chandrapur",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 509,
        "Geographical Area": 11443,
        "Total Fr": 4073,
        "Forest_frac": 0.355938128,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Dhule",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 498,
        "Geographical Area": 7189,
        "Total Fr": 316,
        "Forest_frac": 0.043956044,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Gadchiroli",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 508,
        "Geographical Area": 14412,
        "Total Fr": 10096,
        "Forest_frac": 0.700527338,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Gondiya",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 507,
        "Geographical Area": 5733,
        "Total Fr": 2010,
        "Forest_frac": 0.350601779,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hingoli",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 512,
        "Geographical Area": 4686,
        "Total Fr": 114,
        "Forest_frac": 0.024327785,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jalgaon",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 499,
        "Geographical Area": 11765,
        "Total Fr": 1182,
        "Forest_frac": 0.100467488,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Jalna",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 514,
        "Geographical Area": 7718,
        "Total Fr": 65,
        "Forest_frac": 0.008421871,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kolhapur",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 530,
        "Geographical Area": 7685,
        "Total Fr": 1781,
        "Forest_frac": 0.231750163,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Latur",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 524,
        "Geographical Area": 7157,
        "Total Fr": 5,
        "Forest_frac": 0.000698617,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mumbai City",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 519,
        "Geographical Area": 157,
        "Total Fr": 2,
        "Forest_frac": 0.012738854,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mumbai Suburban",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 518,
        "Geographical Area": 446,
        "Total Fr": 120,
        "Forest_frac": 0.269058296,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nagpur",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 505,
        "Geographical Area": 9892,
        "Total Fr": 2021,
        "Forest_frac": 0.20430651,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nanded",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 511,
        "Geographical Area": 10528,
        "Total Fr": 914,
        "Forest_frac": 0.086816109,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Nandurbar",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 497,
        "Geographical Area": 5961,
        "Total Fr": 1205,
        "Forest_frac": 0.202147291,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Nashik",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 516,
        "Geographical Area": 15530,
        "Total Fr": 1089,
        "Forest_frac": 0.070122344,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Osmanabad",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 525,
        "Geographical Area": 7569,
        "Total Fr": 43,
        "Forest_frac": 0.005681068,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Parbhani",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 513,
        "Geographical Area": 6355,
        "Total Fr": 50,
        "Forest_frac": 0.007867821,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pune",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 521,
        "Geographical Area": 15643,
        "Total Fr": 1734,
        "Forest_frac": 0.110848303,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Raigarh",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 520,
        "Geographical Area": 7152,
        "Total Fr": 2861,
        "Forest_frac": 0.400027964,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Ratnagiri",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 528,
        "Geographical Area": 8208,
        "Total Fr": 4197,
        "Forest_frac": 0.511330409,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sangli",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 531,
        "Geographical Area": 8572,
        "Total Fr": 145,
        "Forest_frac": 0.016915539,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Satara",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 527,
        "Geographical Area": 10480,
        "Total Fr": 1276,
        "Forest_frac": 0.121755725,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Solapur",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 526,
        "Geographical Area": 14895,
        "Total Fr": 46,
        "Forest_frac": 0.003088285,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sindhudurg",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 529,
        "Geographical Area": 5207,
        "Total Fr": 2580,
        "Forest_frac": 0.495486845,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thane",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 517,
        "Geographical Area": 9558,
        "Total Fr": 2908,
        "Forest_frac": 0.304247751,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Wardha",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 504,
        "Geographical Area": 6309,
        "Total Fr": 859,
        "Forest_frac": 0.1361547,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Washim",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 502,
        "Geographical Area": 5184,
        "Total Fr": 332,
        "Forest_frac": 0.06404321,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Yavatmal",
        "State": "Maharashtra",
        "State Code": 27,
        "State ID": 21,
        "District Code": 510,
        "Geographical Area": 13582,
        "Total Fr": 2604,
        "Forest_frac": 0.191724341,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Bishnupur",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 275,
        "Geographical Area": 496,
        "Total Fr": 21,
        "Forest_frac": 0.04233871,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chandel",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 280,
        "Geographical Area": 3313,
        "Total Fr": 2789,
        "Forest_frac": 0.841835195,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Churachandpur",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 274,
        "Geographical Area": 4570,
        "Total Fr": 4286,
        "Forest_frac": 0.93785558,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Imphal East",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 278,
        "Geographical Area": 669,
        "Total Fr": 220,
        "Forest_frac": 0.328849028,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Imphal West",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 277,
        "Geographical Area": 559,
        "Total Fr": 55,
        "Forest_frac": 0.098389982,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Senapati",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 272,
        "Geographical Area": 3271,
        "Total Fr": 2173,
        "Forest_frac": 0.664322837,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tamenglong",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 273,
        "Geographical Area": 4391,
        "Total Fr": 3865,
        "Forest_frac": 0.880209519,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thoubal",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 276,
        "Geographical Area": 514,
        "Total Fr": 55,
        "Forest_frac": 0.107003891,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ukhrul",
        "State": "Manipur",
        "State Code": 14,
        "State ID": 22,
        "District Code": 279,
        "Geographical Area": 4544,
        "Total Fr": 3526,
        "Forest_frac": 0.77596831,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Aizawl",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 283,
        "Geographical Area": 3575,
        "Total Fr": 3249,
        "Forest_frac": 0.908811189,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Champhai",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 284,
        "Geographical Area": 3185,
        "Total Fr": 2765,
        "Forest_frac": 0.868131868,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kolasib",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 282,
        "Geographical Area": 1382,
        "Total Fr": 1229,
        "Forest_frac": 0.889290883,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lawngtlai",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 287,
        "Geographical Area": 2557,
        "Total Fr": 2350,
        "Forest_frac": 0.919045757,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lunglei",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 286,
        "Geographical Area": 4536,
        "Total Fr": 4196,
        "Forest_frac": 0.925044092,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mamit",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 281,
        "Geographical Area": 3025,
        "Total Fr": 2776,
        "Forest_frac": 0.91768595,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Saiha",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 288,
        "Geographical Area": 1400,
        "Total Fr": 1265,
        "Forest_frac": 0.903571429,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Serchhip",
        "State": "Mizoram",
        "State Code": 15,
        "State ID": 24,
        "District Code": 285,
        "Geographical Area": 1421,
        "Total Fr": 1224,
        "Forest_frac": 0.861365236,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dimapur",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 265,
        "Geographical Area": 758,
        "Total Fr": 427,
        "Forest_frac": 0.563324538,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kiphire",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 269,
        "Geographical Area": 1130,
        "Total Fr": 835,
        "Forest_frac": 0.738938053,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kohima",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 270,
        "Geographical Area": 3283,
        "Total Fr": 2897,
        "Forest_frac": 0.882424612,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Longleng",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 268,
        "Geographical Area": 562,
        "Total Fr": 375,
        "Forest_frac": 0.667259786,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mokokchung",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 262,
        "Geographical Area": 1615,
        "Total Fr": 1360,
        "Forest_frac": 0.842105263,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mon",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 261,
        "Geographical Area": 1786,
        "Total Fr": 1203,
        "Forest_frac": 0.673572228,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Peren",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 271,
        "Geographical Area": 1651,
        "Total Fr": 1438,
        "Forest_frac": 0.87098728,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Phek",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 266,
        "Geographical Area": 2026,
        "Total Fr": 1692,
        "Forest_frac": 0.835143139,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tuensang",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 267,
        "Geographical Area": 4228,
        "Total Fr": 3126,
        "Forest_frac": 0.73935667,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Wokha",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 264,
        "Geographical Area": 1628,
        "Total Fr": 1354,
        "Forest_frac": 0.831695332,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Zunheboto",
        "State": "Nagaland",
        "State Code": 13,
        "State ID": 25,
        "District Code": 263,
        "Geographical Area": 1255,
        "Total Fr": 985,
        "Forest_frac": 0.784860558,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Anugul",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 384,
        "Geographical Area": 6375,
        "Total Fr": 2702,
        "Forest_frac": 0.423843137,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Baleshwar",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 377,
        "Geographical Area": 3806,
        "Total Fr": 348,
        "Forest_frac": 0.091434577,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Bargarh",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 370,
        "Geographical Area": 5837,
        "Total Fr": 943,
        "Forest_frac": 0.161555594,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Bhadrak",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 378,
        "Geographical Area": 2505,
        "Total Fr": 62,
        "Forest_frac": 0.024750499,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Balangir",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 393,
        "Geographical Area": 6575,
        "Total Fr": 966,
        "Forest_frac": 0.146920152,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bauda",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 391,
        "Geographical Area": 3098,
        "Total Fr": 1263,
        "Forest_frac": 0.407682376,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Cuttack",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 381,
        "Geographical Area": 3932,
        "Total Fr": 771,
        "Forest_frac": 0.196083418,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Debagarh",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 373,
        "Geographical Area": 2940,
        "Total Fr": 1375,
        "Forest_frac": 0.467687075,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Dhenkanal",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 383,
        "Geographical Area": 4452,
        "Total Fr": 1399,
        "Forest_frac": 0.314240791,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gajapati",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 389,
        "Geographical Area": 4325,
        "Total Fr": 2471,
        "Forest_frac": 0.57132948,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Ganjam",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 388,
        "Geographical Area": 8206,
        "Total Fr": 2100,
        "Forest_frac": 0.25591031,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Jagatsinghapur",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 380,
        "Geographical Area": 1668,
        "Total Fr": 133,
        "Forest_frac": 0.079736211,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jajapur",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 382,
        "Geographical Area": 2899,
        "Total Fr": 296,
        "Forest_frac": 0.102104174,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jharsuguda",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 371,
        "Geographical Area": 2081,
        "Total Fr": 311,
        "Forest_frac": 0.149447381,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Kalahandi",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 395,
        "Geographical Area": 7920,
        "Total Fr": 2407,
        "Forest_frac": 0.303914141,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Kendrapara",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 379,
        "Geographical Area": 2644,
        "Total Fr": 269,
        "Forest_frac": 0.101739788,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kendujhar",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 375,
        "Geographical Area": 8303,
        "Total Fr": 3211,
        "Forest_frac": 0.386727689,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Kandhamal",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 390,
        "Geographical Area": 8021,
        "Total Fr": 5371,
        "Forest_frac": 0.669617255,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Khordha",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 386,
        "Geographical Area": 2813,
        "Total Fr": 456,
        "Forest_frac": 0.162104515,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Koraput",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 398,
        "Geographical Area": 8807,
        "Total Fr": 1928,
        "Forest_frac": 0.218916771,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Malkangiri",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 399,
        "Geographical Area": 5791,
        "Total Fr": 2321,
        "Forest_frac": 0.400794336,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Mayurbhanj",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 376,
        "Geographical Area": 10418,
        "Total Fr": 4021,
        "Forest_frac": 0.385966596,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Nuapada",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 394,
        "Geographical Area": 3852,
        "Total Fr": 1240,
        "Forest_frac": 0.321910696,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Nabarangapur",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 397,
        "Geographical Area": 5291,
        "Total Fr": 1125,
        "Forest_frac": 0.212625213,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Nayagarh",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 385,
        "Geographical Area": 3890,
        "Total Fr": 1682,
        "Forest_frac": 0.432390746,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Puri",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 387,
        "Geographical Area": 3479,
        "Total Fr": 207,
        "Forest_frac": 0.059499856,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rayagada",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 396,
        "Geographical Area": 7073,
        "Total Fr": 3133,
        "Forest_frac": 0.442952071,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Sambalpur",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 372,
        "Geographical Area": 6657,
        "Total Fr": 3358,
        "Forest_frac": 0.504431426,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Subarnapur",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 392,
        "Geographical Area": 2337,
        "Total Fr": 330,
        "Forest_frac": 0.141206675,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sundargarh",
        "State": "Odisha",
        "State Code": 21,
        "State ID": 26,
        "District Code": 374,
        "Geographical Area": 9712,
        "Total Fr": 4148,
        "Forest_frac": 0.427100494,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Amritsar",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 49,
        "Geographical Area": 5088,
        "Total Fr": 45,
        "Forest_frac": 0.00884434,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Barnala",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 54,
        "Geographical Area": 1423,
        "Total Fr": 8,
        "Forest_frac": 0.005621926,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bathinda",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 46,
        "Geographical Area": 3353,
        "Total Fr": 47,
        "Forest_frac": 0.014017298,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Faridkot",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 45,
        "Geographical Area": 1458,
        "Total Fr": 22,
        "Forest_frac": 0.015089163,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Fatehgarh Sahib",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 40,
        "Geographical Area": 1180,
        "Total Fr": 3,
        "Forest_frac": 0.002542373,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Firozpur",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 43,
        "Geographical Area": 5874,
        "Total Fr": 29,
        "Forest_frac": 0.004937011,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gurdaspur",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 35,
        "Geographical Area": 3551,
        "Total Fr": 186,
        "Forest_frac": 0.052379611,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hoshiarpur",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 38,
        "Geographical Area": 3386,
        "Total Fr": 688,
        "Forest_frac": 0.203189604,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jalandhar",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 37,
        "Geographical Area": 2624,
        "Total Fr": 10,
        "Forest_frac": 0.003810976,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kapurthala",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 36,
        "Geographical Area": 1633,
        "Total Fr": 11,
        "Forest_frac": 0.006736069,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ludhiana",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 41,
        "Geographical Area": 3578,
        "Total Fr": 68,
        "Forest_frac": 0.019005031,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mansa",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 47,
        "Geographical Area": 2198,
        "Total Fr": 6,
        "Forest_frac": 0.002729754,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Moga",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 42,
        "Geographical Area": 1689,
        "Total Fr": 11,
        "Forest_frac": 0.006512729,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Muktsar",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 44,
        "Geographical Area": 2593,
        "Total Fr": 21,
        "Forest_frac": 0.008098727,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nawanshahr",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 39,
        "Geographical Area": 1282,
        "Total Fr": 111,
        "Forest_frac": 0.086583463,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Patiala",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 48,
        "Geographical Area": 3654,
        "Total Fr": 91,
        "Forest_frac": 0.024904215,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rupnagar",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 51,
        "Geographical Area": 2113,
        "Total Fr": 391,
        "Forest_frac": 0.18504496,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tarn Taran",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 50,
        "Geographical Area": 2449,
        "Total Fr": 18,
        "Forest_frac": 0.007349939,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sahibzada Ajit Singh Nagar",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 52,
        "Geographical Area": 1094,
        "Total Fr": 142,
        "Forest_frac": 0.129798903,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sangrur",
        "State": "Punjab",
        "State Code": 3,
        "State ID": 28,
        "District Code": 53,
        "Geographical Area": 5108,
        "Total Fr": 32,
        "Forest_frac": 0.006264683,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ajmer",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 119,
        "Geographical Area": 8481,
        "Total Fr": 282,
        "Forest_frac": 0.033250796,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Alwar",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 104,
        "Geographical Area": 8380,
        "Total Fr": 1203,
        "Forest_frac": 0.143556086,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Banswara",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 125,
        "Geographical Area": 5037,
        "Total Fr": 375,
        "Forest_frac": 0.074449077,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Baran",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 128,
        "Geographical Area": 6992,
        "Total Fr": 1084,
        "Forest_frac": 0.155034325,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Barmer",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 115,
        "Geographical Area": 28387,
        "Total Fr": 178,
        "Forest_frac": 0.006270476,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bharatpur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 105,
        "Geographical Area": 5066,
        "Total Fr": 243,
        "Forest_frac": 0.047966838,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bhilwara",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 122,
        "Geographical Area": 10455,
        "Total Fr": 227,
        "Forest_frac": 0.021712099,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bikaner",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 101,
        "Geographical Area": 27244,
        "Total Fr": 210,
        "Forest_frac": 0.007708119,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bundi",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 121,
        "Geographical Area": 5550,
        "Total Fr": 453,
        "Forest_frac": 0.081621622,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chittaurgarh",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 126,
        "Geographical Area": 10856,
        "Total Fr": 1675,
        "Forest_frac": 0.154292557,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Churu",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 102,
        "Geographical Area": 16830,
        "Total Fr": 92,
        "Forest_frac": 0.005466429,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dausa",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 109,
        "Geographical Area": 3432,
        "Total Fr": 117,
        "Forest_frac": 0.034090909,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dhaulpur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 106,
        "Geographical Area": 3033,
        "Total Fr": 420,
        "Forest_frac": 0.138476756,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dungarpur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 124,
        "Geographical Area": 3770,
        "Total Fr": 256,
        "Forest_frac": 0.067904509,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Ganganagar",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 99,
        "Geographical Area": 20634,
        "Total Fr": 189,
        "Forest_frac": 0.009159639,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hanumangarh",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 100,
        "Geographical Area": 9656,
        "Total Fr": 90,
        "Forest_frac": 0.00932063,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jaipur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 110,
        "Geographical Area": 14069,
        "Total Fr": 631,
        "Forest_frac": 0.04485038,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jaisalmer",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 114,
        "Geographical Area": 38401,
        "Total Fr": 177,
        "Forest_frac": 0.004609255,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jalor",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 116,
        "Geographical Area": 10640,
        "Total Fr": 201,
        "Forest_frac": 0.018890977,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jhalawar",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 129,
        "Geographical Area": 6219,
        "Total Fr": 392,
        "Forest_frac": 0.063032642,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jhunjhunun",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 103,
        "Geographical Area": 5928,
        "Total Fr": 195,
        "Forest_frac": 0.032894737,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jodhpur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 113,
        "Geographical Area": 22850,
        "Total Fr": 93,
        "Forest_frac": 0.004070022,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karauli",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 107,
        "Geographical Area": 5524,
        "Total Fr": 870,
        "Forest_frac": 0.157494569,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kota",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 127,
        "Geographical Area": 5443,
        "Total Fr": 613,
        "Forest_frac": 0.112621716,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nagaur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 112,
        "Geographical Area": 17718,
        "Total Fr": 121,
        "Forest_frac": 0.006829213,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pali",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 118,
        "Geographical Area": 12387,
        "Total Fr": 660,
        "Forest_frac": 0.053281666,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pratapgarh",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 131,
        "Geographical Area": 4117,
        "Total Fr": 1044,
        "Forest_frac": 0.253582706,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Rajsamand",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 123,
        "Geographical Area": 3860,
        "Total Fr": 425,
        "Forest_frac": 0.110103627,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Sawai Madhopur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 108,
        "Geographical Area": 10528,
        "Total Fr": 1298,
        "Forest_frac": 0.123290274,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sikar",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 111,
        "Geographical Area": 7732,
        "Total Fr": 193,
        "Forest_frac": 0.0249612,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sirohi",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 117,
        "Geographical Area": 5136,
        "Total Fr": 913,
        "Forest_frac": 0.177764798,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Tonk",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 120,
        "Geographical Area": 7194,
        "Total Fr": 167,
        "Forest_frac": 0.023213789,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Udaipur",
        "State": "Rajasthan",
        "State Code": 8,
        "State ID": 29,
        "District Code": 130,
        "Geographical Area": 13419,
        "Total Fr": 3120,
        "Forest_frac": 0.232506148,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "East",
        "State": "Sikkim",
        "State Code": 11,
        "State ID": 30,
        "District Code": 244,
        "Geographical Area": 954,
        "Total Fr": 699,
        "Forest_frac": 0.732704403,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North",
        "State": "Sikkim",
        "State Code": 11,
        "State ID": 30,
        "District Code": 241,
        "Geographical Area": 4226,
        "Total Fr": 1316,
        "Forest_frac": 0.311405584,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South",
        "State": "Sikkim",
        "State Code": 11,
        "State ID": 30,
        "District Code": 243,
        "Geographical Area": 750,
        "Total Fr": 571,
        "Forest_frac": 0.761333333,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West",
        "State": "Sikkim",
        "State Code": 11,
        "State ID": 30,
        "District Code": 242,
        "Geographical Area": 1166,
        "Total Fr": 772,
        "Forest_frac": 0.662092624,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ariyalur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 616,
        "Geographical Area": 1947,
        "Total Fr": 330,
        "Forest_frac": 0.169491525,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chennai",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 603,
        "Geographical Area": 144,
        "Total Fr": 11,
        "Forest_frac": 0.076388889,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Coimbatore",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 632,
        "Geographical Area": 7469,
        "Total Fr": 1860,
        "Forest_frac": 0.249029321,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Cuddalore",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 617,
        "Geographical Area": 3706,
        "Total Fr": 434,
        "Forest_frac": 0.117107393,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dharmapuri",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 630,
        "Geographical Area": 9622,
        "Total Fr": 3028,
        "Forest_frac": 0.31469549,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dindigul",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 612,
        "Geographical Area": 5580,
        "Total Fr": 1526,
        "Forest_frac": 0.273476703,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Erode",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 610,
        "Geographical Area": 8209,
        "Total Fr": 2210,
        "Forest_frac": 0.269216713,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kancheepuram",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 604,
        "Geographical Area": 4474,
        "Total Fr": 372,
        "Forest_frac": 0.083147072,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kanniyakumari",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 629,
        "Geographical Area": 1684,
        "Total Fr": 747,
        "Forest_frac": 0.443586698,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 613,
        "Geographical Area": 2901,
        "Total Fr": 94,
        "Forest_frac": 0.03240262,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Krishnagiri",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 631,
        "Geographical Area": 5143,
        "Total Fr": 1549,
        "Forest_frac": 0.301186078,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Madurai",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 623,
        "Geographical Area": 4277,
        "Total Fr": 602,
        "Forest_frac": 0.140752864,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nagapattinam",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 618,
        "Geographical Area": 2140,
        "Total Fr": 99,
        "Forest_frac": 0.046261682,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Namakkal",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 609,
        "Geographical Area": 3413,
        "Total Fr": 564,
        "Forest_frac": 0.165250513,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Perambalur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 615,
        "Geographical Area": 1748,
        "Total Fr": 145,
        "Forest_frac": 0.082951945,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pudukkottai",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 621,
        "Geographical Area": 4651,
        "Total Fr": 281,
        "Forest_frac": 0.060417115,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ramanathapuram",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 626,
        "Geographical Area": 4232,
        "Total Fr": 303,
        "Forest_frac": 0.071597353,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Salem",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 608,
        "Geographical Area": 5235,
        "Total Fr": 1235,
        "Forest_frac": 0.23591213,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sivaganga",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 622,
        "Geographical Area": 4086,
        "Total Fr": 282,
        "Forest_frac": 0.069016153,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thanjavur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 620,
        "Geographical Area": 3415,
        "Total Fr": 192,
        "Forest_frac": 0.056222548,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "The Nilgiris",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 611,
        "Geographical Area": 2549,
        "Total Fr": 2087,
        "Forest_frac": 0.818752452,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Theni",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 624,
        "Geographical Area": 2764,
        "Total Fr": 948,
        "Forest_frac": 0.342981187,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thiruvallur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 602,
        "Geographical Area": 3413,
        "Total Fr": 228,
        "Forest_frac": 0.066803399,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thiruvarur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 619,
        "Geographical Area": 2716,
        "Total Fr": 51,
        "Forest_frac": 0.018777614,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tiruchirappalli",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 614,
        "Geographical Area": 4511,
        "Total Fr": 426,
        "Forest_frac": 0.094435824,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tirunelveli",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 628,
        "Geographical Area": 6810,
        "Total Fr": 1232,
        "Forest_frac": 0.180910426,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tiruppur",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 633,
        "Geographical Area": 5186.34,
        "Total Fr": 805,
        "Forest_frac": 0.155215431,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tiruvannamalai",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 606,
        "Geographical Area": 6191,
        "Total Fr": 1369,
        "Forest_frac": 0.221127443,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Thoothukkudi",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 627,
        "Geographical Area": 4621,
        "Total Fr": 160,
        "Forest_frac": 0.03462454,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Vellore",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 605,
        "Geographical Area": 6077,
        "Total Fr": 1744,
        "Forest_frac": 0.286983709,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Viluppuram",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 607,
        "Geographical Area": 7190,
        "Total Fr": 983,
        "Forest_frac": 0.136717663,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Virudhunagar",
        "State": "Tamil Nadu",
        "State Code": 33,
        "State ID": 31,
        "District Code": 625,
        "Geographical Area": 4283,
        "Total Fr": 301,
        "Forest_frac": 0.070277843,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dhalai",
        "State": "Tripura",
        "State Code": 16,
        "State ID": 32,
        "District Code": 291,
        "Geographical Area": 2402,
        "Total Fr": 1898,
        "Forest_frac": 0.790174854,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North Tripura",
        "State": "Tripura",
        "State Code": 16,
        "State ID": 32,
        "District Code": 292,
        "Geographical Area": 2039,
        "Total Fr": 1459,
        "Forest_frac": 0.715546837,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South Tripura",
        "State": "Tripura",
        "State Code": 16,
        "State ID": 32,
        "District Code": 290,
        "Geographical Area": 3057,
        "Total Fr": 2474,
        "Forest_frac": 0.809290154,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "West Tripura",
        "State": "Tripura",
        "State Code": 16,
        "State ID": 32,
        "District Code": 289,
        "Geographical Area": 2993,
        "Total Fr": 2035,
        "Forest_frac": 0.679919813,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Agra",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 146,
        "Geographical Area": 4027,
        "Total Fr": 273,
        "Forest_frac": 0.067792401,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Aligarh",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 143,
        "Geographical Area": 3650,
        "Total Fr": 67,
        "Forest_frac": 0.018356164,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Allahabad",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 175,
        "Geographical Area": 5137,
        "Total Fr": 96,
        "Forest_frac": 0.01868795,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ambedkar Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 178,
        "Geographical Area": 2337,
        "Total Fr": 34,
        "Forest_frac": 0.014548567,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Azamgarh",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 191,
        "Geographical Area": 4234,
        "Total Fr": 27,
        "Forest_frac": 0.006376949,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Baghpat",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 139,
        "Geographical Area": 1321,
        "Total Fr": 18,
        "Forest_frac": 0.013626041,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bahraich",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 180,
        "Geographical Area": 5237,
        "Total Fr": 550.1,
        "Forest_frac": 0.105041054,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ballia",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 193,
        "Geographical Area": 3349,
        "Total Fr": 25,
        "Forest_frac": 0.007464915,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Balrampur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 182,
        "Geographical Area": 2981,
        "Total Fr": 529,
        "Forest_frac": 0.177457229,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Banda",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 170,
        "Geographical Area": 4532,
        "Total Fr": 103,
        "Forest_frac": 0.022727273,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Barabanki",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 176,
        "Geographical Area": 4402,
        "Total Fr": 83,
        "Forest_frac": 0.018855066,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bareilly",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 150,
        "Geographical Area": 4120,
        "Total Fr": 44,
        "Forest_frac": 0.010679612,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Basti",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 185,
        "Geographical Area": 2688,
        "Total Fr": 18,
        "Forest_frac": 0.006696429,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bijnor",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 134,
        "Geographical Area": 4561,
        "Total Fr": 417,
        "Forest_frac": 0.091427319,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Budaun",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 149,
        "Geographical Area": 5168,
        "Total Fr": 44,
        "Forest_frac": 0.008513932,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bulandshahar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 142,
        "Geographical Area": 2910,
        "Total Fr": 116,
        "Forest_frac": 0.039862543,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chandauli",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 196,
        "Geographical Area": 2549,
        "Total Fr": 565,
        "Forest_frac": 0.221655551,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chitrakoot",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 171,
        "Geographical Area": 3092,
        "Total Fr": 563,
        "Forest_frac": 0.182082794,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Deoria",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 190,
        "Geographical Area": 2538,
        "Total Fr": 15,
        "Forest_frac": 0.005910165,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Etah",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 201,
        "Geographical Area": 4446,
        "Total Fr": 98,
        "Forest_frac": 0.022042285,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Etawah",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 161,
        "Geographical Area": 2311,
        "Total Fr": 190,
        "Forest_frac": 0.082215491,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Faizabad",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 177,
        "Geographical Area": 2174,
        "Total Fr": 55,
        "Forest_frac": 0.025298988,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Farrukhabad",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 159,
        "Geographical Area": 2181,
        "Total Fr": 46,
        "Forest_frac": 0.021091243,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Fatehpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 172,
        "Geographical Area": 4152,
        "Total Fr": 45,
        "Forest_frac": 0.01083815,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Firozabad",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 147,
        "Geographical Area": 2361,
        "Total Fr": 48,
        "Forest_frac": 0.020330368,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gautam Buddha Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 141,
        "Geographical Area": 1442,
        "Total Fr": 36,
        "Forest_frac": 0.024965326,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ghaziabad",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 140,
        "Geographical Area": 2590,
        "Total Fr": 50,
        "Forest_frac": 0.019305019,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ghazipur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 195,
        "Geographical Area": 3377,
        "Total Fr": 31,
        "Forest_frac": 0.009179745,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gonda",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 183,
        "Geographical Area": 4003,
        "Total Fr": 107,
        "Forest_frac": 0.026729953,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Gorakhpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 188,
        "Geographical Area": 3321,
        "Total Fr": 64,
        "Forest_frac": 0.019271304,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hamirpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 168,
        "Geographical Area": 4282,
        "Total Fr": 174,
        "Forest_frac": 0.040635217,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hardoi",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 155,
        "Geographical Area": 5986,
        "Total Fr": 120,
        "Forest_frac": 0.020046776,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hathras",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 144,
        "Geographical Area": 1840,
        "Total Fr": 23,
        "Forest_frac": 0.0125,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jyotiba Phule Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 137,
        "Geographical Area": 2249,
        "Total Fr": 86,
        "Forest_frac": 0.038239217,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jalaun",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 165,
        "Geographical Area": 4565,
        "Total Fr": 245,
        "Forest_frac": 0.053669222,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jaunpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 194,
        "Geographical Area": 4038,
        "Total Fr": 51,
        "Forest_frac": 0.012630015,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jhansi",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 166,
        "Geographical Area": 5024,
        "Total Fr": 214,
        "Forest_frac": 0.042595541,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kannauj",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 160,
        "Geographical Area": 2093,
        "Total Fr": 28,
        "Forest_frac": 0.013377926,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kanpur Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 164,
        "Geographical Area": 3155,
        "Total Fr": 66,
        "Forest_frac": 0.020919176,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kanpur Dehat",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 163,
        "Geographical Area": 3021,
        "Total Fr": 41,
        "Forest_frac": 0.013571665,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kanshiram Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 202,
        "Geographical Area": 1993.08,
        "Total Fr": 48.8,
        "Forest_frac": 0.024484717,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kaushambi",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 174,
        "Geographical Area": 2124,
        "Total Fr": 27,
        "Forest_frac": 0.012711864,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kheri",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 153,
        "Geographical Area": 7680,
        "Total Fr": 1311,
        "Forest_frac": 0.170703125,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kushinagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 189,
        "Geographical Area": 2906,
        "Total Fr": 35,
        "Forest_frac": 0.012044047,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lalitpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 167,
        "Geographical Area": 5039,
        "Total Fr": 572,
        "Forest_frac": 0.113514586,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lucknow",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 157,
        "Geographical Area": 2528,
        "Total Fr": 304,
        "Forest_frac": 0.120253165,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Maharajganj",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 187,
        "Geographical Area": 2952,
        "Total Fr": 461,
        "Forest_frac": 0.156165312,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mahoba",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 169,
        "Geographical Area": 2884,
        "Total Fr": 95,
        "Forest_frac": 0.032940361,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mainpuri",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 148,
        "Geographical Area": 2760,
        "Total Fr": 14,
        "Forest_frac": 0.005072464,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mathura",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 145,
        "Geographical Area": 3340,
        "Total Fr": 58,
        "Forest_frac": 0.017365269,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mau",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 192,
        "Geographical Area": 1713,
        "Total Fr": 7,
        "Forest_frac": 0.004086398,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Meerut",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 138,
        "Geographical Area": 2590,
        "Total Fr": 66,
        "Forest_frac": 0.025482625,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mirzapur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 199,
        "Geographical Area": 4521,
        "Total Fr": 867,
        "Forest_frac": 0.191771732,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Moradabad",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 135,
        "Geographical Area": 3718,
        "Total Fr": 26,
        "Forest_frac": 0.006993007,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Muzaffarnagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 133,
        "Geographical Area": 4008,
        "Total Fr": 41,
        "Forest_frac": 0.010229541,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Oraiya",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 162,
        "Geographical Area": 2015,
        "Total Fr": 68,
        "Forest_frac": 0.033746898,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pilibhit",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 151,
        "Geographical Area": 3499,
        "Total Fr": 690,
        "Forest_frac": 0.1971992,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pratapgarh",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 173,
        "Geographical Area": 3717,
        "Total Fr": 93,
        "Forest_frac": 0.025020178,
        "is_scheduled_area": "Y",
        "is_scheduled_area_norm": 1
    },
    {
        "District": "Rae Bareli",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 158,
        "Geographical Area": 4609,
        "Total Fr": 98,
        "Forest_frac": 0.021262747,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rampur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 136,
        "Geographical Area": 2367,
        "Total Fr": 77,
        "Forest_frac": 0.032530629,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Saharanpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 132,
        "Geographical Area": 3689,
        "Total Fr": 375,
        "Forest_frac": 0.101653565,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sant Kabir Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 186,
        "Geographical Area": 1646,
        "Total Fr": 2,
        "Forest_frac": 0.001215067,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sant Ravidas Nagar (Bhadohi)",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 198,
        "Geographical Area": 1015,
        "Total Fr": 1,
        "Forest_frac": 0.000985222,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Shahjahanpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 152,
        "Geographical Area": 4575,
        "Total Fr": 123,
        "Forest_frac": 0.026885246,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Shravasti",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 181,
        "Geographical Area": 1640,
        "Total Fr": 284.54,
        "Forest_frac": 0.1735,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Siddharth Nagar",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 184,
        "Geographical Area": 2895,
        "Total Fr": 40,
        "Forest_frac": 0.013816926,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sitapur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 154,
        "Geographical Area": 5743,
        "Total Fr": 214,
        "Forest_frac": 0.037262755,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sonbhadra",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 200,
        "Geographical Area": 6788,
        "Total Fr": 2540,
        "Forest_frac": 0.374189747,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Sultanpur",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 179,
        "Geographical Area": 4436,
        "Total Fr": 177,
        "Forest_frac": 0.039900812,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Unnao",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 156,
        "Geographical Area": 4558,
        "Total Fr": 250,
        "Forest_frac": 0.054848618,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Varanasi",
        "State": "Uttar Pradesh",
        "State Code": 9,
        "State ID": 33,
        "District Code": 197,
        "Geographical Area": 1528,
        "Total Fr": 12,
        "Forest_frac": 0.007853403,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Almora",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 64,
        "Geographical Area": 3139,
        "Total Fr": 1577,
        "Forest_frac": 0.502389296,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bageshwar",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 63,
        "Geographical Area": 2246,
        "Total Fr": 1385,
        "Forest_frac": 0.616651825,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chamoli",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 57,
        "Geographical Area": 8030,
        "Total Fr": 2700,
        "Forest_frac": 0.336239103,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Champawat",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 65,
        "Geographical Area": 1766,
        "Total Fr": 1187,
        "Forest_frac": 0.67214043,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dehradun",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 60,
        "Geographical Area": 3088,
        "Total Fr": 1610,
        "Forest_frac": 0.521373057,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Garhwal",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 61,
        "Geographical Area": 5329,
        "Total Fr": 3291,
        "Forest_frac": 0.617564271,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Haridwar",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 68,
        "Geographical Area": 2360,
        "Total Fr": 615,
        "Forest_frac": 0.26059322,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nainital",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 66,
        "Geographical Area": 4251,
        "Total Fr": 3074,
        "Forest_frac": 0.723123971,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Pithoragarh",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 62,
        "Geographical Area": 7090,
        "Total Fr": 2100,
        "Forest_frac": 0.296191819,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Rudraprayag",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 58,
        "Geographical Area": 1984,
        "Total Fr": 1130,
        "Forest_frac": 0.569556452,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Tehri Garhwal",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 59,
        "Geographical Area": 3642,
        "Total Fr": 2148,
        "Forest_frac": 0.589785832,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Udham Singh Nagar",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 67,
        "Geographical Area": 2542,
        "Total Fr": 546,
        "Forest_frac": 0.214791503,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Uttarkashi",
        "State": "Uttarakhand",
        "State Code": 5,
        "State ID": 34,
        "District Code": 56,
        "Geographical Area": 8016,
        "Total Fr": 3145,
        "Forest_frac": 0.392340319,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Bankura",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 339,
        "Geographical Area": 6882,
        "Total Fr": 1244,
        "Forest_frac": 0.180761407,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Barddhaman",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 335,
        "Geographical Area": 7024,
        "Total Fr": 319,
        "Forest_frac": 0.045415718,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Birbhum",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 334,
        "Geographical Area": 4545,
        "Total Fr": 176,
        "Forest_frac": 0.038723872,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Kolkata",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 342,
        "Geographical Area": 185,
        "Total Fr": 0,
        "Forest_frac": 0,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Koch Bihar",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 329,
        "Geographical Area": 3387,
        "Total Fr": 348,
        "Forest_frac": 0.102745793,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dakshin Dinajpur",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 331,
        "Geographical Area": 2219,
        "Total Fr": 87,
        "Forest_frac": 0.03920685,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Darjeeling",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 327,
        "Geographical Area": 3149,
        "Total Fr": 2378,
        "Forest_frac": 0.755160368,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Howrah",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 341,
        "Geographical Area": 1467,
        "Total Fr": 304,
        "Forest_frac": 0.207225631,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Hooghly",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 338,
        "Geographical Area": 3149,
        "Total Fr": 160,
        "Forest_frac": 0.050809781,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jalpaiguri",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 328,
        "Geographical Area": 6227,
        "Total Fr": 2863,
        "Forest_frac": 0.459771961,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Maldah",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 332,
        "Geographical Area": 3733,
        "Total Fr": 504,
        "Forest_frac": 0.135012055,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Paschim Medinipur",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 344,
        "Geographical Area": 14081,
        "Total Fr": 2984,
        "Forest_frac": 0.211916767,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Purba Medinipur",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 345,
        "Geographical Area": 4295,
        "Total Fr": 820,
        "Forest_frac": 0.190919674,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Murshidabad",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 333,
        "Geographical Area": 5324,
        "Total Fr": 348,
        "Forest_frac": 0.065364388,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nadia",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 336,
        "Geographical Area": 3927,
        "Total Fr": 479,
        "Forest_frac": 0.121976063,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North 24 Parganas",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 337,
        "Geographical Area": 4094,
        "Total Fr": 725,
        "Forest_frac": 0.177088422,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Puruliya",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 340,
        "Geographical Area": 6259,
        "Total Fr": 869,
        "Forest_frac": 0.13884007,
        "is_scheduled_area": "N",
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South 24 Parganas",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 343,
        "Geographical Area": 9960,
        "Total Fr": 2774,
        "Forest_frac": 0.278514056,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Uttar Dinajpur",
        "State": "West Bengal",
        "State Code": 19,
        "State ID": 35,
        "District Code": 330,
        "Geographical Area": 3140,
        "Total Fr": 243,
        "Forest_frac": 0.077388535,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Nicobar",
        "State": "Andaman and Nicobar Islands",
        "State Code": 35,
        "State ID": 1,
        "District Code": 638,
        "Geographical Area": 1841,
        "Total Fr": 1406,
        "Forest_frac": 0.763715372,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "North & Middle Andaman",
        "State": "Andaman and Nicobar Islands",
        "State Code": 35,
        "State ID": 1,
        "District Code": 639,
        "Geographical Area": 3736,
        "Total Fr": 3071,
        "Forest_frac": 0.822002141,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South Andaman",
        "State": "Andaman and Nicobar Islands",
        "State Code": 35,
        "State ID": 1,
        "District Code": 640,
        "Geographical Area": 2672,
        "Total Fr": 2265,
        "Forest_frac": 0.847679641,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Dadra and Nagar Haveli",
        "State": "Dadra and Nagar Haveli",
        "State Code": 26,
        "State ID": 8,
        "District Code": 496,
        "Geographical Area": 491,
        "Total Fr": 41.63,
        "Forest_frac": 0.084786151,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Karaikal",
        "State": "Pondicherry",
        "State Code": 34,
        "State ID": 27,
        "District Code": 637,
        "Geographical Area": 161,
        "Total Fr": 15.4,
        "Forest_frac": 0.095652174,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Mahe",
        "State": "Pondicherry",
        "State Code": 34,
        "State ID": 27,
        "District Code": 636,
        "Geographical Area": 9,
        "Total Fr": 5.67,
        "Forest_frac": 0.63,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Puducherry",
        "State": "Pondicherry",
        "State Code": 34,
        "State ID": 27,
        "District Code": 635,
        "Geographical Area": 294,
        "Total Fr": 26.1,
        "Forest_frac": 0.08877551,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Yanam",
        "State": "Pondicherry",
        "State Code": 34,
        "State ID": 27,
        "District Code": 634,
        "Geographical Area": 30,
        "Total Fr": 6.5,
        "Forest_frac": 0.216666667,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "East Garo Hills",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 294,
        "Geographical Area": 2603,
        "Total Fr": 2217,
        "Forest_frac": 0.851709566,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "East Khasi Hills",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 298,
        "Geographical Area": 2820,
        "Total Fr": 1800,
        "Forest_frac": 0.638297872,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Jaintia Hills",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 299,
        "Geographical Area": 3819,
        "Total Fr": 2516,
        "Forest_frac": 0.658811207,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Ri Bhoi",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 297,
        "Geographical Area": 2376,
        "Total Fr": 2121,
        "Forest_frac": 0.892676768,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "South Garo Hills",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 295,
        "Geographical Area": 1849,
        "Total Fr": 1639,
        "Forest_frac": 0.886425095,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West Garo Hills",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 293,
        "Geographical Area": 3715,
        "Total Fr": 2974,
        "Forest_frac": 0.800538358,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "West Khasi Hills",
        "State": "Meghalaya",
        "State Code": 17,
        "State ID": 23,
        "District Code": 296,
        "Geographical Area": 5247,
        "Total Fr": 4008,
        "Forest_frac": 0.763865066,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Chandigarh",
        "State": "Chandigarh",
        "State Code": 4,
        "State ID": 6,
        "District Code": 55,
        "Geographical Area": 114,
        "Total Fr": 21.56,
        "Forest_frac": 0.189122807,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Daman",
        "State": "Daman and Diu",
        "State Code": 25,
        "State ID": 9,
        "District Code": 495,
        "Geographical Area": 72,
        "Total Fr": 10.96,
        "Forest_frac": 0.152222222,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Diu",
        "State": "Daman and Diu",
        "State Code": 25,
        "State ID": 9,
        "District Code": 494,
        "Geographical Area": 39,
        "Total Fr": 9.53,
        "Forest_frac": 0.244358974,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    },
    {
        "District": "Lakshadweep",
        "State": "Lakshadweep Islands",
        "State Code": 31,
        "State ID": 19,
        "District Code": 587,
        "Geographical Area": 30,
        "Total Fr": 27.1,
        "Forest_frac": 0.903333333,
        "is_scheduled_area": null,
        "is_scheduled_area_norm": 0
    }
]

export const getStateAttrFromLayer = (stateLayer) => {
    let stateColorByAttr = 'acts' //default
    if (stateLayer === 'Forest Cover') {
        stateColorByAttr = 'recorded_forest_frac'
    } else if (stateLayer === 'Dams') {
        stateColorByAttr = 'dams_count_2019'
    } else if (stateLayer === 'Mines') {
        stateColorByAttr = 'mineral_prod_crore_2015_16'
    } else if (stateLayer === 'Scheduled Areas') {
        stateColorByAttr = 'is_scheduled_area_norm'
    }
    // console.log('[attr.update] stateLayer->', stateLayer, 'stateColorByAttr->', stateColorByAttr)
    return stateColorByAttr
}

export const getDistrictAttrFromLayer = (districtLayer) => {
    let districtColorByAttr = 'acts' //default
    if (districtLayer === 'Forest Cover') {
        districtColorByAttr = 'Forest_frac'
    } else if (districtLayer === 'Dams') {
        districtColorByAttr = 'State ID'
    } else if (districtLayer === 'Mines') {
        districtColorByAttr = 'Geographical Area'
    } else if (districtLayer === 'Scheduled Areas') {
        districtColorByAttr = 'is_scheduled_area_norm'
    }
    // console.log('[attr.update] districtLayer->', districtLayer, 'districtColorByAttr->', districtColorByAttr)
    return districtColorByAttr
}