import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  copytext: {
    // padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

export default function Copyright() {
  const classes = useStyles();
  return (
    <Typography
      className={classes.copytext}
      variant="caption"
      display="block"
      align="center"
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://www.cprindia.org/projects/land-rights-initiative/"
      >
        CPR LRI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
