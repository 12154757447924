import React, { useContext, useState, useEffect } from "react";
import firebase from "firebase/app";
import { auth } from "../firebase";
import { db } from "../firebase";
import { analytics } from "../firebase";
import { useHistory } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentUserProfile, setCurrentUserProfile] = useState();
  const [loading, setLoading] = useState(true);
  const subscribersRef = db.collection("subscribers");
  const history = useHistory();

  // function signup(email, password) {
  //     return auth.createUserWithEmailAndPassword(email, password)
  // }

  // function login(email, password) {
  //     return auth.signInWithEmailAndPassword(email, password)
  // }
  function signinLink(email) {
    let actionCodeSettings = {
      // URL you want to redirect back to.
      url: "https://landlawsofindia.org/verify",
      // url: 'https://mill-dev.web.app/verify',
      // url: 'http://localhost:3000/verify',
      // This must be true.
      handleCodeInApp: true,
    };
    return auth.sendSignInLinkToEmail(email, actionCodeSettings);
  }

  function signUpWithGoogle() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    return auth
      .signInWithPopup(provider)
      .then((result) => {
        console.log("google auth");
        // console.log(result)
        // console.log(result.user)
        // console.log(result.user.uid)
        // console.log(result.additionalUserInfo)

        localStorage.setItem("name", result.user.displayName);
        localStorage.setItem(
          "first_name",
          result.additionalUserInfo.profile.given_name
        );
        localStorage.setItem(
          "last_name",
          result.additionalUserInfo.profile.family_name
        );
        // localStorage.setItem("email", result.user.email)
        // localStorage.setItem("profilePic", result.user.photoURL)

        if (result.additionalUserInfo.isNewUser) {
          history.push("/profile?uid=" + result.user.uid);
        } else {
          history.push("/?intro=false");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function logout() {
    return auth.signOut();
  }

  // function resetPassword(email) {
  //     return auth.sendPasswordResetEmail(email)
  // }

  // function verify(email) {
  //     return auth.sendVerificationEmail(email)
  // }

  function isSignInLink(href) {
    return auth.isSignInWithEmailLink(href);
  }

  function signInWithLink(email, href) {
    return auth.signInWithEmailLink(email, href);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // console.log('[auth.update] user:', user)
      setCurrentUser(user);
      // if user is not null load profile
      if (user) {
        console.log("auth change");
        // console.log(user)
        analytics.setUserId(user.uid);
        analytics.logEvent("user-sigin", user);
        subscribersRef
          .doc(user.uid)
          .set(
            {
              email: user.email,
              creationTime: user.metadata.creationTime,
              lastSignInTime: user.metadata.lastSignInTime,
            },
            { merge: true }
          )
          .then(() =>
            subscribersRef
              .doc(user.uid)
              .get()
              .then((doc) => {
                // console.log(doc.id, " => ", doc.data().email);
                // if doc.data().email is null or undefined
                // profile is missing
                setCurrentUserProfile(doc.data());
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
              })
              .finally(() => {
                setLoading(false);
              })
          );
      } else {
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    currentUserProfile,
    // verify,
    // login,
    signinLink,
    isSignInLink,
    signInWithLink,
    // signup,
    signUpWithGoogle,
    logout,
    // resetPassword,
    // updateEmail,
    // updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
