import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { analytics } from "../../firebase";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as _ from "lodash";
import matchSorter from "match-sorter";
import SearchOptions from "./SearchOptions";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { searchTags } from "../../searchtags";
import { Typography } from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import AboutDialog from "../ViewPanel/AboutDialog";
import { useAuth } from "../../contexts/AuthContext";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: "flex",
    // border: '2px solid red',
    // flex: '0 0 50px',
    marginTop: "11px",
    marginRight: "10px",
    zIndex: 1, //1000
  },
  searchBarMobile: {
    display: "flex",
    // border: '2px solid red',
    justifyContent: "flex-start",
    marginTop: "11px",
    // marginRight: '65px',
    // border: 'red solid 2px',
    zIndex: 1, //1000
  },
  // searchBarMobile: {
  //     display: 'flex',
  // },
  logo: {
    position: "absolute",
    top: "15px",
    right: "8px",
  },
  paperRoot: {
    display: "flex",
    flexGrow: 4,
    flexShrink: 4,
    alignItems: "center",
    padding: "0px 4px 0px 8px",
    margin: "3px",
    color: theme.palette.text.secondary,
    height: "42px",
    // width: "100%",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      color: fade(theme.palette.text.secondary, 1),
    },
  },
  inputBaseRoot: {
    border: "1px solid blue",
    fontSize: "1rem",
    // width: '100%',
    color: "inherit",
  },
  InputAdornmentRoot: {
    marginLeft: "22px",
  },
  inputBaseInput: {
    paddingLeft: "5px",
    // padding: '6px 0 7px 24px',
  },

  dividerRoot: {
    height: "28px",
    margin: "4px",
    background: theme.palette.divider,
  },
  iconButtonRoot: {
    padding: theme.spacing(1),
    color: fade(theme.palette.primary.main, 0.5),
  },
  fabRoot: {
    minHeight: "10px",
    color: "rgba(0, 0, 0, 0.87)",
    width: "20px",
    height: "20px",
    padding: 0,
    position: "absolute",
    // top: '4px',
    // left: '3px'
  },
  circularProgressRoot: {
    position: "absolute",
    top: "2px",
    left: "1px",
    color: theme.palette.text.secondary,
  },
  suggestComponent: {
    container: {
      position: "relative",
    },
    input: {
      // width: 200,
      height: 30,
      padding: "10px 20px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: 16,
      border: "1px solid #aaa",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    inputFocused: {
      outline: "none",
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    suggestionsContainer: {
      display: "none",
    },
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      top: 51,
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 300,
      fontSize: 16,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      zIndex: 2,
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "15px 20px",
    },
    suggestionHighlighted: {
      backgroundColor: "#ddd",
    },
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function SearchBar({
  elems,
  inSelect,
  setSelectionHandler,
  showIntroAtStart,
  showTourAfterIntro,
  toggleTourStateHandler,
}) {
  var winSize = useWindowSize();
  const classes = useStyles();
  const themeCols = useTheme();
  // const [searchQuery, setSearchQuery] = useState('')
  // const [searchFocus, setSearchFocus] = useState(false)
  const timeoutRef = useRef(null);
  const elemsArray = useRef(null);
  const [showOption, setShowOption] = useState(false);
  const [searchKeys, setSearchKeys] = useState({
    NameOfAct: true,
    StatesWhereApplicable: true,
    Categories: true,
    TimePeriod: true,
    YearOfEnactment: true,
    SummaryText: true,
  });

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [recentSearches, setRecentSearches] = useState([
    {
      title: "recent searches",
      suggestions: [],
    },
  ]);
  const { logout, currentUser, currentUserProfile } = useAuth();

  // console.log(_.values(elems)[0])
  // console.log(_.keys(_.pickBy(searchKeys)))
  useEffect(() => {
    elemsArray.current = _.values(elems);
    return () => {
      console.log("[searchbar] unmounting");
      elemsArray.current = null;
      timeoutRef.current = null;
    };
  }, [elems]);

  const handleOptionChange = (event) => {
    setSearchKeys({ ...searchKeys, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    // deselection resets the searchquery and triggers
    // list change with query=''
    if (inSelect.ids.length === 0 || inSelect.source !== "search-bar") {
      setValue("");
    }
  }, [inSelect]);

  const toggleShowOption = () => {
    setShowOption(!showOption);
  };

  const handleOnSelect = (item) => {
    // the item selected
    // console.log('select', item)
    setRecentSearches((prev) => {
      let recents = prev[0].suggestions.map((el) => el.name);
      if (!recents.includes(item)) {
        // console.log('adding to recent', item, prev)
        prev[0].suggestions.unshift({ name: item });
        prev[0].suggestions = prev[0].suggestions.slice(0, 4);
      } else {
        // console.log('already in recent', item, prev)
      }
      // console.log('post,', prev)
      return prev;
    });
    // console.log('searchkeys', searchKeys)
    const searchQuery = item;
    if (searchQuery !== "") {
      // analytics.logEvent('search-query', searchQuery)
      // console.log(searchKeys)
      // console.log(elemsArray.current[0])
      const results = matchSorter(elemsArray.current, searchQuery, {
        keys: _.keys(_.pickBy(searchKeys)),
        threshold: matchSorter.rankings.CONTAINS,
      });
      // const ids = _.values(_.mapValues(results, (x) => x.id.toString()))
      const ids = _.values(_.mapValues(results, (x) => x.id));
      // console.log(ids)
      setSelectionHandler(ids, "search-bar", "reset");
    } else {
      setSelectionHandler([], "search-bar", "reset");
    }
  };

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    // console.log('fetch')
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    // console.log('clear')
    setSuggestions([]);
  };

  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  const split = (text) => {
    return (
      text
        // insert a space before all caps, &, |, /
        .replace(/([A-Z&|/])/g, " $1")
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
        // remove leading, trailing spaces
        .trim()
    );
  };

  function getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      // console.log('recentSearches', recentSearches)
      return [];
    } else {
      const escapedValue = escapeRegexCharacters(value.trim());

      if (escapedValue === "") {
        return [];
      }

      const expr = `(^.+?\\s${escapedValue})|(^${escapedValue})`;
      // console.log(expr)
      const regex = new RegExp(expr, "i");

      const st = searchTags
        .map((section) => {
          return {
            title: section.title,
            suggestions: section.suggestions
              .filter((elem) => regex.test(split(elem.name)))
              .slice(0, 5),
          };
        })
        .filter((section) => section.suggestions.length > 0);
      return st;
    }
  }

  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  // function renderSuggestion(suggestion) {
  //     return (
  //         <Typography variant="body2">{suggestion.name}</Typography>
  //     );
  // }

  function renderSuggestion(suggestion, { query }) {
    const name = split(suggestion.name);
    const matches = AutosuggestHighlightMatch(name, query);
    const parts = AutosuggestHighlightParse(name, matches);

    return (
      <span>
        {parts.map((part, index) => {
          const variant = part.highlight ? "subtitle2" : "body2";
          const fw = part.highlight ? "600" : "400";
          return (
            <Typography
              variant={variant}
              key={index}
              display="inline"
              style={{
                fontWeight: fw,
                color: themeCols.palette.primary.contrastTextLight,
              }}
            >
              {part.text}
            </Typography>
          );
        })}
      </span>
    );
  }

  function renderSectionTitle(section) {
    return (
      <Typography
        variant="overline"
        style={{
          marginLeft: "20px",
          opacity: "0.5",
          color: themeCols.palette.primary.contrastTextLight,
          // 'textUnderlineOffset': '2px',
          // 'textDecoration': 'underline'
        }}
      >
        {section.title}
      </Typography>
    );
  }

  const renderSuggestionsContainer = ({ containerProps, children, query }) => (
    <div {...containerProps}>
      {children}
      {
        <Typography
          variant="caption"
          gutterBottom
          style={{
            opacity: 0.5,
            margin: "20px",
            color: themeCols.palette.primary.contrastTextLight,
          }}
        >
          Click on text to search
        </Typography>
      }
    </div>
  );

  function getSectionSuggestions(section) {
    return section.suggestions;
  }

  function shouldRenderSuggestions(value, reason) {
    return value.trim().length >= 0;
  }

  function onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    // console.log('selected')
    handleOnSelect(suggestionValue);
  }

  const submitHandler = (e) => {
    // console.log(e, value)
    if (e.key === "Enter") {
      e.preventDefault();
      if (value !== "") {
        handleOnSelect(value);
      }
    }
  };

  const inputProps = {
    placeholder: "Search LRI Archive..",
    id: `search_placeholder_${themeCols.palette.type}`,
    value,
    onChange: onChange,
    onKeyPress: submitHandler,
  };

  const gt1200 = useMediaQuery("(min-width:1280px)");
  const gt980 = useMediaQuery("(min-width:960px)");
  const width = gt1200 ? 458 : gt980 ? 380 : 380;
  const suggestStyle = {
    container: {
      position: "relative",
    },
    input: {
      background: "transparent",
      width: isMobile ? winSize[0] - 100 : width,
      height: "30px",
      // padding: '10px 20px',
      paddingLeft: isBrowser ? "35px" : "15px",
      fontFamily: "Helvetica, sans-serif",
      fontWeight: 400,
      fontSize: 16,
      border: "none",
      color: themeCols.palette.secondary.main,
      // border: '1px solid #aaa',
      // borderTopLeftRadius: 4,
      // borderTopRightRadius: 4,
      // borderBottomLeftRadius: 4,
      // borderBottomRightRadius: 4,
    },
    inputFocused: {
      outline: "none",
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    sectionContainer: {
      padding: "10px 0px",
    },
    suggestionsContainer: {
      display: "none",
    },
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      top: 39,
      left: isMobile ? -20 : -8,
      width: isMobile ? winSize[0] - 20 : width + 13,
      height: winSize[1] - 100,
      overflowY: "auto",
      // border: '1px solid #aaa',
      border: "none",
      backgroundColor: themeCols.palette.primary.light,
      fontFamily: "Helvetica, sans-serif",
      // fontWeight: 300,
      // fontSize: 16,
      // borderBottomLeftRadius: 4,
      // borderBottomRightRadius: 4,
      borderRadius: 4,
      zIndex: 2,
      boxShadow: `2px 3px 5px 0px ${themeCols.palette.background.paperDarkest}`,
      paddingBottom: isMobile ? "240px" : "20px",
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "10px 20px",
    },
    suggestionHighlighted: {
      backgroundColor: "rgba(255,255,255,0.2)",
    },
  };
  return (
    <>
      <Box
        name="search-panel"
        className={
          isBrowser ? classes.searchBar : classes.searchBarMobile + " noselect"
        }
      >
        <Paper
          name="search-bar"
          className={classes.paperRoot}
          elevation={0}
          component="form"
          noValidate
          autoComplete="off"
        >
          {isMobile ? (
            <SearchIcon style={{ marginLeft: "6px" }} />
          ) : (
            <Fab
              disabled
              classes={{ root: classes.fabRoot }}
              aria-label="logo"
              size="small"
            >
              <img src={"img/logo.svg"} width={"20px"} alt="lri-logo"></img>
            </Fab>
          )}

          <Autosuggest
            multiSection={true}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSectionTitle={renderSectionTitle}
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSectionSuggestions={getSectionSuggestions}
            shouldRenderSuggestions={shouldRenderSuggestions}
            highlightFirstSuggestion={true}
            inputProps={inputProps}
            theme={suggestStyle}
          />
        </Paper>
        {isMobile ? (
          <AboutDialog
            showIntroAtStart={showIntroAtStart}
            showTourAfterIntro={false}
            toggleTourStateHandler={toggleTourStateHandler}
            isLoggedIn={currentUser ? true : false}
          />
        ) : null}
      </Box>
      <Collapse name="search-options" in={showOption}>
        <SearchOptions
          searchKeys={searchKeys}
          toggleShowOption={toggleShowOption}
          handleOptionChange={handleOptionChange}
        />
      </Collapse>
    </>
  );
}
