import React, { useState, useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SizeMe } from "react-sizeme";
import { Box, Typography, Paper, Tabs, Tab } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import parse from "html-react-parser";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { fade } from "@material-ui/core/styles/colorManipulator";
import DocView from "./DocView2";
import { storage } from "../../firebase";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import TranslateIcon from "@material-ui/icons/Translate";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import TitleIcon from "@material-ui/icons/Title";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  docContainerRoot: {
    background: "none",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    flexFlow: "row",
    zIndex: 2,
  },

  docViewPanel: {
    display: "flex",
    background: theme.palette.background.paperDark,
    flexGrow: 1,
    flexShrink: 2,
    flexFlow: "column",
    padding: "0px",
    marginTop: "0px",
    textAlign: "center",
    position: "relative",
    minHeight: "100px",
    padding: "0 20px",
    // '-webkit-animation': 'fadein 2s', /* Safari, Chrome and Opera > 12.1 */
    // '-moz-animation': 'fadein 2s', /* Firefox < 16 */
    // '-ms-animation': 'fadein 2s', /* Internet Explorer */
    // '-o-animation': 'fadein 2s', /* Opera < 12.1 */
    // 'animation': 'fadein 2s',
  },
  svgIconRoot: {
    // color: theme.palette.primary.contrastTextLight + ' !important',
    fontSize: "11px",
    maxWidth: "140px",
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
    // color: theme.palette.background.paper,
    "&:hover": {
      color: theme.palette.secondary.main + " !important",
    },
  },
  filterOptionBtn: {
    // color: theme.palette.primary.contrastTextLight,
    fontSize: "0.625rem",
    maxWidth: "140px",
    marginRight: "5px",
  },

  summHeaderRow: {
    display: "flex",
    flexFlow: "row",
    padding: "0px 0px 0px 0px",
    maxWidth: "100%",
  },
  summHeaderRowMobile: {
    display: "flex",
    flexFlow: "row",
    padding: "10px 0px 0px 0px",
    maxWidth: "100%",
  },
  summHeaderTitleBlockMobile: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    padding: "10px 0px 0px 0px",
  },
  summHeaderTitleCitation: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
  },
  summHeaderClose: {
    display: "flex",
    flexFlow: "row-reverse",
    padding: "10px 0px 0px 0px",
    maxWidth: "100%",
  },

  summHeaderCitation: {
    display: "flex",
    // border: 'solid red',
    fontSize: "12px",
    // height: '50px',
    padding: "5px 0px 0px 10px",
    // marginTop: '38px'
  },
  summHeaderTitle: {
    display: "flex",
    // border: 'solid red',
    fontSize: isMobile ? "18px" : "24px",
    fontWeight: "500",
    textAlign: "left",
    padding: "0px 20px 0px 10px",
    // height: '100px'
  },

  summParams: {
    // display: 'flex',
    padding: "5px 0px 5px 10px",
    // fontSize: '12px',
    // border: 'solid grey 0.5px'
  },
  summBody: {
    // display: 'flex',
    // border: 'solid grey 0.5px',
    // minHeight: '200px',
    background: fade(theme.palette.background.paper, 0.4),
    border: `solid 1px ${theme.palette.background.paperDark}`,
    boxShadow: "inset 0.1em 0.1em 0.2em 0.1em rgba(0,0,0,0.15)",
    overflowY: "scroll",
    // padding: '5px 40px 5px 10px',
    margin: "10px 0px 20px 0px",
  },
  summBodyText: {
    maxWidth: "800px",
    // textAlign: 'justify',
    lineHeight: isBrowser ? "2" : "1.5",
    margin: "5px 40px 5px 10px",
  },

  summPropContainer: {
    maxWidth: "800px",
    textAlign: "left",
    lineHeight: "2",
    display: "flex",
    flexFlow: "column",
    // overflowY: 'scroll',
  },
  summPropHeaders: {
    lineHeight: "1",
    marginTop: "10px",
    opacity: 0.5,
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const split = (text) => {
  return (
    text
      // insert a space before all caps, &, |, /
      .replace(/([A-Z&|/])/g, " $1")
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
      // remove leading, trailing spaces
      .replaceAll(".", " >")
      .trim()
  );
};

const deDup = (arr) => {
  return arr
    .sort((a, b) => a.length - b.length)
    .filter((item, pos, ary) => {
      // console.log('---item:', item);
      let temp = ary.slice(pos + 1);
      // console.log('posterior arr:', temp)
      // console.log(temp.find(el => el.includes(item)))
      return temp.find((el) => el.includes(item)) == undefined;
    });
};

export default function SummaryView({
  open,
  handleDocClose,
  elem,
  url,
  typ,
  handleDocOpenNew,
}) {
  const allowMultiLang = true;
  var winSize = useWindowSize();
  const classes = useStyles();
  const gt1200 = useMediaQuery("(min-width:1280px)");
  const gt980 = useMediaQuery("(min-width:960px)");
  const containerLeft = gt1200
    ? { left: 486, right: 0 }
    : gt980
    ? { left: 408, right: 0 }
    : { left: 0, right: 0 };
  // const containerLeft = gt1200 ? { left: 481, right: 0 } : gt980 ? { left: 401, right: 0 } : { left: 0, right: 0 }
  // console.log(elem)
  const [value, setValue] = useState(0);
  const [prevTyp, setPrevType] = useState(null);
  const [alignment, setAlignment] = useState("justify");
  const [fontSize, setFontSize] = useState("16px");
  const [lang, setLang] = useState("en");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const handleLangChange = (event) => {
    if (event !== null) {
      setLang(event.target.value);
    }
  };
  const handleFontSize = (event, newSize) => {
    if (newSize !== null) {
      setFontSize(newSize);
    }
  };

  useEffect(() => {
    console.log("summary mount");
    return () => {
      console.log("summary unmount");
    };
  }, []);

  useEffect(() => {
    console.log("Act change");
    if (typ === "pdf") {
      if (prevTyp === "weblink" && value === 1) {
        setValue((prev) => prev + 1);
      }
    } else {
      if (prevTyp === "pdf") {
        setValue((prev) => Math.max(prev - 1, 0));
      }
    }
    setPrevType(typ);
  }, [elem]);

  let tab_idx = 0;
  if (typ === "pdf") {
    tab_idx = 1;
  } else {
    //weblink
    if (value == 2) {
      setValue(1);
      setPrevType(typ);
    }
  }

  const handleChange = (event, newValue) => {
    console.log("tab change=>", newValue);
    setValue(newValue);
  };

  return (
    <Box className={classes.docContainerRoot} style={containerLeft}>
      <Box className={classes.docViewPanel}>
        <SizeMe monitorHeight>
          {({ size }) => {
            // console.log('size:', size.width, size.height)
            return (
              <>
                <BrowserView>
                  <Box className={classes.summHeaderClose}>
                    <Tooltip title={"Close Summary"} aria-label="Close Summary">
                      <CloseIcon
                        classes={{ root: classes.svgIconRoot }}
                        onClick={() => handleDocClose(elem.id)}
                      />
                    </Tooltip>
                  </Box>
                  <Box className={classes.summHeaderRow}>
                    <Box className={classes.summHeaderCitation}>
                      {elem.CitationOfAct}
                    </Box>
                  </Box>
                  <Box className={classes.summHeaderRow}>
                    <Box className={classes.summHeaderTitle}>
                      {elem.NameOfAct}
                    </Box>
                    <Box className={classes.summHeaderTitle}>
                      <Tooltip
                        title={"open act in new tab"}
                        aria-label="open act"
                      >
                        <Chip
                          variant="outlined"
                          className={classes.filterOptionBtn}
                          size="medium"
                          label={"source " + typ}
                          clickable
                          color="secondary"
                          onClick={() => handleDocOpenNew(elem.id)}
                          // onDelete={() => handleDocOpenNewTab(elem.id)}
                          // deleteIcon={<LaunchIcon classes={{ root: classes.svgIconRoot }} />}
                        />
                      </Tooltip>
                    </Box>
                    <Box
                      style={{
                        display:
                          (typ === "pdf" && value === 1) ||
                          (typ === "weblink" && value === 0)
                            ? ""
                            : "none",
                      }}
                    >
                      <Tooltip title={"change text alignment"}>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          aria-label="text alignment"
                        >
                          <ToggleButton
                            value="justify"
                            aria-label="justified"
                            style={{
                              padding: "6px",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FormatAlignJustifyIcon
                              fontSize="small"
                              color="secondary"
                            />
                          </ToggleButton>

                          {/* <Tooltip title={"left align summary text"}> */}
                          <ToggleButton
                            value="left"
                            aria-label="left aligned"
                            style={{
                              padding: "6px",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FormatAlignLeftIcon
                              fontSize="small"
                              color="secondary"
                            />
                          </ToggleButton>
                          {/* </Tooltip> */}
                        </ToggleButtonGroup>
                      </Tooltip>
                      {allowMultiLang ? (
                        <Tooltip
                          placement="right"
                          title={"choose summary language"}
                          aria-label="choose language"
                        >
                          <FormControl>
                            <Select
                              labelId="lang-select-label"
                              id="lang-select"
                              value={lang}
                              displayEmpty
                              onChange={handleLangChange}
                            >
                              <MenuItem value={"en"}>english</MenuItem>
                              <MenuItem value={"hi"}>हिन्दी</MenuItem>
                            </Select>
                          </FormControl>
                        </Tooltip>
                      ) : null}
                      {/* <ToggleButtonGroup
                                            value={fontSize}
                                            exclusive
                                            onChange={handleFontSize}
                                            aria-label="text alignment"
                                        >

                                            <ToggleButton value="small" aria-label="small font" style={{
                                                padding: '6px',
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '0.25rem!important'
                                            }}>
                                                A+
                                            </ToggleButton>

                                            <ToggleButton value="large" aria-label="large font" style={{
                                                padding: '6px',
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '1.25rem!important'
                                            }}>
                                                A-
                                            </ToggleButton>

                                        </ToggleButtonGroup> */}
                    </Box>
                  </Box>
                  <Box className={classes.summHeaderRow}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Summary Tabs"
                    >
                      {typ === "pdf" ? <Tab label={`Document`} /> : null}
                      <Tab label={`Summary`} />
                      <Tab label="Properties" />
                    </Tabs>
                  </Box>
                  <Paper
                    elevation={0}
                    variant="outlined"
                    className={classes.summBody}
                    style={{
                      maxHeight: winSize[1] - 190 + "px",
                      scrollbarWidth: "thin",
                    }}
                  >
                    {typ === "pdf" ? (
                      <TabPanel value={value} index={0}>
                        <DocView
                          key={elem.id}
                          open={true}
                          elem={elem}
                          url={url}
                        />
                      </TabPanel>
                    ) : null}
                    <TabPanel value={value} index={tab_idx}>
                      <Typography
                        className={classes.summBodyText}
                        style={{ textAlign: alignment }}
                      >
                        {parse(
                          (lang == "hi"
                            ? elem.SummaryText_hi_v3
                            : elem.SummaryText) + "<br>--<br><br><br><br>"
                        )}
                      </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={parseInt(tab_idx) + 1}>
                      <Box className={classes.summPropContainer}>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            {" "}
                            Applicable in
                          </Typography>
                          <ul
                            style={{
                              listStyleType: "square",
                              listStylePosition: "outside",
                              listStyleImage: "none",
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                          >
                            {elem.StatesWhereApplicable.map((x, idx) => (
                              <Typography key={idx}>
                                <li>{x}</li>
                              </Typography>
                            ))}
                          </ul>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            {" "}
                            Purpose categories
                          </Typography>
                          <ul
                            style={{
                              listStyleType: "square",
                              listStylePosition: "outside",
                              listStyleImage: "none",
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                          >
                            {deDup(elem.Categories)
                              .sort()
                              .map((x, idx) => (
                                <Typography key={idx}>
                                  <li>{split(x)}</li>
                                </Typography>
                              ))}
                          </ul>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Status of Act
                          </Typography>
                          <Typography>{elem.StatusOfAct}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Time Period
                          </Typography>
                          <Typography>{elem.TimePeriod}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Nature Of Law
                          </Typography>
                          <Typography>{elem.NatureOfLaw}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Language Of Act
                          </Typography>
                          <Typography>{elem.LanguageOfAct}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Enacted
                          </Typography>
                          <Typography>{elem.YearOfEnactment}</Typography>
                        </Box>
                      </Box>
                    </TabPanel>
                  </Paper>
                </BrowserView>
                <MobileView>
                  <Box className={classes.summHeaderTitleBlockMobile}>
                    <Box className={classes.summHeaderTitleCitation}>
                      <Box className={classes.summHeaderCitation}>
                        {elem.CitationOfAct}
                      </Box>
                      <Box className={classes.summHeaderTitle}>
                        {elem.NameOfAct}
                      </Box>
                    </Box>
                    <Box className={classes.summHeaderCloseMobile}>
                      <Tooltip
                        title={"Close Summary"}
                        aria-label="Close Summary"
                      >
                        <CloseIcon
                          classes={{ root: classes.svgIconRoot }}
                          onClick={() => handleDocClose(elem.id)}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box className={classes.summHeaderRowMobile}>
                    <Box>
                      <Tooltip
                        title={"open act in new tab"}
                        aria-label="open act"
                      >
                        <Chip
                          variant="outlined"
                          className={classes.filterOptionBtn}
                          size="medium"
                          label={"source " + typ}
                          clickable
                          color="secondary"
                          onClick={() => handleDocOpenNew(elem.id)}
                          // onDelete={() => handleDocOpenNewTab(elem.id)}
                          // deleteIcon={<LaunchIcon classes={{ root: classes.svgIconRoot }} />}
                        />
                      </Tooltip>
                    </Box>
                    <Box
                      style={{
                        display:
                          (typ === "pdf" && value === 1) ||
                          (typ === "weblink" && value === 0)
                            ? ""
                            : "none",
                      }}
                    >
                      <Tooltip title={"change text alignment"}>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          aria-label="text alignment"
                        >
                          <ToggleButton
                            value="justify"
                            aria-label="justified"
                            style={{
                              padding: "6px",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FormatAlignJustifyIcon
                              fontSize="small"
                              color="secondary"
                            />
                          </ToggleButton>

                          {/* <Tooltip title={"left align summary text"}> */}
                          <ToggleButton
                            value="left"
                            aria-label="left aligned"
                            style={{
                              padding: "6px",
                              background: "none",
                              border: "none",
                            }}
                          >
                            <FormatAlignLeftIcon
                              fontSize="small"
                              color="secondary"
                            />
                          </ToggleButton>
                          {/* </Tooltip> */}
                        </ToggleButtonGroup>
                      </Tooltip>
                      <Tooltip
                        placement="right"
                        title={"choose summary language"}
                        aria-label="choose language"
                      >
                        <FormControl>
                          <Select
                            labelId="lang-select-label"
                            id="lang-select"
                            value={lang}
                            displayEmpty
                            onChange={handleLangChange}
                          >
                            <MenuItem value={"en"}>english</MenuItem>
                            <MenuItem value={"hi"}>हिन्दी</MenuItem>
                          </Select>
                        </FormControl>
                      </Tooltip>
                      {/* <ToggleButtonGroup
                                            value={fontSize}
                                            exclusive
                                            onChange={handleFontSize}
                                            aria-label="text alignment"
                                        >

                                            <ToggleButton value="small" aria-label="small font" style={{
                                                padding: '6px',
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '0.25rem!important'
                                            }}>
                                                A+
                                            </ToggleButton>

                                            <ToggleButton value="large" aria-label="large font" style={{
                                                padding: '6px',
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '1.25rem!important'
                                            }}>
                                                A-
                                            </ToggleButton>

                                        </ToggleButtonGroup> */}
                    </Box>
                  </Box>
                  <Box className={classes.summHeaderRow}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Summary Tabs"
                    >
                      {typ === "pdf" ? <Tab label={`Document`} /> : null}
                      <Tab label={`Summary`} />
                      <Tab label="Properties" />
                    </Tabs>
                  </Box>
                  <Paper
                    elevation={0}
                    variant="outlined"
                    className={classes.summBody}
                    style={{
                      maxHeight: winSize[1] - 190 + "px",
                      scrollbarWidth: "thin",
                    }}
                  >
                    {typ === "pdf" ? (
                      <TabPanel value={value} index={0}>
                        <DocView
                          key={elem.id}
                          open={true}
                          elem={elem}
                          url={url}
                        />
                      </TabPanel>
                    ) : null}
                    <TabPanel value={value} index={tab_idx}>
                      <Typography
                        className={classes.summBodyText}
                        style={{ textAlign: alignment }}
                      >
                        {parse(
                          (lang == "hi"
                            ? elem.SummaryText_hi_v3
                            : elem.SummaryText) + "<br>--<br><br><br><br>"
                        )}
                      </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={parseInt(tab_idx) + 1}>
                      <Box className={classes.summPropContainer}>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            {" "}
                            Applicable in
                          </Typography>
                          <ul
                            style={{
                              listStyleType: "square",
                              listStylePosition: "outside",
                              listStyleImage: "none",
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                          >
                            {elem.StatesWhereApplicable.map((x, idx) => (
                              <Typography key={idx}>
                                <li>{x}</li>
                              </Typography>
                            ))}
                          </ul>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            {" "}
                            Purpose categories
                          </Typography>
                          <ul
                            style={{
                              listStyleType: "square",
                              listStylePosition: "outside",
                              listStyleImage: "none",
                              marginTop: "0px",
                              marginBottom: "10px",
                            }}
                          >
                            {deDup(elem.Categories)
                              .sort()
                              .map((x, idx) => (
                                <Typography key={idx}>
                                  <li>{split(x)}</li>
                                </Typography>
                              ))}
                          </ul>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Status of Act
                          </Typography>
                          <Typography>{elem.StatusOfAct}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Time Period
                          </Typography>
                          <Typography>{elem.TimePeriod}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Nature Of Law
                          </Typography>
                          <Typography>{elem.NatureOfLaw}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Language Of Act
                          </Typography>
                          <Typography>{elem.LanguageOfAct}</Typography>
                        </Box>
                        <Box className={classes.summParams}>
                          <Typography
                            variant="overline"
                            display="block"
                            className={classes.summPropHeaders}
                          >
                            Enacted
                          </Typography>
                          <Typography>{elem.YearOfEnactment}</Typography>
                        </Box>
                      </Box>
                    </TabPanel>
                  </Paper>
                </MobileView>
              </>
            );
          }}
        </SizeMe>
      </Box>
    </Box>
  );
}
