import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '../Styled/StyledCheckbox'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(2),
    },
    formControlLabelRoot: {
        marginLeft: '0px'
    },
    formControlLabel: {
        color: theme.palette.text.primary,
        fontSize: '0.8rem',
        paddingBottom: '3px'
    },
    paperRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        margin: '2px 2px 60px 2px',
        paddingBottom: '20px'
    }
}));


// const keys = ['NameOfAct', 'StatesWhereApplicable', 'Categories', 'TimePeriod', 'YearOfEnactment']

export default function SearchOptions({ searchKeys, toggleShowOption, handleOptionChange }) {
    const classes = useStyles()

    const { NameOfAct, StatesWhereApplicable, Categories, TimePeriod, YearOfEnactment, SummaryText } = searchKeys;

    return (
        <Paper elevation={0} className={classes.paperRoot}>
            <FormControl className={classes.formControl} component="fieldset">
                <FormHelperText>
                    The following Act attributes are used for searching the archive. You can optionally remove attributes by unchecking them.
                    <br /><br />
                </FormHelperText>
                <FormGroup>
                    <FormControlLabel
                        classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabel }}
                        control={<Checkbox checked={NameOfAct} onChange={handleOptionChange} name="NameOfAct" />}
                        label="Name"
                    />
                    <FormControlLabel
                        classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabel }}
                        control={<Checkbox checked={StatesWhereApplicable} onChange={handleOptionChange} name="StatesWhereApplicable" />}
                        label="States where applicable"
                    />
                    <FormControlLabel
                        classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabel }}
                        control={<Checkbox checked={Categories} onChange={handleOptionChange} name="Categories" />}
                        label="Categories"
                    />
                    <FormControlLabel
                        classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabel }}
                        control={<Checkbox checked={TimePeriod} onChange={handleOptionChange} name="TimePeriod" />}
                        label="Time period"
                    />
                    <FormControlLabel
                        classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabel }}
                        control={<Checkbox checked={YearOfEnactment} onChange={handleOptionChange} name="YearOfEnactment" />}
                        label="Year of Enactment"
                    />
                    <FormControlLabel
                        classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabel }}
                        control={<Checkbox checked={SummaryText} onChange={handleOptionChange} name="SummaryText" />}
                        label="Summary"
                    />
                </FormGroup>
            </FormControl>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
                width={'100%'}
            >
                <Button color='primary' variant='outlined' size="small" onClick={toggleShowOption}>
                    Close Search Options
                </Button>
            </Box>
        </Paper>
    )
}