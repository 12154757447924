import React, { useState } from 'react';
import './App.css';
import Profile from './components/Profile'
import Signin from './components/Signin'
import SigninVerify from './components/SigninVerify'
import AdminView from './components/Admin'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import MILLContainer from './components/MILLContainer';
import { LightTheme, DarkTheme } from "./components/Styled/Theme2"
import { ThemeProvider } from '@material-ui/core/styles';
import Policy from './components/Policy';

function App() {

  const date = new Date();
  const hour = date.getHours()
  const [theme, setTheme] = useState(hour > 17 ? 'dark' : 'light')

  const setThemeHandler = () => {
    console.log(theme)
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <CssBaseline>
      <ThemeProvider theme={theme === 'light' ? LightTheme : DarkTheme}>
        <Router>
          <AuthProvider>
            <Switch>
              <Route path="/profile" component={Profile} />
              <Route path="/signin" component={Signin} />
              <Route path="/admin" component={AdminView} />
              <Route path="/verify" component={SigninVerify} />
              <Route path="/policy" component={Policy} />
              <Route path="/" render={(props) => <MILLContainer theme={theme} setThemeHandler={setThemeHandler} {...props} />} />
            </Switch>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </CssBaseline>
  )
}

export default App;
