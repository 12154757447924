import React from 'react'
import Tour from 'reactour'
import { useTheme } from '@material-ui/core/styles';


export default function GuidedTour({ isTourOpen, setIsTourOpen }) {
    const theme = useTheme()
    const steps = [
        {
            selector: '',
            content: (
                <p>Let's give you a quick tour of the main interface elements! Click on the arrows below or, keyboard cursors ← → to step forward or backward in this tour. Click outside anytime to close this tour.</p>
            ),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="left-panel"]',
            content: (<p>In this left panel, you can search, sort, select, deselect, and view Acts from the database. Let's look at the details...</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="search-bar"]',
            content: (<p>Right on top is your search bar. You can search for Acts by their name or by categorical terms like 'rent', 'forest' or a state name like 'Bihar'. Matched results will be immediately listed below.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="list-options"]',
            content: (<p>Right below is a small set of utilities. On the left you have buttons to sort the list of Acts by their names or index. If there is an active selection, you can use the button on the right to deselect and reset the list below.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="list-view"]',
            content: (<p>You can vertically scroll through this list of Acts. If you select a subset of Acts -- either using the search bar or the interactive visualization, this list will immediately update showing only active selections.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="act-card"]',
            content: (<p>Hovering on a specific Act hightlights it in the visualization as well. If you are signed in you can view the summary of the Act by clicking on these cards.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="view-container"]',
            content: (<p>On the right, these are the interactive maps. The visualizations respond to mouse hovers / clicks. Try clicking on a state yourself. Notice that it selects all applicable Acts and updates the list on the left panel. Click outside the state border to deselect.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="view-header"]',
            content: (<p>This control panel on the top allows you to change mapping strategies and relevant parameters to update the map.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="view-toggle"]',
            content: (<p>This button allows you to change the mapping strategy. There are three strategies: Geographic, Categorical, and Timeline.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="current user"]',
            content: (<p>This button allows you to sign in. If you are already signed in, it shows your user profile on click.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="guided-tour"]',
            content: (<p>This button starts this guided tour of the interface if you need it again.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="change-theme"]',
            content: (<p>This button allows you to toggle between dark and light themes. Try it!</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '[name="show-about"]',
            content: (<p>This button opens up the landing page, which outlines the broader vision of the project.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
        {
            selector: '',
            content: (<p>Hope this was helpful. Please click anywhere outside in the shaded area or the close button to start using the interface. For feedback regarding the user interface please write to namita.wahi@cprindia.org.</p>),
            style: {
                backgroundColor: theme.palette.tour.background,
                color: theme.palette.tour.text
            }
        },
    ];
    return (
        <>
            { /* other stuff */}
            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                showNavigation={false}
                startAt={0}
                rounded={5}
                maskSpace={5}
                accentColor={theme.palette.tour.text}
                badgeContent={(curr, tot) => `${curr}`}
            />
        </>
    )
};