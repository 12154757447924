import React, { useState, Fragment, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import LogoCard from "./LogoCard";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { Typography } from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdropRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  logo: {
    position: "absolute",
    top: "10px",
    right: "5px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    zIndex: 1000,
    // '-webkit-touch-callout': 'none', /* iOS Safari */
    // '-webkit-user-select': 'none', /* Safari */
    // '-khtml-user-select': 'none', /* Konqueror HTML */
    // '-moz-user-select': 'none', /* Old versions of Firefox */
    // '-ms-user-select': 'none', /* Internet Explorer/Edge */
    // 'user-select': 'none' /* Non-prefixed version, currently*/
  },
  fade: {
    background: "url('/img/farm.jpg') center center no-repeat fixed",
    backgroundSize: "cover",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    position: "absolute",
    overflow: "hidden",
    willChange:
      "transform, opacity, left, top" /* Example of two <animateable-feature> */,
    "&:focus": {
      outline: "2px solid " + theme.palette.background.paper,
    },
  },
  container: {
    width: "100%",
    height: "100%",
  },
  landingBg: {
    width: "100%",
    height: "100%",
  },
  landingLeft: {
    flex: "0 0 300px",
    height: "100%",
    paddingLeft: "26px",
    paddingTop: "44px",
    [theme.breakpoints.down("sm")]: {
      width: "380px",
    },

    [theme.breakpoints.up("md")]: {
      flex: "0 0 400px",
    },

    [theme.breakpoints.up("lg")]: {
      flex: "0 0 480px",
    },
    // '-webkit-touch-callout': 'none', /* iOS Safari */
    // '-webkit-user-select': 'none', /* Safari */
    // '-khtml-user-select': 'none', /* Konqueror HTML */
    // '-moz-user-select': 'none', /* Old versions of Firefox */
    // '-ms-user-select': 'none', /* Internet Explorer/Edge */
    // 'user-select': 'none' /* Non-prefixed version, currently*/
  },

  landingRight: {
    // backgroundColor: 'rgba(0,0,0,0.5)',
    backgroundColor: fade(theme.palette.primary.darkest, 0.8),
    flexGrow: 1,
    // border: '1px green solid',
    height: "100%",
    padding: "20px 40px 20px 85px",
    color: "white", //theme.palette.text.default,
    overflow: "scroll",
    "& p": {
      lineHeight: "2em",
    },
    // '-webkit-touch-callout': 'none', /* iOS Safari */
    // '-webkit-user-select': 'none', /* Safari */
    // '-khtml-user-select': 'none', /* Konqueror HTML */
    // '-moz-user-select': 'none', /* Old versions of Firefox */
    // '-ms-user-select': 'none', /* Internet Explorer/Edge */
    // 'user-select': 'none' /* Non-prefixed version, currently*/
  },
  treeRoot: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select": "none" /* Non-prefixed version, currently*/,
  },
  button: {
    marginTop: "140px",
    color: "white",
    border: "2px solid rgb(250,255, 255)",
    backgroundColor: "rgba(0,0,0,0.4)",
    "&:hover": {
      border: "2px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paperDark,
    },
  },
  txtContainer: {
    maxWidth: "800px",
  },
  txtHeader: {
    marginBottom: "0.5em",
    marginTop: "1em",
  },

  txtSubheader: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  txtBody: {
    fontSize: "1.5em",
    marginBottom: "2em",
  },
  txtTeamTitle: {
    fontWeight: "500",
    fontSize: "1.3em",
  },
  txtTeamTitleLink: {
    color: "#fff",
  },
  txtTeamProfile: {
    marginBottom: "1em",
  },
  links: {
    cursor: "pointer",
    opacity: 0.8,
    color: "white",
    textDecoration: "underline",
  },
  noselect: {
    "-webkit-touch-callout": "none" /* iOS Safari */,
    "-webkit-user-select": "none" /* Safari */,
    "-khtml-user-select": "none" /* Konqueror HTML */,
    "-moz-user-select": "none" /* Old versions of Firefox */,
    "-ms-user-select": "none" /* Internet Explorer/Edge */,
    "user-select": "none" /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */,
  },
  mobileTxtTitle: {
    marginBottom: "0.5em",
    marginTop: "0.5em",
  },
  mobileTxtBody: {
    fontSize: "1.2em",
    marginBottom: "2em",
  },
  mobileHeader: {
    // position: 'absolute',
    // top: '5px',
    // right: '0px',
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-start",
    marginRight: "50px",
  },
  mobileHeaderBtn: {
    marginTop: "0px",
    marginRight: "3px",
    color: "white",
    // border: '2px solid rgb(250,255, 255)',
    backgroundColor: "rgba(0,0,0,0.4)",
    "&:hover": {
      border: "2px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paperDark,
    },
  },
  mobileActionBtn: {
    position: "absolute",
    bottom: 20,
    right: 16,
    border: "2px solid " + theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paperDark,
    "&:hover": {
      border: "2px solid " + "white",
      color: "white",
      backgroundColor: "rgba(0,0,0,0.4)",
    },
  },
  mobileLandingRight: {
    // backgroundColor: 'rgba(0,0,0,0.5)',
    backgroundColor: fade(theme.palette.primary.darkest, 0.8),
    flexGrow: 1,
    // border: '1px green solid',
    height: "100%",
    padding: "20px 20px 20px 25px",
    color: "white", //theme.palette.text.default,
    overflow: "scroll",
    "& p": {
      lineHeight: "2em",
    },
    // '-webkit-touch-callout': 'none', /* iOS Safari */
    // '-webkit-user-select': 'none', /* Safari */
    // '-khtml-user-select': 'none', /* Konqueror HTML */
    // '-moz-user-select': 'none', /* Old versions of Firefox */
    // '-ms-user-select': 'none', /* Internet Explorer/Edge */
    // 'user-select': 'none' /* Non-prefixed version, currently*/
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  var size = useWindowSize();

  const style = useSpring({
    from: {
      opacity: open ? 0 : 1,
      height: open ? 50 : size[1], // - 40,,
      width: open ? 50 : size[0], // - 100,
      right: open ? 4 : 0,
      top: open ? 10 : 0,
      borderRadius: open ? 50 : 0,
    },
    to: {
      opacity: open ? 1 : 0.2,
      height: open ? size[1] : 50,
      width: open ? size[0] : 50,
      right: open ? 0 : 4,
      top: open ? 0 : 10,
      borderRadius: open ? 0 : 50,
    },
    config: { mass: 4, tension: 350, friction: 80 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      // console.log("done anim:" + open);
      if (!open && onExited) {
        // console.log("fade exit");
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function AboutDialog({
  showIntroAtStart,
  showTourAfterIntro,
  toggleTourStateHandler,
  isLoggedIn,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser, signinLink } = useAuth();
  const [expanded, setExpanded] = React.useState("4");
  const [selected, setSelected] = React.useState("4");
  const [countOpenAboutDialog, setCountOpenAboutDialog] = React.useState(0);
  const [openAboutDialog, setOpenAboutDialog] = React.useState(true);

  useEffect(() => {
    if (isLoggedIn || !showIntroAtStart) {
      // console.log('if user logged in. dont open the about dialog at the start. ')
      setOpenAboutDialog(false);
    }
  }, []);

  const handleTreeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleTreeSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const toggleOpenAboutDialog = () => {
    // console.log(
    //   "clicked on logo. countOpenAboutDialog:" + countOpenAboutDialog
    // );
    // open tour after AboutDialog closes. only for the first time.
    if (
      openAboutDialog === true &&
      countOpenAboutDialog === 0 &&
      showTourAfterIntro
    ) {
      toggleTourStateHandler();
    }
    // toggle AboutDialog state
    setOpenAboutDialog((prevOpen) => !prevOpen);
    setCountOpenAboutDialog((prevCount) => prevCount + 1);
  };

  // const handleClose = () => {
  //     setOpen(false);
  //     setIsTourOpen()
  // };

  // const onExited = () => {

  // }

  const mill = isBrowser ? (
    <Typography variant="h3" className={classes.txtHeader}>
      Mapping Indian Land Laws
    </Typography>
  ) : (
    <Typography variant="h4" className={classes.txtHeader}>
      Mapping Indian Land Laws
    </Typography>
  );

  const millBody = (
    <>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        Why do we need to map Indian land laws?
      </Typography>
      <Typography variant="body1" className={classes.txtBody} gutterBottom>
        More than seventy years since India became a constitutional democratic
        republic, almost &nbsp;
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://theprint.in/opinion/land-conflict-rampant-in-india-because-constitution-has-made-sparse-reference-to-it/372597/"
        >
          60 percent of Indians depend upon land for their livelihood
        </Link>
        . Land is not only the most important economic resource for most
        Indians, it is central to individual and community identity, history,
        and culture. Worryingly, land conflict is ubiquitous in India today, and
        threatens its economic development and its social and political
        stability. Millions of Indians are affected by conflict over land, which
        threatens investments worth billions of dollars. Land disputes clog all
        levels of courts, accounting for the{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://www.cprindia.org/sites/default/files/Understanding%20Land%20Conflict%20in%20India%20and%20Suggestions%20for%20Reform%20by%20Namita%20Wahi.pdf"
        >
          largest set of cases
        </Link>{" "}
        in absolute numbers and judicial pendency. Conflicts between laws, and
        individual and government failure to comply with the rule of law, create
        legal disputes. Yet, the number and extent of land laws in India is
        anyone's guess, because there is no existing publicly available
        comprehensive database of land laws. This in turn restricts citizen
        access to the laws that govern one of the most important aspects of
        their lives, thereby hampering the realisation of the constitutional
        promise of participatory democracy.
      </Typography>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        How did we create the Mapping Indian Land Laws website?
      </Typography>
      <Typography variant="body1" className={classes.txtBody} gutterBottom>
        Over the past five years, researchers at the Land Rights Initiative
        (LRI) have travelled the length and breadth of the country to create a
        database of over one thousand colonial and post-colonial central and
        state laws. The LRI team has painstakingly collected officially
        authenticated copies of all originally enacted central and state laws
        from a geographically representative sample of eight states, namely,
        Andhra Pradesh, Assam, Bihar, Gujarat, Jharkhand, Meghalaya, Punjab, and
        Telangana, numbering nearly five hundred. From laws pertaining to land
        reforms and{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://cprindia.org/research/reports/land-acquision-india-review-supreme-court-cases-1950-2016"
        >
          land acquisition
        </Link>
        , to revenue and taxation and land use;{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://www.cprindia.org/research/reports/legal-regime-and-political-economy-land-rights-scheduled-tribes-scheduled-areas#:~:text=Opinions-,The%20Legal%20Regime%20and%20Political%20Economy%20of%20Land%20Rights%20Of,the%20Scheduled%20Areas%20of%20India&text=Context:&text=Creating%20a%20legal%20framework%20that,politically%20feasible,%20has%20proved%20challenging."
        >
          forest and mining laws to laws applicable to Scheduled Areas
        </Link>
        ; from laws promoting and regulating urban and infrastructure
        development, to laws dealing with evacuee, enemy, ancestral and
        religious property, this vast legal apparatus governs the lives of the
        people of India, and their interactions with each other and the
        state.All laws on this website have been categorized into thirty one
        separate thematic categories, and further sub categorized into as many
        as one hundred and fifty one sub categories. Moreover, all laws have
        been summarised using simple English for easy comprehension by
        all.Through this process has been birthed the most comprehensive, online
        interactive architecture of land laws in India.
      </Typography>
      {/* <Typography variant="h4" display="block" className={classes.txtSubheader}>Thanks to our sponsors!</Typography>
            <Typography variant='body1' className={classes.txtBody} gutterBottom>
                This project has been made possible through generous financial support from the Indian Council for Social Science Research and Omidyar Network India.
            </Typography> */}
      <Typography
        variant="caption"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        ---
        <br />
        DISCLAIMER: The documents on the Mapping Indian Land Laws Website (“MILL
        Website”) are being furnished to you by the Land Rights Initiative at
        the Centre for Policy Research, New Delhi for your information.LRI makes
        every effort to use reliable and comprehensive information, but LRI does
        not represent that this information is fully accurate or complete.The
        contents of the documents have been obtained from sources LRI believes
        to be reliable.These contents have not been independently verified, and
        LRI makes no representation or warranty as to their accuracy,
        completeness, or correctness.In some cases, the Principal Act and/or the
        Amendment Act may not be available.Principal Acts may or may not include
        subsequent amendments.For authoritative text, please contact the
        relevant state department concerned, or refer to the latest government
        publication, or the gazette notification.Any person using any material
        on the MILL Website should take their own professional and legal advice
        before acting on any information contained in the material.LRI, or any
        persons or organisations connected with it, do not accept any liability
        arising from the use of any document on the MILL Website.LRI, or any
        persons or organisations connected with it, shall not be in any way
        responsible for any loss, damage, or distress to any person on account
        of any action taken or not taken on the basis of any document on the
        MILL Website.
      </Typography>
      <Typography
        variant="caption"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <br />
        <br />
        Copyright © 2022 CPR LRI. All Rights Reserved.
      </Typography>
    </>
  );

  const millBodyMobile = (
    <>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        Why do we need to map Indian land laws?
      </Typography>
      <Typography
        variant="body1"
        className={classes.mobileTxtBody}
        gutterBottom
      >
        More than seventy years since India became a constitutional democratic
        republic, almost &nbsp;
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://theprint.in/opinion/land-conflict-rampant-in-india-because-constitution-has-made-sparse-reference-to-it/372597/"
        >
          60 percent of Indians depend upon land for their livelihood
        </Link>
        . Land is not only the most important economic resource for most
        Indians, it is central to individual and community identity, history,
        and culture. Worryingly, land conflict is ubiquitous in India today, and
        threatens its economic development and its social and political
        stability. Millions of Indians are affected by conflict over land, which
        threatens investments worth billions of dollars. Land disputes clog all
        levels of courts, accounting for the{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://www.cprindia.org/sites/default/files/Understanding%20Land%20Conflict%20in%20India%20and%20Suggestions%20for%20Reform%20by%20Namita%20Wahi.pdf"
        >
          largest set of cases
        </Link>{" "}
        in absolute numbers and judicial pendency. Conflicts between laws, and
        individual and government failure to comply with the rule of law, create
        legal disputes. Yet, the number and extent of land laws in India is
        anyone's guess, because there is no existing publicly available
        comprehensive database of land laws. This in turn restricts citizen
        access to the laws that govern one of the most important aspects of
        their lives, thereby hampering the realisation of the constitutional
        promise of participatory democracy.
      </Typography>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        How did we create the Mapping Indian Land Laws website?
      </Typography>
      <Typography
        variant="body1"
        className={classes.mobileTxtBody}
        gutterBottom
      >
        Over the past five years, researchers at the Land Rights Initiative
        (LRI) have travelled the length and breadth of the country to create a
        database of over one thousand colonial and post-colonial central and
        state laws. The LRI team has painstakingly collected officially
        authenticated copies of all originally enacted central and state laws
        from a geographically representative sample of eight states, namely,
        Andhra Pradesh, Assam, Bihar, Gujarat, Jharkhand, Meghalaya, Punjab, and
        Telangana, numbering nearly five hundred. From laws pertaining to land
        reforms and{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://cprindia.org/research/reports/land-acquision-india-review-supreme-court-cases-1950-2016"
        >
          land acquisition
        </Link>
        , to revenue and taxation and land use;{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://www.cprindia.org/research/reports/legal-regime-and-political-economy-land-rights-scheduled-tribes-scheduled-areas#:~:text=Opinions-,The%20Legal%20Regime%20and%20Political%20Economy%20of%20Land%20Rights%20Of,the%20Scheduled%20Areas%20of%20India&text=Context:&text=Creating%20a%20legal%20framework%20that,politically%20feasible,%20has%20proved%20challenging."
        >
          forest and mining laws to laws applicable to Scheduled Areas
        </Link>
        ; from laws promoting and regulating urban and infrastructure
        development, to laws dealing with evacuee, enemy, ancestral and
        religious property, this vast legal apparatus governs the lives of the
        people of India, and their interactions with each other and the
        state.All laws on this website have been categorized into thirty one
        separate thematic categories, and further sub categorized into as many
        as one hundred and fifty one sub categories. Moreover, all laws have
        been summarised using simple English for easy comprehension by
        all.Through this process has been birthed the most comprehensive, online
        interactive architecture of land laws in India.
      </Typography>
      {/* <Typography variant="h5" display="block" className={classes.txtSubheader}>Thanks to our sponsors!</Typography>
            <Typography variant='body1' className={classes.mobileTxtBody} gutterBottom>
                This project has been made possible through generous financial support from the Indian Council for Social Science Research and Omidyar Network India.
            </Typography> */}
      <Typography
        variant="caption"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        ---
        <br />
        DISCLAIMER: The documents on the Mapping Indian Land Laws Website (“MILL
        Website”) are being furnished to you by the Land Rights Initiative at
        the Centre for Policy Research, New Delhi for your information.LRI makes
        every effort to use reliable and comprehensive information, but LRI does
        not represent that this information is fully accurate or complete.The
        contents of the documents have been obtained from sources LRI believes
        to be reliable.These contents have not been independently verified, and
        LRI makes no representation or warranty as to their accuracy,
        completeness, or correctness.In some cases, the Principal Act and/or the
        Amendment Act may not be available.Principal Acts may or may not include
        subsequent amendments.For authoritative text, please contact the
        relevant state department concerned, or refer to the latest government
        publication, or the gazette notification.Any person using any material
        on the MILL Website should take their own professional and legal advice
        before acting on any information contained in the material.LRI, or any
        persons or organisations connected with it, do not accept any liability
        arising from the use of any document on the MILL Website.LRI, or any
        persons or organisations connected with it, shall not be in any way
        responsible for any loss, damage, or distress to any person on account
        of any action taken or not taken on the basis of any document on the
        MILL Website.
      </Typography>
      <Typography
        variant="caption"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <br />
        <br />
        Copyright © 2022 CPR LRI. All Rights Reserved.
      </Typography>
    </>
  );

  const lri = isBrowser ? (
    <Typography variant="h3" className={classes.txtHeader}>
      Land Rights Initiative
    </Typography>
  ) : (
    <Typography variant="h4" className={classes.txtHeader}>
      Land Rights Initiative
    </Typography>
  );
  const lriBodyMobile = (
    <>
      <Typography
        variant="body1"
        className={classes.mobileTxtBody}
        gutterBottom
      >
        The Land Rights Initiative (LRI) at the Centre for Policy Research was
        founded on November 11, 2014 as an institutional space for building
        systematic knowledge on land rights issues. As the pioneering initiative
        in the land policy space, LRI seeks to reorient the discourse on land
        from the utilitarian development discourse, which is premised on the
        greatest good of the greatest number, to the dignitarian rights
        discourse, which is premised on empowering each individual and ensuring
        the realisation of a dignified life by all. LRI generates comprehensive
        primary data and research reports to help us better understand the
        reasons for land conflict with a view to informing policy change that
        can mitigate such conflict. At LRI, we believe that systemic and
        long-term policy change cannot occur without substantial buy in from an
        informed citizenry that can push for equitable, efficient and
        sustainable land laws and policies. Find out more about LRI{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://cprindia.org/projects/land-rights-initiative"
        >
          here
        </Link>
        .
      </Typography>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        LRI Publications
      </Typography>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        Briefs and reports:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/legal-regime-and-political-economy-land-rights-scheduled-tribes-scheduled-areas"
            >
              {" "}
              The Legal Regime and Political Economy of Land Rights of Scheduled
              Tribes in the Scheduled Areas of India
            </Link>
            , Centre for Policy Research (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/land-acquision-india-review-supreme-court-cases-1950-2016"
            >
              {" "}
              Land Acquisition in India: A Review of Supreme Court Cases
              (1950-2016)
            </Link>
            , Centre for Policy Research (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/litigating-right-health-india-can-litigation-fix-health-system-crisis"
            >
              {" "}
              Litigating the right to health in India: Can litigation fix a
              health system in crisis?
            </Link>
            , Chr. Michelsen Institute (2012)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/land-rights-and-digital-revolution-india-potential-and-pitfalls"
            >
              {" "}
              Land Rights and The Digital Revolution in India: Potential and
              Pitfalls
            </Link>
            , Centre for Policy Research (2022)
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        Book chapters:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/chapters/fundamental-right-property-indian-constitution"
            >
              The Fundamental Right to Property in the Indian Constitution
            </Link>
            , The Oxford Handbook of the Indian Constitution (2016)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/chapters/tension-between-property-rights-and-social-and-economic-rights-case-study-india"
            >
              The Tension between Property Rights and Social and Economic
              Rights: A Case Study of India
            </Link>
            , in Social and Economic Rights in Theory and Practice: Critical
            Inquiries (2014)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/chapters/litigating-health-rights-can-courts-bring-more-justice-health"
            >
              Litigating Health Rights: Can Courts Bring More Justice to Health,
              in India: Citizens, Courts and the Right to Health: Between
              Promise and Progress?
            </Link>
            , Harvard University Press (2011)
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        Working Papers:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/papers/constituent-assembly-debates-property-unravelling-property-paradox"
            >
              The Constituent Assembly Debates on Property: Unravelling the
              Property Paradox
            </Link>
            , Social Science Research Network (2021)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/papers/evolution-right-property-india-drafting-constitution"
            >
              The Evolution of the Right to Property in India before the
              Drafting of the Constitution
            </Link>
            , Centre for Policy Research (2021)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/papers/property-and-sovereignty-creating-destroying-and-resurrecting-property-rights"
            >
              Property and Sovereignty: Creating, Destroying, and Resurrecting
              Property Rights in British India (1600-1800)
            </Link>
            , Centre for Policy Research (2020)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.cprindia.org/policy-challenge/7872/regulation-and-resources"
            >
              Understanding Land Conflict in India and Suggestions for Reform
            </Link>
            , Centre for Policy Research (2019)
          </li>
        </ul>
      </Typography>

      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        Articles:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/articles/land-acquisition-development-and-constitution"
            >
              Land acquisition, Development and the Constitution
            </Link>
            , Seminar (2013)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/articles/human-rights-accountability-imf-and-world-bank-critique-existing-mechanisms-and-theory"
            >
              Human Rights Accountability of the IMF and the World Bank: A
              Critique of Existing Mechanisms and a Theory of Horizontal
              Accountability
            </Link>
            , U. C. Davis Journal of International Law and Policy (2006)
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" display="block" className={classes.txtSubheader}>
        Opinion pieces:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://theprint.in/opinion/land-conflict-rampant-in-india-because-constitution-has-made-sparse-reference-to-it/372597/"
            >
              Land conflict rampant in India because Constitution has made
              sparse reference to it
            </Link>
            , The Print (2020)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://indconlawphil.wordpress.com/2019/01/07/iclp-book-discussion-rohit-des-a-peoples-constitution-iv-always-of-the-people/"
            >
              ICLP Book Discussion: Rohit De’s “A People’s Constitution” – IV:
              Always of the People?
            </Link>
            , Indian Constitutional Law and Philosophy (2019)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://economictimes.indiatimes.com/news/politics-and-nation/how-central-and-state-governments-have-diluted-the-historic-land-legislation-of-2013/articleshow/63764378.cms"
            >
              How Central and state governments have diluted the historic land
              legislation of 2013
            </Link>
            , The Economic Times (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.livelaw.in/conflict-benches-scs-february-8-judgment-indore-development-authority-not-supported-text-intent-act-says-namita-wahi-expert-land-acquisition/"
            >
              Conflict of Benches: The SC’s February 8 Judgment in Indore
              Development Authority is Not Supported by Text or Intent of the
              Act
            </Link>
            , Live Law (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://thewire.in/law/singur-turned-tides"
            >
              How Singur Turned the Tide on Land Acquisition in India
            </Link>
            , The Wire (2016)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.hindustantimes.com/analysis/sc-questioning-public-purpose-shows-singur-ruling-is-critical/story-qgTeOCEf01gTfeNDxH75zN.html"
            >
              SC questioning ‘public purpose’ shows Singur ruling is critical
            </Link>
            , The Hindustan Times (2016)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.indiatoday.in/magazine/books/story/20150622-jairam-ramesh-land-acquisition-law-book-819874-2015-06-11"
            >
              The story of Jairam Rajya
            </Link>
            , India Today (2015)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://indianexpress.com/article/opinion/op_eds/lay-of-the-land/"
            >
              Lay of the land
            </Link>
            , The Indian Express (2015)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.newindianexpress.com/opinions/2013/sep/11/Compromise-over-land-takeover-515435.html"
            >
              Compromise over land takeover
            </Link>
            , The New Indian Express (2013)
          </li>
        </ul>
      </Typography>
    </>
  );
  const lriBody = (
    <>
      <Typography variant="body1" className={classes.txtBody} gutterBottom>
        The Land Rights Initiative (LRI) at the Centre for Policy Research was
        founded on November 11, 2014 as an institutional space for building
        systematic knowledge on land rights issues. As the pioneering initiative
        in the land policy space, LRI seeks to reorient the discourse on land
        from the utilitarian development discourse, which is premised on the
        greatest good of the greatest number, to the dignitarian rights
        discourse, which is premised on empowering each individual and ensuring
        the realisation of a dignified life by all. LRI generates comprehensive
        primary data and research reports to help us better understand the
        reasons for land conflict with a view to informing policy change that
        can mitigate such conflict. At LRI, we believe that systemic and
        long-term policy change cannot occur without substantial buy in from an
        informed citizenry that can push for equitable, efficient and
        sustainable land laws and policies. Find out more about LRI{" "}
        <Link
          className={classes.links}
          target="_blank"
          rel="noreferrer"
          href="https://cprindia.org/projects/land-rights-initiative"
        >
          here
        </Link>
        .
      </Typography>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        LRI Publications
      </Typography>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        Briefs and reports:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/legal-regime-and-political-economy-land-rights-scheduled-tribes-scheduled-areas"
            >
              {" "}
              The Legal Regime and Political Economy of Land Rights of Scheduled
              Tribes in the Scheduled Areas of India
            </Link>
            , Centre for Policy Research (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/land-acquision-india-review-supreme-court-cases-1950-2016"
            >
              {" "}
              Land Acquisition in India: A Review of Supreme Court Cases
              (1950-2016)
            </Link>
            , Centre for Policy Research (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/litigating-right-health-india-can-litigation-fix-health-system-crisis"
            >
              {" "}
              Litigating the right to health in India: Can litigation fix a
              health system in crisis?
            </Link>
            , Chr. Michelsen Institute (2012)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/reports/land-rights-and-digital-revolution-india-potential-and-pitfalls"
            >
              {" "}
              Land Rights and The Digital Revolution in India: Potential and
              Pitfalls
            </Link>
            , Centre for Policy Research (2022)
          </li>
        </ul>
      </Typography>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        Book chapters:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/chapters/fundamental-right-property-indian-constitution"
            >
              The Fundamental Right to Property in the Indian Constitution
            </Link>
            , The Oxford Handbook of the Indian Constitution (2016)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/chapters/tension-between-property-rights-and-social-and-economic-rights-case-study-india"
            >
              The Tension between Property Rights and Social and Economic
              Rights: A Case Study of India
            </Link>
            , in Social and Economic Rights in Theory and Practice: Critical
            Inquiries (2014)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/chapters/litigating-health-rights-can-courts-bring-more-justice-health"
            >
              Litigating Health Rights: Can Courts Bring More Justice to Health,
              in India: Citizens, Courts and the Right to Health: Between
              Promise and Progress?
            </Link>
            , Harvard University Press (2011)
          </li>
        </ul>
      </Typography>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        Working Papers:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/papers/constituent-assembly-debates-property-unravelling-property-paradox"
            >
              The Constituent Assembly Debates on Property: Unravelling the
              Property Paradox
            </Link>
            , Social Science Research Network (2021)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/papers/evolution-right-property-india-drafting-constitution"
            >
              The Evolution of the Right to Property in India before the
              Drafting of the Constitution
            </Link>
            , Centre for Policy Research (2021)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/research/papers/property-and-sovereignty-creating-destroying-and-resurrecting-property-rights"
            >
              Property and Sovereignty: Creating, Destroying, and Resurrecting
              Property Rights in British India (1600-1800)
            </Link>
            , Centre for Policy Research (2020)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.cprindia.org/policy-challenge/7872/regulation-and-resources"
            >
              Understanding Land Conflict in India and Suggestions for Reform
            </Link>
            , Centre for Policy Research (2019)
          </li>
        </ul>
      </Typography>

      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        Articles:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/articles/land-acquisition-development-and-constitution"
            >
              Land acquisition, Development and the Constitution
            </Link>
            , Seminar (2013)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://cprindia.org/articles/human-rights-accountability-imf-and-world-bank-critique-existing-mechanisms-and-theory"
            >
              Human Rights Accountability of the IMF and the World Bank: A
              Critique of Existing Mechanisms and a Theory of Horizontal
              Accountability
            </Link>
            , U. C. Davis Journal of International Law and Policy (2006)
          </li>
        </ul>
      </Typography>
      <Typography variant="h4" display="block" className={classes.txtSubheader}>
        Opinion pieces:
      </Typography>
      <Typography
        variant="body1"
        className={classes.txtTeamProfile}
        gutterBottom
      >
        <ul>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://theprint.in/opinion/land-conflict-rampant-in-india-because-constitution-has-made-sparse-reference-to-it/372597/"
            >
              Land conflict rampant in India because Constitution has made
              sparse reference to it
            </Link>
            , The Print (2020)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://indconlawphil.wordpress.com/2019/01/07/iclp-book-discussion-rohit-des-a-peoples-constitution-iv-always-of-the-people/"
            >
              ICLP Book Discussion: Rohit De’s “A People’s Constitution” – IV:
              Always of the People?
            </Link>
            , Indian Constitutional Law and Philosophy (2019)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://economictimes.indiatimes.com/news/politics-and-nation/how-central-and-state-governments-have-diluted-the-historic-land-legislation-of-2013/articleshow/63764378.cms"
            >
              How Central and state governments have diluted the historic land
              legislation of 2013
            </Link>
            , The Economic Times (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.livelaw.in/conflict-benches-scs-february-8-judgment-indore-development-authority-not-supported-text-intent-act-says-namita-wahi-expert-land-acquisition/"
            >
              Conflict of Benches: The SC’s February 8 Judgment in Indore
              Development Authority is Not Supported by Text or Intent of the
              Act
            </Link>
            , Live Law (2018)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://thewire.in/law/singur-turned-tides"
            >
              How Singur Turned the Tide on Land Acquisition in India
            </Link>
            , The Wire (2016)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.hindustantimes.com/analysis/sc-questioning-public-purpose-shows-singur-ruling-is-critical/story-qgTeOCEf01gTfeNDxH75zN.html"
            >
              SC questioning ‘public purpose’ shows Singur ruling is critical
            </Link>
            , The Hindustan Times (2016)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.indiatoday.in/magazine/books/story/20150622-jairam-ramesh-land-acquisition-law-book-819874-2015-06-11"
            >
              The story of Jairam Rajya
            </Link>
            , India Today (2015)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://indianexpress.com/article/opinion/op_eds/lay-of-the-land/"
            >
              Lay of the land
            </Link>
            , The Indian Express (2015)
          </li>
          <li>
            <Link
              className={classes.links}
              target="_blank"
              rel="noreferrer"
              href="https://www.newindianexpress.com/opinions/2013/sep/11/Compromise-over-land-takeover-515435.html"
            >
              Compromise over land takeover
            </Link>
            , The New Indian Express (2013)
          </li>
        </ul>
      </Typography>
    </>
  );

  const team = isBrowser ? (
    <Typography variant="h3" className={classes.txtHeader}>
      Research and Development Team
    </Typography>
  ) : (
    <Typography variant="h4" className={classes.txtHeader}>
      Research and Development Team
    </Typography>
  );
  const teamBody = (
    <>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Dr. Namita Wahi,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Senior Fellow, CPR and Founding Director, Land Rights Initiative
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://cprindia.org/people/namita-wahi"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Kaustuv De Biswas,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Visiting Fellow, CPR, Land Rights Initiative
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/kaustuvdebiswas/"
          >
            Profile
          </Link>
        </span>
      </div>
      {/* <Typography variant="h4" className={classes.txtSubheader}>
        Alumni
      </Typography> */}
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Aakansha Jain,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/aakansha-jain"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Ankit Bhatia,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/ankit-r-bhatia/"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Kuldeep Lakwal,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.cprindia.org/people/kuldeep-lakwal"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Sanjana Sethi,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/sanjanasethi"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Soumya Jha,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/soumya-jha-920609142"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Trisha Pande,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.cprindia.org/people/trisha-pande"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Upasana Chauhan,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        ></span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Vrinda Bhardwaj,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://cprindia.org/people/vrinda-bhardwaj"
          >
            Profile
          </Link>
        </span>
      </div>
    </>
  );
  const teamBodyMobile = (
    <>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Dr. Namita Wahi,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Senior Fellow, CPR and Founding Director, Land Rights Initiative
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://cprindia.org/people/namita-wahi"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Kaustuv De Biswas,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Visiting Fellow, CPR, Land Rights Initiative
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/kaustuvdebiswas/"
          >
            Profile
          </Link>
        </span>
      </div>
      {/* <Typography variant="h4" className={classes.txtSubheader}>
        Alumni
      </Typography> */}
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Aakansha Jain,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/aakansha-jain"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Ankit Bhatia,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/ankit-r-bhatia/"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Kuldeep Lakwal,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.cprindia.org/people/kuldeep-lakwal"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Sanjana Sethi,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/sanjanasethi"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Soumya Jha,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/soumya-jha-920609142"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Trisha Pande,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://www.cprindia.org/people/trisha-pande"
          >
            Profile
          </Link>
        </span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Upasana Chauhan,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        ></span>
      </div>
      <div style={{ fontSize: "20px", marginBottom: "20px" }}>
        Vrinda Bhardwaj,{" "}
        <span style={{ fontStyle: "italic", fontSize: "14px" }}>
          Former Research Associate
        </span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "12px",
            marginLeft: "15px",
            opacity: 0.5,
          }}
        >
          <Link
            className={classes.links}
            target="_blank"
            rel="noreferrer"
            href="https://cprindia.org/people/vrinda-bhardwaj"
          >
            Profile
          </Link>
        </span>
      </div>
    </>
  );

  return (
    <>
      <BrowserView>
        <LogoCard handleOpen={toggleOpenAboutDialog} />
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={openAboutDialog}
          onClose={toggleOpenAboutDialog}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            classes: { root: classes.backdropRoot },
          }}
        >
          <Fade in={openAboutDialog} name="modal" className={classes.fade}>
            <div name="container" className={classes.container}>
              {true ? (
                <Box
                  name="landing-bg"
                  className={classes.landingBg}
                  display="flex"
                  flexDirection="row"
                >
                  <Box className={classes.landingLeft}>
                    <TreeView
                      className={classes.treeRoot}
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      defaultSelected={"4"}
                      expanded={expanded}
                      selected={selected}
                      onNodeToggle={handleTreeToggle}
                      onNodeSelect={handleTreeSelect}
                    >
                      <TreeItem
                        nodeId="4"
                        label="About Mapping Indian Land Laws"
                      >
                        {/* <TreeItem nodeId="5" label="Laws of our Land" />
                                            <TreeItem nodeId="6" label="Informed Citizenry" />
                                            <TreeItem nodeId="7" label="Social Change" /> */}
                      </TreeItem>
                      <TreeItem nodeId="8" label="About Land Rights Initiative">
                        {/* <TreeItem nodeId="9" label="Mapping in Category Space" />
                                            <TreeItem nodeId="10" label="Mapping in Time" /> */}
                      </TreeItem>
                      {/* <TreeItem nodeId="11" label="Report">
                                            <TreeItem nodeId="12" label="Download" />
                                        </TreeItem> */}
                      <TreeItem
                        nodeId="13"
                        label="Research and Development Team"
                      >
                        {/* <TreeItem nodeId="14" label="About the Intiative" />
                                            <TreeItem nodeId="15" label="Team and Donors" /> */}
                      </TreeItem>
                    </TreeView>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="large"
                      classes={{ outlinedSecondary: classes.button }}
                      onClick={toggleOpenAboutDialog}
                    >
                      {"Explore Maps \u1405"}
                    </Button>
                  </Box>
                  <Box className={classes.landingRight}>
                    <div className={classes.logo}>
                      <LogoCard
                        isOpen={openAboutDialog}
                        handleOpen={toggleOpenAboutDialog}
                      />
                    </div>

                    {selected === "4" ? mill : selected === "8" ? lri : team}

                    <div className={classes.txtContainer}>
                      {/* <Typography variant="overline" display="block">Vision</Typography> */}
                      {selected === "4"
                        ? millBody
                        : selected === "8"
                        ? lriBody
                        : teamBody}
                    </div>
                  </Box>
                </Box>
              ) : null}
            </div>
          </Fade>
        </Modal>
      </BrowserView>
      <MobileView>
        <LogoCard handleOpen={toggleOpenAboutDialog} />

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={openAboutDialog}
          onClose={toggleOpenAboutDialog}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            classes: { root: classes.backdropRoot },
          }}
        >
          <Fade in={openAboutDialog} name="modal" className={classes.fade}>
            <div name="container" className={classes.container}>
              {true ? (
                <Box
                  name="landing-bg"
                  className={classes.landingBg}
                  display="flex"
                  flexDirection="row"
                >
                  <Box className={classes.mobileLandingRight}>
                    <Box
                      className={classes.mobileHeader}
                      style={{ display: openAboutDialog ? "block" : "none" }}
                    >
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        classes={{ outlinedSecondary: classes.mobileHeaderBtn }}
                        onClick={() => setSelected("4")}
                      >
                        About
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        classes={{ outlinedSecondary: classes.mobileHeaderBtn }}
                        onClick={() => setSelected("8")}
                      >
                        LRI
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        classes={{ outlinedSecondary: classes.mobileHeaderBtn }}
                        onClick={() => setSelected("13")}
                      >
                        Team
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        classes={{ outlinedSecondary: classes.mobileHeaderBtn }}
                        onClick={() => history.push("/profile")}
                      >
                        {currentUser ? "Profile" : "Sign In"}
                      </Button>
                      <div className={classes.logo}>
                        <LogoCard
                          isOpen={openAboutDialog}
                          handleOpen={toggleOpenAboutDialog}
                        />
                      </div>
                    </Box>

                    {selected === "4" ? mill : selected === "8" ? lri : team}

                    <div className={classes.txtContainer}>
                      {/* <Typography variant="overline" display="block">Vision</Typography> */}
                      {selected === "4"
                        ? millBodyMobile
                        : selected === "8"
                        ? lriBodyMobile
                        : teamBodyMobile}
                    </div>
                    <Fab
                      color="secondary"
                      variant="outlined"
                      size="large"
                      className={classes.mobileActionBtn}
                      onClick={toggleOpenAboutDialog}
                      style={{ display: openAboutDialog ? "block" : "none" }}
                    >
                      {"Explore Archive \u1405"}
                    </Fab>
                  </Box>
                </Box>
              ) : null}
            </div>
          </Fade>
        </Modal>
      </MobileView>
    </>
  );
}
