import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { analytics } from "../../firebase";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import TooltipMouse from "./../TooltipMouse";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  tileRoot: {
    width: "100%",
    cursor: "default",
  },
  cardRoot: {
    display: "flex",
    minWidth: 275,
    // transition: 'all 0.4s',
    "&:hover": {
      "& $svgIconRoot": {
        color: theme.palette.background.paperDark,
      },
      "& $labelTextConstrained": {
        color: theme.palette.text.primary,
      },
    },
  },
  cardContentRoot: {
    padding: "7px",
    width: "100%",
  },
  labelArea: {
    cursor: "pointer",
  },
  typographyRoot: {
    color: theme.palette.text.primary,
  },
  breadcrumbsSeparator: {
    fontSize: "0.625rem",
    marginLeft: "8px",
    marginRight: "8px",
  },

  sel: {
    background: "lightgrey",
  },
  nosel: {
    "&:hover": {
      background: fade(theme.palette.secondary.main, 0.2),
    },
  },
  labelTextConstrained: {
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    marginTop: "1px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    // display: 'block',
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  labelTextFull: {
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    marginTop: "1px",
    // maxWidth: '300px'
  },

  catText: {
    color: theme.palette.text.disabled,
    fontSize: "0.625rem",
    marginTop: "3px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "280px",
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: "280px",
    },

    [theme.breakpoints.up("lg")]: {
      maxWidth: "360px",
    },
  },
  small: {
    fontSize: 12,
    // margin: '12px 0 6px 0px',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "300px",
  },
  pos: {
    paddingTop: 16,
    marginBottom: 12,
  },
  svgIconRoot: {
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
    color: theme.palette.background.paper,
    "&:hover": {
      color: theme.palette.secondary.main + " !important",
    },
  },
  tagIcon: {
    marginRight: "4px",
  },
  circle: {
    fontSize: "0.625rem",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    padding: "8px 0px",
    // border: '1px solid #53B3B9',
    background: theme.palette.background.paperDark,
    color: theme.palette.primary.main,
    textAlign: "center",
    marginRight: "8px",
  },
  circleOpenDoc: {
    fontSize: "0.625rem",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    padding: "8px 0px",
    background: theme.palette.secondary.main,
    color: theme.palette.background.paperDark,
    textAlign: "center",
    marginRight: "8px",
  },
  tileButton: {
    flex: "0 0 100px",
    root: {
      color: "yellow",
    },
  },
  unselectable: {
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
}));

const ListElem = ({
  serialNum,
  elem,
  openDocId,
  inFocus,
  isSelected,
  listOptions,
  tileHeight,
  setFocusHandler,
  handleDocOpen,
  handleDocClose,
  handleDocOpenNewTab,
}) => {
  const [docOpen, setDocOpen] = useState(false);
  const { currentUser } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  // const handleTagClick = e => {
  //     e.preventDefault();
  //     // console.log("[ListElem].handleclick.id", elem.id)
  //     // return toggleHandler(elem.id)
  // }

  useEffect(() => {
    if (openDocId === null || openDocId !== elem.id) {
      setDocOpen(false);
    } else if (elem.id === openDocId) {
      setDocOpen(true);
    }
  }, [openDocId, elem.id]);

  const handleDocClick = (elem) => {
    analytics.logEvent("doc-click", elem.NameOfAct);
    //  REMOVE LOGIN REQUIREMENT
    // if (currentUser) {
    setDocOpen((prevState) => !prevState);
    // console.log('docOpen', docOpen)
    docOpen ? handleDocClose(elem.id) : handleDocOpen(elem.id);
    // } else {
    //   history.push("/signin");
    // }
  };

  const handleLinkClick = (elem) => {
    analytics.logEvent("doc-click", elem.NameOfAct);
    if (currentUser) {
      window.open(elem.UploadReadyStatus, "_blank", "noopener");
      // console.log('linkOpen', docOpen)
      // docOpen ? handleDocClose(elem.id) : handleDocOpen(elem.id)
      analytics.logEvent("doc-open", elem.NameOfAct);
    } else {
      history.push("/signin");
    }
  };

  const handleElemFocus = (id) => {
    // console.log('list-elem-focus', id)
    setFocusHandler([id], "list-elem");
  };

  const handleElemBlur = (id) => {
    // console.log('list-elem-blur', id, inFocus.ids)
    if (id in inFocus.ids) setFocusHandler([], "list-elem");
  };

  const inferDocType = (elem) => {
    let docType = null;
    let uploadStatus = elem.UploadReadyStatus.toLowerCase();
    if (uploadStatus.includes("http") || uploadStatus.includes("www")) {
      docType = "weblink";
      // console.log(docType, elem.UploadReadyStatus)
    } else if (uploadStatus.includes("upload")) {
      docType = "pdf";
      // console.log(docType, elem.SourceFileName)
    } else if (uploadStatus.includes("manupatra")) {
      docType = "not-available";
      // console.log(docType, elem.UploadReadyStatus)
    } else {
      docType = "not-available";
      // console.log(docType, elem.UploadReadyStatus)
    }

    return docType;
  };
  let docType = inferDocType(elem);

  const split = (text) => {
    return (
      text
        // insert a space before all caps, &, |, /
        .replace(/([A-Z&|/])/g, " $1")
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
        // remove leading, trailing spaces
        .trim()
    );
  };

  return (
    <div className={classes.tileRoot} style={{ height: tileHeight - 5 }}>
      <Card
        name="act-card"
        elevation={0}
        style={
          openDocId === elem.id
            ? {
                height: tileHeight - 5,
                border: `${theme.palette.background.paperDarkest} solid 2px`,
              }
            : { height: tileHeight - 5 }
        }
        className={
          classes.cardRoot + " " + (isSelected ? classes.sel : classes.nosel)
        }
      >
        <CardContent
          className={classes.cardContentRoot}
          onMouseEnter={(event) => {
            handleElemFocus(elem.id);
          }}
          onMouseLeave={() => handleElemBlur(elem.id)}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="Row" height={tileHeight - 20}>
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={0}
                alignItems="center"
                justifyContent="center"
              >
                <div
                  className={
                    openDocId === elem.id
                      ? classes.circleOpenDoc
                      : classes.circle
                  }
                >
                  <div className={classes.unselectable}>{elem.id + 1}</div>
                </div>
              </Box>
              {docType !== "not-available" ? (
                docType === "pdf" ? (
                  <TooltipMouse
                    title={
                      // (currentUser ? "" : "Sign in to ") +
                      (openDocId === elem.id ? "close" : "show") + " summary"
                    }
                    placement="bottom-start"
                    aria-label={
                      currentUser ? "View Summary" : "Sign in to View Summary"
                    }
                  >
                    <Box
                      classes={{ root: classes.labelArea }}
                      display="flex"
                      flexGrow={1}
                      flexDirection="column"
                      onClick={() => handleDocClick(elem)}
                    >
                      <Box
                        name="cats"
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Breadcrumbs
                          classes={{ separator: classes.breadcrumbsSeparator }}
                          aria-label="breadcrumb"
                        >
                          <Typography
                            className={classes.catText}
                            color="textSecondary"
                            gutterBottom
                          >
                            {elem.CategoriesPrimary !== "Not Available"
                              ? split(elem.CategoriesPrimary.join("|"))
                              : null}
                          </Typography>
                        </Breadcrumbs>
                      </Box>
                      <Box
                        name="label"
                        display="flex"
                        flexGrow={1}
                        flexShrink={1}
                        flexDirection="row"
                      >
                        <Typography
                          variant="subtitle2"
                          className={
                            listOptions["compactView"]
                              ? classes.labelTextConstrained
                              : classes.labelTextFull
                          }
                        >
                          {" "}
                          {elem.NameOfAct}{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </TooltipMouse>
                ) : (
                  <TooltipMouse
                    title={
                      // (currentUser ? "" : "Sign in to ") +
                      (openDocId === elem.id ? "close" : "show") + " summary"
                    }
                    placement="bottom-start"
                    aria-label={
                      currentUser ? "View Summary" : "Sign in to View Summary"
                    }
                  >
                    <Box
                      classes={{ root: classes.labelArea }}
                      display="flex"
                      flexGrow={1}
                      flexDirection="column"
                      onClick={() => handleDocClick(elem)}
                    >
                      <Box
                        name="cats"
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Breadcrumbs
                          classes={{ separator: classes.breadcrumbsSeparator }}
                          aria-label="breadcrumb"
                        >
                          <Typography
                            className={classes.catText}
                            color="textSecondary"
                            gutterBottom
                          >
                            {elem.CategoriesPrimary !== "Not Available"
                              ? split(elem.CategoriesPrimary.join("|"))
                              : null}
                          </Typography>
                        </Breadcrumbs>
                      </Box>
                      <Box
                        name="label"
                        display="flex"
                        flexGrow={1}
                        flexShrink={1}
                        flexDirection="row"
                      >
                        <Typography
                          variant="subtitle2"
                          className={
                            listOptions["compactView"]
                              ? classes.labelTextConstrained
                              : classes.labelTextFull
                          }
                        >
                          {" "}
                          {elem.NameOfAct}{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </TooltipMouse>
                )
              ) : (
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Box
                    name="cats"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Breadcrumbs
                      classes={{ separator: classes.breadcrumbsSeparator }}
                      aria-label="breadcrumb"
                    >
                      <Typography
                        className={classes.catText}
                        color="textSecondary"
                        gutterBottom
                      >
                        {elem.CategoriesPrimary !== "Not Available"
                          ? split(elem.CategoriesPrimary.join("|"))
                          : null}
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                  <Box
                    name="label"
                    display="flex"
                    flexGrow={1}
                    flexShrink={1}
                    flexDirection="row"
                  >
                    <Typography
                      variant="subtitle2"
                      className={
                        listOptions["compactView"]
                          ? classes.labelTextConstrained
                          : classes.labelTextFull
                      }
                    >
                      {" "}
                      {elem.NameOfAct}{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
              {/* <Box display="flex" flexGrow={0} alignItems="center">
                                {
                                    docType === 'not-available'
                                        ? null
                                        : currentUser
                                            ? docType !== 'pdf' ? null :
                                                <TooltipMouse
                                                    title="open in new tab"
                                                    placement="bottom-start"
                                                    aria-label="View Document"
                                                >
                                                    <LaunchIcon classes={{ root: classes.svgIconRoot }} onClick={() => handleDocOpenNewTab(elem.id)} />
                                                </TooltipMouse>
                                            : <TooltipMouse
                                                title={"Sign in to " + (openDocId === elem.id ? "close" : "open") + (docType === 'pdf' ? " document" : " link")}
                                                placement="bottom-start"
                                                aria-label="View Document"
                                            >
                                                <VpnKeyIcon classes={{ root: classes.svgIconRoot }} onClick={() => handleDocClick(elem)} />
                                            </TooltipMouse>

                                }
                            </Box> */}
            </Box>
            {listOptions["compactView"] ? null : (
              <div style={{ height: "350px", margin: "12px 10px 6px 30px" }}>
                <Typography className={classes.small} color="textSecondary">
                  Enacted by {elem.enacting_authority} in {elem.state}
                </Typography>
                <Skeleton variant="rect" height={160} />
                <Box>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </div>
            )}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default ListElem;
