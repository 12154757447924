import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import * as d3 from "d3";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { color } from "d3";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    position: "fixed",
    bottom: 10,
    display: "flex",
    // alignItems: 'flex-end',
    justifyContent: "center",
    flexDirection: "row",
    // width: '100%'
  },
  paperRoot: {
    // position: 'fixed',
    // bottom: 25,
    display: "flex",
    // alignItems: 'flex-end',
    flexDirection: "column",
    // marginLeft: '20px',
    padding: "2px 20px 2px 20px",
    // background: 'none',
    // float: 'right',
    // marginRight: '45px',
    // listStyle: 'none',
    // marginTop: '90px',
    // zIndex: 1000,
  },
  chipRoot: {
    margin: "2px",
    fontSize: "0.625rem",
    opacity: 1,
    color: theme.palette.text.default,
    zIndex: 100,
    "&:hover": {
      opacity: 1,
    },
  },
  legendTitle: {
    // position: 'absolute',
    // left: '20px',
    // bottom: '96px',
    // fontSize: '20px',
    color: theme.palette.primary.darkest,
  },
}));

function uuidv4() {
  // generates a uid. used for dom elements
  return "x-4xxx-yxxx-x".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const entity = (character) => {
  return `&#${character.charCodeAt(0).toString()};`;
};

export default function MapLegend({
  theme,
  colorFade,
  geoResolution,
  stateLayer,
  districtLayer,
  attrValues,
}) {
  const classes = useStyles();
  const themeCols = useTheme();
  const ref = useRef();
  const canvasRef = useRef();
  const swatchRef = useRef();
  const [dim, setDim] = useState([340, 64]);
  const [legend_title, setLegendTitle] = useState(
    "Regions: No of applicable Acts"
  );
  //   let legend_title = "Regions: No of applicable Acts";

  const swatches = ({
    color,
    columns = null,
    format = (x) => x,
    swatchSize = 15,
    swatchWidth = swatchSize,
    swatchHeight = swatchSize,
    marginLeft = 0,
  }) => {
    const id = "a" + uuidv4();

    if (columns !== null)
      return `<div style="display: flex; align-items: center; margin-left: ${+marginLeft}px; min-height: 33px; font: 10px sans-serif;">
  <style>

.${id}-item {
  break-inside: avoid;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
}

.${id}-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - ${+swatchWidth}px - 0.5em);
}

.${id}-swatch {
  width: ${+swatchWidth}px;
  height: ${+swatchHeight}px;
  margin: 0 0.5em 0 0;
}

  </style>
  <div style="width: 100%; columns: ${columns};">${color
        .domain()
        .map((value) => {
          const label = format(value);
          return `<div class="${id}-item">
      <div class="${id}-swatch" style="background:${color(value)};"></div>
      <div class="${id}-label" title="${label.replace(
            /["&]/g,
            entity
          )}">${document.createTextNode(label)}</div>
    </div>`;
        })}
  </div>
</div>`;

    return `<div style="display: flex; align-items: center; min-height: 33px; margin-left: ${+marginLeft}px; font: 10px sans-serif;">
  <style>

.${id} {
  display: inline-flex;
  align-items: center;
  margin-right: 1em;
}

.${id}::before {
  content: "";
  width: ${+swatchWidth}px;
  height: ${+swatchHeight}px;
  margin-right: 0.5em;
  background: var(--color);
}

  </style>
  <div>${color
    .domain()
    .map(
      (value) =>
        `<span class="${id}" style="--color: ${color(value)}">${value}</span>`
    )}</div> `;
  };

  const legend = ({
    color,
    title,
    tickSize = 6,
    width = 200,
    height = 34 + tickSize,
    marginTop = 5,
    marginRight = 0,
    marginBottom = 16 + tickSize,
    marginLeft = 0,
    ticks = width / 64,
    tickFormat,
    tickValues,
  } = {}) => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible")
      .style("display", "block");

    let tickAdjust = (g) => {
      const n = Math.min(color.domain().length, color.range().length);
      g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height);
      g.selectAll(".tick text").attr("dx", (-0.5 * width) / n);
    };
    let x;

    // Continuous
    if (color.interpolate) {
      // console.log("Continuous")
      const n = Math.min(color.domain().length, color.range().length);

      x = color
        .copy()
        .rangeRound(
          d3.quantize(d3.interpolate(marginLeft, width - marginRight), n)
        );

      svg
        .append("image")
        .attr("x", marginLeft)
        .attr("y", marginTop)
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginTop - marginBottom)
        .attr("preserveAspectRatio", "none")
        .attr(
          "xlink:href",
          ramp(
            color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))
          ).toDataURL()
        );
    }

    // Sequential
    else if (color.interpolator) {
      // console.log("Sequential")
      x = Object.assign(
        color
          .copy()
          .interpolator(d3.interpolateRound(marginLeft, width - marginRight)),
        {
          range() {
            return [marginLeft, width - marginRight];
          },
        }
      );

      svg
        .append("image")
        .attr("x", marginLeft)
        .attr("y", marginTop)
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginTop - marginBottom)
        .attr("preserveAspectRatio", "none")
        .attr("xlink:href", ramp(color.interpolator()).toDataURL());

      // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
      if (!x.ticks) {
        if (tickValues === undefined) {
          const n = Math.round(ticks + 1);
          tickValues = d3
            .range(n)
            .map((i) => d3.quantile(color.domain(), i / (n - 1)));
        }
        if (typeof tickFormat !== "function") {
          tickFormat = d3.format(tickFormat === undefined ? ",f" : tickFormat);
        }
      }
    }

    // Threshold
    else if (color.invertExtent) {
      // console.log("Threshold")
      const thresholds = color.thresholds
        ? color.thresholds() // scaleQuantize
        : color.quantiles
        ? color.quantiles() // scaleQuantile
        : color.domain(); // scaleThreshold

      const thresholdFormat =
        tickFormat === undefined
          ? (d) => d
          : typeof tickFormat === "string"
          ? d3.format(tickFormat)
          : tickFormat;

      x = d3
        .scaleLinear()
        .domain([-1, color.range().length - 1])
        .rangeRound([marginLeft, width - marginRight]);

      svg
        .append("g")
        .selectAll("rect")
        .data(color.range())
        .join("rect")
        .attr("x", (d, i) => x(i - 1))
        .attr("y", marginTop)
        .attr("width", (d, i) => x(i) - x(i - 1))
        .attr("height", height - marginTop - marginBottom)
        .attr("fill", (d) => d);

      tickValues = d3.range(thresholds.length);
      tickFormat = (i) => thresholdFormat(thresholds[i], i);
    }

    // Ordinal
    else {
      // console.log("Ordinal")
      x = d3
        .scaleBand()
        .domain(color.domain())
        .rangeRound([marginLeft, width - marginRight]);

      svg
        .append("g")
        .selectAll("rect")
        .data(color.domain())
        .join("rect")
        .attr("x", x)
        .attr("y", marginTop)
        .attr("width", Math.max(0, x.bandwidth() - 1))
        .attr("height", height - marginTop - marginBottom)
        .attr("fill", color);

      tickAdjust = () => {};
    }

    svg
      .append("g")
      .attr("transform", `translate(0, ${height - marginBottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
          .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
          .tickSize(tickSize)
          .tickValues(tickValues)
      )
      .call(tickAdjust)
      .call((g) => g.select(".domain").remove());
    // .call(g => g.append("text")
    //     .attr("x", marginLeft + width / 2 - 50)
    //     .attr("y", height - marginBottom)
    //     .attr("fill", "currentColor")
    //     .attr("text-anchor", "start")
    //     .attr("font-weight", "bold")
    //     .attr("class", "title")
    //     .text(title));

    return svg.node();
  };

  const ramp = (color, n = 256) => {
    const canvas = canvasRef.current;
    console.log(canvas);
    const context = canvas.getContext("2d");
    for (let i = 0; i < n; ++i) {
      context.fillStyle = color(i / (n - 1));
      context.fillRect(i, 0, 1, 1);
    }
    return canvas;
  };

  useEffect(() => {
    //remove legend elements
    if (ref.current) {
      d3.select(ref.current).selectAll("g").remove();
    }
    if (swatchRef.current) {
      swatchRef.current.innerHTML = null;
    }

    // redraw legend
    if (attrValues && attrValues.max > -1) {
      // const threshArr = []
      // const colorArr = []

      // generate threshold array from 10% to 100% of the max value
      // generate corresponding color array (faded to 0.7)
      const scale = attrValues.layer === "Forest Cover" ? 100 : 1;
      const n = Math.min(Math.round(attrValues.max * scale), 9);
      // console.log(`number of values -> ${n}, ${attrValues.max}, ${scale}`)
      const threshArr = d3.quantize(
        d3.interpolateRound(0, attrValues.max * scale),
        n + 1
      );

      const start = d3.color(themeCols.palette.primary.main);
      start.opacity = 0.05;
      const end = d3.color(themeCols.palette.primary.main);
      end.opacity = colorFade;
      const colorInterpolator = d3.interpolate(start + "", end + "");
      const colorArr = d3.quantize(colorInterpolator, n + 1);
      // console.log(threshArr, colorArr)
      const color = d3.scaleThreshold(threshArr, colorArr);

      switch (attrValues.layer) {
        case "Acts":
          setDim([340, 70]);
          legend({
            color: color,
            title: "No of applicable Acts",
            tickFormat: ".0f",
            width: 300,
          });
          // legend_title = "No of applicable Acts";
          setLegendTitle("No of applicable Acts");
          break;
        case "Forest Cover":
          setDim([340, 70]);
          legend({
            color: color,
            title: "% of Geographical Area",
            tickFormat: ".0f",
            width: 300,
          });
          // legend_title = "% of Geographical Area";
          setLegendTitle("% of Geographical Area");
          break;
        case "Mines":
          setDim([380, 70]);
          legend({
            color: color,
            title: "Mineral Production in Crores",
            tickFormat: ".2s",
            width: 300,
          });
          // legend_title = "Mineral Production in Crores";
          setLegendTitle("Mineral Production in Crores");
          break;
        case "Scheduled Areas":
          const sa_col = d3.color(themeCols.palette.primary.main);
          sa_col.opacity = colorFade;
          setDim([138, 58]);
          swatchRef.current.innerHTML = swatches({
            color: d3.scaleOrdinal(["Scheduled Areas"], [sa_col + ""]),
          });
          // legend_title = "Scheduled Areas";
          setLegendTitle("Scheduled Areas");
          break;
        case "Dams":
          setDim([340, 70]);
          legend({
            color: color,
            title: "No of Dams (2019)",
            tickFormat: ".2s",
            width: 300,
          });
          // legend_title = "No of Dams (2019)";
          setLegendTitle("No of Dams (2019)");
          break;
        default:
          setDim([340, 70]);
          legend({
            color: color,
            title: "No of applicable Acts",
            tickFormat: ".0f",
          });
          // legend_title = "No of applicable Acts";
          setLegendTitle("No of applicable Acts");
      }
    }
  }, [theme, geoResolution, stateLayer, districtLayer, attrValues]);

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <>
            <Box
              className={classes.boxRoot}
              style={{
                marginLeft: 10,
                width: width,
                display:
                  attrValues.layer == "Scheduled Areas" ? "none" : "block",
              }}
            >
              <Paper
                className={classes.paperRoot}
                style={{ width: dim[0], height: dim[1] }}
              >
                <div
                  className={classes.legendTitle}
                  style={{
                    color: themeCols.palette.primary.main,
                    marginTop: 5,
                  }}
                >
                  {legend_title}
                </div>
                <svg ref={ref}></svg>
                {/* <canvas ref={canvasRef} width={300} height={44}></canvas> */}
              </Paper>
            </Box>
            :
            <div
              ref={swatchRef}
              style={{ position: "absolute", left: "20px", bottom: "12px" }}
            ></div>
          </>
        );
      }}
    </AutoSizer>
  );
}
