import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'
import * as d3 from 'd3'
import * as _ from "lodash"
import { purposeData } from '../../categories'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator';
// import { elemsData } from '../../lawsdictx'
import TagLegendBottom from './TagLegendBottom'

const useStyles = makeStyles((theme) => ({
    tagRoot: {
        marginTop: '75px',
        marginLeft: '15px',

        '& .unselected': {
            cursor: 'default'
        },

        '& .tag-rect': {
            fill: theme.palette.primary.main, //theme.palette.background.paperDark,
            stroke: theme.palette.background.paper,
            strokeWidth: '0.125rem',
            // opacity: 0.1,
            // transition: 'fill 0.5s'
        },

        '& .focussed .tag-rect': {
            // fill: fade(theme.palette.secondary.main, 0.2),
            // fill: 'none',
            // fill: theme.palette.primary.main,
            stroke: theme.palette.background.paper,
            strokeWidth: '0.125rem',
            fontWeight: '400',
            // opacity: 1,
            // transition: 'fill 0.5s',
        },

        // '& .hovered .tag-rect': {
        //     fill: theme.palette.secondary.dark,
        //     stroke: theme.palette.background.paper,
        //     strokeWidth: '0.125rem',
        //     opacity: 1
        // },
        // '& .hovered .tag-rect': {
        //     fill: 'none',
        //     stroke: theme.palette.secondary.main,
        //     strokeWidth: '0.1rem',
        //     opacity: 1,
        // },

        // '& .selected .tag-rect': {
        //     fill: theme.palette.primary.main, //theme.palette.background.paperDark,
        //     stroke: theme.palette.background.paper,
        //     strokeWidth: '0.125rem',
        // },

        '& .selected .tag-rect': {
            // fill: theme.palette.primary.main, //theme.palette.background.paperDark,
            stroke: theme.palette.background.paper,
            strokeWidth: '0.125rem',
            // opacity: 0.3
        },

        // '& .selected .tag-rect': {
        //     fill: fade(theme.palette.secondary.main, 0.2),
        //     stroke: theme.palette.background.paper,
        //     strokeWidth: '0.125rem',
        //     opacity: 1,
        //     transition: 'fill 0.5s'
        // },

        '& .clicked .tag-rect': {
            fill: theme.palette.secondary.dark,
            // stroke: theme.palette.primary.darkest,
            // strokeWidth: '0.125rem',
            // opacity: 1,
        },

        '& .tag-rect-hover': {
            fill: 'none',
            stroke: 'none',
            strokeWidth: '0',
            opacity: 0,
        },

        '& .focussed .tag-rect-hover': {
            fill: 'none',
            stroke: theme.palette.secondary.main,
            strokeWidth: '0.1rem',
            opacity: 1,
            // transition: 'fill 0.5s',
        },

        '& .tag-text': {
            fill: theme.palette.type == 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
            fontWeight: 400
        },

        '& .selected .tag-text': {
            fill: theme.palette.text.primary,
            fontWeight: 400
        },

        '& .unselected .tag-text': {
            opacity: 0.1
        },

        '& .focussed .tag-text': {
            fill: theme.palette.text.primary,
            fontWeight: 400
        },

        '& .hovered .tag-text': {
            fill: theme.palette.primary.contrastTextLight,
            fontWeight: 400
        },

        '& .clicked .tag-text': {
            fill: theme.palette.primary.contrastText
        },

        '& .tag-text-sub': {
            fill: theme.palette.primary.main,
            fillOpacity: 1,
            fontWeight: 300,
            // fontSize: '0.625rem'
        },

        '& .hovered .tag-text-sub': {
            fill: theme.palette.primary.contrastTextLight,
            // fontWeight: 400
        },

        '& .clicked .tag-text-sub': {
            fill: theme.palette.primary.contrastTextLight
        }
    },

    tooltipRoot: {
        color: theme.palette.primary.contrastTextLight,
        position: 'absolute',
        textAlign: 'left',
        width: 'auto',
        height: 'auto',
        padding: '5px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '0.875rem',
        background: theme.palette.primary.main,
        border: '0px',
        borderRadius: '4px',
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '0.825rem'
    },

    tooltipSub: {
        fontSize: '0.625rem'
    }
}))

const marginX = 15
const marginY = 150
function uuidv4() {
    // generates a uid. used for dom elements
    return 'x-4xxx-yxxx-x'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

function useWindowSize() {
    // hook to detect window size changes
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const split = (text) => {
    return text
        // insert a space before all caps, &, |, /
        .replace(/([A-Z&|/])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
        // remove leading, trailing spaces
        .replaceAll('.', ' >')
        .trim()
}

const name = d => '\u00AB ' + d.ancestors().reverse().map(d => d.data.name).join(" \u007C ")

export default function TagView({ inSelect, inFocus, selectionData, setFocusHandler, setSelectionHandler }) {
    const classes = useStyles()
    var size = useWindowSize()
    const ref = useRef();
    const tooltipRef = useRef();
    // const [data, setData] = useState(purposeData)
    const [globRoot, setGlobRoot] = useState(null)
    const [targetRoot, setTargetRoot] = useState(null)

    const getSelectionIntersection = (uniqPopulation, width) => {
        let num_ids = _.uniq(uniqPopulation.filter(id => inSelect.ids.includes(parseInt(id)))).length
        return num_ids
    }

    const formatTitle = (ids, uniqPopulation, population, children) => {

        let tx = children.length === 0
            ? ""
            : `${d3.format(",d")(_.uniq(children).length)} Subcategories`
        // : `Children:${d3.format(",d")(children.length)} ids:[${ids}] uniq:[${uniqPopulation}] sum:[${population}]`

        let populationInSelection = 0
        if (inSelect.ids.length !== 0 && inSelect.ids.length !== _.uniq(uniqPopulation).length) {
            populationInSelection = _.uniq(uniqPopulation.filter(id => inSelect.ids.includes(parseInt(id)))).length

            // tx += ` [Acts: ${_.uniq(uniqPopulation).length}]`
            tx += ` [Acts: ${populationInSelection} of total ${_.uniq(uniqPopulation).length} selected]`
        } else {
            tx += ` [Acts: ${_.uniq(uniqPopulation).length}]`
        }

        return tx
    }

    const format = (ids, uniqPopulation, populationCount, children) => {
        // let populationInSelection = _.uniq(uniqPopulation.filter(id => inSelect.ids.includes(parseInt(id)))).length
        if (inSelect.ids.length !== 0) {
            let ratio = getSelectionIntersection(uniqPopulation)
            return (children && children.length === 0 && ids === 0)
                ? "None in the database yet"
                // : `${uniqPopulation.length} Acts`
                // : `${populationInSelection}/${uniqPopulation.length} Acts`
                : `${ratio} Acts`
            // : `ids:${d3.format(",d")(ids)} uniq:[${uniqPopulationCount}] sum:${population} Acts`
        } else {
            return (children && children.length === 0 && ids === 0)
                ? "None in the database yet"
                : `${_.uniq(uniqPopulation).length} Acts`
        }
    }

    const treeroot = (data) => {
        const { clientWidth, clientHeight } = document.getElementById('view-container')
        const width = clientWidth - marginX
        const height = clientHeight - marginY

        function tile(node, x0, y0, x1, y1) {
            d3.treemapBinary(node, 0, 0, width, height)

            for (const child of node.children) {
                child.x0 = x0 + (child.x0 - x0) / width * (x1 - x0);
                child.x1 = x0 + (child.x1 - x0) / width * (x1 - x0);
                child.y0 = y0 + child.y0 / height * (y1 - y0);
                child.y1 = y0 + child.y1 / height * (y1 - y0);
            }
        }

        return d3.treemap()
            .tile(tile)
            (d3.hierarchy(data)
                .sort((a, b) => b.data.sumIds.length - a.data.sumIds.length)
                .each(d => {
                    d.value = d.data.totLen
                })
            )
    }

    useEffect(() => {
        console.log("[tag-view] mount")
        const svg = d3.select(ref.current)
            .attr('id', 'view-d3')
            .style("font", "10px Roboto")
            .style("overflow", "hidden")

        svg.append("g")

        // const tooltip = d3.select(tooltipRef.current)
        //     .attr("id", "tag-tooltip")
        //     .style("opacity", 0);

        setGlobRoot(treeroot(purposeData))

        return () => {
            console.log("[tag-view] unmount")
            ref.current = null;
        }
    }, [])

    useEffect(() => {
        if (inSelect.ids.length === 0) {
            // hard reset on deselection
            // zooms out to global root
            setTargetRoot(null)
            redraw(true)
        } else {
            redraw(false)
        }
    }, [inSelect])

    useEffect(() => {
        // console.log('in-focus')
        redraw(false)
    }, [inFocus])

    useEffect(() => {
        if (size[0] > 0 && size[1] > 0) {
            redraw()
        }
    }, [size]);

    // var t = d3.transition()
    //     .duration(750)
    //     .ease(d3.easeLinear);

    // var isAnimatingFlag = false;

    const redraw = (resetFlag = false) => {
        const { clientWidth, clientHeight } = document.getElementById('view-container')
        const width = clientWidth - 30
        const height = clientHeight - 150

        if (width > 100 && height > 100) {
            // reset
            const svg = d3.select(ref.current)
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [-1, -51, width + 2, height + 52])
                .attr("preserveAspectRatio", 'none')

            svg.selectAll('rect').remove()
            svg.selectAll('text').remove()
            svg.selectAll('clipPath').remove()

            draw(svg, width, height, resetFlag)
        }
    }

    const draw = (svg, width, height, reset) => {
        // console.log('tagview->width/height', width, height)
        if (globRoot == undefined) return
        const root = globRoot //treeroot(data)

        const x = d3.scaleLinear().rangeRound([0, width]);
        const y = d3.scaleLinear().rangeRound([0, height]);

        let topNode = null
        if (reset || targetRoot === null) {
            topNode = root
        } else {
            topNode = root.find(d => d.data.name === targetRoot)
            // prevent zooming into nodes with children
            if (topNode.children === undefined) topNode = topNode.parent
        }

        x.domain([topNode.x0, topNode.x1]);
        y.domain([topNode.y0, topNode.y1]);


        let group = svg.select("g")
            .call(render, topNode)
        // .call(position, topNode)

        function render(group, root) {
            var t_max = 1
            var t_min = 0
            var f_max = 1
            var f_min = 0
            // console.log('tag-view->root', root)
            // console.log('tag-view->group', group)

            if (inSelect.ids.length === 0) {
                if (root.children !== undefined) {
                    t_max = root.children[0].data.sumIds.length;
                    t_min = root.children[root.children.length - 1].data.sumIds.length
                    f_max = root.children[0].data.sumIds.length;
                    f_min = root.children[root.children.length - 1].data.sumIds.length
                }
            } else {
                t_max = inSelect.ids.length
                t_min = 0
                if (root.children !== undefined) {
                    f_max = root.children[0].data.sumIds.length;
                    f_min = root.children[root.children.length - 1].data.sumIds.length
                }
            }

            // console.log('tag-view->tmax,tmin', t_max, t_min)

            const opacity = d3.scaleLinear()
                .domain([t_min, t_max])
                .range([0.1, 0.6])
                .clamp(true)

            const fontOpacity = d3.scaleLinear()
                .domain([f_min, f_max])
                .range([0.9, 2])
                .clamp(true)

            const fontsize = d3.scaleSqrt()
                .domain([f_min, f_max])
                .rangeRound([10, 22])
                .clamp(true)

            // const fontsizeSelection = d3.scaleSqrt()
            //     .domain([f_min, f_max])
            //     .rangeRound([9, 12])
            //     .clamp(true)

            const node = group
                .selectAll("g")
                .data(root.children.concat(root))
                .join("g")

            node
                .classed("clicked", false)
                .classed("focussed", false)
                .classed("hovered", false)
                .classed("selected", false)
                .classed("unselected", false)

            node
                .attr("name", d => `node-${d.data.name}`)
                .attr("cursor", "pointer")
                // .attr("opacity", (d) => {
                //     const selectionInt = d.data.sumIds.filter(value => inSelect.ids.includes(parseInt(value))).length
                //     const frac = selectionInt / d.data.sumIds.length
                //     console.log(d.data.name, frac)
                //     return frac
                // })
                .attr("opacity", 1)
                .classed("selected", d => d.data.sumIds.filter(value => inSelect.ids.includes(parseInt(value))).length > 0 && d !== root && d.depth <= 1)
                .classed("unselected", d => d.data.sumIds.filter(value => inSelect.ids.includes(parseInt(value))).length === 0 && inSelect.ids.length > 0)
                .classed("focussed", d => d.data.sumIds.filter(value => inFocus.ids.includes(parseInt(value))).length > 0 && d !== root)
                .classed("hovered", false)
                .on("click", function (event, d) {
                    if (d === root) {
                        zoomout(d)
                        group.selectAll("g")
                        // .attr("opacity", 1)
                    } else {
                        if (!d3.select(this).classed("unselected")) zoomin(d)
                    }

                    if (d.parent && !d3.select(this).classed("unselected")) {
                        group.selectAll("g")
                            .classed("clicked", false)
                        // .attr("opacity", d => d === root ? 1 : 0.1)
                        d3.select(this)
                            .classed("clicked", true)
                        // .attr("opacity", 1)
                    } else {
                        group.selectAll("g")
                            .classed("clicked", false)
                    }
                })
                .on("mouseover", function (event, d) {
                    tooltipOn(event, d, d === root)
                    d3.select(this)
                        .classed("focussed", true)
                })
                .on("mouseout", function (event, d,) {
                    tooltipOff(event, d)
                    d3.selectAll('g')
                        .classed("focussed", false)
                })
                .on("mousemove", function (event, d) {
                    tooltipOn(event, d, d === root)
                })

            node.append("rect")
                .classed("tag-rect", true)
                .attr("id", d => (d.leafUid = uuidv4()))
                .attr("opacity", d => d === root ? 0.1 : inSelect.ids.length === 0 ? opacity(d.data.sumIds.length) : getSelectionIntersection(d.data.sumIds) === 0 ? 0.1 : opacity(getSelectionIntersection(d.data.sumIds) + 3))
            // .attr("opacity", d => d === root ? 0.1 : inSelect.ids.length === 0 ? 0 : 1)

            node.append("clipPath")
                .classed("tag-rect-clip", true)
                .attr("id", d => (d.clipUid = uuidv4()))
                .append("rect")
                .attr("opacity", d => d === root ? 0.1 : opacity(d.data.sumIds.length))
                .attr("width", d => d === root ? width - 4 : (d.x1 - d.x0) / (d.parent.x1 - d.parent.x0) * width - 4)
                .attr("height", d => d === root ? 50 : (d.y1 - d.y0) / (d.parent.y1 - d.parent.y0) * height - 4)

            node.append("rect")
                .classed("tag-rect-hover", true)
                .attr("width", d => d === root ? width - 4 : (d.x1 - d.x0) / (d.parent.x1 - d.parent.x0) * width - 4)
                .attr("height", d => d === root ? 50 : (d.y1 - d.y0) / (d.parent.y1 - d.parent.y0) * height - 4)
                .attr("transform", d => d === root ? `translate(0,0)` : `translate(2,2)`)

            node.append("text")
                .classed("noselect", true)
                .classed("tag-text", true)
                .attr("clip-path", d => `url(#${d.clipUid})`)
                .attr("font-weight", d => d === root ? "500" : null)
                // .attr("font-size", d => d === root ? "1.5em" : inSelect.ids.length === 0 ? fontsize(d.data.sumIds.length) : fontsizeSelection(getSelectionRatio(d.data.sumIds, d.width)))
                .attr("font-size", d => d === root ? "1.5em" : fontsize(d.data.sumIds.length))
                .attr("fill-opacity", d => d === root ? 1 : inSelect.ids.length === 0 ? 1 : fontOpacity(getSelectionIntersection(d.data.sumIds)))
                .selectAll("tspan")
                .data(d => (d === root
                    ? [name(d)].concat(formatTitle(d.data.ids.length, d.data.sumIds, d.value, d.children))
                    : (d.data.name).split(/(?=[A-Z][^A-Z])/g).concat(format(d.data.ids.length, d.data.sumIds, d.value, d.children))
                ))
                .join("tspan")
                .attr("x", 5)
                .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 1}em`)
                .classed("tag-text-sub", (d, i, nodes) => i === nodes.length - 1 ? true : false)
                .text(d => d)

            group.call(position, root);
        }

        function position(group, root) {
            group.selectAll("g")
                .attr("transform", d => d === root ? `translate(0,-50)` : `translate(${x(d.x0)},${y(d.y0)})`)
                .select("rect")
                .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
                .attr("height", d => d === root ? 50 : y(d.y1) - y(d.y0));
        }

        const intersect = (a, b) => {
            var setB = new Set(b);
            return [...new Set(a)].filter(x => setB.has(x));
        }

        function selectNodes(d) {
            if ('data' in d) {
                const paddedPopulation = [...d.data.sumIds]
                // remove the pad nodes
                const ids_padRemoved = paddedPopulation.filter(x => x !== 'pad')
                let int_ids_all = ids_padRemoved.map((id) => parseInt(id))

                // if there is a selection. intersect
                if (inSelect.ids.length > 0) {
                    let int_ids_intersect = intersect(int_ids_all, inSelect.ids)
                    setSelectionHandler(int_ids_intersect, 'tag-view', 'reset')
                } else {
                    // console.log('sel-nodes', int_ids_all)
                    setSelectionHandler(int_ids_all, 'tag-view', 'reset')
                }
            }
        }

        function deselectNodes() {
            setSelectionHandler([], 'tag-view', 'reset')
        }

        // When zooming in, draw the new nodes on top, and fade them in.
        function zoomin(d) {
            setTargetRoot(d.data.name)
            if (d.children) {
                const group0 = group
                    .attr("pointer-events", "none")
                    .attr("id", "archive")
                const group1 = group = svg.insert("g", "*").attr("id", "active").call(render, d);

                x.domain([d.x0, d.x1]);
                y.domain([d.y0, d.y1]);

                svg.transition()
                    .duration(600)
                    .call(t => group0.transition(t).remove()
                        .attrTween("opacity", () => d3.interpolate(1, 0))
                        .call(position, d.parent))
                    .call(t => group1.transition(t)
                        .attrTween("opacity", () => d3.interpolate(0, 1))
                        .call(position, d))
                    .on("start", () => {
                        // isAnimatingFlag = true
                    })
                    .on("end", () => {
                        // isAnimatingFlag = false
                        selectNodes(d)
                    })

            } else {
                selectNodes(d)
            }
        }

        // When zooming out, draw the old nodes on top, and fade them out.
        function zoomout(d) {
            // console.log('parent->', d.parent)
            if (d.parent) {
                // console.log('zoomout', d)
                setTargetRoot(d.parent.data.name)
                const group0 = group.attr("pointer-events", "none").attr("id", "archive");
                const group1 = group = svg.insert("g", "*").attr("id", "active").call(render, d.parent);

                x.domain([d.parent.x0, d.parent.x1]);
                y.domain([d.parent.y0, d.parent.y1]);

                svg.transition()
                    .duration(300)
                    .call(t => group0.transition(t).remove()
                        .attrTween("opacity", () => d3.interpolate(0.5, 0))
                        .call(position, d))
                    .call(t => group1.transition(t)
                        .attrTween("opacity", () => d3.interpolate(0.5, 1))
                        .call(position, d.parent)
                    )
                    .on("start", () => {
                        // remove selection highlight
                        group.selectAll("g")
                            .classed("selected", false)
                        // isAnimatingFlag = true
                    })
                    .on("end", () => {
                        selectNodes(d)
                        // isAnimatingFlag = false
                    })
            } else {
                deselectNodes()
            }
        }


        //------------------------------------------------------------------
        function tooltipOn(e, d, root = false) {
            // console.log(d.data.name)
            const tooltip = d3.select(tooltipRef.current)
            const { clientWidth, clientHeight } = document.getElementById('view-container')
            let mx = e.pageX - document.getElementById("view-container").offsetLeft
            let my = e.pageY
            tooltip.style("opacity", 0.8);

            let num_ids = _.uniq(d.data.sumIds).length
            if (inSelect.ids.length > 0) {
                num_ids = getSelectionIntersection(d.data.sumIds)
            }

            if (root) {
                if (d.parent) {
                    tooltip.html(`<span style="font-size: 0.625rem">[BACK] click to zoom out</span>`)
                } else {
                    if (inSelect.ids.length > 0) {
                        // if node in selection
                        tooltip.html(`<span style="font-size: 0.625rem">click to deselect ${inSelect.ids.length} Acts</span>`)
                    } else {
                        // if node in selection
                        tooltip.html(`<span style="font-size: 0.625rem">click to select categories below</span>`)
                    }
                }
            } else {
                if (num_ids > 0) {
                    if (num_ids === inSelect.ids.length) {
                        tooltip.html(`<span style="font-size: 0.925rem; font-weight: bold">${split(d.data.name)}</span><span style="font-size: 0.625rem">-<br>Category <strong>applies to all selected</strong> Acts</span>`)
                    } else {
                        // if node in selection
                        "children" in d
                            ? tooltip.html(`<span style="font-size: 0.925rem; font-weight: bold">${split(d.data.name)}</span><span style="font-size: 0.725rem">${d.children.length} sub-categories</span><span style="font-size: 0.625rem">click to ${inSelect.ids.length > 0 ? 'sub-' : ''}select ${num_ids} Acts</span>`)
                            : tooltip.html(`<span style="font-size: 0.925rem; font-weight: bold">${split(d.data.name)}</span><span style="font-size: 0.725rem">No sub-categories</span><span style="font-size: 0.625rem">click to ${inSelect.ids.length > 0 ? 'sub-' : ''}select ${num_ids} Acts</span>`)
                    }
                } else {
                    // if node not in selection
                    tooltip.html(`<span style="font-size: 0.925rem; font-weight: bold">${split(d.data.name)}</span><span style="font-size: 0.625rem">-<br>Category <strong>does not apply</strong> to Acts in selection</span>`)
                }
            }
            const test = clientWidth - mx + marginX / 2
            const dx = test < 180 ? -150 : 0
            const dy = -60
            tooltip
                .style("left", (mx + dx) + "px")
                .style("top", (my + dy) + "px")

        }//tooltipOn

        function tooltipOff(event, d) {
            const tooltip = d3.select(tooltipRef.current)
            tooltip.style("opacity", 0);
        }//tooltipOff
    }
    return (
        <div className={classes.tagRoot}>
            <svg ref={ref}></svg>
            <div style={{ opacity: 0, zIndex: 1000 }} className={classes.tooltipRoot} ref={tooltipRef}></div>
            <TagLegendBottom />
        </div>
    )
}