import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({

    boxRoot: {
        position: 'fixed',
        bottom: 10,
        display: 'flex',
        // alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
        // width: '100%'
    },
    paperRoot: {
        // position: 'fixed',
        // bottom: 25,
        display: 'flex',
        // alignItems: 'flex-end',
        flexDirection: 'column',
        // marginLeft: '20px',
        padding: '2px 20px 2px 20px',
        // background: 'none',
        // float: 'right',
        // marginRight: '45px',
        // listStyle: 'none',
        // marginTop: '90px',
        // zIndex: 1000,
    },
    legendTitle: {
        // position: 'absolute',
        // left: '20px',
        // bottom: '96px',
        // fontSize: '20px',
        color: theme.palette.primary.darkest
    }
}))



export default function HistoLegendBottom({ theme, attrValues }) {
    const classes = useStyles()
    const themeCols = useTheme()
    const [dim, setDim] = useState([340, 64])
    let legend_title = "Distribution by Decades"


    useEffect(() => {
        //remove legend elements
    }, [theme, attrValues])


    return (
        <AutoSizer>
            {({ height, width }) => (
                <Box className={classes.boxRoot} style={{ width: width }}>
                    <Paper elevation={0} className={classes.paperRoot} style={{ width: dim[0], height: dim[1] }}>
                        <div className={classes.legendTitle} style={{ color: themeCols.palette.primary.main }}> Distribution by Decade</div>
                        {/* <svg ref={ref}></svg>
                        <canvas ref={canvasRef} width={220} height={44}></canvas>
                        <div ref={swatchRef} style={{ position: 'absolute', left: '20px', top: '12px' }}></div> */}
                    </Paper>
                </Box>
            )
            }
        </AutoSizer >
    )
}