import React, { useState, useEffect } from 'react'
import { db } from '../firebase'
import { DataGrid } from '@material-ui/data-grid';
import AutoSizer from "react-virtualized-auto-sizer";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoveToInboxSharpIcon from '@material-ui/icons/MoveToInboxSharp';
import * as _ from 'lodash'
import { CSVLink } from "react-csv";

const headers = [
    { label: "ID", key: "id" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Affiliation", key: "org" },
    { label: "Research Interest", key: "research" },
    { label: "Receive Updates?", key: "receiveUpdates" },
    { label: "Created", key: "creationTime" },
    { label: "LastSignedIn", key: "lastSignInTime" },

];


const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'email', headerName: 'email', width: 200 },
    { field: 'firstname', headerName: 'First name', width: 130 },
    { field: 'lastname', headerName: 'Last name', width: 130 },
    { field: 'org', headerName: 'Affiliation', width: 130 },
    { field: 'research', headerName: 'Research Interest', width: 130 },
    { field: 'receiveUpdates', headerName: 'Updates?', width: 100 },
    { field: 'lastSignInTime', headerName: 'LastSignedIn', width: 200 },
    { field: 'creationTime', headerName: 'Created', width: 200 }

    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //         `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    // },
];


const useStyles = makeStyles((theme) => ({
    adminContainer: {
        display: 'flex',
        flexGrow: 1,
        flexFlow: 'column',
        height: '100vh',
        color: theme.palette.text.default,
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        },
        backgroundColor: theme.palette.background.paper
    },

    adminHeader: {
        display: 'flex',
        // border: 'solid 1px blue',
        flex: '0 0 50px',
        // marginTop: '5px',
        backgroundColor: theme.palette.background.paperDark,
        // margin: '10px 0 10px 0px',
        // color: theme.palette.secondary.main,
    },
    headerText: {
        // marginTop: '5px',
        padding: '10px 15px 10px 15px',
        color: theme.palette.secondary.main,
    },
    headerBtn: {
        // marginTop: '5px',
        // padding: '10px 15px 10px 15px',
        color: theme.palette.secondary.main,
    },
    adminContent: {
        // border: 'solid 1px red',
        display: 'flex',
        flex: '1 1 100vh',
        // height: '100vh'
    },
    paper: {
        padding: theme.spacing(4),
        // marginTop: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        margin: theme.spacing(1),
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.paperSecondary,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    smalltext: {
        fontSize: '12px',
        color: theme.palette.primary.main
    },
    copytext: {
        // padding: theme.spacing(4),
        color: theme.palette.primary.darkest,
        cursor: 'pointer'
    }
}));

export default function AdminView() {
    const classes = useStyles();
    const [subscriberList, setSubscriberList] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    // const [userSelection, setUserSelection] = useState([])
    const [exportSelection, setExportSelection] = useState([])
    const subscribersRef = db.collection("subscribers")
    // console.log(subscribersRef)

    const getSubscribers = () => {
        setLoading(true);
        console.log("getting subs")
        subscribersRef.onSnapshot(querySnapshot => {
            const items = []
            querySnapshot.forEach((doc) => {
                let x = doc.data()
                x['id'] = doc.id
                // console.log(x)
                items.push(x)
            })
            setSubscriberList(items)
            setExportSelection(items)
        }, (error) => {
            console.log("error getting subs")
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode)
            console.log(errorMessage)
            setError(errorCode + ": " + errorMessage)
        })

        setLoading(false)
    }

    const handleSelection = (listOfUserIds) => {
        // setUserSelection(listOfUserIds)
        let selected = (listOfUserIds.length > 0) ? _.filter(subscriberList, function (user) { return listOfUserIds.includes(user.id); }) : subscriberList
        console.log(selected)
        setExportSelection(selected)
    }

    useEffect(() => {
        try {
            getSubscribers()
        } catch (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            console.log(errorCode)
        }

    }, [])

    return (
        <div className={classes.adminContainer} id="admin-container" name="admin-container">
            <Box
                className={classes.adminHeader}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
                height="100%"
            >
                <Typography className={classes.headerText} component="h1" variant="h5">
                    Users List
                </Typography>
                <CSVLink
                    data={exportSelection}
                    headers={headers}
                    filename={'MILL-users-' + Date().substring(0, 24)}
                    className="btn btn-primary"
                    target="_blank"
                    onClick={() => console.log(exportSelection)}
                >
                    <Badge badgeContent={exportSelection.length} color="primary">
                        <Tooltip title="Download list as CSV" placement="right-end">
                            <IconButton aria-label="download csv" className={classes.headerBtn} size="small">
                                <MoveToInboxSharpIcon />
                            </IconButton>
                        </Tooltip>
                    </Badge>
                </CSVLink>
            </Box>
            <AutoSizer>
                {({ height, width }) => (
                    <div className={classes.adminContent} style={{ width: width, height: height - 50 }}>
                        <DataGrid
                            classes={{ cell: classes.gridCell }}
                            rows={subscriberList}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            onSelectionModelChange={(selection) => handleSelection(selection.selectionModel)}
                        />
                    </div>
                )}
            </AutoSizer>
        </div >
    )
}
