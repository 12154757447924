import { fade } from "@material-ui/core/styles/colorManipulator";
import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const breakpoints = createBreakpoints({});
// outputs {xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920}
breakpoints.values.md = 980;
breakpoints.values.lg = 1280;

export const DarkTheme = createMuiTheme({
  palette: {
    type: "dark",
    tour: {
      background: "#97EFFF",
      text: "#0F4162",
    },
    default: {
      main: "yellow",
    },
    primary: {
      darkest: "#0C3754",
      main: "#81D3E6",
      light: "#97EFFF",
      contrastText: "#fff",
      contrastTextLight: "#0C3754",
    },
    secondary: {
      dark: "#38A09C",
      main: "#63FFD6",
      contrastText: "#0F4162",
    },
    text: {
      default: "rgba(255, 255, 255, 0.8)",
      primary: "rgba(99, 255, 214, 1)",
      secondary: "#81D3E6",
      disabled: "rgba(255, 255, 255, 0.58)",
      hint: "rgba(255, 255, 255, 0.1)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: "#0F4162",
      paperDark: "#0C3754",
      paperDarkest: "#006A80",
      paperSecondary: "rgba(48,132,119,0.75)",
      default: "#0F4162",
    },
  },
  overrides: {
    MuiDataGrid: {
      root: {
        color: "#81D3E6",
        border: "none",
        fontSize: "0.7rem",
        "& .MuiDataGrid-row:hover": {
          // backgroundColor: 'transparent !important',
          border: "0.5px solid rgba(99, 255, 214, 1)",
        },
        "& .MuiDataGrid-columnsContainer": {
          borderBottom: "1px solid #81D3E6",
        },
        "& .MuiDataGrid-columnSeparator": {
          color: "#81D3E6",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "0.5px solid #0F4162",
        },
        "& .MuiDataGrid-colCell": {
          backgroundColor: "#0F4162",
          // borderBottom: '2px solid white'
        },
        "& .MuiDataGrid-footer": {
          backgroundColor: "#0C3754",
        },
      },
    },
    MuiPaper: {
      root: {
        color: "#81D3E6", //primary main
      },
    },
    MuiIconButton: {
      root: {
        padding: "6px",
        "&:hover": {
          boxShadow: "0px 0px 0px 1px rgb(99, 255, 214) inset",
          backgroundColor: "rgba(99, 255, 214, 0.1)", //text.primary
        },
      },
      colorPrimary: {
        "&:hover": {
          backgroundColor: "rgba(99, 255, 214, 0.2)", //text.primary
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "1.2rem",
        height: "1.2rem",
      },
    },
    MuiToggleButton: {
      root: {
        color: fade("#81D3E6", 0.5) + " !important",
        backgroundColor: "#0C3754",
        border: "1px solid " + fade("#81D3E6", 0.1),
        "&:hover": {
          color: "#81D3E6 !important",
          backgroundColor: "#0C3754 !important",
          // border: '0.5px solid rgb(99, 255, 214)' //+ fade('#81D3E6', 0.5),
        },
        "&.Mui-selected": {
          cursor: "auto",
          // pointerEvents: 'none',
          color: "rgba(99, 255, 214, 1) !important",
          backgroundColor: "rgba(45, 90, 115, 0.7)", // 'rgba(99, 255, 214, 0.2)',
          "&:hover": {
            backgroundColor: "rgba(45, 90, 115, 1) !important", //'rgba(99, 255, 214, 0.5) !important'
          },
        },
      },
      sizeSmall: {
        height: "37px",
        padding: "4px",
        fontSize: "0.8125rem",
        // marginTop: '6px',
        color: "#81D3E6", //primary.main
      },
    },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        // '&:not(:first-child)': {
        "&:hover": {
          // border: '0.5px solid rgb(99, 255, 214)'
          boxShadow: "0px 0px 0px 1px rgb(99, 255, 214) inset",
        },
        // }
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: "rgba(129, 211, 230,0.2)",
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: "7px",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        color: "#0F4162", //primary.contrastText
        backgroundColor: "#81D3E6", //primary.main
      },
      popper: {
        opacity: 0.8,
      },
    },
    MuiTab: {
      root: {
        minWidth: "100px!important",
        fontWeight: "400",
      },
      wrapper: {
        alignItems: "self-start",
        justifyContent: "left",
      },
    },
    MuiInput: {
      root: {
        marginRight: "5px",
      },
    },

    MuiSelect: {
      select: {
        paddingLeft: "5px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "131px",
        fontSize: "13px",
        "&$select": {
          paddingLeft: " 0.5rem",
          paddingRight: "1rem",
        },
        "&$focus": {
          paddingRight: "1rem",
        },
        "&:focus": {
          // color: 'red',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          // maxWidth: '50px',
        },
      },
      outlined: {
        "&$outlined": {
          backgroundColor: "rgba(129, 211, 230, 0.2)",
          paddingLeft: "0.8rem",
        },
      },
      icon: {
        top: "calc(50 % - 9px)",
        color: "rgba(99, 255, 214, 1)",
        right: "0",
        position: "absolute",
        pointerEvents: "none",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "center",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "90%",
      },
    },
  },
  breakpoints: breakpoints,
});

export const LightTheme = createMuiTheme({
  palette: {
    type: "light",
    tour: {
      background: "#e1c5dd",
      text: "#3d0042",
    },
    primary: {
      darkest: "#3d0042", //'#4B0052',
      main: "#5D0066",
      light: "rgb(142 78 149)", //'rgb(207, 180, 210)',
      contrastText: "#fff",
      contrastTextLight: "#fff",
    },
    secondary: {
      main: "#5D0066",
      contrastText: "#fff", //'#0F4162',
    },
    text: {
      secondary: "rgba(93, 0, 102, 1)", //'rgb(207, 180, 210)',
      default: "rgba(0, 0, 0, 0.8)",
      primary: "rgba(93, 0, 102, 1)",
      // secondary: 'rgba(255, 255, 255, 0.54)',
      disabled: "rgba(0, 0, 0, 0.58)",
      hint: "rgba(0, 0, 0, 0.1)",
    },
    divider: "rgba(255, 255, 255, 255.12)",
    background: {
      paper: "#fff",
      paperDark: "#eee",
      paperDarkest: "#D9B0D1",
      paperSecondary: "rgba(233,233,233,0.75)",
      default: "#fafafa",
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px",
        "&:hover": {
          boxShadow: "0px 0px 0px 1px rgba(93, 0, 102, 1) inset",
          // backgroundColor: 'rgba(99, 255, 214, 0.2)' //text.primary
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "1.2rem",
        height: "1.2rem",
      },
    },
    MuiToggleButton: {
      root: {
        color: "rgba(255, 255, 255, 0.5) !important",
        backgroundColor: "rgb(207, 180, 210)",
        "&:hover": {
          // border: 'none !important',
          color: "rgba(93, 0, 102, 0.7) !important",
          backgroundColor: "rgb(207, 180, 210)!important",
        },
        "&.Mui-selected": {
          cursor: "auto",
          // pointerEvents: 'none',
          color: "rgba(255, 255, 255, 1) !important",
          backgroundColor: "rgba(93, 0, 102, 0.7)", // 'rgba(99, 255, 214, 0.2)',
          "&:hover": {
            backgroundColor: "rgba(93, 0, 102, 0.7) !important", //'rgba(99, 255, 214, 0.5) !important'
          },
        },
      },
      sizeSmall: {
        height: "37px",
        padding: "4px",
        fontSize: "0.8125rem",
        // marginTop: '6px',
        color: "#81D3E6", //primary.main
      },
    },

    // MuiToggleButton: {
    //     sizeSmall: {
    //         height: '37px',
    //         padding: '4px',
    //         fontSize: '0.8125rem',
    //         marginTop: '6px',
    //         color: '#fafafa'
    //     }
    // },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        // '&:not(:first-child)': {
        "&:hover": {
          border: "0.5px solid rgba(93, 0, 102,1)",
          // boxShadow: '0px 0px 0px 1px rgb(93, 0, 102) inset'
        },
        // }
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: "rgba(129, 211, 230,0.2)",
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: "7px",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        color: "#fff", //primary.contrastText
        backgroundColor: "#5D0066", //primary.main
      },
    },
    MuiTab: {
      root: {
        minWidth: "100px!important",
        fontWeight: "400",
      },
      wrapper: {
        alignItems: "self-start",
        justifyContent: "left",
      },
    },
    MuiInput: {
      root: {
        marginRight: "5px",
      },
    },

    MuiSelect: {
      select: {
        color: "#5D0066",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        paddingLeft: "5px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "131px",
        fontSize: "13px",
        "&$select": {
          paddingLeft: " 0.5rem",
          paddingRight: "1rem",
        },
        "&$focus": {
          paddingRight: "1rem",
        },
        "&:focus": {
          color: "#5D0066",
          backgroundColor: "rgba(93, 0, 102, 0.2)",
          // color: 'red',
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          // maxWidth: '50px',
        },
      },
      outlined: {
        "&$outlined": {
          color: "#5D0066",
          backgroundColor: "rgba(93, 0, 102, 0.2)",
          paddingLeft: "0.8rem",
        },
      },
      icon: {
        top: "calc(50% - 9px)",
        color: "rgba(93, 0, 102, 0.8)",
        right: "0",
        position: "absolute",
        pointerEvents: "none",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "center",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "90%",
      },
    },
  },
  breakpoints: breakpoints,
});
