import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import * as _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    chipRoot: {
        margin: '0px',
        fontSize: '0.625rem',
        fontWeight: 500,
        opacity: 0.9,
        // background: theme.palette.secondary.default,
        color: theme.palette.secondary.contrastText,// theme.palette.text.default,
        zIndex: 100,
        background: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.darkest,
            // color: theme.palette.text.default,
            opacity: 1
        },
        '&:focus': {
            // color: theme.palette.secondary.dark,
            borderColor: theme.palette.secondary.contrastText,
            opacity: 1
        }
    },
    chip: {
        marginLeft: '0px'
    },

    outline: {
        color: theme.palette.primary.contrastText
    },

    clickable: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.secondary.contrastText,
        },

    }
}))


export default function HistoLegendX({ xoff, cellWidth, binGap, bins, filterBy, legend, inFocus, setFocusHandler, inSelect, setSelectionHandler }) {

    const classes = useStyles();
    const [chipData, setChipData] = React.useState([]);

    useEffect(() => {
        setChipData(bins)
        return () => {
            setChipData([])
        }
    }, [bins])

    const handleLegendClick = (ids) => (event) => {
        setSelectionHandler(ids, 'histo-legend', 'reset')
        event.stopPropagation();
    }

    return (
        <div className="histo-legend-x" style={{ paddingLeft: `${xoff}px` }}>
            {chipData.map((data, i) => {
                const ids = data.groups[filterBy] //_.map(data, elem => elem.id)
                const isInSelection = id => inSelect.ids.includes(id)
                const opac = (inSelect.ids.length !== 0) && (_.filter(ids, id => isInSelection(id)).length === 0) ? 0.3 : 1
                return (
                    <li key={i} className={classes.chip} style={{ opacity: `${opac}`, width: `${cellWidth}px`, paddingRight: `${binGap}px`, marginLeft: `${0}px` }}>
                        <Chip
                            classes={{ root: classes.chipRoot, outlined: classes.outline, clickable: classes.clickable }}
                            size="small"
                            // variant="outlined"
                            label={`${data.descr}`}
                            onMouseEnter={() => setFocusHandler(ids, 'histo-legend', 'reset')}
                            onMouseLeave={() => setFocusHandler([], 'histo-legend', 'reset')}
                            onClick={handleLegendClick(ids)}
                        />
                    </li>
                );
            })}
        </div>
    );
}
