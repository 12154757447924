export const stateLatLngData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        description: "All India",
        type: "group",
      },
      geometry: {
        type: "Point",
        coordinates: [84, 15.7],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "All India (except Part B States)",
        type: "group",
      },
      geometry: {
        type: "Point",
        coordinates: [84, 12.5],
      },
    },
    // {
    //     'type': 'Feature',
    //     'properties': {
    //         'description': "All India (except Assam/Tripura/West Bengal/Manipur/Jammu and Kashmir)",
    //         'type': 'group'
    //     },
    //     'geometry': {
    //         'type': 'Point',
    //         'coordinates': [84, 14]
    //     }
    // },
    {
      type: "Feature",
      properties: {
        description: "All India (except Jammu and Kashmir)",
        type: "group",
      },
      geometry: {
        type: "Point",
        coordinates: [84, 14],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "All India (except Part B States and Jammu and Kashmir)",
        type: "group",
      },
      geometry: {
        type: "Point",
        coordinates: [84, 11],
      },
    },
    //----------------------------
    {
      type: "Feature",
      properties: {
        description: "Andhra Pradesh",
      },
      geometry: {
        type: "Point",
        coordinates: [78.5, 15.988],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Arunachal Pradesh",
      },
      geometry: {
        type: "Point",
        coordinates: [94.1, 29.0],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Assam",
      },
      geometry: {
        type: "Point",
        coordinates: [92.0, 26.5],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Bihar",
      },
      geometry: {
        type: "Point",
        coordinates: [85.8, 26.0],
      },
    },
    // {
    //     'type': 'Feature',
    //     'properties': {
    //         'description': "Chandigarh"
    //     },
    //     'geometry': {
    //         'type': 'Point',
    //         'coordinates': [76.78000565, 30.71999697]
    //     }
    // },
    {
      type: "Feature",
      properties: {
        description: "Chhattisgarh",
      },
      geometry: {
        type: "Point",
        coordinates: [81.0, 21.925],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Dadra and Nagar Haveli",
        type: "UT",
      },
      geometry: {
        type: "Point",
        coordinates: [72.5, 21.0],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Daman and Diu",
      },
      geometry: {
        type: "Point",
        coordinates: [70.5, 22.0],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Delhi",
      },
      geometry: {
        type: "Point",
        coordinates: [77.0, 28.8],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Goa",
      },
      geometry: {
        type: "Point",
        coordinates: [74.01800065, 15.31997],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Gujarat",
      },
      geometry: {
        type: "Point",
        coordinates: [71.05, 23.727],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Haryana",
      },
      geometry: {
        type: "Point",
        coordinates: [75.1, 29.75],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Himachal Pradesh",
      },
      geometry: {
        type: "Point",
        coordinates: [76.6, 32.4],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Jammu and Kashmir",
      },
      geometry: {
        type: "Point",
        coordinates: [76.0, 35],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Jharkhand",
      },
      geometry: {
        type: "Point",
        coordinates: [84.0, 23.7],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Karnataka",
      },
      geometry: {
        type: "Point",
        coordinates: [75.0, 13.934],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Kerala",
      },
      geometry: {
        type: "Point",
        coordinates: [76.222, 10.55],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Ladakh",
        type: "UT",
      },
      geometry: {
        type: "Point",
        coordinates: [77.0, 34.5],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Lakshadweep",
        type: "UT",
      },
      geometry: {
        type: "Point",
        coordinates: [72.63686717, 10.56257331],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Madhya Pradesh",
      },
      geometry: {
        type: "Point",
        coordinates: [78.027, 24.0],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Maharashtra",
      },
      geometry: {
        type: "Point",
        coordinates: [74.5, 20.2],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Manipur",
      },
      geometry: {
        type: "Point",
        coordinates: [93.0, 24.8],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Meghalaya",
      },
      geometry: {
        type: "Point",
        coordinates: [90.0, 25.8],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Mizoram",
      },
      geometry: {
        type: "Point",
        coordinates: [92.0, 23.5],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Nagaland",
      },
      geometry: {
        type: "Point",
        coordinates: [94.0, 26.0],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Odisha",
      },
      geometry: {
        type: "Point",
        coordinates: [84.05, 20.5],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Puducherry",
        type: "UT",
      },
      geometry: {
        type: "Point",
        coordinates: [79.75, 12.2],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Punjab",
      },
      geometry: {
        type: "Point",
        coordinates: [75.069, 31.037],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Rajasthan",
      },
      geometry: {
        type: "Point",
        coordinates: [72.0, 27.0],
      },
    },
    // {
    //     'type': 'Feature',
    //     'properties': {
    //         'description': "Rajasthan (Ajmer)"
    //     },
    //     'geometry': {
    //         'type': 'Point',
    //         'coordinates': [72.63998124, 26.44999921]
    //     }
    // },
    {
      type: "Feature",
      properties: {
        description: "Sikkim",
      },
      geometry: {
        type: "Point",
        coordinates: [88.1, 27.8],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Tamil Nadu",
      },
      geometry: {
        type: "Point",
        coordinates: [78.0, 11.85],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Telangana",
      },
      geometry: {
        type: "Point",
        coordinates: [78.0, 18.3],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Tripura",
      },
      geometry: {
        type: "Point",
        coordinates: [91.2, 24.1],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Uttar Pradesh",
      },
      geometry: {
        type: "Point",
        coordinates: [80.0, 28.0],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "Uttarakhand",
      },
      geometry: {
        type: "Point",
        coordinates: [78.2, 30.5],
      },
    },
    {
      type: "Feature",
      properties: {
        description: "West Bengal",
      },
      geometry: {
        type: "Point",
        coordinates: [87.5, 23.75],
      },
    },
  ],
};
