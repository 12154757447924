import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Fade } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    tooltip: {
        opacity: 0.8,
        zIndex: 1500,
        pointerEvents: 'none'
    },
    tooltipNone: {
        opacity: 0
    }
}))

export default function TooltipMouse(props) {
    const [position, setPosition] = useState({ x: undefined, y: undefined });
    const classes = useStyles();
    return <Tooltip
        classes={{ popper: position.x ? classes.tooltip : classes.tooltipNone }}
        title={props.title}
        placement={props.placement}
        // enterDelay={300}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
        onMouseMove={e => setPosition({ x: e.pageX, y: e.pageY })}
        // onMouseClick={e => setPosition({ x: e.pageX, y: e.pageY })}
        disableFocusListener={false}
        PopperProps={{
            // open: true,
            anchorEl: {
                clientHeight: 0,
                clientWidth: 0,
                getBoundingClientRect: () => ({
                    top: position.y,
                    left: position.x,
                    right: position.x,
                    bottom: position.y,
                    width: 0,
                    height: 0,
                })
            }
        }
        }
    >
        {props.children}
    </Tooltip >
}