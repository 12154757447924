import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import * as _ from "lodash";
import SearchBar from "./LeftPanel/SearchBar";
import FilterPanel from "./LeftPanel/FilterPanel";
import ViewPanel from "./ViewPanel/ViewPanel";
import { elemsData } from "../lawsdict";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import GuidedTour from "./ViewPanel/GuidedTour";
import queryString from "query-string";
import { useLocation } from "react-router";
import { Beforeunload } from "react-beforeunload";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => {
  // console.log("theme", theme.palette)
  return {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    paperTemp: {
      padding: theme.spacing(4),
      // marginTop: theme.spacing(16),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      display: "flex",
      flexFlow: "row",
    },

    leftPanel: {
      display: "flex",
      // width: '380px',
      flexGrow: 1,
      flexFlow: "column",
      minHeight: 0,
      background: theme.palette.background.paperDark,
      zIndex: 1000,

      [theme.breakpoints.down("sm")]: {
        width: "380px",
      },

      [theme.breakpoints.up("md")]: {
        flex: "0 0 400px",
      },

      [theme.breakpoints.up("lg")]: {
        flex: "0 0 480px",
      },
    },

    leftBlinkPanel: {
      position: "absolute",
      width: "380px",
      flexGrow: 1,
      flexFlow: "column",
      minHeight: 0,
      // background: theme.palette.background.paperDark,
      zIndex: 1010,
      height: "100%",

      [theme.breakpoints.down("sm")]: {
        width: "380px",
      },

      [theme.breakpoints.up("md")]: {
        width: "400px",
      },

      [theme.breakpoints.up("lg")]: {
        width: "480px",
      },
    },

    blinkOn: {
      // backgroundColor: theme.palette.background.paperDark,
      animation: `$fadeIn 1000ms`,
      // transition: 'all 300ms',
    },

    "@keyframes fadeIn": {
      "0%": {
        backgroundColor: theme.palette.background.paperDark,
      },
      "50%": {
        backgroundColor: fade(theme.palette.secondary.main, 0.3),
      },
      "100%": {
        backgroundColor: theme.palette.background.paperDark,
      },
    },
  };
});

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function MILLContainer({ theme, setThemeHandler }) {
  var winSize = useWindowSize();
  const isMobile = winSize[0] <= 768;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const showTourAfterIntro = "tour" in qs ? qs["tour"] === "true" : true;
  const showIntroAtStart = "intro" in qs ? qs["intro"] === "true" : true;

  const [elems, setElems] = useState(() => elemsData);

  const [isTourOpen, setIsTourOpen] = useState(false);
  const toggleTourStateHandler = () => {
    console.log(
      "toggle current tour state (" + isTourOpen + ") to " + !isTourOpen
    );
    setIsTourOpen((prev) => !prev);
  };

  const [inSelect, setInSelect] = useState({
    ids: [],
    source: null,
    strat: "intersect",
  });

  const [inFocus, setInFocus] = useState({
    ids: [],
    source: null,
    strat: "intersect",
  });

  const [UIOptions, setUIOptions] = useState({
    showViews: true,
  });

  const timeoutRefX = useRef(null);
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    setBlink(true);
    if (timeoutRefX.current !== null) {
      clearTimeout(timeoutRefX.current);
    }
    timeoutRefX.current = setTimeout(() => {
      timeoutRefX.current = null;
      setBlink(false);
    }, 400);
  }, [inSelect]);

  const setSelectionHandler = (ids, source, strat = "intersect") => {
    // console.log("[MILL].inSelect change", source)
    ids = _.uniq(ids);
    // console.log(ids)
    let newIds;
    if (strat === "intersect") {
      // selection intersect
      var ix = inSelect.ids.filter((x) => ids.includes(x));
      // console.log("[MILL].inSelect.intersect ix length", ix.length)
      newIds = ix.length === 0 ? ids : ix;
    } else {
      // selection reset
      // console.log("[MILL].inSelect.no-intersect", ids.length)
      newIds = ids;
    }

    if (ids.length === _.keys(elems).length) {
      // if everything is selected, remove selection
      setInSelect({ ids: [], source: source, strat: "reset" });
    } else {
      // else select
      setInSelect({ ids: newIds, source: source, strat: strat });
    }
  };

  const setFocusHandler = (ids, source, strat = "intersect") => {
    // console.log('mill-focus-handlers', ids, source, strat)
    setInFocus({ ids: ids, source: source, strat: strat });
  };

  const setShowViewHandler = (show) => {
    setUIOptions({ ...UIOptions, showViews: show });
  };

  const handleElemBlur = () => {
    // console.log('list-elem-blur', inFocus.ids)
    setFocusHandler([], "left-panel");
  };

  return (
    <>
      <BrowserView>
        <Beforeunload onBeforeunload={() => "Are you sure?"} />
        <Paper name="container" square className={classes.paperRoot}>
          <div
            name="left-panel"
            className={classes.leftPanel}
            onMouseLeave={() => handleElemBlur()}
          >
            <SearchBar
              name="search-bar"
              elems={elems}
              inSelect={inSelect}
              setSelectionHandler={setSelectionHandler}
            />
            <FilterPanel
              name="filter-panel"
              elems={elems}
              inSelect={inSelect}
              inFocus={inFocus}
              setFocusHandler={setFocusHandler}
              setSelectionHandler={setSelectionHandler}
              setShowViewHandler={setShowViewHandler}
            />
          </div>
          <ViewPanel
            name="view-panel"
            elems={elems}
            inSelect={inSelect}
            inFocus={inFocus}
            theme={theme}
            showIntroAtStart={showIntroAtStart}
            showTourAfterIntro={showTourAfterIntro}
            UIOptions={UIOptions}
            toggleTourStateHandler={toggleTourStateHandler}
            setFocusHandler={setFocusHandler}
            setSelectionHandler={setSelectionHandler}
            setThemeHandler={setThemeHandler}
          />
          <GuidedTour isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />
        </Paper>
      </BrowserView>
      <MobileView>
        {/* <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={6} style={isBrowser ? { maxWidth: '50%' } : { maxWidth: '95%' }}>
                        <Box mb={3} display="flex" justifyContent="center" alignItems="center" >
                            <Typography className={classes.copytext} variant="caption" display="block">
                                Thank you for visiting landlawsofindia.org!
                            </Typography>
                        </Box>
                        <Paper elevation={3} className={classes.paperTemp}>
                            <Typography variant='subtitle1'>
                                This site is designed for larger screens. Mobile version will be launched soon. Please use a desktop or a laptop to explore this site.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid> */}
        <Paper name="container" square className={classes.paperRoot}>
          <div
            name="left-panel"
            className={classes.leftPanel}
            onMouseLeave={() => handleElemBlur()}
          >
            <SearchBar
              name="search-bar"
              elems={elems}
              inSelect={inSelect}
              setSelectionHandler={setSelectionHandler}
              showIntroAtStart={showIntroAtStart}
              showTourAfterIntro={showTourAfterIntro}
              toggleTourStateHandler={toggleTourStateHandler}
            />
            <FilterPanel
              name="filter-panel"
              elems={elems}
              inSelect={inSelect}
              inFocus={inFocus}
              setFocusHandler={setFocusHandler}
              setSelectionHandler={setSelectionHandler}
              setShowViewHandler={setShowViewHandler}
            />
          </div>
          <ViewPanel
            name="view-panel"
            elems={elems}
            inSelect={inSelect}
            inFocus={inFocus}
            theme={theme}
            showIntroAtStart={showIntroAtStart}
            showTourAfterIntro={showTourAfterIntro}
            UIOptions={UIOptions}
            toggleTourStateHandler={toggleTourStateHandler}
            setFocusHandler={setFocusHandler}
            setSelectionHandler={setSelectionHandler}
            setThemeHandler={setThemeHandler}
          />
        </Paper>
      </MobileView>
    </>
  );
}
