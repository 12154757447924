import React, { useState, useEffect, useLayoutEffect } from 'react';
import { SizeMe } from 'react-sizeme'
import AutoSizer from "react-virtualized-auto-sizer";
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Chip from '@material-ui/core/Chip';
import { Document, Page, pdfjs } from "react-pdf";
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles((theme) => ({

    docContainerRoot: {
        background: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexFlow: 'row'
    },

    docViewPanel: {
        display: 'flex',
        background: 'white',
        flexGrow: 1,
        flexShrink: 2,
        flexFlow: 'column',
        padding: '0px',
        marginTop: '0px',
        textAlign: 'center',
        position: 'relative',
        minHeight: '100px',
        '-webkit-animation': 'fadein 2s', /* Safari, Chrome and Opera > 12.1 */
        '-moz-animation': 'fadein 2s', /* Firefox < 16 */
        '-ms-animation': 'fadein 2s', /* Internet Explorer */
        '-o-animation': 'fadein 2s', /* Opera < 12.1 */
        'animation': 'fadein 2s',
    },

    docViewPDF: {
        display: 'flex',
        overflowY: 'scroll'
    },

    docViewPDFEmpty: {
        display: 'flex',
        overflowY: 'scroll',
        height: '100%',
        backgroundColor: 'white'
    },

    docViewFooter: {
        display: 'flex',
        flexFlow: 'row',
        // flexGrow: 1,
        maxHeight: '58px',
        padding: '10px',
        background: theme.palette.background.paperDark,

        '& .left': {
            // border: '1px blue solid',
            width: '160px'
        },

        '& .mid': {
            // border: '1px green solid',
            display: 'flex'
        },

        '& .right': {
            color: theme.palette.primary.contrastTextLight,
            // fontSize: '0.625rem',
            maxWidth: '140px',
            // border: '1px red solid',
            // flex: '0 0 100px',
            marginRight: '10px !important'
        },

        '& .cancelBtn': {
            color: theme.palette.primary.contrastTextLight + ' !important',
            fontSize: '11px',
            maxWidth: '140px'
        },

        '& .pagination': {
            // paddingTop: '20px'
        }
    },
}))

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function DocView({ open, handleDocClose, elem, url }) {
    var winSize = useWindowSize()
    const classes = useStyles();
    const gt1200 = useMediaQuery('(min-width:1280px)');
    const gt980 = useMediaQuery('(min-width:960px)');

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [docType, setDocType] = useState(null);

    const inferDocType = (elem) => {
        let docType = null
        let uploadStatus = elem.UploadReadyStatus.toLowerCase()
        if (uploadStatus.includes('http') || uploadStatus.includes('www')) {
            docType = 'weblink'
            console.log(docType, elem.UploadReadyStatus)
        } else if (uploadStatus.includes('upload')) {
            docType = 'pdf'
            console.log(docType, elem.SourceFileName)
        } else if (uploadStatus.includes('manupatra')) {
            docType = 'manu'
            console.log(docType, elem.UploadReadyStatus)
        } else {
            docType = 'not-available'
            console.log(docType, elem.UploadReadyStatus)
        }

        return docType
    }

    useEffect(() => {
        // console.log("[docview].change: resetting pagenums", numPages, pageNumber)
        // console.log(`pdf/1986AP0${elem.id % 5}.pdf`)
        // console.log(elem.UploadReadyStatus)
        let docType = inferDocType(elem)
        setDocType(docType)
        console.log(docType)
        // if (docType === 'weblink') {
        //     console.log(elem.SourceFileName)
        // }

        setNumPages(null)
        setPageNumber(1)
    }, [elem.id])

    useEffect(() => {
        return () => {
            console.log("[docview].change: resetting pagenums")
            setDocType(null)
            setNumPages(null)
            setPageNumber(1)
        }
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };

    const handleClose = (event, value) => {
        // console.log(event, value)
        setPageNumber(1)
        setNumPages(null)
        handleDocClose(elem.id)
    }

    const containerLeft = gt1200 ? { left: 481, right: 0 } : gt980 ? { left: 401, right: 0 } : { left: 0, right: 0 }

    console.log(url)
    console.log(elem)

    return (
        <Box className={classes.docContainerRoot} style={containerLeft}>
            <Box className={classes.docViewPanel}>
                <SizeMe monitorHeight>
                    {({ size }) => {
                        console.log('size:', size.width, size.height)
                        return (
                            docType === 'pdf'
                                ? <div key={docType + '-div'} id="pdf-canvas" className={(numPages !== null) ? classes.docViewPDF : classes.docViewPDFEmpty}>
                                    <Document key={docType + '-doc'} file={url} onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page pageNumber={pageNumber} width={size.width ? size.width - containerLeft : 1} />
                                    </Document>
                                </div>
                                : docType === 'weblink'
                                    ? <div>
                                        {/* <a href={elem.UploadReadyStatus} target="_blank">weblink</a> */}
                                        <iframe src={elem.UploadReadyStatus} width={size.width} height={winSize[1] - 58} ></iframe>
                                    </div>
                                    : <div>{elem.UploadReadyStatus}</div>

                        )
                    }}
                </SizeMe>
                <Box flexGrow={1} className={classes.docViewFooter}>
                    <Box className={"mid"} flexGrow={1} justifyContent="center" alignItems="center" alignContent="center">
                        {
                            numPages > 1
                                ? <Pagination className={"pagination"} count={numPages} page={pageNumber} siblingCount={1} size={gt1200 ? 'large' : gt980 ? 'medium' : 'small'} onChange={handlePageChange} />
                                : null
                        }
                    </Box>
                    <Chip
                        className={"right"}
                        size="medium"
                        label={"close document"}
                        clickable
                        color="secondary"
                        onClick={handleClose}
                        onDelete={handleClose}
                        deleteIcon={<CancelIcon className={"cancelBtn"} />}
                    />
                    {/* <Button color='secondary' variant='contained' size="small" className={"right"} onClick={handleClose}>
                        Close
                    </Button> */}
                </Box>
            </Box>
        </Box >


    );
}